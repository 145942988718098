export const ROUTES_NAMES = {
  home: 'index',
  login: 'auth-login',
  signup: 'auth-signup',
  signupInvitation: 'auth-invitation',
  forgotPassword: 'auth-forgot-password',
  resetPassword: 'auth-reset-password',
  buildingPage: 'buildings-id',
  presentationBuildingPage: 'p-b-ids',
  presentationBuildingPageFromSuggestion: 'p-s-ids',
  market: 'market',
  suggestions: 'suggestions',
  suggestionPage: 'suggestions-uniqueId',
  notFound: 'error/not-found',
  forbidden: 'error/forbidden',
  suggestionsManagement: 'suggestions-management',
  clients: 'clients',
  changeList: 'changelist',
  developerPage: 'developers-id',
  profile: 'profile',
  profileDelete: 'profile-delete',
  settings: 'settings',
  journal: 'journal',
  journalNews: 'journal-slug',
  events: 'events',
  privatePolicy: 'private-policy',
  licenseAgreement: 'license-agreement',
} as const
