export const COLOR = {
  primary: 'primary',
  success: 'success',
  danger: 'danger',
  warning: 'warning',
  secondary: 'secondary',
  info: 'info',
  light: 'light',
  dropdown: 'dropdown',
  none: 'none',
  neutral: 'neutral',
  transparent: 'transparent',
  negative: 'negative',
} as const
