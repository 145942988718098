<template>
  <custom-button
    class="text-sm font-semibold !rounded-[19px] flex"
    color="transparent"
    @click="getDecorations"
  >
    <nuxt-icon
      name="entities/decoration"
      class="text-base"
    />
    <span class="ml-1">{{ $t('building.actions.layout.label') }}</span>
  </custom-button>
  <modal-or-bottom-sheet
    v-model="isModalOpen"
    size="lg"
    :drawer-props="{fullHeight: true}"
    :modal-props="{size: 'w-full max-w-[1000px]', contentClass: 'min-h-[570px]', bodyClass: isFetching ? 'flex justify-center items-center' : '', centered: false, hideFooter: true}"
  >
    <template #header>
      <h2 class="font-semibold  text-neutral-900 text-2xl">
        {{ $t('flat.decorationType.label') }}
      </h2>
    </template>
    <div
      v-if="isFetching"
      class="grow flex justify-center items-center"
    >
      <custom-cube-spinner />
    </div>
    <building-page-decorations
      v-else-if="!isDecorationsEmpty && expandedBuilding"
      :is-header-visible="false"
      :building="expandedBuilding"
    />
    <div v-else>
      {{ $t('flat.decorationType.emptyDecoration') }}
    </div>
  </modal-or-bottom-sheet>
</template>
<script setup lang="ts">
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import CustomButton from '~/ui/buttons/CustomButton.vue'
import { BuildingService } from '~/modules/building/services/BuildingService'
import { BuildingExpanded } from '~/modules/building/types/Building.expanded'
import ModalOrBottomSheet from '~/components/common/ModalOrBottomSheet.vue'
import CustomCubeSpinner from '~/ui/spinners/CustomCubeSpinner.vue'
import BuildingPageDecorations from '~/modules/building/components/page/decorations/BuildingPageDecorations.vue'
import { useUserStore } from '~/modules/user/store'

const props = defineProps({
  buildingId: {
    type: Number,
    required: true,
  },
  building: {
    type: Object as PropType<BuildingExpanded | null>,
    default: null,
  },
})

const userStore = useUserStore()

const service = new BuildingService()

const expandedBuilding = ref<BuildingExpanded | null>(props.building)

const isFetching = ref(false)

const isModalOpen = ref(false)

const isDecorationsEmpty = computed(() => (expandedBuilding.value ? !expandedBuilding.value.houses.some(house => house.decorations.length > 0) : null))

const getDecorations = () => {
  isModalOpen.value = true
  if (expandedBuilding.value) {
    return
  }
  let promise: Promise<BuildingExpanded> | null = null

  if (userStore.user?.id) {
    promise = service.getBuilding(props.buildingId)
  } else {
    promise = service.getPresentationBuilding(props.buildingId)
  }

  isFetching.value = true

  promise.then(buildingData => {
    expandedBuilding.value = buildingData
  })
    .finally(() => {
      isFetching.value = false
    })
}

</script>
