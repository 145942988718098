import { defineStore } from 'pinia'
import { Suggestion } from '~/modules/suggestions/types/Suggestion'

type SuggestionsState = {
  suggestion: Suggestion,
  suggestionId: string,
}
export const useSuggestionsStore = defineStore('suggestionsStore', {
  state: () => ({
    suggestion: {},
    suggestionId: '',
  }) as SuggestionsState,
  actions: {
    setSuggestion(suggestion: Suggestion):void {
      this.suggestion = suggestion
    },
    setSuggestionId(suggestionId: string):void {
      this.suggestionId = suggestionId
    },
  },
})
