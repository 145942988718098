<template>
  <modal-or-bottom-sheet
    v-model="isModalVisible"
    :title="`${$t('clients.fixation.fixationTerms.title')} ${developer.name}`"
    :drawer-props="{fullHeight: true, hideHeader: false}"
  >
    <div class="flex flex-col gap-y-4 font-semibold">
      <div
        v-if="developer.clientFixationTerms?.durationInDays"
        class="flex justify-between"
      >
        <div class="text-gray-500">
          {{ $t('clients.fixation.fixationTerms.durationInDays') }}
        </div>
        <div class="text-right">
          {{ $t('common.terms.day', developer.clientFixationTerms.durationInDays) }}
        </div>
      </div>
      <div
        v-if="developer.clientFixationTerms?.clientsData"
        class="flex justify-between"
      >
        <div class="text-gray-500">
          {{ $t('clients.fixation.fixationTerms.clientsData') }}
        </div>
        <div class="text-right">
          {{ developer.clientFixationTerms.clientsData }}
        </div>
      </div>
      <div v-if="developer.clientFixationTerms?.additionalInfo">
        <div class="text-gray-500">
          {{ $t('clients.fixation.fixationTerms.additionalInfo') }}
        </div>
        <div>
          {{ developer.clientFixationTerms.additionalInfo }}
        </div>
      </div>
    </div>
  </modal-or-bottom-sheet>
</template>
<script setup lang="ts">
import ModalOrBottomSheet from '~/components/common/ModalOrBottomSheet.vue'
import type { Developer } from '~/common/types/developer/Developer'
import type { PropType } from 'vue'

const props = defineProps({
  developer: {
    type: Object as PropType<Developer>,
    required: true,
  },
  isVisible: {
    type: Boolean,
    default: false,
  },
})
const emits = defineEmits<{(e: 'update:isVisible', val: boolean): void}>()

const isModalVisible = computed({
  get: () => props.isVisible,
  set: (value: boolean) => emits('update:isVisible', value),
})
</script>
