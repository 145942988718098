import { default as forgot_45passwordxkPB7k1jvUMeta } from "/var/www/quick-deal/qd.estate-market/pages/auth/forgot-password.vue?macro=true";
import { default as invitationqCbp8iyR8wMeta } from "/var/www/quick-deal/qd.estate-market/pages/auth/invitation.vue?macro=true";
import { default as loginuxIpu1wUqYMeta } from "/var/www/quick-deal/qd.estate-market/pages/auth/login.vue?macro=true";
import { default as reset_45passwordJICxfKaqqqMeta } from "/var/www/quick-deal/qd.estate-market/pages/auth/reset-password.vue?macro=true";
import { default as signupeTv3A19DTTMeta } from "/var/www/quick-deal/qd.estate-market/pages/auth/signup.vue?macro=true";
import { default as _91id_93NXMhXDLH7PMeta } from "/var/www/quick-deal/qd.estate-market/pages/buildings/[id].vue?macro=true";
import { default as indexSGbdClnjHiMeta } from "/var/www/quick-deal/qd.estate-market/pages/changelist/index.vue?macro=true";
import { default as indexoU3jD6qZZDMeta } from "/var/www/quick-deal/qd.estate-market/pages/clients/index.vue?macro=true";
import { default as indexGYUaBV9tPvMeta } from "/var/www/quick-deal/qd.estate-market/pages/contacting-support/index.vue?macro=true";
import { default as _91id_93SsOr7MjQ8yMeta } from "/var/www/quick-deal/qd.estate-market/pages/developers/[id].vue?macro=true";
import { default as ErrorPageTemplateTUb7vefKDDMeta } from "/var/www/quick-deal/qd.estate-market/pages/error/ErrorPageTemplate.vue?macro=true";
import { default as forbiddenxGtloabZXOMeta } from "/var/www/quick-deal/qd.estate-market/pages/error/forbidden.vue?macro=true";
import { default as not_45foundH4BiBEAbPSMeta } from "/var/www/quick-deal/qd.estate-market/pages/error/not-found.vue?macro=true";
import { default as indexLx1e0M7oUhMeta } from "/var/www/quick-deal/qd.estate-market/pages/events/index.vue?macro=true";
import { default as index9BNDKe3DdIMeta } from "/var/www/quick-deal/qd.estate-market/pages/index.vue?macro=true";
import { default as _91slug_936cQZJsd5eeMeta } from "/var/www/quick-deal/qd.estate-market/pages/journal/[slug].vue?macro=true";
import { default as indexcY7k7zOwDpMeta } from "/var/www/quick-deal/qd.estate-market/pages/journal/index.vue?macro=true";
import { default as indexgIEdfx68MIMeta } from "/var/www/quick-deal/qd.estate-market/pages/license-agreement/index.vue?macro=true";
import { default as PageTemplate6MDMtuHmhmMeta } from "/var/www/quick-deal/qd.estate-market/pages/market/PageTemplate.vue?macro=true";
import { default as indexDsVqjzXemiMeta } from "/var/www/quick-deal/qd.estate-market/pages/market/index.vue?macro=true";
import { default as _91_46_46_46ids_93rLgqzDWsojMeta } from "/var/www/quick-deal/qd.estate-market/pages/p/b/[...ids].vue?macro=true";
import { default as _91_46_46_46ids_93m4L60Oh9NSMeta } from "/var/www/quick-deal/qd.estate-market/pages/p/s/[...ids].vue?macro=true";
import { default as index64K51bnnEUMeta } from "/var/www/quick-deal/qd.estate-market/pages/private-policy/index.vue?macro=true";
import { default as delete74wFOFo2qsMeta } from "/var/www/quick-deal/qd.estate-market/pages/profile/delete.vue?macro=true";
import { default as indexH5IbzlsoZQMeta } from "/var/www/quick-deal/qd.estate-market/pages/profile/index.vue?macro=true";
import { default as indexbKVxY0mD4vMeta } from "/var/www/quick-deal/qd.estate-market/pages/settings/index.vue?macro=true";
import { default as _91uniqueId_93Bpj33aBqp6Meta } from "/var/www/quick-deal/qd.estate-market/pages/suggestions/[uniqueId].vue?macro=true";
import { default as managementzchMCmVTVUMeta } from "/var/www/quick-deal/qd.estate-market/pages/suggestions/management.vue?macro=true";
export default [
  {
    name: forgot_45passwordxkPB7k1jvUMeta?.name ?? "auth-forgot-password",
    path: forgot_45passwordxkPB7k1jvUMeta?.path ?? "/auth/forgot-password",
    meta: forgot_45passwordxkPB7k1jvUMeta || {},
    alias: forgot_45passwordxkPB7k1jvUMeta?.alias || [],
    redirect: forgot_45passwordxkPB7k1jvUMeta?.redirect || undefined,
    component: () => import("/var/www/quick-deal/qd.estate-market/pages/auth/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: invitationqCbp8iyR8wMeta?.name ?? "auth-invitation",
    path: invitationqCbp8iyR8wMeta?.path ?? "/auth/invitation",
    meta: invitationqCbp8iyR8wMeta || {},
    alias: invitationqCbp8iyR8wMeta?.alias || [],
    redirect: invitationqCbp8iyR8wMeta?.redirect || undefined,
    component: () => import("/var/www/quick-deal/qd.estate-market/pages/auth/invitation.vue").then(m => m.default || m)
  },
  {
    name: loginuxIpu1wUqYMeta?.name ?? "auth-login",
    path: loginuxIpu1wUqYMeta?.path ?? "/auth/login",
    meta: loginuxIpu1wUqYMeta || {},
    alias: loginuxIpu1wUqYMeta?.alias || [],
    redirect: loginuxIpu1wUqYMeta?.redirect || undefined,
    component: () => import("/var/www/quick-deal/qd.estate-market/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordJICxfKaqqqMeta?.name ?? "auth-reset-password",
    path: reset_45passwordJICxfKaqqqMeta?.path ?? "/auth/reset-password",
    meta: reset_45passwordJICxfKaqqqMeta || {},
    alias: reset_45passwordJICxfKaqqqMeta?.alias || [],
    redirect: reset_45passwordJICxfKaqqqMeta?.redirect || undefined,
    component: () => import("/var/www/quick-deal/qd.estate-market/pages/auth/reset-password.vue").then(m => m.default || m)
  },
  {
    name: signupeTv3A19DTTMeta?.name ?? "auth-signup",
    path: signupeTv3A19DTTMeta?.path ?? "/auth/signup",
    meta: signupeTv3A19DTTMeta || {},
    alias: signupeTv3A19DTTMeta?.alias || [],
    redirect: signupeTv3A19DTTMeta?.redirect || undefined,
    component: () => import("/var/www/quick-deal/qd.estate-market/pages/auth/signup.vue").then(m => m.default || m)
  },
  {
    name: _91id_93NXMhXDLH7PMeta?.name ?? "buildings-id",
    path: _91id_93NXMhXDLH7PMeta?.path ?? "/buildings/:id()",
    meta: _91id_93NXMhXDLH7PMeta || {},
    alias: _91id_93NXMhXDLH7PMeta?.alias || [],
    redirect: _91id_93NXMhXDLH7PMeta?.redirect || undefined,
    component: () => import("/var/www/quick-deal/qd.estate-market/pages/buildings/[id].vue").then(m => m.default || m)
  },
  {
    name: indexSGbdClnjHiMeta?.name ?? "changelist",
    path: indexSGbdClnjHiMeta?.path ?? "/changelist",
    meta: indexSGbdClnjHiMeta || {},
    alias: indexSGbdClnjHiMeta?.alias || [],
    redirect: indexSGbdClnjHiMeta?.redirect || undefined,
    component: () => import("/var/www/quick-deal/qd.estate-market/pages/changelist/index.vue").then(m => m.default || m)
  },
  {
    name: indexoU3jD6qZZDMeta?.name ?? "clients",
    path: indexoU3jD6qZZDMeta?.path ?? "/clients",
    meta: indexoU3jD6qZZDMeta || {},
    alias: indexoU3jD6qZZDMeta?.alias || [],
    redirect: indexoU3jD6qZZDMeta?.redirect || undefined,
    component: () => import("/var/www/quick-deal/qd.estate-market/pages/clients/index.vue").then(m => m.default || m)
  },
  {
    name: indexGYUaBV9tPvMeta?.name ?? "contacting-support",
    path: indexGYUaBV9tPvMeta?.path ?? "/contacting-support",
    meta: indexGYUaBV9tPvMeta || {},
    alias: indexGYUaBV9tPvMeta?.alias || [],
    redirect: indexGYUaBV9tPvMeta?.redirect || undefined,
    component: () => import("/var/www/quick-deal/qd.estate-market/pages/contacting-support/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93SsOr7MjQ8yMeta?.name ?? "developers-id",
    path: _91id_93SsOr7MjQ8yMeta?.path ?? "/developers/:id()",
    meta: _91id_93SsOr7MjQ8yMeta || {},
    alias: _91id_93SsOr7MjQ8yMeta?.alias || [],
    redirect: _91id_93SsOr7MjQ8yMeta?.redirect || undefined,
    component: () => import("/var/www/quick-deal/qd.estate-market/pages/developers/[id].vue").then(m => m.default || m)
  },
  {
    name: ErrorPageTemplateTUb7vefKDDMeta?.name ?? "error-ErrorPageTemplate",
    path: ErrorPageTemplateTUb7vefKDDMeta?.path ?? "/error/ErrorPageTemplate",
    meta: ErrorPageTemplateTUb7vefKDDMeta || {},
    alias: ErrorPageTemplateTUb7vefKDDMeta?.alias || [],
    redirect: ErrorPageTemplateTUb7vefKDDMeta?.redirect || undefined,
    component: () => import("/var/www/quick-deal/qd.estate-market/pages/error/ErrorPageTemplate.vue").then(m => m.default || m)
  },
  {
    name: forbiddenxGtloabZXOMeta?.name ?? "error-forbidden",
    path: forbiddenxGtloabZXOMeta?.path ?? "/error/forbidden",
    meta: forbiddenxGtloabZXOMeta || {},
    alias: forbiddenxGtloabZXOMeta?.alias || [],
    redirect: forbiddenxGtloabZXOMeta?.redirect || undefined,
    component: () => import("/var/www/quick-deal/qd.estate-market/pages/error/forbidden.vue").then(m => m.default || m)
  },
  {
    name: not_45foundH4BiBEAbPSMeta?.name ?? "error-not-found",
    path: not_45foundH4BiBEAbPSMeta?.path ?? "/error/not-found",
    meta: not_45foundH4BiBEAbPSMeta || {},
    alias: not_45foundH4BiBEAbPSMeta?.alias || [],
    redirect: not_45foundH4BiBEAbPSMeta?.redirect || undefined,
    component: () => import("/var/www/quick-deal/qd.estate-market/pages/error/not-found.vue").then(m => m.default || m)
  },
  {
    name: indexLx1e0M7oUhMeta?.name ?? "events",
    path: indexLx1e0M7oUhMeta?.path ?? "/events",
    meta: indexLx1e0M7oUhMeta || {},
    alias: indexLx1e0M7oUhMeta?.alias || [],
    redirect: indexLx1e0M7oUhMeta?.redirect || undefined,
    component: () => import("/var/www/quick-deal/qd.estate-market/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: index9BNDKe3DdIMeta?.name ?? "index",
    path: index9BNDKe3DdIMeta?.path ?? "/",
    meta: index9BNDKe3DdIMeta || {},
    alias: index9BNDKe3DdIMeta?.alias || [],
    redirect: index9BNDKe3DdIMeta?.redirect || undefined,
    component: () => import("/var/www/quick-deal/qd.estate-market/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_936cQZJsd5eeMeta?.name ?? "journal-slug",
    path: _91slug_936cQZJsd5eeMeta?.path ?? "/journal/:slug()",
    meta: _91slug_936cQZJsd5eeMeta || {},
    alias: _91slug_936cQZJsd5eeMeta?.alias || [],
    redirect: _91slug_936cQZJsd5eeMeta?.redirect || undefined,
    component: () => import("/var/www/quick-deal/qd.estate-market/pages/journal/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexcY7k7zOwDpMeta?.name ?? "journal",
    path: indexcY7k7zOwDpMeta?.path ?? "/journal",
    meta: indexcY7k7zOwDpMeta || {},
    alias: indexcY7k7zOwDpMeta?.alias || [],
    redirect: indexcY7k7zOwDpMeta?.redirect || undefined,
    component: () => import("/var/www/quick-deal/qd.estate-market/pages/journal/index.vue").then(m => m.default || m)
  },
  {
    name: indexgIEdfx68MIMeta?.name ?? "license-agreement",
    path: indexgIEdfx68MIMeta?.path ?? "/license-agreement",
    meta: indexgIEdfx68MIMeta || {},
    alias: indexgIEdfx68MIMeta?.alias || [],
    redirect: indexgIEdfx68MIMeta?.redirect || undefined,
    component: () => import("/var/www/quick-deal/qd.estate-market/pages/license-agreement/index.vue").then(m => m.default || m)
  },
  {
    name: PageTemplate6MDMtuHmhmMeta?.name ?? "market-PageTemplate",
    path: PageTemplate6MDMtuHmhmMeta?.path ?? "/market/PageTemplate",
    meta: PageTemplate6MDMtuHmhmMeta || {},
    alias: PageTemplate6MDMtuHmhmMeta?.alias || [],
    redirect: PageTemplate6MDMtuHmhmMeta?.redirect || undefined,
    component: () => import("/var/www/quick-deal/qd.estate-market/pages/market/PageTemplate.vue").then(m => m.default || m)
  },
  {
    name: indexDsVqjzXemiMeta?.name ?? "market",
    path: indexDsVqjzXemiMeta?.path ?? "/market",
    meta: indexDsVqjzXemiMeta || {},
    alias: indexDsVqjzXemiMeta?.alias || [],
    redirect: indexDsVqjzXemiMeta?.redirect || undefined,
    component: () => import("/var/www/quick-deal/qd.estate-market/pages/market/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46ids_93rLgqzDWsojMeta?.name ?? "p-b-ids",
    path: _91_46_46_46ids_93rLgqzDWsojMeta?.path ?? "/p/b/:ids(.*)*",
    meta: _91_46_46_46ids_93rLgqzDWsojMeta || {},
    alias: _91_46_46_46ids_93rLgqzDWsojMeta?.alias || [],
    redirect: _91_46_46_46ids_93rLgqzDWsojMeta?.redirect || undefined,
    component: () => import("/var/www/quick-deal/qd.estate-market/pages/p/b/[...ids].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46ids_93m4L60Oh9NSMeta?.name ?? "p-s-ids",
    path: _91_46_46_46ids_93m4L60Oh9NSMeta?.path ?? "/p/s/:ids(.*)*",
    meta: _91_46_46_46ids_93m4L60Oh9NSMeta || {},
    alias: _91_46_46_46ids_93m4L60Oh9NSMeta?.alias || [],
    redirect: _91_46_46_46ids_93m4L60Oh9NSMeta?.redirect || undefined,
    component: () => import("/var/www/quick-deal/qd.estate-market/pages/p/s/[...ids].vue").then(m => m.default || m)
  },
  {
    name: index64K51bnnEUMeta?.name ?? "private-policy",
    path: index64K51bnnEUMeta?.path ?? "/private-policy",
    meta: index64K51bnnEUMeta || {},
    alias: index64K51bnnEUMeta?.alias || [],
    redirect: index64K51bnnEUMeta?.redirect || undefined,
    component: () => import("/var/www/quick-deal/qd.estate-market/pages/private-policy/index.vue").then(m => m.default || m)
  },
  {
    name: delete74wFOFo2qsMeta?.name ?? "profile-delete",
    path: delete74wFOFo2qsMeta?.path ?? "/profile/delete",
    meta: delete74wFOFo2qsMeta || {},
    alias: delete74wFOFo2qsMeta?.alias || [],
    redirect: delete74wFOFo2qsMeta?.redirect || undefined,
    component: () => import("/var/www/quick-deal/qd.estate-market/pages/profile/delete.vue").then(m => m.default || m)
  },
  {
    name: indexH5IbzlsoZQMeta?.name ?? "profile",
    path: indexH5IbzlsoZQMeta?.path ?? "/profile",
    meta: indexH5IbzlsoZQMeta || {},
    alias: indexH5IbzlsoZQMeta?.alias || [],
    redirect: indexH5IbzlsoZQMeta?.redirect || undefined,
    component: () => import("/var/www/quick-deal/qd.estate-market/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexbKVxY0mD4vMeta?.name ?? "settings",
    path: indexbKVxY0mD4vMeta?.path ?? "/settings",
    meta: indexbKVxY0mD4vMeta || {},
    alias: indexbKVxY0mD4vMeta?.alias || [],
    redirect: indexbKVxY0mD4vMeta?.redirect || undefined,
    component: () => import("/var/www/quick-deal/qd.estate-market/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: _91uniqueId_93Bpj33aBqp6Meta?.name ?? "suggestions-uniqueId",
    path: _91uniqueId_93Bpj33aBqp6Meta?.path ?? "/suggestions/:uniqueId()",
    meta: _91uniqueId_93Bpj33aBqp6Meta || {},
    alias: _91uniqueId_93Bpj33aBqp6Meta?.alias || [],
    redirect: _91uniqueId_93Bpj33aBqp6Meta?.redirect || undefined,
    component: () => import("/var/www/quick-deal/qd.estate-market/pages/suggestions/[uniqueId].vue").then(m => m.default || m)
  },
  {
    name: managementzchMCmVTVUMeta?.name ?? "suggestions-management",
    path: managementzchMCmVTVUMeta?.path ?? "/suggestions/management",
    meta: managementzchMCmVTVUMeta || {},
    alias: managementzchMCmVTVUMeta?.alias || [],
    redirect: managementzchMCmVTVUMeta?.redirect || undefined,
    component: () => import("/var/www/quick-deal/qd.estate-market/pages/suggestions/management.vue").then(m => m.default || m)
  }
]