<template>
  <custom-button-group
    v-model="displayingEntity"
    :is-adaptive="false"
    span-class="hidden lg:inline"
    :options="entityOptionsForGroup"
    size="none"
    button-class="flex font-semibold justify-center items-center px-4"
  />
</template>

<script setup lang="ts">
import CustomButtonGroup from '~/ui/buttons/CustomButtonGroup.vue'
import { FLATS_DISPLAYING_ENTITY } from '~/modules/building/components/page/flats/filter/constants/FlatsDisplaying.entity'
import { FlatsDisplayingEntity } from '~/modules/building/components/page/flats/filter/types/FlatsDisplaying.entity'

const props = defineProps({
  modelValue: {
    type: String as PropType<FlatsDisplayingEntity>,
    default: FLATS_DISPLAYING_ENTITY.table,
  },
  withLayouts: {
    type: Boolean,
    default: true,
  },
})

const emits = defineEmits<{(e: 'update:modelValue', value: FlatsDisplayingEntity): void
}>()

const { t } = useI18n()

const entityOptionsForGroup = computed(() => {
  const options = [
    {
      value: FLATS_DISPLAYING_ENTITY.table,
      label: t('flat.table.entityName'),
      icon: 'common/list',
      iconClass: 'text-[20px]',
    },
    {
      value: FLATS_DISPLAYING_ENTITY.grid,
      label: t('flat.grid.entityName'),
      icon: 'common/grid',
      iconClass: 'text-[18px]',
    },
    {
      value: FLATS_DISPLAYING_ENTITY.layouts,
      label: t('layout.entityName'),
      icon: 'entities/layout',
      iconClass: 'text-[20px]',
    },
  ]

  if (!props.withLayouts) {
    options.pop()
  }

  return options
})

const displayingEntity = computed({
  get() {
    return props.modelValue
  },
  set(value: FlatsDisplayingEntity) {
    emits('update:modelValue', value)
  },
})
</script>
