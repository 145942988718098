import { NavGroupItem } from '~/modules/nav-menu/types/NavGroupItem.type'
import { NavLinkItem } from '~/modules/nav-menu/types/NavLinkItem.type'
import { ROUTES_NAMES } from '~/router/constants/Router.routesNames'

export const NAV_MENU_ITEMS: Array<NavGroupItem | NavLinkItem> = [
  {
    title: 'navMenu.navigation.home',
    icon: 'common/home',
    routeName: ROUTES_NAMES.home,
  },
  {
    title: 'navMenu.navigation.market',
    icon: 'common/list',
    routeName: ROUTES_NAMES.market,
  },
  {
    title: 'navMenu.navigation.journal',
    icon: 'common/journal',
    routeName: ROUTES_NAMES.journal,
  },
  {
    title: 'navMenu.navigation.events',
    icon: 'common/speaker',
    routeName: ROUTES_NAMES.events,
  },
  {
    title: 'navMenu.navigation.suggestions',
    icon: 'entities/building',
    routeName: ROUTES_NAMES.suggestionsManagement,
  },
  {
    title: 'navMenu.navigation.clients',
    icon: 'common/person',
    routeName: ROUTES_NAMES.clients,
  },
  {
    title: 'navMenu.navigation.settings',
    icon: 'common/settings',
    routeName: ROUTES_NAMES.settings,
  },
]
