<template>
  <component
    :is="label ? 'label' : 'div'"
    class="w-full flex flex-col"
  >
    <div
      v-if="label"
      class="w-full mb-2 flex justify-between gap-x-2"
    >
      <span
        :class="[
          {'form-field__required' : required },
          'block inter whitespace-nowrap',
          labelClass || 'text-lg font-medium text-neutral-900'
        ]"
      >
        {{ label }}
      </span>
      <slot name="labelSuffix"/>
    </div>
    <div
      class="relative h-full w-full"
      :class="wrapperClass"
    >
      <div class="flex flex-col w-full h-full">
        <input
          :id="inputId"
          ref="inputRef"
          v-model="inputValue"
          :type="type"
          :disabled="disabled"
          :placeholder="placeholder"
          :autocomplete="autocomplete"
          class="custom-input w-full rounded-lg inter"
          :class="['focus:ring-0 border-2 placeholder-neutral-500 disabled:text-neutral-150 disabled:border-neutral-150 disabled:cursor-not-allowed',
                   stateClass,
                   {'pl-10': $slots.prefix || $slots.prefixInner,
                    'pr-8': state === false || $slots.suffix || $slots.suffixInner
                   },
                   sizeClass,
                   inputClass || 'bg-inherit'
          ]"
          v-bind="getActions($attrs)"
          :readonly="readonly"
        >
        <slot name="appendWrapper" />
      </div>
      <slot
        name="prefix"
      >
        <div class="absolute left-3 top-1/2 -translate-y-1/2 text-primary">
          <slot
            name="prefixInner"
          />
        </div>
      </slot>
      <slot
        name="suffix"
      >
        <div
          class="absolute right-4 top-1/2 -translate-y-1/2"
        >
          <slot
            name="suffixInner"
          >
            <client-only>
              <font-awesome-icon
                v-if="state === false"
                icon="fa-solid fa-circle-exclamation"
                class="text-danger"
              />
            </client-only>
          </slot>
        </div>
      </slot>
    </div>
    <slot
      name="helper"
    >
      <p
        v-if="state === false && invalidFeedback || description"
        class="mt-2 text-sm"
      >
        <span
          v-if="state === false"
          class="text-danger"
        >
          {{ invalidFeedback }}
        </span>
        <span v-else-if="description">
          {{ description }}
        </span>
      </p>
    </slot>
  </component>
</template>

<script setup lang="ts">
import { SIZE } from '~/ui/constants/size'
import { COLOR } from '~/ui/constants/color'
import { Size } from '~/ui/types/types'
import { InputType } from '~/ui/inputs/types/types'

const getActions = (attrs: { [index: string]: unknown }) => {
  const newObject: { [index: string]: unknown } = {}
  Object.keys(attrs).forEach(attr => {
    if (attr.indexOf('on') === 0) {
      newObject[attr] = attrs[attr]
    }
  })
  return newObject
}

const modelModifiers = {
  noEmptyString(value: string | number | null) {
    if (typeof value === 'string' && value.trim() === '') {
      return null
    }
    return value
  },
} as const

const props = defineProps({
  modelValue: {
    type: [Number, String] as PropType<string | number | null>,
    default: null,
  },
  modelModifiers: {
    type: Object as PropType<Record<keyof typeof modelModifiers, boolean>>,
    default: () => ({
      noEmptyString: true,
    }),
  },
  size: {
    type: String as PropType<Size>,
    default: SIZE.sm,
  },
  color: {
    type: String as PropType<keyof typeof COLOR>,
    default: COLOR.primary,
  },
  state: {
    type: Boolean as PropType<boolean | null>,
    default: null,
  },
  type: {
    type: String as PropType<InputType>,
    default: 'text',
  },
  placeholder: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: '',
  },
  required: {
    type: Boolean,
    default: false,
  },
  invalidFeedback: {
    type: String,
    default: '',
  },
  description: {
    type: String,
    default: '',
  },
  inputClass: {
    type: [String, Object, Array],
    default: '',
  },
  labelClass: {
    type: [String, Object, Array],
    default: '',
  },
  wrapperClass: {
    type: [String, Object, Array],
    default: '',
  },
  autocomplete: {
    type: String,
    default: null,
  },
  inputId: {
    type: String,
    default: null,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
})

const emits = defineEmits<{(e: 'update:modelValue', value: typeof props.modelValue): void }>()

function emitValue(value: string | number | null) {
  let emitingValue: string | number | null = value
  if (props.modelModifiers?.noEmptyString) {
    emitingValue = modelModifiers.noEmptyString(emitingValue)
  }
  emits('update:modelValue', emitingValue)
}

const inputRef = ref<HTMLInputElement | null>(null)
const inputValue = computed<string | number | null>({
  get() {
    return props.modelValue
  },
  set: emitValue,
})

const stateClass = computed(() => {
  switch (props.state) {
    case true:
      return 'text-success border-success hover:border-success focus:border-success'
    case false:
      return 'text-danger border-danger hover:border-danger focus:border-danger'
    default:
      return 'text-neutral-900 border-neutral-150 hover:border-neutral-500 focus:border-neutral-900'
  }
})
const sizeClass = computed(() => {
  switch (props.size) {
    case SIZE.sm:
      return 'px-4 py-2'
    default:
      return ''
  }
})

defineExpose({ inputRef })
</script>

<style lang="scss">
.custom-input {
  &[type="number"]::-webkit-outer-spin-button,
  &[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'],
  &[type="number"]:hover,
  &[type="number"]:focus {
    appearance: none;
    -moz-appearance: textfield;
  }
}
</style>
