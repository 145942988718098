<template>
  <custom-button
    v-bind="$attrs"
    class="font-semibold text-sm !rounded-[19px] flex items-center justify-center px-3 h-[30px]"
    color="transparent"
    size="none"
    @click="isModalOpen = true"
  >
    <nuxt-icon
      name="building/mortgage"
      class="text-[20px]"
    />
    <span class="ml-1">{{ $t('mortgage.table.stake.stakeRange.default', {value: minStake}) }}</span>
  </custom-button>
  <modal-or-bottom-sheet
    v-model="isModalOpen"
    size="lg"
    :drawer-props="{fullHeight: true, hideHeader: false}"
    :modal-props="{size: 'w-full max-w-[1000px]', centered: false, hideFooter: true}"
  >
    <template #header>
      <h2 class="font-semibold  text-neutral-900 text-2xl">
        {{ $t('building.page.mortgage') }}
      </h2>
    </template>
    <div>
      <building-page-mortgage
        v-if="isModalOpen"
        :building="building"
        :displaying-type="MORTGAGE_DISPLAYING_TYPE.modal"
        without-header
      />
    </div>
  </modal-or-bottom-sheet>
</template>

<script setup lang="ts">
import CustomButton from '~/ui/buttons/CustomButton.vue'
import { Building } from '~/modules/building/BuildingModule'
import ModalOrBottomSheet from '~/components/common/ModalOrBottomSheet.vue'
import BuildingPageMortgage from '~/modules/building/components/page/mortgage/BuildingPageMortgage.vue'
import {MORTGAGE_DISPLAYING_TYPE} from '~/modules/bank/constants/Mortgage.displayingType'

const props = defineProps({
  building: {
    type: Object as PropType<Building>,
    required: true,
  },
})

const isModalOpen = ref(false)
const minStake = computed(() => {
  const stakes = props.building.mortgages.map(morgage => morgage.stake)

  return Math.min(...stakes)
})

</script>
