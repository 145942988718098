<script lang="ts" setup>
import CustomButton from '~/ui/buttons/CustomButton.vue'
import { MONTH } from '~/common/constants/date/Month'

const props = defineProps({
  selectedMonth: {
    type: Number,
    default: 1,
  },
})

const emits = defineEmits<{(e: 'changePage', month: number): void }>()

const months = Object.values(MONTH)
</script>

<template>
  <div
    class="flex flex-wrap -m-[5px] w-full"
  >
    <div
      v-for="(month, index) in months"
      :key="month"
      class="p-[5px] w-1/2"
    >
      <custom-button
        class="w-full font-semibold hover:text-white hover:bg-primary"
        :class="{'bg-primary-light text-primary': index === selectedMonth - 1}"
        color="none"
        pill
        @click="emits('changePage', index + 1)"
      >
        {{ $t(`common.months.full.${month}`) }}
      </custom-button>
    </div>
  </div>
</template>
