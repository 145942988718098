export const atob = (base64: string) => {
  if (process.client) {
    return window.atob(base64)
  }
  const buffer = Buffer.from(base64, 'base64')
  return buffer.toString('binary')
}

export const btoa = (text: string) => {
  if (process.client) {
    return window.btoa(text)
  }
  const buffer = Buffer.from(text, 'binary')
  return buffer.toString('base64')
}
