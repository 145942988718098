<template>
  <client-form-template
    :button-title="submitButtonTitle"
    :is-fetching="isFetching"
    @submit="onSubmit"
  >
    <template #default>
      <client-form-field-client
        :client="view?.client"
        :comment="view?.comment"
      />
      <client-date-view :date="view?.date" />
      <client-view-status :status="view?.status" />
      <client-form-field-user :assigned="view?.assigned" />
    </template>
  </client-form-template>
</template>
<script setup lang="ts">

import ClientFormFieldClient from '~/modules/clients/components/forms/ClientFormFieldClient.vue'
import ClientFormTemplate from '~/modules/clients/components/forms/ClientFormTemplate.vue'
import ClientFormFieldUser from '~/modules/clients/components/forms/ClientFormFieldUser.vue'
import { User } from '~/modules/user/UserModule'
import { ClientsService } from '~/modules/clients/services/ClientsService'
import ClientDateView from '~/modules/clients/components/forms/client-view/ClientViewDate.vue'
import { ClientView } from '~/modules/clients/types/ClientView'
import { useClientsStore } from '~/modules/clients/store'
import ClientViewStatus from '~/modules/clients/components/forms/client-view/ClientViewStatus.vue'

const props = defineProps({
  manager: {
    type: Object as PropType<User | null>,
    default: null,
  },
  view: {
    type: Object as PropType<ClientView | null>,
    default: null,
  },
})

const store = useClientsStore()

const selectedFlat = computed(() => store.selectedViewFlat)

const emits = defineEmits<{(e: 'submit', view: ClientView): void,
  (e: 'close'): void,
  (e: 'update:views', view: ClientView): void
}>()

const submitButtonTitle = computed(() => {
  if (!props.view) {
    return t('clients.views.actions.addView')
  }
  return t('clients.views.actions.save')
})

const { t } = useI18n()

const isFetching = ref(false)

const clientsService = new ClientsService()

const { values, handleSubmit, setErrors, handleReset } = useForm<ClientView>()

const onSubmit = handleSubmit(() => {
  isFetching.value = true
  if (props.view) {
    clientsService.updateView({ ...values, id: props.view.id, flat: props.view.flat })
      .then(res => {
        emits('update:views', res)
        toast(t('clients.views.toasts.update.success'), { variant: 'success' })
        emits('close')
        handleReset()
      })
      .catch(response => {
        toast(t('clients.views.toasts.update.error'), { variant: 'danger' })
        if (response.status === 422) {
          const { errors } = response.data
          setErrors(errors)
        }
      })
      .finally(() => {
        isFetching.value = false
      })
  } else {
    clientsService.createView({ ...values, flat: selectedFlat.value })
      .then(res => {
        emits('update:views', res)
        console.log(res)
        toast(t('clients.views.toasts.create.success'), { variant: 'success' })
        emits('close')
        handleReset()
      })
      .catch(response => {
        toast(t('clients.views.toasts.create.error'), { variant: 'danger' })
        if (response.status === 422) {
          const { errors } = response.data
          setErrors(errors)
        }
      })
      .finally(() => {
        isFetching.value = false
      })
  }
})
</script>
