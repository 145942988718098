<template>
  <div
    ref="containerRef"
    class="w-full overflow-hidden h-[220px]"
    @wheel="onScroll"
    @touchmove="touchMove"
    @touchend="endMoving"
  >
    <div
      ref="numberContainerRef"
      class="w-full relative flex flex-col items-center"
      @wheel.stop="onScroll"
      @touchmove.stop="touchMove"
      @touchend="endMoving"
    >
      <custom-button
        v-for="(num, inx) in beforeNumbers"
        :key="num"
        size="none"
        color="none"
        :class="{'font-semibold text-[22px] text-neutral-750': inx === beforeNumbers.length - 1, 'font-medium text-[18px] text-neutral-950/40': inx === beforeNumbers.length - 2, 'text-neutral-950/20': inx < beforeNumbers.length - 2}"
        variant="withoutBorders"
        @click="currentNumber = num"
        @wheel.stop="onScroll"
        @touchmove.stop="touchMove"
        @touchend="endMoving"
      >
        {{ DateTimeHelper.normalizeTimeLength(num) }}
      </custom-button>
      <div
        ref="currentChosenRef"
        class="text-[28px] font-bold"
        size="none"
        color="none"
        variant="withoutBorders"
        @wheel.stop="onScroll"
        @touchmove.stop="touchMove"
        @touchend="endMoving"
      >
        {{ DateTimeHelper.normalizeTimeLength(currentNumber) }}
      </div>
      <custom-button
        v-for="(num, inx) in afterNumbers"
        :key="num"
        :class="{'font-semibold text-[22px] text-neutral-750': inx === 0, 'font-medium text-[18px] text-neutral-950/40': inx === 1, 'text-neutral-950/20': inx > 1}"
        size="none"
        color="none"
        variant="withoutBorders"
        @click="currentNumber = num"
        @wheel.stop="onScroll"
        @touchmove.stop="touchMove"
        @touchend="endMoving"
      >
        {{ DateTimeHelper.normalizeTimeLength(num) }}
      </custom-button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { DateTimeHelper } from '~/common/helpers/date/dateTime'
import CustomButton from '~/ui/buttons/CustomButton.vue'

const props = defineProps({
  wheelArrayLength: {
    type: Number,
    default: 24,
  },
  modelValue: {
    type: Number,
    required: true,
  },
})

const emits = defineEmits<{(e: 'update:modelValue', value: number): void }>()

const numbers = Array.from(Array(props.wheelArrayLength).keys())
const currentChosenRef = ref<HTMLSpanElement | null>(null)
const containerRef = ref<HTMLDivElement | null>(null)
const numberContainerRef = ref<HTMLDivElement | null>(null)
const screenY = ref<null | number>(null)

const currentNumber = computed({
  get: () => props.modelValue,
  set: value => {
    emits('update:modelValue', value)
  },
})

const beforeNumbers = computed(() => {
  const hoursArray: number[] = []
  const lastPart = numbers.slice(0, numbers.indexOf(currentNumber.value))

  if (lastPart.length < props.wheelArrayLength / 4) {
    hoursArray.push(...numbers.slice(lastPart.length - props.wheelArrayLength / 4))
  } else {
    return lastPart.slice(-(props.wheelArrayLength / 4))
  }

  hoursArray.push(...lastPart)
  return hoursArray
})

const afterNumbers = computed(() => {
  const hoursArray: number[] = []
  const firstPart = numbers.slice(numbers.indexOf(currentNumber.value) + 1)

  hoursArray.push(...firstPart)

  if (firstPart.length < props.wheelArrayLength / 4) {
    hoursArray.push(...numbers.slice(0, (props.wheelArrayLength / 4) - firstPart.length))
  } else {
    return hoursArray.slice(0, props.wheelArrayLength / 4)
  }

  return hoursArray
})

const onScroll = (e: WheelEvent) => {
  e.preventDefault()
  if (e.deltaY > 0) {
    if (currentNumber.value === numbers[props.wheelArrayLength - 1]) {
      [currentNumber.value] = numbers
    } else {
      currentNumber.value += 1
    }
  } else if (currentNumber.value === numbers[0]) {
    currentNumber.value = numbers[props.wheelArrayLength - 1]
  } else {
    currentNumber.value -= 1
  }
}

const touchMove = (e: TouchEvent) => {
  e.preventDefault()

  const touchScreenY = e.touches[0].screenY

  if (screenY.value && screenY.value > touchScreenY) {
    if (currentNumber.value === numbers[props.wheelArrayLength - 1]) {
      [currentNumber.value] = numbers
    } else {
      currentNumber.value += 1
    }
  }

  if (screenY.value && screenY.value < touchScreenY) {
    if (currentNumber.value === numbers[0]) {
      currentNumber.value = numbers[props.wheelArrayLength - 1]
    } else {
      currentNumber.value -= 1
    }
  }

  if (touchScreenY) {
    screenY.value = touchScreenY
  }
}

const endMoving = () => {
  screenY.value = null
}

onMounted(() => {
  if (currentChosenRef.value && containerRef.value && numberContainerRef.value) {
    const containerHeight = containerRef.value.clientHeight / 2
    numberContainerRef.value.style.top = `${-currentChosenRef.value.offsetTop - (currentChosenRef.value.clientHeight / 2) + containerHeight}px`
  }
})
</script>
