export const FLATS_SORT = {
  ascPrice: 'price',
  descPrice: '-price',
  ascArea: 'area',
  descArea: '-area',
  ascFloor: 'floor',
  descFloor: '-floor',
  ascAgentMotivation: 'agentMotivation',
  descAgentMotivation: '-agentMotivation',
} as const
