<template>
  <div class="flex max-h-[251px]">
    <div
      class="pr-4"
      :class="generalClass"
    >
      <filter-from-to-element
        v-model:value="innerFrom"
        v-model:selected-hint="innerSelectedHint"
        :hint-options="hintOptions"
        :placeholder="placeholderFrom"
        :selecting-values="selectingValuesFrom"
        :select-value-handler="selectValueHandlerFrom"
        :selected-hint="selectedHint"
        :inner-input-suffix="innerInputSuffix"
        :input-class="inputFromClass"
      >
        <template #input>
          <slot name="input-from" />
        </template>
      </filter-from-to-element>

    </div>
    <div
      class="pl-4"
      :class="generalClass"
    >
      <filter-from-to-element
        v-model:value="innerTo"
        v-model:selected-hint="innerSelectedHint"
        :hint-options="hintOptions"
        :placeholder="placeholderTo"
        :selecting-values="selectingValuesTo"
        :select-value-handler="selectValueHandlerTo"
        :inner-input-suffix="innerInputSuffix"
        :input-class="inputToClass"
      >
        <template #input>
          <slot name="input-to" />
        </template>
      </filter-from-to-element>
    </div>
  </div>
</template>

<script setup lang="ts">

import FilterFromToElement from '~/components/filters/from-to/FilterFromToElement.vue'
import { HintOption } from '~/common/types/filters/from-to/FromTo.hintOption'
import { FromToSelectingValue } from '~/common/types/filters/from-to/FromTo.selectingValue'

const props = defineProps({
  from: {
    type: [Object, String, Number, null],
    default: null,
  },
  to: {
    type: [Object, String, Number, null],
    default: null,
  },
  selectedHint: {
    type: [String, null],
    default: null,
  },
  placeholderFrom: {
    type: String,
    default: '',
  },
  placeholderTo: {
    type: String,
    default: '',
  },
  hintOptions: {
    type: Array <PropType<HintOption>>,
    default: () => ([]),
  },
  selectingValuesFrom: {
    type: Array as PropType<Array<FromToSelectingValue>>,
    default: () => ([]),
  },
  selectingValuesTo: {
    type: Array as PropType<Array<FromToSelectingValue>>,
    default: () => ([]),
  },
  selectValueHandlerFrom: {
    type: Function,
    default: null,
  },
  selectValueHandlerTo: {
    type: Function,
    default: null,
  },
  innerInputSuffix: {
    type: String,
    default: '',
  },
  generalClass: {
    type: [String, Object, Array],
    default: '',
  },
  isGeneral: {
    type: Boolean,
    default: false,
  },
})

const emits = defineEmits<{(e: 'update:from', value: number | string | null,): void,
  (e: 'update:to', value: number | string | null,): void,
  (e: 'update:selectedHint', value: number | string | null,): void
}>()

const innerFrom = computed({
  get: () => props.from,
  set: value => {
    emits('update:from', value)
  },
})

const innerTo = computed({
  get: () => props.to,
  set: value => {
    emits('update:to', value)
  },
})

const innerSelectedHint = computed({
  get: () => props.selectedHint,
  set: value => {
    emits('update:selectedHint', value)
  },
})

const inputFromClass = computed(() => {
  if (props.isGeneral) {
    return 'rounded-r-none border-r-[0.5px] hover:border-r-[2px] focus:border-r-[2px]'
  }
  return ''
})

const inputToClass = computed(() => {
  if (props.isGeneral) {
    return 'rounded-l-none border-l-[0.5px] hover:border-l-[2px] focus:border-l-[2px]'
  }
  return ''
})

</script>
