import { defineStore } from 'pinia'
import { User } from '~/modules/user/types/user'
import { EntryUserData } from '~/modules/user/types/EntryUserData'
import { ORGANISATION_USER_ROLES } from '~/modules/organisation/constants/OrganisationUserRoles'

type UserState = {
  user: User
  entryData: EntryUserData
  isAdmin: boolean
}
export const useUserStore = defineStore('user', {
  state: () => ({
    user: {} as User,
    entryData: {} as EntryUserData,
    isAdmin: false,
  }) as UserState,
  getters: {
    isUserAuthorized(store) {
      return !!store.user.id
    },
    isAdmin(store) {
      return store.user?.role?.name === ORGANISATION_USER_ROLES.admin
    },
  },
  actions: {
    setUser(user: User) {
      this.user = user
    },
    setUserEntryData(entryData: EntryUserData) {
      this.entryData = entryData
    },
  },
})
