import { InterceptorsHandler } from '~/services/http/quick-deal/interceptors'
import QuickDealHttpApiService from '~/services/http/quick-deal/QuickDealHttpApiService'

export default defineNuxtPlugin(nuxtApp => {
  const qdHttpApiInstance: QuickDealHttpApiService = nuxtApp.$qdHttpApiInstance
  const interceptorsHandler = new InterceptorsHandler()

  qdHttpApiInstance.interceptors.response.use(
    response => response,
    interceptorsHandler.handleRejected,
  )
})
