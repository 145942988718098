<template>
  <flats-prices-statistics-btns-toggle
    v-if="isToggleVisible"
    v-model="displayingRooms"
  />
  <div
    v-show="statisticsForTable.length"
    class="flex gap-x-6 w-full flex-col "
    :class="{'xl:flex-row' : isToggleVisible}"
  >
    <flats-prices-statistics-chart
      v-model:statistics="statistics"
      :displaying-rooms="displayingRooms"
      :currency="currency"
      :building-id="buildingId"
      :developer-id="developerId"
      :flat-service="flatService"
      :flat="flat"
      :layout="layout"
      @update:is-statistics-fetching="isFetching = $event"
    />
    <flats-prices-statistics-table
      :is-more-button-show="isMoreButtonShow"
      :statistics-for-table="statisticsForTable"
      :is-need-two-table="!isXl && isMd && statisticsForTable.length > 4"
      class="w-full "
      :class="{'xl:w-1/3' : isToggleVisible}"
      :currency="currency"
    />
  </div>
  <div
    v-if="isFetching"
    class="flex justify-center w-full"
  >
    <custom-cube-spinner />
  </div>
  <div v-else-if="!statisticsForTable.length">
    <span>{{ $t('flat.statistics.price.notFound') }}</span>
  </div>
</template>

<script setup lang="ts">
import FlatsPricesStatisticsBtnsToggle from '~/components/flats/statistics/prices/FlatsPricesStatisticsBtnsToggle.vue'
import FlatsPricesStatisticsChart from '~/components/flats/statistics/prices/FlatsPricesStatisticsChart.vue'
import { FlatPriceStatisticsDisplayingRooms } from '~/common/types/flat/Flat.priceStatisticsDisplayingRooms'
import { FLAT_PRICE_STATISTICS_DISPLAYING_ROOMS } from '~/common/constants/flat/Flat.priceStatisticsDisplayingRooms'
import FlatsPricesStatisticsTable from '~/components/flats/statistics/prices/FlatsPricesStatisticsTable.vue'
import { FlatPriceStatistics } from '~/common/types/flat/Flat.priceStatistics'
import { useAppStateStore } from '~/store/app'
import { ObjectHelper } from '~/common/helpers/object'
import { useUserStore } from '~/modules/user/store'
import { MONTH } from '~/common/constants/date/Month'
import CustomCubeSpinner from '~/ui/spinners/CustomCubeSpinner.vue'
import { FlatGuestService } from '~/common/services/flat/FlatGuestService'
import { FlatService } from '~/common/services/flat/FlatService'
import { Flat } from '~/common/types/flat/Flat'
import { FlatLayoutItem } from '~/common/types/flat/Flat.layoutItem'

const months = Object.values(MONTH)

const props = defineProps({
  buildingId: {
    type: Number as PropType<number | null>,
    default: null,
  },
  developerId: {
    type: Number as PropType<number | null>,
    default: null,
  },
  isReverseDates: {
    type: Boolean,
    default: false,
  },
  flat: {
    type: Object as PropType<Flat | null>,
    default: null,
  },
  layout: {
    type: Object as PropType<FlatLayoutItem | null>,
    default: null,
  },
  isToggleVisible: {
    type: Boolean,
    default: true,
  },
})

const { t } = useI18n()
const userStore = useUserStore()
const appStateStore = useAppStateStore()

const initialDisplayingRooms = computed(() => (props.flat ? FLAT_PRICE_STATISTICS_DISPLAYING_ROOMS[props.flat.flatRoomsCount] as FlatPriceStatisticsDisplayingRooms : FLAT_PRICE_STATISTICS_DISPLAYING_ROOMS.all))

const displayingRooms = ref<FlatPriceStatisticsDisplayingRooms>(initialDisplayingRooms.value)
const currency = computed(() => appStateStore.currency)

const statistics = ref<FlatPriceStatistics | null>(null)
const flatService = userStore.user?.id ? new FlatService() : new FlatGuestService()

const isFetching = ref(false)
const isXl = computed(() => appStateStore.breakpoints.isXl)
const isMd = computed(() => appStateStore.breakpoints.isMd)

const formatDate = (dateString: string) => {
  let newDateString = ''
  const date = new Date(dateString)

  newDateString += `${date.getDate()} `
  newDateString += `${t(`common.months.short.${months[date.getMonth()]}`)} `
  newDateString += `${date.getFullYear()}`.slice(2, 4)

  return newDateString
}

const statisticsForTable = computed(() => {
  const copiedStatistics = ObjectHelper.copy(statistics.value)
  if (copiedStatistics) {
    let prevMinPrice = 0

    const statisticsInArray = Object.keys(copiedStatistics).map((key, inx) => {
      let minArray: Array<number> = []
      if (displayingRooms.value === FLAT_PRICE_STATISTICS_DISPLAYING_ROOMS.all) {
        minArray = Object.values(copiedStatistics[key]).map(item => item.averagePricePerSqMeter)
      } else {
        const copiedRoom = copiedStatistics[key][displayingRooms.value]
        minArray = copiedRoom ? [copiedRoom.averagePricePerSqMeter] : []
      }

      const price = minArray.length ? Math.min(...minArray) : 0

      if (!price) {
        return null
      }

      let persentage = 0

      if (inx !== 0) {
        if (prevMinPrice) {
          persentage = ((prevMinPrice - price) / prevMinPrice * 100)
        } else {
          persentage = 100
        }
      }

      prevMinPrice = price

      return { date: formatDate(key), price, persentage }
    })

    if (props.isReverseDates && statisticsInArray?.[0]) {
      statisticsInArray.reverse()
    }

    return statisticsInArray?.[0]
      ? (statisticsInArray as Array<{
        date: string;
        price: number;
        persentage: number
      }>)
      : []
  }
  return []
})
const isMoreButtonShow = computed(() => {
  if (statisticsForTable.value.length) {
    const statisticsLength = statisticsForTable.value.length

    if (isXl.value) {
      return statisticsLength > 4
    }
    if (isMd.value) {
      return statisticsLength > 8
    }
    return statisticsLength > 4
  }
  return false
})
</script>
