<template>
  <carousel
    v-model="currentImageIndex"
    class="w-full"
    :style="{ maxHeight, minHeight }"
    :transition="isImagesChanging ? 0 : 300"
    :items-to-show="itemsToShow"
    :wrap-around="images.length > 1"
    :mouse-drag="isDraggable"
    :touch-drag="isDraggable"
    :snap-align="snapAlign"
  >
    <slot name="default">
      <slide
        v-for="(image, index) in images"
        :key="index"
        :style="{ maxHeight, minHeight }"
      >
        <slot
          name="slide"
          :image="image"
          :index="index"
        >
          <div
            v-if="!lazy || loadedImagesIndex.includes(index)"
            class="relative w-full h-full flex items-center justify-center overflow-hidden"
            :class="{'cursor-pointer': (isModalOpen !== undefined && isOpenModalOnClick) || !isOpenModalOnClick }"
            @click.stop="open"
          >
            <img
              class="h-full w-full absolute z-[-1] top-0 left-0 object-fill blur-md"
              :src="image.link"
              :alt="image.alt"
            >
            <iframe
              v-if="image.video && !withoutVideo && currentImageIndex === index"
              width="90%"
              height="100%"
              :src="image.video"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            />
            <img
              v-else
              :class="fullScreenClasses"
              :src="image.link"
              :alt="image.alt"
            >
          </div>
        </slot>
      </slide>
    </slot>
    <template #addons>
      <navigation v-if="controls && images.length > 1" />
    </template>
  </carousel>
</template>
<script setup lang="ts">
import { Carousel, Navigation, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
import { Image } from '~/common/types/image/Image'
import { showAuthModalOrCallback } from '~/modules/auth/helpers/showAuthModalOrCallback'

const props = defineProps({
  images: {
    type: Array as PropType<Array<Image>>,
    default() {
      return []
    },
  },
  modelValue: {
    type: Number,
    required: true,
  },
  isModalOpen: {
    type: Boolean,
    default: undefined,
  },
  indicators: {
    type: Boolean,
    default: false,
  },
  controls: {
    type: Boolean,
    default: true,
  },
  maxHeight: {
    type: String,
    default: '100%',
  },
  minHeight: {
    type: String,
    default: '',
  },
  lazy: {
    type: Boolean,
    default: true,
  },
  loadedImagesIndex: {
    type: Array as PropType<Array<number>>,
    required: true,
  },
  isImagesChanging: {
    type: Boolean,
    default: false,
  },
  isOpenModalOnClick: {
    type: Boolean,
    default: true,
  },
  itemsToShow: {
    type: Number,
    default: 1,
  },
  withoutVideo: {
    type: Boolean,
    default: false,
  },
  isDraggable: {
    type: Boolean,
    default: true,
  },
  snapAlign: {
    type: String as PropType<'center' | 'center-even' | 'center-odd' | 'start' |'end'>,
    default: 'center',
  },
  isFullScreen: {
    type: Boolean,
    default: false,
  },
})

const emits = defineEmits<{(e: 'update:modelValue', value: number): void,
  (e: 'update:isModalOpen', value: boolean): void
  (e: 'onClickBySlide', index: number): void
}>()

const currentImageIndex = computed({
  get: () => props.modelValue,
  set: imageIndex => {
    emits('update:modelValue', imageIndex)
  },
})

const fullScreenClasses = computed(() => {
  if (props.isFullScreen) {
    return 'h-full w-full object-cover'
  }
  return 'h-full object-contain'
})

const open = (event: Event) => {
  if (props.isModalOpen !== undefined) { emits('update:isModalOpen', true) }
  emits('onClickBySlide', currentImageIndex.value)
}
</script>
