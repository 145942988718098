export const isEmpty = (data: Array<any> | Record<string, any> | null | number | undefined): boolean => {
  if (data === null) {
    return true
  } if (Array.isArray(data)) {
    return !data.length
  } if (typeof data === 'object') {
    return !Object.keys(data).length
  }
  return !data
}
