<template>
  <custom-button
    v-if="isFull"
    variant="outline"
    class="font-semibold border-[2px] border-neutral-150 !rounded-[19px]"
    @click="emits('reset-filter')"
  >
    {{ t('entityActions.filter.resetButton') }}
  </custom-button>
  <custom-tooltip
    v-if="!isFull"
    placement="bottom"
  >
    <template #content>
      {{ t('entityActions.filter.actions.resetFilters') }}
    </template>
    <custom-button-icon
      class="p-3 text-red-950"
      color="secondary"
      icon="actions/close"
      icon-class="text-danger text-[1.65rem]"
      round
      @click="emits('reset-filter')"
    />
  </custom-tooltip>
</template>
<script setup lang="ts">
import CustomButtonIcon from '~/ui/buttons/CustomButtonIcon.vue'
import CustomTooltip from '~/ui/tooltips/CustomTooltip.vue'
import CustomButton from '~/ui/buttons/CustomButton.vue'

const props = defineProps({
  variant: {
    type: String as PropType<'full' | 'short'>,
    default: 'short',
  },
})

const { t } = useI18n()

const emits = defineEmits<{(e: 'reset-filter'): void}>()

const isFull = computed(() => props.variant === 'full')

</script>
