<template>
  <custom-tooltip placement="bottom">
    <template #content>
      {{ $t('building.actions.fixViewing.label') }}
    </template>
    <custom-button-icon
      icon="common/eye-add"
      icon-class="text-[24px]"
      :class="buttonClass"
      color="secondary"
      round
      @click="showAuthModalOrCallback(view)"
    />
  </custom-tooltip>
</template>

<script setup lang="ts">
import CustomButtonIcon from '~/ui/buttons/CustomButtonIcon.vue'
import CustomTooltip from '~/ui/tooltips/CustomTooltip.vue'
import { showAuthModalOrCallback } from '~/modules/auth/helpers/showAuthModalOrCallback'
import { useClientsStore } from '~/modules/clients/store'
import { Flat } from '~/common/types/flat/Flat'

const props = defineProps({
  buttonClass: {
    type: [String, Object, Array],
    default: '',
  },
  flat: {
    type: Object as PropType<Flat>,
    required: true,
  },
})

const clientStore = useClientsStore()

const emits = defineEmits<{(e: 'click'): void }>()

const view = () => {
  emits('click')
  clientStore.selectedViewFlat = props.flat
}

</script>
