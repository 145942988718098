<template>
  <h3 class="text-2xl font-semibold cursor-pointer text-primary">

    {{ name ?? defaultName }}
  </h3>
</template>

<script setup lang="ts">

const { t } = useI18n()

defineProps({
  name: {
    type: String,
    default: null,
  },
  buildingId: {
    type: Number,
    required: true,
  },
})
const defaultName = t('building.name.default')

</script>
