<template>
  <client-form-template
    :button-title="$t('clients.fixation.form.submit')"
    :progress="progress"
    :is-fetching="isFetching"
    @submit="onSubmit"
  >
    <template #mobileAbout>
      <div class="mx-6">
        <client-fixation-form-about-toasts :developer="values.developer" />
      </div>
      <template
        v-if="manager"
      >
        <custom-card
          class-name="rounded-lg"
        >
          <custom-button
            class="flex justify-between items-center w-full !text-neutral-950"
            size="none"
            variant="withoutBorders"
            @click="isContactsDrawerOpen=true"
          >
            <span class="font-medium text-[16px]">{{ $t('clients.fixation.contacts.mobileTitle') }}</span>
            <nuxt-icon name="common/arrows/chevron-right" />
          </custom-button>
        </custom-card>
        <custom-drawer
          v-model="isContactsDrawerOpen"
        >
          <client-form-template-about-contacts :user="manager" />
        </custom-drawer>
      </template>
    </template>
    <template #default>
      <client-form-field-client
        :progress-step-done="progressSteps.client"
        :progress-step-percent="progressStepsPercents.client"
      />
      <client-fixation-form-developer
        :progress-step-done="progressSteps.developer"
        :progress-step-percent="progressStepsPercents.developer"
        :initial-developer="initialDeveloper"
      />
      <client-form-field-user
        :progress-step-done="progressSteps.user"
        :progress-step-percent="progressStepsPercents.user"
      />
    </template>
    <template #about>
      <div class="flex flex-col gap-y-6">
        <client-fixation-form-about-toasts :developer="values.developer" />
        <client-form-template-about-contacts
          v-if="manager"
          :user="manager"
        />
      </div>
    </template>
  </client-form-template>
</template>
<script setup lang="ts">
import ClientFixationFormAboutToasts
  from '~/modules/clients/components/forms/clients-fixation/ClientFixationFormAboutToasts.vue'
import ClientFormFieldClient from '~/modules/clients/components/forms/ClientFormFieldClient.vue'
import ClientFormTemplate from '~/modules/clients/components/forms/ClientFormTemplate.vue'
import ClientFormFieldUser from '~/modules/clients/components/forms/ClientFormFieldUser.vue'
import ClientFixationFormDeveloper
  from '~/modules/clients/components/forms/clients-fixation/ClientFixationFormDeveloper.vue'
import type { User } from '~/modules/user/UserModule'
import CustomCard from '~/ui/cards/CustomCard.vue'
import CustomButton from '~/ui/buttons/CustomButton.vue'
import CustomDrawer from '~/ui/drawers/CustomDrawer.vue'
import ClientFormTemplateAboutContacts from '~/modules/clients/components/forms/ClientFormTemplateAboutContacts.vue'
import { ClientsService } from '~/modules/clients/services/ClientsService'
import type { ClientFixation } from '~/modules/clients/types/ClientFixation'
import type { Developer } from '~/common/types/developer/Developer'
import type { PropType } from 'vue'

type Entity = 'developer' | 'user' | 'client'

defineProps({
  manager: {
    type: Object as PropType<User | null>,
    default: null,
  },
  initialDeveloper: {
    type: Object as PropType<Developer>,
    default: null,
  },
})

const { t } = useI18n()

const emits = defineEmits<{(e: 'submit', fixation: ClientFixation): void}>()

const isFetching = ref(false)

const clientsService = new ClientsService()

const { values, handleSubmit, setErrors, handleReset } = useForm<ClientFixation>()

const isContactsDrawerOpen = ref(false)

const progressStepsPercents: Record<Entity, number> = {
  developer: 20,
  user: 30,
  client: 50,
}

const progressSteps = computed<Record<Entity, boolean>>(() => ({
  developer: !!values.developer,
  user: !!values.assigned,
  client: !!values.client,
}))

const progress = computed(() => Object.entries(progressSteps.value).reduce(
  (sum: number, [step, done]) => (done ? sum + progressStepsPercents[step as Entity] : sum),
  0,
))

const onSubmit = handleSubmit(() => {
  isFetching.value = true
  clientsService.fixClient(values)
    .then(res => {
      emits('submit', res)
      handleReset()
      toast(t('clients.toasts.fixation.success'), { variant: 'success' })
    })
    .catch(response => {
      toast(t('clients.toasts.fixation.error'), { variant: 'danger' })
      if (response.status === 422) {
        const { errors } = response.data
        setErrors(errors)
      }
    })
    .finally(() => {
      isFetching.value = false
    })
})
</script>
