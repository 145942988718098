import { YANDEX_MAP_DEFAULT_CONFIG } from '~/common/constants/map/yandex/Map.defaultConfig'
import { ObjectHelper } from '~/common/helpers/object'
import { MapModule } from '~/common/interfaces/map/yandex/Map.module'

export class MapService {
  private modules: Record<string, MapModule>

  private mapConfig = ObjectHelper.copy(YANDEX_MAP_DEFAULT_CONFIG)
  protected map: ymaps.Map = {} as ymaps.Map
  protected i18n
  private loadYmap = useNuxtApp().$loadYmap as any

  constructor(
    mapConfig = { state: {}, options: {} },
    modules: Record<string, MapModule> = {},
  ) {
    this.modules = modules
    this.mapConfig.state = { ...this.mapConfig.state, ...mapConfig.state }
    this.mapConfig.options = { ...this.mapConfig.options, ...mapConfig.options }
    this.i18n = useI18n()
  }

  public async createMap(mapId: string): Promise<void> {
    await this.loadYmap({
      apiKey: useRuntimeConfig().public.YANDEX_MAP_API_KEY,
      lang: this.i18n.locale.value,
      coordorder: 'latlong',
    })

    const require: Array<string> = []
    Object.entries(this.modules).forEach(([key, module]) => {
      require.push(key)
      module.defineModule()
    })

    await ymaps.ready({ require })
    this.initMap(mapId)
  }

  private initMap(mapId: string) {
    this.map = new ymaps.Map(mapId, this.mapConfig.state, this.mapConfig.options)
  }

  protected getBBox(): ymaps.CoordinateBounds {
    return this.map.getBounds()
  }

  protected getZoom(): number {
    return Math.round(this.map.getZoom())
  }

  protected setMapBounds(bounds: ymaps.CoordinateBounds | null) {
    if (this.isMapExist() && bounds) {
      this.map.setBounds(bounds)
    }
  }

  protected setMapCenter(coordinates: ymaps.Coordinate | null, zoom?: number) {
    if (this.isMapExist() && coordinates) {
      this.map.setCenter(coordinates, zoom)
    }
  }

  protected isMapExist(): boolean {
    if (this.map?.action) {
      return true
    }
    return false
  }

  public destroyMap(): void {
    if (this.isMapExist()) {
      this.map.destroy()
    }
  }
}
