export const localeCodes = ["en","ru","ka","kk"]

export const localeMessages = {
  "en": [{ key: "../locales/en.json", load: () => import("../locales/en.json" /* webpackChunkName: "lang_en_json_en_json" */) }],
  "ru": [{ key: "../locales/ru.json", load: () => import("../locales/ru.json" /* webpackChunkName: "lang_ru_json_ru_json" */) }],
  "ka": [{ key: "../locales/ka.json", load: () => import("../locales/ka.json" /* webpackChunkName: "lang_ka_json_ka_json" */) }],
  "kk": [{ key: "../locales/kk.json", load: () => import("../locales/kk.json" /* webpackChunkName: "lang_kk_json_kk_json" */) }],
}

export const additionalMessages = Object({"en":[],"ru":[],"ka":[],"kk":[],})

export const resolveNuxtI18nOptions = async (context) => {
  const nuxtI18nOptions = Object({})
  const vueI18nOptionsLoader = async (context) => Object({"legacy":false,"inheritLocale":false,"pluralRules":Object({"ru":(function (choice, choicesLength) {
          // this === VueI18n instance, so the locale property also exists here

          if (choice === 0) {
            return 0;
          }

          const teen = choice > 10 && choice < 20;
          const endsWithOne = choice % 10 === 1;

          if (choicesLength < 4) {
            return !teen && endsWithOne ? 1 : 2;
          }
          if (!teen && endsWithOne) {
            return 1;
          }
          if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
            return 2;
          }

          return choicesLength < 4 ? 2 : 3;
        }),"ka":(function (choice, choicesLength) {
          if (choice === 0) {
            return 0;
          }

          const teen = choice > 10 && choice < 20;
          const endsWithOne = choice % 10 === 1;

          if (choicesLength < 4) {
            return !teen && endsWithOne ? 1 : 2;
          }
          if (!teen && endsWithOne) {
            return 1;
          }
          if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
            return 2;
          }

          return choicesLength < 4 ? 2 : 3;
        })}),"numberFormats":Object({"en-US":Object({"currency":Object({"style":"currency","currency":"USD","notation":"standard"}),"decimal":Object({"style":"decimal","minimumFractionDigits":2,"maximumFractionDigits":2}),"percent":Object({"style":"percent","useGrouping":false})}),"ru-RU":Object({"currency":Object({"style":"currency","currency":"","useGrouping":true,"currencyDisplay":"symbol"}),"decimal":Object({"style":"decimal","minimumSignificantDigits":3,"maximumSignificantDigits":5}),"percent":Object({"style":"percent","useGrouping":false})})}),"messages": Object({"en-US":{
  
},}),})
  nuxtI18nOptions.vueI18n = await vueI18nOptionsLoader(context)
  nuxtI18nOptions.locales = [Object({"code":"en","name":"English","file":"en.json","path":"/var/www/quick-deal/qd.estate-market/locales/en.json"}),Object({"code":"ru","name":"Russian","file":"ru.json","path":"/var/www/quick-deal/qd.estate-market/locales/ru.json"}),Object({"code":"ka","name":"Georgian","file":"ka.json","path":"/var/www/quick-deal/qd.estate-market/locales/ka.json"}),Object({"code":"kk","name":"Kazakh","file":"kk.json","path":"/var/www/quick-deal/qd.estate-market/locales/kk.json"})]
  nuxtI18nOptions.defaultLocale = ""
  nuxtI18nOptions.defaultDirection = "ltr"
  nuxtI18nOptions.routesNameSeparator = "___"
  nuxtI18nOptions.trailingSlash = false
  nuxtI18nOptions.defaultLocaleRouteNameSuffix = "default"
  nuxtI18nOptions.strategy = "no_prefix"
  nuxtI18nOptions.lazy = true
  nuxtI18nOptions.langDir = "locales"
  nuxtI18nOptions.rootRedirect = null
  nuxtI18nOptions.detectBrowserLanguage = Object({"alwaysRedirect":false,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true})
  nuxtI18nOptions.differentDomains = false
  nuxtI18nOptions.baseUrl = ""
  nuxtI18nOptions.dynamicRouteParams = false
  nuxtI18nOptions.customRoutes = "page"
  nuxtI18nOptions.pages = Object({})
  nuxtI18nOptions.skipSettingLocaleOnNavigate = false
  nuxtI18nOptions.onBeforeLanguageSwitch = (() => "")
  nuxtI18nOptions.onLanguageSwitched = (() => null)
  nuxtI18nOptions.types = undefined
  nuxtI18nOptions.debug = false
  return nuxtI18nOptions
}

export const nuxtI18nOptionsDefault = Object({vueI18n: undefined,locales: [],defaultLocale: "",defaultDirection: "ltr",routesNameSeparator: "___",trailingSlash: false,defaultLocaleRouteNameSuffix: "default",strategy: "prefix_except_default",lazy: false,langDir: null,rootRedirect: null,detectBrowserLanguage: Object({"alwaysRedirect":false,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true}),differentDomains: false,baseUrl: "",dynamicRouteParams: false,customRoutes: "page",pages: Object({}),skipSettingLocaleOnNavigate: false,onBeforeLanguageSwitch: (() => ""),onLanguageSwitched: (() => null),types: undefined,debug: false})

export const nuxtI18nInternalOptions = Object({__normalizedLocales: [Object({"code":"en","name":"English","file":"en.json","path":"/var/www/quick-deal/qd.estate-market/locales/en.json"}),Object({"code":"ru","name":"Russian","file":"ru.json","path":"/var/www/quick-deal/qd.estate-market/locales/ru.json"}),Object({"code":"ka","name":"Georgian","file":"ka.json","path":"/var/www/quick-deal/qd.estate-market/locales/ka.json"}),Object({"code":"kk","name":"Kazakh","file":"kk.json","path":"/var/www/quick-deal/qd.estate-market/locales/kk.json"})]})
export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const isSSG = false
export const isSSR = true
