<template>
  <client-form-field-template
    :title="$t('clients.fixation.form.client.title')"
    :progress-step-percent="progressStepPercent"
    :progress-step-done="progressStepDone"
  >
    <labeled-wrapper
      label-class="font-medium text-lg inter"
      :label="$t('clients.fixation.form.client.label')"
    >
      <template #content>
        <select-auto-suggest
          ref="suggestRef"
          v-model="client"
          :fetch="searchClientsByName"
          option-label="fullName"
          :invalid-feedback="errorClient"
          :placeholder="$t('clients.fixation.form.client.placeholder')"
          infinite-scroll
        />
      </template>
    </labeled-wrapper>
    <contacts-view
      v-if="client"
      class="mt-6 max-xl:flex-col gap-x-6 gap-y-3"
      :mobile-phone="client?.phone"
    />
    <custom-button
      variant="withoutBorders"
      size="none"
      color="none"
      class="flex items-center mt-6"
      @click="isAddFormOpen = true"
    >
      <div class="rounded-full bg-primary-light p-[9px]">
        <nuxt-icon
          class="text-neutral-500 text-[20px]"
          name="common/circle-plus-regular"
        />
      </div>
      <span class="ml-2 font-semibold">
        {{ $t('clients.fixation.form.client.add') }}
      </span>
    </custom-button>
    <labeled-wrapper
      class="mt-6"
      label-class="font-medium text-lg inter"
      :label="$t('clients.fixation.form.comment.label')"
    >
      <template #content>
        <custom-textarea
          v-model="comment"
          :placeholder="$t('clients.fixation.form.comment.placeholder')"
          :invalid-feedback="errorComment"
          rows="6"
          class="w-full"
        />
      </template>
    </labeled-wrapper>
    <modal-or-bottom-sheet
      v-model="isAddFormOpen"
      :modal-props="{hideFooter: true, hideHeader: true}"
    >
      <client-add-form @update:add="onAddSubmit">
        <template #closeButton>
          <custom-button
            class="!text-neutral-950 text-2xl hidden md:block"
            variant="withoutBorders"
            size="none"
            type="button"
            @click="isAddFormOpen = false"
          >
            <nuxt-icon
              name="actions/close"
            />
          </custom-button>
        </template>
      </client-add-form>
    </modal-or-bottom-sheet>
  </client-form-field-template>
</template>

<script setup lang="ts">
import { Client } from '~/modules/clients/types/Client'
import LabeledWrapper from '~/components/common/LabeledWrapper/LabeledWrapper.vue'
import ClientFormFieldTemplate from '~/modules/clients/components/forms/ClientFormFieldTemplate.vue'
import SelectAutoSuggest from '~/components/common/AutoSuggest/SelectAutoSuggest.vue'
import CustomTextarea from '~/ui/inputs/CustomTextarea.vue'
import { ClientsService } from '~/modules/clients/services/ClientsService'
import CustomButton from '~/ui/buttons/CustomButton.vue'
import ContactsView from '~/components/contacts/ContactsView.vue'
import ModalOrBottomSheet from '~/components/common/ModalOrBottomSheet.vue'
import ClientAddForm from '~/modules/clients/components/forms/ClientAddForm.vue'
import { SelectAutoSuggestExpose } from '~/common/types/auto-suggest/SelectAutoSuggest.expose'

const props = defineProps({
  progressStepPercent: {
    type: Number,
    default: 0,
  },
  progressStepDone: {
    type: Boolean,
    default: false,
  },
  client: {
    type: Object as PropType<Client | null>,
    default: null,
  },
  comment: {
    type: String as PropType<string | null>,
    default: null,
  },
})

const { t } = useI18n()
const clientsService = new ClientsService()

const isAddFormOpen = ref(false)
const suggestRef = ref<SelectAutoSuggestExpose | null>(null)

const { value: client, errorMessage: errorClient } = useField<Client | null>(
  'client',
  { required: true },
  { initialValue: props.client, label: t('clients.fixation.form.client.title') },
)

const { value: comment, errorMessage: errorComment } = useField<string | null>(
  'comment',
  { min: 3 },
  { initialValue: props.comment, label: t('clients.fixation.form.comment.label') },
)

const searchClientsByName = (name: string) => clientsService.getClients(name)

const onAddSubmit = (newClient: Client) => {
  isAddFormOpen.value = false
  client.value = newClient
  if (suggestRef.value) {
    suggestRef.value.addOption(newClient)
  }
}
</script>
