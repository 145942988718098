<template>
  <div
    :class="innerContainerClass"
  >
    <div
      v-for="promo in promoLabelsObjects"
      :key="promo.label"
      :class="`${promo.bgColor} ${innerPromoLabelsClass}`"
      @click="showAuthModalOrCallback(getPromoAndOpenModal)"
    >
      <nuxt-icon
        :name="promo.icon"
        class="nuxt-icon text-base"
        filled
      />
      <span
        v-if="!isGroupLabels"
        class="ml-1"
      > {{ promo.label }} </span>
    </div>
  </div>
  <modal-or-bottom-sheet
    v-if="buildingId"
    v-model="isModalOpen"
    :modal-props="{hideFooter: true, size: 'w-full max-w-[60vw]'}"
    :title="t('journal.filter.promotions')"
  >
    <template v-if="isFetching">
      <div class="flex justify-center items-center w-full">
        <custom-cube-spinner />
      </div>
    </template>
    <template v-else-if="journalNews.length">
      <div class="flex flex-wrap -my-6 -mx-3">
        <div
          v-for="(journalNewsItem, index) in journalNews"
          :key="index"
          class="w-full max-w-[50%] px-3 py-6"
        >
          <journal-card
            :journal-news="journalNewsItem"
          />
        </div>
      </div>
    </template>
    <span v-else>
      {{ t('building.promotions.promotionsNotFound') }}
    </span>
  </modal-or-bottom-sheet>
</template>

<script setup lang="ts">
import ModalOrBottomSheet from '~/components/common/ModalOrBottomSheet.vue'
import CustomCubeSpinner from '~/ui/spinners/CustomCubeSpinner.vue'
import JournalCard from '~/modules/journal/components/cards/JournalCard.vue'
import { JournalService } from '~/modules/journal/services/JournalService'
import { JournalNewsPromotionType } from '~/modules/journal/types/JournalNews.promotionType'
import { showAuthModalOrCallback } from '~/modules/auth/helpers/showAuthModalOrCallback'
import { SIZE } from '~/ui/constants/size'

type TMaxCount = 1 | 2 | 3 | 4 | 5

const props = defineProps({
  promotions: {
    type: Array<JournalNewsPromotionType>,
    default: () => [],
  },
  countToGroup: {
    type: Number as PropType<TMaxCount>,
    default: null,
  },
  containerClass: {
    type: String,
    default: '',
  },
  verticalAlignment: {
    type: Boolean,
    default: false,
  },
  buildingId: {
    type: Number,
    default: null,
  },
  size: {
    type: String as PropType<'sm' | 'md'>,
    default: SIZE.sm,
  },
})
const { t } = useI18n()
const isModalOpen = ref(false)
const isFetching = ref(false)
const journalNews = ref<Array<any>>([])
const journalAuthService = new JournalService()

const getLabel = {
  promotion: {
    label: t('journal.form.fields.promotionType.options.promotion'),
    bgColor: 'bg-[#6482E6]',
    icon: 'common/tag',
  },
  discount: {
    label: t('journal.form.fields.promotionType.options.discount'),
    bgColor: 'bg-[#4EC74D]',
    icon: 'common/sale',
  },
  sale: {
    label: t('journal.form.fields.promotionType.options.sale'),
    bgColor: 'bg-[#FF513C]',
    icon: 'common/flame',
  },
  increasedPercentage: {
    label: t('journal.form.fields.promotionType.options.increasedPercentageShort'),
    bgColor: 'bg-[#FF3C6A]',
    icon: 'common/percent',
  },
}

const promoLabelsObjects = computed(() => props.promotions.map(promo => getLabel[promo]))
const isGroupLabels = computed(() => props.countToGroup && props.promotions.length >= props.countToGroup)
const innerContainerClass = computed(() => `absolute flex ${props.verticalAlignment && !isGroupLabels.value ? 'flex-col items-start' : 'justify-start'} ${props.containerClass}`)
const innerPromoLabelsClass = computed(() => `${isGroupLabels.value ? 'w-[30px] mr-[-15px]' : 'px-3'} ${props.size === SIZE.sm ? 'h-[30px] text-sm' : 'h-[38px]'} ${props.buildingId ? 'cursor-pointer ' : ''}rounded-full text-white font-semibold flex items-center justify-center whitespace-nowrap`)
const getPromoAndOpenModal = () => {
  if (props.buildingId) {
    isModalOpen.value = true
    searchPromotions()
  }
}

const searchPromotions = () => {
  isFetching.value = true
  journalAuthService.searchBuildingPromotions(props.buildingId)
    .then(res => {
      journalNews.value = res.data
    })
    .finally(() => {
      isFetching.value = false
    })
}
</script>
