<template>
  <custom-button
    class="flex items-center text-sm h-[38px] px-4"
    :href="link"
    color="success"
    target="_blank"
    pill
    is-link
  >
    <nuxt-icon
      name="common/book-viewing"
      class="stroke-0 text-[18px]"
    />
    <span
      class="hidden xs:inline ml-2 text-xs md:text-sm font-semibold"
    >{{ $t('flat.button.bookViewing.label') }}</span>
  </custom-button>
</template>

<script setup lang="ts">
import { FlatLayoutItem } from '~/common/types/flat/Flat.layoutItem'
import CustomButton from '~/ui/buttons/CustomButton.vue'

const props = defineProps({
  layout: {
    type: Object as PropType<FlatLayoutItem>,
    required: true,
  },
  mobilePhone: {
    type: String,
    required: true,
  },
})

const { t } = useI18n()

const textForSend = t('flat.button.bookViewing.messageInLayoutForWhatsApp', {
  buildingName: props.layout.flats[0].building?.name || t('flat.button.bookViewing.messageDefaultField'),
  houseName: props.layout.flats[0].houseName || t('flat.button.bookViewing.messageDefaultField'),
  price: props.layout.price || t('flat.button.bookViewing.messageDefaultField'),
  totalArea: props.layout.totalArea || t('flat.button.bookViewing.messageDefaultField'),
  layoutName: props.layout.flats?.[0]?.layout.name || t('flat.button.bookViewing.messageDefaultField'),
})

const link = `https://wa.me/${props.mobilePhone}?text=${textForSend}`
</script>
