<template>
  <div v-if="isFull">
    <custom-dropdown
      :close-on-select="false"
      color="secondary"
      :button-class="[{'!bg-blue-100': countSelectedOptions}, 'w-full font-semibold']"
      dropdown-class="min-w-[375px]"
    >
      <template #triggerContent>
        <filter-counter v-if="countSelectedOptions">
          {{ countSelectedOptions }}
        </filter-counter>
        {{ triggerText }}
      </template>

      <div
        v-if="isCompletedHousesVisible"
        class="flex items-center gap-x-3 mb-3"
      >
        <custom-checkbox disabled />
        <p class="text-neutral-200">
          {{ $t('entityActions.filter.houseFilter.deadLineYear.showHandOver') }}
        </p>
      </div>
      <filter-from-to
        v-model:from="deadlineYearFrom"
        v-model:to="deadlineYearTo"
        :placeholder-from="$t('common.from')"
        :placeholder-to="$t('common.to')"
        :selecting-values-from="selectingValues"
        :selecting-values-to="selectingValues"
      >
        <template #input-from>
          <div class="flex mb-2">
            <filter-from-to
              v-model:from="deadlineYearFrom.quarter"
              v-model:to="deadlineYearFrom.year"
              :placeholder-from="$t('building.timeConstraints.quarter.label.short')"
              :placeholder-to="$t('building.timeConstraints.year.label.full')"
              general-class="!p-0"
              is-general
            />
          </div>
        </template>
        <template #input-to>
          <div class="flex mb-2">
            <filter-from-to
              v-model:from.number="deadlineYearTo.quarter"
              v-model:to.number="deadlineYearTo.year"
              :placeholder-from="$t('building.timeConstraints.quarter.label.short')"
              :placeholder-to="$t('building.timeConstraints.year.label.full')"
              general-class="!p-0"
              is-general
            />
          </div>
        </template>
      </filter-from-to>
    </custom-dropdown>
  </div>
  <div
    v-if="!isFull"
    class="flex flex-col md:flex-row gap-x-3"
  >
    <div class="w-full flex gap-x-2 items-center md:w-2/3 mb-2 md:mb-0">
      <div class="min-w-[154px] md:min-w-[252px]">
        <filter-from-to
          v-model:from="deadlineYearFrom.quarter"
          v-model:to="deadlineYearFrom.year"
          :placeholder-from="$t('building.timeConstraints.quarter.label.short')"
          :placeholder-to="$t('building.timeConstraints.year.label.full')"
          general-class="!p-0"
          is-general
        />
      </div>
      <p class="font-semibold">
        -
      </p>
      <div class="min-w-[154px] md:min-w-[252px]">
        <filter-from-to
          v-model:to.number="deadlineYearTo.year"
          v-model:from.number="deadlineYearTo.quarter"
          :placeholder-from="$t('building.timeConstraints.quarter.label.short')"
          :placeholder-to="$t('building.timeConstraints.year.label.full')"
          general-class="!p-0"
          is-general
        />
      </div>
    </div>
    <div
      v-if="isCompletedHousesVisible"
      class="flex items-center gap-x-3 ml-0 md:ml-4"
    >
      <custom-checkbox disabled />
      <p class="text-neutral-200">
        {{ $t('entityActions.filter.houseFilter.deadLineYear.showHandOver') }}
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import FilterFromTo from '~/components/filters/from-to/FilterFromTo.vue'
import CustomDropdown from '~/ui/dropdowns/CustomDropdown.vue'
import { FromToSelectingValue } from '~/common/types/filters/from-to/FromTo.selectingValue'
import { QuarterUtils } from '~/common/helpers/date/QuarterUtils'
import type { YearQuarterObject } from '~/common/types/base/YearQuarterObject'
import { QUARTER } from '~/common/constants/date/Quarter'
import CustomCheckbox from '~/ui/checkbox/CustomCheckbox.vue'
import { ObjectHelper } from '~/common/helpers/object'
import FilterCounter from '~/components/filters/FilterCounter.vue'

const props = defineProps({
  from: {
    type: Object as PropType<YearQuarterObject>,
    required: true,
  },
  to: {
    type: Object as PropType<YearQuarterObject>,
    required: true,
  },
  defaultValues: {
    type: Object as PropType<{ from: YearQuarterObject, to: YearQuarterObject }>,
    default() {
      return {
        from: { year: null, quarter: null },
        to: { year: null, quarter: null },
      }
    },
  },
  triggerText: {
    type: String,
    default: '',
  },
  isFull: {
    type: Boolean,
    default: true,
  },
})

const emits = defineEmits<{(e: 'update:from', value: YearQuarterObject): void,
  (e: 'update:to', value: YearQuarterObject): void
}>()

const { t } = useI18n()

const yearQuarterObjectTransform = (yearQuarter: YearQuarterObject): YearQuarterObject => ({
  year: yearQuarter.year,
  quarter: QUARTER[yearQuarter.quarter] ?? null,
})

const deadlineYearFrom = ref(yearQuarterObjectTransform(props.from))

const deadlineYearTo = ref(yearQuarterObjectTransform(props.to))

const isCompletedHousesVisible = false
const isYearQuarterObjectEmpty = (period: YearQuarterObject) => period.year === props.defaultValues?.from.year &&
    period.quarter === props.defaultValues?.from.quarter

const countSelectedOptions = computed(() => {
  if (!isYearQuarterObjectEmpty(deadlineYearFrom.value as YearQuarterObject) &&
      !isYearQuarterObjectEmpty(deadlineYearTo.value as YearQuarterObject)) {
    return 2
  }
  if (!isYearQuarterObjectEmpty(deadlineYearFrom.value as YearQuarterObject) ||
      !isYearQuarterObjectEmpty(deadlineYearTo.value as YearQuarterObject)) {
    return 1
  }
  return 0
})

const selectingValues = computed<Array<FromToSelectingValue>>(() => QuarterUtils.getNextQuarters(12)
  .reverse()
  .map(timeObject => {
    const yearQuarter = yearQuarterObjectTransform(timeObject)
    return {
      value: yearQuarter, label: t('building.timeConstraints.yearQuarter', yearQuarter),
    }
  }))

watch(deadlineYearFrom, value => {
  emits('update:from', yearQuarterObjectTransform(value))
}, { deep: true })

watch(() => props.from, value => {
  if (ObjectHelper.isEqual(yearQuarterObjectTransform(props.from), deadlineYearFrom.value)) {
    return
  }
  deadlineYearFrom.value = yearQuarterObjectTransform(value)
})
watch(deadlineYearTo, value => {
  emits('update:to', yearQuarterObjectTransform(value))
}, { deep: true })

watch(() => props.to, value => {
  if (ObjectHelper.isEqual(yearQuarterObjectTransform(props.to), deadlineYearTo.value)) {
    return
  }
  deadlineYearTo.value = yearQuarterObjectTransform(value)
})

</script>
