<script lang="ts" setup>
import {useAppStateStore} from '~/store/app'
import BankMortgageScheduleTableDesktop
  from '~/modules/bank/components/mortgage/schedule/BankMortgageScheduleTableDesktop.vue'
import BankMortgageScheduleTableMobile
  from '~/modules/bank/components/mortgage/schedule/BankMortgageScheduleTableMobile.vue'
import {MortgageSchedule} from '~/modules/bank/types/Mortgage.schedule'

defineProps({
  schedules: {
    type: Array as PropType<Array<MortgageSchedule>>,
    required: true,
  },
})

const appStateStore = useAppStateStore()

const isMd = computed(() => appStateStore.breakpoints.isMd)
const currency = computed(() => appStateStore.currency)
</script>

<template>
  <bank-mortgage-schedule-table-desktop
    v-if="isMd"
    :currency="currency"
    :schedules="schedules"
  />
  <bank-mortgage-schedule-table-mobile
    v-else
    :currency="currency"
    :schedules="schedules"
  />
</template>
