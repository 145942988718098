<script lang="ts" setup>
import {Mortgage} from '~/modules/bank/types/Mortgage'
import CustomTable from '~/ui/tables/CustomTable.vue'
import {getEntityName} from '~/common/helpers/getEntityName'
import {AvailableCountry} from '~/common/types/country/Country.available'
import CustomButtonIcon from '~/ui/buttons/CustomButtonIcon.vue'
import {useAppStateStore} from '~/store/app'

defineProps({
  mortgages: {
    type: Array as PropType<Array<Mortgage>>,
    default: () => [] as Array<Mortgage>,
  },
  countryIsoCode: {
    type: String as PropType<AvailableCountry>,
    default: 'RU',
  },
})

const emits = defineEmits<{ (e: 'openSchedule', value: Mortgage): void }>()

const appStateStore = useAppStateStore()

const currency = computed(() => appStateStore.currency)
const isSm = computed(() => !appStateStore.breakpoints.isMd)
</script>

<template>
  <custom-table
    class="overflow-x-auto scrollbar-thin mt-[5px]"
    v-bind="$attrs"
  >
    <thead>
    <tr>
      <td class="font-semibold text-base w-1/4">
        {{ $t('mortgage.table.bank.label') }}
      </td>
      <td class="font-semibold text-base w-1/6">
        {{ $t('mortgage.table.stake.label') }}
      </td>
      <td class="font-semibold text-base w-1/6">
        {{ $t('mortgage.table.maxSum.label') }}
      </td>
      <td class="font-semibold text-base w-1/6">
        {{ $t('mortgage.table.term.label') }}
      </td>
      <td class="font-semibold text-base w-1/6">
        {{ $t('mortgage.table.monthlyPayment.label') }}
      </td>
      <td class="font-semibold text-base w-1/6 whitespace-nowrap">
        {{ isSm ? $t('mortgage.table.initialPayment.label.short') : $t('mortgage.table.initialPayment.label.full') }}
      </td>
      <td/>
    </tr>
    </thead>
    <tbody class="text-neutral-500">
    <template
      v-for="(mortgage) in mortgages"
      :key="mortgage.id"
    >
      <tr>
        <td class="w-1/4">
          <div class="flex items-start md:items-center gap-x-3">
            <nuxt-icon class="text-primary" name="entities/bank"/>
            <div class="flex flex-col gap-y-1">
                <span class="flex flex-col md:flex-row gap-x-1">
                  {{ `${mortgage.name} (${mortgage.stake}%)` }}
                </span>
              <span class="text-neutral-500 text-sm">
                  {{
                  getEntityName({
                    name: mortgage.bank.name,
                    internationalName: mortgage.bank.internationalName,
                    entityCountry: countryIsoCode
                  })
                }}
                </span>
            </div>
          </div>
        </td>
        <td class="w-1/6">
          {{ $t('mortgage.table.stake.stakeRange.inText', {value: mortgage.stake}) }}
        </td>
        <td class="font-semibold text-base w-1/6">
          {{
            $t('mortgage.table.maxSum.range', {sum: $n(mortgage.maxSum.amount)})
          }}&nbsp;{{ $t(`currency.options.${currency}`) }}
        </td>
        <td class="w-1/6">
          {{ $t('mortgage.table.term.termRange', {count: mortgage.termInMonths / 12}) }}
        </td>
        <td class="font-semibold text-base w-1/6">
          {{ $n(mortgage.monthPayment) }}&nbsp;{{ $t(`currency.options.${currency}`) }}
        </td>
        <td class="w-1/6">
          {{ $t('mortgage.table.initialPayment.initialPaymentRange', {value: mortgage.initialPayment}) }}
        </td>
        <td>
          <custom-button-icon
            class="bg-white"
            color="secondary"
            round
            icon="common/calendar-check"
            icon-class="text-2xl"
            @click="emits('openSchedule', mortgage)"
          />
        </td>
      </tr>
    </template>
    </tbody>
  </custom-table>
</template>
