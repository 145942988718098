import { DeveloperHttpApiService } from '~/modules/developer/api/DeveloperHttpApiService'
import type { Developer } from '~/common/types/developer/Developer'
import { HttpPaginatedResponseData } from '~/services/http/types/Http.paginatedResponseData'
import { InternationalAddress } from '~/modules/address/types/InternationalAddress'
import { Country } from '~/modules/address/types'

export class DeveloperService {
  private http: DeveloperHttpApiService

  constructor() {
    const nuxtApp = useNuxtApp()
    const httpInstance = nuxtApp.$qdHttpApiInstance
    this.http = new DeveloperHttpApiService(httpInstance)
  }

  search(filter: Pick<Developer, 'name'> & { internationalAddress?: InternationalAddress[] } & { country?: Country }, pageUrl?: string | null) {
    return new Promise<HttpPaginatedResponseData<Developer>>((resolve, reject) => {
      this.http.getDevelopers(filter, pageUrl).then(response => {
        resolve(response.data)
      }).catch(reject)
    })
  }

  getDeveloper(id: number) {
    return new Promise<Developer>((resolve, reject) => {
      this.http.getDeveloper(id).then(response => {
        resolve(response.data)
      }).catch(reject)
    })
  }
}
