import { MONTH } from '~/common/constants/date/Month'

const months = Object.values(MONTH)

export class DateTimeHelper {
  public static getUTCDateTimeStringFromDate(date: Date): string {
    const offset = date.getTimezoneOffset() * 60 * 1000
    return (new Date(date.getTime() - offset)).toISOString()
  }

  public static getDateStringFromDate(date: Date): string {
    return this.getUTCDateTimeStringFromDate(date).split('T')[0]
  }

  public static getTimeStringFromDate(date: Date) {
    const timeArray = this.getUTCDateTimeStringFromDate(date).split('T')[1].split(':')
    timeArray.pop()

    return timeArray.join(':')
  }

  public static getUTCDateTimeStringFromString(dateString: string): string | null {
    if (dateString) {
      const innerDate = dateString.endsWith('Z') ? dateString : (new Date(dateString)).toISOString()
      return innerDate.split(':00.')[0]
    }
    return null
  }

  public static getDateTimeStringWithMonthInterval(dateInterval: {start: string, end: string | null}): string {
    const { $i18n } = useNuxtApp()
    const dateStart: Date = new Date(dateInterval.start)
    const dateEnd: Date | null = dateInterval.end ? new Date(dateInterval.end) : null
    const today: Date = new Date()
    const isFullYearStartEndEqual: boolean = dateStart.getFullYear() === dateEnd?.getFullYear()
    const isFullYearStartTodayEqual: boolean = dateStart.getFullYear() === today.getFullYear()

    let dateTimeStringInterval = `${$i18n.t('building.promotions.promotionInterval')}`
    if (!dateEnd) {
      dateTimeStringInterval += ` ${$i18n.t('building.promotions.unlimited')}`
      if (dateStart > today) {
        dateTimeStringInterval += ` ${$i18n.t('common.time.from')} ${dateStart.getDate()}`
        dateTimeStringInterval += ` ${$i18n.t(`common.months.genitive.${months[dateStart.getMonth()]}`).toLocaleLowerCase()}`
        if (!isFullYearStartTodayEqual) {
          dateTimeStringInterval += ` ${dateStart.getFullYear()}`
        }
      }
    } else if (dateStart.getTime() !== dateEnd.getTime()) {
      dateTimeStringInterval += ` ${$i18n.t('common.time.from')} ${dateStart.getDate()}`
      if (dateStart.getMonth() !== dateEnd.getMonth() || !isFullYearStartEndEqual) {
        dateTimeStringInterval += ` ${$i18n.t(`common.months.genitive.${months[dateStart.getMonth()]}`).toLocaleLowerCase()}`
        if (!isFullYearStartEndEqual) {
          dateTimeStringInterval += ` ${dateStart.getFullYear()}`
        }
      }
      dateTimeStringInterval += ` ${$i18n.t('common.time.up')} ${dateEnd.getDate()}`
      dateTimeStringInterval += ` ${$i18n.t(`common.months.genitive.${months[dateEnd.getMonth()]}`).toLocaleLowerCase()}`
      if (!isFullYearStartEndEqual || !isFullYearStartTodayEqual) {
        dateTimeStringInterval += ` ${dateEnd.getFullYear()}`
      }
    } else {
      dateTimeStringInterval += ` ${dateStart.getDate()} ${$i18n.t(`common.months.genitive.${months[dateStart.getMonth()]}`)
        .toLocaleLowerCase()}`
      if (!isFullYearStartTodayEqual) {
        dateTimeStringInterval += ` ${dateStart.getFullYear()}`
      }
    }

    return dateTimeStringInterval
  }

  public static getDateTimeStringWithMonth(dateString: string, monthType: 'short' | 'full' | 'genitive' = 'short', withTime = true): string {
    const { $i18n } = useNuxtApp()
    const date = new Date(dateString)
    const today = new Date()

    let dateTimeStringWithMonth = ''

    dateTimeStringWithMonth += `${date.getDate()}`

    dateTimeStringWithMonth += ` ${$i18n.t(`common.months.${monthType}.${months[date.getMonth()]}`).toLocaleLowerCase()}`

    if (date.getFullYear() !== today.getFullYear()) {
      dateTimeStringWithMonth += ` ${date.getFullYear()}`
    }
    if (withTime) {
      dateTimeStringWithMonth += ` ${$i18n.t('common.time.at')} ${this.getTimeStringFromDate(date)}`
    }

    return dateTimeStringWithMonth
  }

  public static normalizeTimeLength(hoursOrMinutes: number): string {
    return hoursOrMinutes.toString().padStart(2, '0')
  }

  public static getArrayTime(timeStep = 15) {
    return Array.from({ length: 24 * 60 / timeStep }, (_, index) => {
      const hours = Math.floor(index * timeStep / 60)
      const minutes = index * timeStep % 60

      return `${this.normalizeTimeLength(hours)}:${this.normalizeTimeLength(minutes)}`
    })
  }
}
