import { HttpPaginatedResponseData } from '~/services/http/types/Http.paginatedResponseData'
import { HttpApiService } from '~/services/http/HttpApiService'
import { HttpHandledResponse } from '~/services/http/types/Http.handledResponse'
import { Service } from '~/services/http/Service'
import { HouseExpanded } from '~/common/types/house/House.expanded'
import { FlatFilterForRequest } from '~/modules/building/components/page/flats/filter/types/FlatsFilter'
import { HouseInfo } from '~/modules/house/types/HouseInfo'
import { FlatsSort } from '~/modules/building/types/Flat.sort'

export class HouseHttpApiService extends Service {
  private endPoints = {
    firstPart: '/v4/estate-market/development/houses',
    search: '/search',
    searchInfo: '/search-info',
  }

  constructor(httpInstance: HttpApiService) {
    super(httpInstance)
  }

  public search(filter: FlatFilterForRequest): Promise<HttpHandledResponse<Array<HouseExpanded>>> {
    return this.httpInstance.post(`${this.endPoints.firstPart}${this.endPoints.search}`, filter)
  }

  public searchInfo(filter: FlatFilterForRequest, sort: FlatsSort): Promise<HttpHandledResponse<HttpPaginatedResponseData<HouseInfo>>> {
    return this.httpInstance.post(`${this.endPoints.firstPart}${this.endPoints.searchInfo}`, { ...filter, sort })
  }
}
