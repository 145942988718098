import { defineStore } from 'pinia'
import { breakpointsTailwind } from '@vueuse/core'
import { Currency } from '~/common/types/money/Currency'
import { Platforms } from '~/common/types/platform/Platforms'
import { Capacitor } from '@capacitor/core'
import { PLATFORMS } from '~/common/constants/platform/Platforms'
import { QdStorage } from '~/common/storage/interfaces/QdStorage'
import { Measure } from '~/common/types/measure/Measure'

export const useAppStateStore = defineStore('appStateStore', {
  state: () => ({
    appPlatform: Capacitor.getPlatform() as Platforms,
    isFetchingApp: false,
    isFetching: false,
    isFetchingTokens: false,
    openElementsCount: {
      modals: 0,
    },
    size: null as keyof typeof breakpointsTailwind | null,
    scrollToTopBottom: 'bottom-[12%]',
    breakpoints: { isMd: false } as Record<'isMd' | 'isLg' | 'isXl', boolean>,
    // TODO типизировать стэйт
    currency: null as Currency,
    clientMode: false,
    measure: null as Measure,
  }),
  getters: {
    getSize() {
      return useBreakpoints()
    },
    isWeb(state) {
      return state.appPlatform === PLATFORMS.web
    },
  },
  actions: {
    setIsFetchingApp(value: boolean): void {
      this.isFetchingApp = value
    },
    setIsFetchingTokens(value: boolean): void {
      this.isFetchingTokens = value
    },
    setScrollToTopBottom(value: string): void {
      this.scrollToTopBottom = value
    },
    openModal() {
      this.openElementsCount.modals += 1
    },
    closeModal() {
      this.openElementsCount.modals -= 1
    },
    setCurrency(value: Currency) {
      const storage: QdStorage = useNuxtApp().$appStorage

      this.currency = value
      storage.setItem('currency', value)
      window.location.reload()
    },
    setMeasure(value: Measure) {
      const storage: QdStorage = useNuxtApp().$appStorage

      this.measure = value
      storage.setItem('measure', value)
    },

    setClientMode(value: boolean) {
      const storage: QdStorage = useNuxtApp().$appStorage

      storage.setItem('clientMode', value)
      this.clientMode = value
    },
  },
})
