import { COLOR } from '~/ui/constants/color'

const COLOR_CLASSES: Record<keyof typeof COLOR, { text: string, textActive?: string, textHover?: string, bg: string, bgHover?: string, bgActive?: string, border: string, borderActive?: string, borderHover?: string, placeholder?: string }> = {
  [COLOR.primary]: {
    text: 'text-primary',
    textHover: 'hover:text-white',
    textActive: 'active:text-white',
    bg: 'bg-primary-light',
    bgHover: 'hover:bg-primary',
    bgActive: 'active:bg-primary-dark',
    border: 'border-neutral-150',
    borderHover: 'hover:border-primary',
    borderActive: 'active:border-primary-dark',
    placeholder: 'placeholder-primary',
  },
  [COLOR.secondary]: {
    text: 'text-secondary',
    textHover: 'hover:text-white',
    textActive: 'active:text-white',
    bg: 'bg-secondary-light',
    bgHover: 'hover:bg-primary',
    bgActive: 'active:bg-primary-dark',
    border: 'border-secondary-light',
    borderHover: 'hover:border-primary',
    borderActive: 'active:border-primary-dark',
    placeholder: 'placeholder-primary',
  },
  [COLOR.light]: {
    text: 'text-light',
    bg: 'bg-light',
    bgActive: 'active:bg-light',
    border: 'border-light',
  },
  [COLOR.danger]: {
    text: 'text-danger',
    textHover: 'hover:text-white',
    textActive: 'active:text-white',
    bg: 'bg-danger-light',
    bgHover: 'hover:bg-danger',
    bgActive: 'active:bg-danger',
    border: 'border-danger',
  },
  [COLOR.success]: {
    text: 'text-success',
    bg: 'bg-success-light',
    bgActive: '',
    border: 'border-success',
  },
  [COLOR.warning]: {
    text: 'text-warning',
    bg: 'bg-warning-light',
    bgActive: '',
    border: 'border-warning',
  },
  [COLOR.info]: {
    text: 'text-primary',
    textHover: 'hover:text-white',
    textActive: 'active:text-white',
    bg: 'bg-white',
    bgHover: 'hover:bg-primary',
    bgActive: 'active:bg-primary-dark',
    border: 'border-white',
    borderHover: 'hover:border-primary',
    borderActive: 'active:border-primary-dark',
    placeholder: 'placeholder-primary',
  },
  [COLOR.transparent]: {
    text: 'text-white',
    textHover: 'hover:text-white',
    textActive: 'active:text-white',
    bg: 'bg-black bg-opacity-30 backdrop-blur-lg',
    bgHover: 'hover:bg-primary',
    bgActive: 'active:bg-primary-dark',
    border: 'border-secondary-light',
    borderHover: 'hover:border-primary',
    borderActive: 'active:border-primary-dark',
    placeholder: 'placeholder-primary',
  },
  [COLOR.negative]: {
    text: 'text-white',
    textHover: 'hover:text-white',
    textActive: 'active:text-white',
    bg: 'bg-red-900',
    bgHover: 'hover:bg-primary',
    bgActive: 'active:bg-primary-dark',
    border: 'border-secondary-light',
    borderHover: 'hover:border-primary',
    borderActive: 'active:border-primary-dark',
    placeholder: 'placeholder-primary',
  },
  [COLOR.dropdown]: {
    text: 'text-neutral-500',
    bg: 'bg-neutral-100',
    bgActive: '',
    border: '',
  },
  [COLOR.none]: {
    text: '',
    textHover: '',
    textActive: '',
    bg: '',
    bgHover: '',
    bgActive: '',
    border: '',
    borderHover: '',
    borderActive: '',
    placeholder: '',
  },
}

export const FILLED_COLOR_CLASSES: Record<keyof typeof COLOR, { bg: string, bgHover?: string, bgActive?: string }> = {
  [COLOR.primary]: {
    bg: 'bg-primary',
    bgHover: 'hover:bg-primary-dark',
    bgActive: 'active:bg-primary-dark',
  },
  [COLOR.secondary]: {
    bg: 'bg-secondary',
    bgHover: 'hover:bg-primary',
    bgActive: 'active:bg-primary-dark',
  },
  [COLOR.light]: {
    bg: 'bg-light',
    bgActive: 'active:bg-light',
  },
  [COLOR.danger]: {
    bg: 'bg-danger',
    bgActive: '',
  },
  [COLOR.success]: {
    bg: 'bg-success',
    bgActive: '',
  },
  [COLOR.warning]: {
    bg: 'bg-warning',
    bgActive: '',
  },
  [COLOR.info]: {
    bg: 'bg-white',
    bgHover: 'hover:bg-primary',
    bgActive: 'active:bg-primary-dark',
  },
  [COLOR.transparent]: {
    bg: 'bg-black bg-opacity-20',
    bgHover: 'hover:bg-primary',
    bgActive: 'active:bg-primary-dark',
  },
  [COLOR.negative]: {
    bg: 'bg-red-900',
    bgHover: 'hover:bg-primary',
    bgActive: 'active:bg-primary-dark',
  },
  [COLOR.dropdown]: {
    bg: 'bg-neutral-100',
    bgActive: '',
  },
  [COLOR.none]: {
    bg: '',
    bgHover: '',
    bgActive: '',
  },
}

export const DISABLED_CLASSES = {
  text: 'text-neutral-150',
  bg: 'bg-neutral-100',
  border: 'border-neutral-150',
}
export default COLOR_CLASSES
