<template>
  <div class="flex gap-x-2 md:gap-x-4 mt-4">
    <div
      class="relative h-[90px] flex items-center justify-center rounded-md overflow-hidden basis-full"
    >
      <custom-carousel
        v-model="carouselImageIndex"
        :images="images"
        carousel-class="overflow-hidden carousel_building-slider"
        size="md"
        :default-image="defaultImage"
        max-height="90px"
        :items-to-show="itemsToShow"
        snap-align="center-even"
        :is-open-modal-on-click="false"
        without-video
        :controls="false"
        :is-draggable="false"
        :first-show-image-index="firstShowImageIndex"
        :preferred-video-index="preferredVideoIndex"
      >
        <template #slide="{image, index}">
          <div
            class="relative w-full h-full flex items-center justify-center overflow-hidden rounded-lg"
            :class="{'border-2 border-primary': carouselImageIndex === index}"
            @click="carouselImageIndex = index"
          >
            <img
              class="h-full w-full object-cover rounded-lg p-[2px]"
              :src="image.link"
              :alt="image.alt"
              :class="{'cursor-pointer': carouselImageIndex !== index}"
            >
            <div
              v-if="image.video"
              class="absolute flex items-center justify-center w-full h-full"
              :class="{'cursor-pointer': carouselImageIndex !== index}"
            >
              <span class="bg-black/30 backdrop-blur-lg w-[45px] h-[45px] rounded-full flex items-center justify-center">
                <nuxt-icon
                  name="entities/video-review"
                  class="stroke-0 text-3xl text-white"
                  filled
                />
              </span>
            </div>
          </div>
        </template>
      </custom-carousel>
    </div>
  </div>
</template>
<script setup lang="ts">
import { Image } from '~/common/types/image/Image'
import CustomCarousel from '~/ui/carousels/CustomCarousel.vue'

const props = defineProps({
  modelValue: {
    type: Number,
    default: 0,
  },
  images: {
    type: Array as PropType<Image[]>,
    default: () => [],
  },
  itemsToShow: {
    type: Number,
    default: 4,
  },
  firstShowImageIndex: {
    type: Number,
    default: 0,
  },
  preferredVideoIndex: {
    type: Number,
    default: 1,
  },
})

const emits = defineEmits<{(e: 'update:modelValue', value: number): void}>()

const carouselImageIndex = computed({
  get: () => props.modelValue,
  set: (value: number) => {
    emits('update:modelValue', value)
  },
})
</script>

<style lang="scss">
.carousel_building-slider {
  .carousel__slide {
    @apply bg-white py-[4px];
  }

  .carousel__slide--visible {
    @apply  pl-0;

    & ~ .carousel__slide--visible {
      @apply pl-3;
    }
  }

  & .carousel__slide--active {
    @apply py-[2px];
  }
}
</style>
