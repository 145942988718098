<script lang="ts" setup>
import { DatePicker } from 'v-calendar'
import { AttributeConfig } from 'v-calendar/dist/types/src/utils/attribute'
import { Page, PageAddress } from 'v-calendar/dist/types/src/utils/page'
import CustomCalendarHeader from '~/ui/calendars/components/CustomCalendarHeader.vue'
import CustomCalendarMonths from '~/ui/calendars/components/CustomCalendarMonths.vue'
import CustomCalendarYears from '~/ui/calendars/components/CustomCalendarYears.vue'
import CustomCalendarHours from '~/ui/calendars/components/CustomCalendarHours.vue'
import CustomTimeInput from '~/ui/inputs/CustomTimeInput.vue'
import { WEEKDAY } from '~/common/constants/date/Weekday'
import 'v-calendar/style.css'
import { onClickOutside } from '@vueuse/core'

type DateOrDateRange = Date | {
  start: Date, end: Date,
}

const props = defineProps({
  modelValue: {
    type: Object as PropType<DateOrDateRange>,
    required: true,
  },
  initialPage: {
    type: Object as PropType<PageAddress>,
    default: undefined,
  },
  attributes: {
    type: Array as PropType<Array<AttributeConfig>>,
    default: () => [] as Array<AttributeConfig>,
  },
  timeFrom: {
    type: String,
    default: null,
  },
  withTimeFrom: {
    type: Boolean,
    default: false,
  },
  timeFromError: {
    type: String,
    default: null,
  },
  timeTo: {
    type: String,
    default: null,
  },
  withTimeTo: {
    type: Boolean,
    default: false,
  },
  timeToError: {
    type: String,
    default: null,
  },
  isRange: {
    type: Boolean,
    default: false,
  },
})

type Emits = {
  (e: 'update:modelValue', date: DateOrDateRange): void
  (e: 'update:timeFrom', time: string): void
  (e: 'update:timeTo', time: string): void
}

const emits = defineEmits<Emits>()

const today = new Date()
const currentMonth = today.getMonth() + 1
const currentYear = today.getFullYear()

const calendarRef = ref<InstanceType<typeof DatePicker> | null>(null)
const pages = ref<Array<Page>>([])
const isMonthSelecting = ref(false)
const isYearSelecting = ref(false)
const isTimeFromSelecting = ref(false)
const isTimeToSelecting = ref(false)

const timeFromRef = ref<HTMLDivElement | null>(null)
const timeToRef = ref<HTMLDivElement | null>(null)

const isAnySelecting = computed(() => isMonthSelecting.value || isYearSelecting.value || isTimeFromSelecting.value || isTimeToSelecting.value)

const selectedMonth = computed(() => pages.value[0]?.month || currentMonth)
const selectedYear = computed(() => pages.value[0]?.year || currentYear)

const date = computed({
  get: () => props.modelValue,
  set: value => {
    emits('update:modelValue', value)
  },
})

const timeFrom = computed({
  get: () => props.timeFrom,
  set: value => {
    emits('update:timeFrom', value)
  },
})

const timeTo = computed({
  get: () => props.timeTo,
  set: value => {
    emits('update:timeTo', value)
  },
})

const weekdays = Object.values(WEEKDAY)

const changePage = (month?: number, year?: number) => {
  calendarRef.value?.move({
    month: month || selectedMonth.value,
    year: year || selectedYear.value,
  })

  isMonthSelecting.value = false
  isYearSelecting.value = false
  isTimeFromSelecting.value = false
  isTimeToSelecting.value = false
}

onClickOutside(timeFromRef, () => {
  isTimeFromSelecting.value = false
})

onClickOutside(timeToRef, () => {
  isTimeToSelecting.value = false
})

watch(isTimeFromSelecting, value => {
  if (value) {
    isMonthSelecting.value = false
    isYearSelecting.value = false
    isTimeToSelecting.value = false
  }
})
watch(isTimeToSelecting, value => {
  if (value) {
    isMonthSelecting.value = false
    isYearSelecting.value = false
    isTimeFromSelecting.value = false
  }
})
</script>

<template>
  <div class="flex flex-col">
    <custom-calendar-header
      v-model:is-month-selecting="isMonthSelecting"
      v-model:is-year-selecting="isYearSelecting"
      class="mb-6"
      :pages="pages"
      :calendar-ref="calendarRef"
      :selected-month="selectedMonth"
      :selected-year="selectedYear"
      :current-year="currentYear"
    />
    <div class="w-[294px]">
      <custom-calendar-months
        v-if="isMonthSelecting"
        :selected-month="selectedMonth"
        @change-page="changePage"
      />
      <custom-calendar-years
        v-show="isYearSelecting"
        :is-year-selecting="isYearSelecting"
        :selected-year="selectedYear"
        :current-year="currentYear"
        @change-page="changePage"
      />
      <custom-calendar-hours
        v-if="withTimeFrom && isTimeFromSelecting"
        ref="timeFromRef"
        v-model="timeFrom"
      />
      <custom-calendar-hours
        v-if="withTimeTo && isTimeToSelecting"
        ref="timeToRef"
        v-model="timeTo"
      />
    </div>
    <div v-show="!isAnySelecting">
      <div class="border-b-2 mb-2 border-neutral-100 flex w-full">
        <div
          v-for="(weekday, inx) in weekdays"
          :key="weekday"
          class="text-lg font-medium flex-1 flex justify-center items-center h-[42px] w-full"
          :class="inx > 4 ? 'text-neutral-500' : 'text-neutral-950'"
        >
          <span>{{ $t(`common.weekdays.short.${weekday}`) }}</span>
        </div>
      </div>
      <!-- TODO is-range поменять на v-model.range -->
      <date-picker
        ref="calendarRef"
        v-model="date"
        class="qm-calendar montserrat"
        :initial-page="initialPage"
        trim-weeks
        :attributes="attributes"
        borderless
        :masks="{weekday: 'WW'}"
        :is-range="isRange"
        @update:pages="pages = $event"
      />
    </div>
    <div
      v-if="withTimeFrom || withTimeTo"
      class="flex gap-x-3 mt-6"
    >
      <div
        v-if="withTimeFrom"
        class="w-1/2"
      >
        <custom-time-input
          v-model="timeFrom"
          v-model:is-time-selecting="isTimeFromSelecting"
          :label="$t('common.time.label.from')"
          :time-error="timeFromError"
        />
      </div>
      <div
        v-if="withTimeTo"
        class="w-1/2"
      >
        <custom-time-input
          v-model="timeTo"
          v-model:is-time-selecting="isTimeToSelecting"
          :label="$t('common.time.label.to')"
          :time-error="timeToError"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.qm-calendar {
  .vc-header {
    @apply hidden;
  }

  .vc-weeks {
    @apply p-0;
  }

  .vc-weekdays {
    @apply hidden;
  }

  .vc-day {
    @apply text-neutral-950 w-[42px] h-[42px];

    &.weekday-1, &.weekday-7 {
      @apply text-neutral-500;
    }

    .vc-day-content {
      @apply text-lg;

      &.vc-highlight-content-solid {
        @apply text-primary;
      }
    }
  }

  .vc-day-layer {
    @apply hidden;
  }
}
</style>
