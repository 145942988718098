<template>
  <div
    class="min-w-[170px] max-w-[170px] min-h-[90px] text-neutral-500 font-semibold text-sm p-3 cursor-pointer flex flex-col test-border justify-between rounded-lg"
    :class="cardBackgroundColorClass"
  >
    <div class="flex justify-between mb-2">
      <div>
        {{ $t(`building.flatsInfo.flatRoomsCountBuildingCard.${flat.flatRoomsCount || 'notIndicated.short'}`) }}
      </div>
      <div>
        {{ $t('flat.card.flatNumber', {value: flat.flatNumber}) }}
      </div>
    </div>
    <div class="pl-[2px]">
      <div class="text-neutral-900 font-semibold text-base">
        {{ getPrice() }}
      </div>
      <div
        :class="statusTextColorClass"
      >
        {{ $t(`flat.status.options.${flat.status}`).toLowerCase() }}
      </div>
    </div>
    <div class="flex justify-between -mb-2">
      <div
        v-if="flat.decoration?.decorationType"
        class="-ml-2 p-2 rounded-tr"
        :class="bottomLabelsBackgroundColor"
      >
        {{ $t(`flat.decorationType.options.${flat.decoration.decorationType}.short`).toLowerCase() }}
      </div>
      <div
        class="-mr-2 p-2 rounded-tl"
        :class="bottomLabelsBackgroundColor"
      >
        {{ flat.totalArea }} {{ $t('common.unitsOfMeasurement.options.squareMeter') }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ChessboardFlat } from '~/modules/chessboard/types/Chessboard.flat'
import { FLAT_STATUS_CLASSES } from '~/common/constants/flat/Flat.statusClasses'

const props = defineProps({
  flat: {
    type: Object as PropType<ChessboardFlat>,
    required: true,
  },
})

const i18n = useI18n()

const getPrice = (): string => {
  const localeStringPrice = new Intl.NumberFormat(i18n.locale.value, {
    notation: 'compact',
    maximumFractionDigits: 1
  }).format(props.flat.price.amount)
  const currency = i18n.t(`currency.options.${props.flat.price.currency}`)
  return `${localeStringPrice} ${currency}`
}
const cardBackgroundColorClass = computed(() => FLAT_STATUS_CLASSES[props.flat?.status]?.bg ?? '')

const statusTextColorClass = computed(() => FLAT_STATUS_CLASSES[props.flat?.status]?.text ?? '')

const bottomLabelsBackgroundColor = computed(() => FLAT_STATUS_CLASSES[props.flat?.status]?.labelBg ?? '')
</script>
