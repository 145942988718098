<template>
  <div class="flex">
    <custom-button-icon
      v-if="!isMd"
      icon="entities/building"
      color="none"
      variant="withoutBorders"
      class="bg-white text-neutral-500"
      round
      @click="isModalOpen = true"
    />
    <custom-button
      v-else
      color="transparent"
      class="text-center text-sm font-semibold whitespace-nowrap py-2 px-4"
      size="none"
      pill
      @click="isModalOpen = true"
    >
      <template #prefix>
        <nuxt-icon
          name="entities/building"
          class="text-xs"
        />
      </template>
      <span
        class="max-w-[150px] overflow-x-hidden text-ellipsis"
      >
        {{
          getEntityName({
            name: building.name,
            internationalName: building.internationalName,
            entityCountry: building.address?.countryIsoCode
          })
        }}
      </span>
    </custom-button>
    <modal-or-bottom-sheet
      v-model="isModalOpen"
      :modal-props="{hideFooter: true}"
    >
      <displayed-building
        v-if="displayType === DISPLAY_TYPE.presentation"
        :building="building"
        :with-map="false"
      />
      <flat-displayed-building
        v-if="displayType === DISPLAY_TYPE.basic"
        :building="building"
      />
    </modal-or-bottom-sheet>
  </div>
</template>
<script setup lang="ts">
import CustomButton from '~/ui/buttons/CustomButton.vue'
import type { Building } from '~/modules/building/BuildingModule'
import ModalOrBottomSheet from '~/components/common/ModalOrBottomSheet.vue'
import DisplayedBuilding from '~/modules/suggestions/components/page/displayed-building/DisplayedBuilding.vue'
import DISPLAY_TYPE from '~/common/constants/flat/Flat.displayType'
import FlatDisplayedBuilding from '~/components/flats/flat-list-card/basic/FlatDisplayedBuilding.vue'
import CustomButtonIcon from '~/ui/buttons/CustomButtonIcon.vue'
import { useAppStateStore } from '~/store/app'
import { getEntityName } from '~/common/helpers/getEntityName'

defineProps({
  building: {
    type: Object as PropType<Building>,
    required: true,
  },
  displayType: {
    type: String as PropType<DISPLAY_TYPE>,
    default: DISPLAY_TYPE.presentation,
  },
})

const isModalOpen = ref(false)
const isMd = computed(() => useAppStateStore().breakpoints.isMd)
</script>
