<script lang="ts" setup>
import { useScroll, watchOnce } from '@vueuse/core'
import CustomButton from '~/ui/buttons/CustomButton.vue'

const props = defineProps({
  selectedYear: {
    type: Number,
    default: 1,
  },
  isYearSelecting: {
    type: Boolean,
    default: false,
  },
  currentYear: {
    type: Number,
    default: 0,
  },
})

const emits = defineEmits<{(e: 'changePage', month?: number, year?: number): void }>()

const addingYearsCount = 30

const yearDivRef = ref<HTMLDivElement | null>(null)
const selectedYearRef = ref<Array<HTMLDivElement> | null>(null)
const years = ref<Array<number>>([props.currentYear, props.currentYear + 1])

const addAfterYears = () => {
  const lastYear = years.value[years.value.length - 1]

  const yearsForAdd = Array.from({ length: addingYearsCount }, (_, i) => lastYear + i + 1)

  years.value.push(...yearsForAdd)
}

const addBeforeYears = () => {
  const firstYear = years.value[0]

  const yearsForAdd = Array.from({ length: addingYearsCount }, (_, i) => firstYear - i - 1)

  years.value.unshift(...yearsForAdd.reverse())
}

const { arrivedState } = useScroll(yearDivRef, {
  offset: { top: 30, bottom: 30 },
})

watch(arrivedState, value => {
  if (value.top) {
    addBeforeYears()
  }
  if (value.bottom) {
    addAfterYears()
  }
})

watchOnce(() => props.isYearSelecting, value => {
  if (value && yearDivRef.value) {
    nextTick().then(() => {
      const offset = selectedYearRef.value?.[0].offsetTop
      yearDivRef.value?.scrollBy({
        top: offset || 0,
      })
    })
  }
})

onMounted(() => {
  addAfterYears()
  addBeforeYears()
})
</script>

<template>
  <div
    ref="yearDivRef"
    class="flex flex-wrap -m-[5px] max-h-[280px] overflow-y-auto relative"
  >
    <div
      v-for="year in years"
      :key="year"
      :ref="year === selectedYear ? 'selectedYearRef' : ''"
      class="p-[5px] w-1/2"
    >
      <custom-button
        :ref="year === selectedYear ? 'itemRefs' : ''"
        class="w-full font-semibold hover:text-white hover:bg-primary"
        :class="{'bg-primary-light text-primary': year === selectedYear}"
        color="none"
        pill
        @click="emits('changePage', undefined, year)"
      >
        {{ year }}
      </custom-button>
    </div>
  </div>
</template>
