<template>
  <custom-tooltip placement="bottom">
    <template #content>
      {{ $t('building.actions.addToSuggestion.label') }}
    </template>
    <custom-button-icon
      icon="common/circle-plus-regular"
      color="secondary"
      round
      @click="showAuthModalOrCallback(addToSuggestions)"
    />
  </custom-tooltip>
</template>

<script setup lang="ts">
import CustomTooltip from '~/ui/tooltips/CustomTooltip.vue'
import type { Building } from '~/modules/building/types/Building'
import { useBuildingStore } from '~/modules/building/store'
import CustomButtonIcon from '~/ui/buttons/CustomButtonIcon.vue'
import { showAuthModalOrCallback } from '~/modules/auth/helpers/showAuthModalOrCallback'

const props = defineProps({
  building: {
    type: Object as PropType<Building>,
    required: true,
  },
})

const store = useBuildingStore()

const addToSuggestions = () => {
  store.buildingsToAddToSuggestion.push(props.building as Building)
  store.isDisplaySuggestionsModal = true
}
</script>
