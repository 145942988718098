<template>
  <custom-input
    id="phone"
    ref="inputRef"
    v-model="phoneValueWithMask"
    :label="label"
    :label-class="labelClass"
    input-id="phone"
    autocomplete="tel"
    type="tel"
    :placeholder="placeholder"
    :disabled="disabled"
    :state="errorMessage ? false : null"
    :input-class="[inputClass, 'bg-inherit']"
    :invalid-feedback="errorMessage"
    :required="required"
    :wrapper-class="wrapperClass"
  >
    <template
      v-if="$slots.prefixInner"
      #prefixInner
    >
      <slot name="prefixInner" />
    </template>
    <template
      v-if="$slots.prefix"
      #prefix
    >
      <slot name="prefix" />
    </template>
    <template
      v-if="$slots.suffix"
      #suffix
    >
      <slot name="suffix" />

    </template>
    <template
      v-if="$slots.suffixInner"
      #suffixInner
    >
      <slot name="suffixInner" />

    </template>
  </custom-input>
</template>

<script setup lang="ts">
import { useField } from 'vee-validate'
import {
  AsYouType,
  isValidPhoneNumber,
  parsePhoneNumber,
} from 'libphonenumber-js'
import CustomInput from '~/ui/inputs/CustomInput.vue'

const props = defineProps({
  mobilePhone: {
    type: String as PropType<string | null>,
    default: null,
  },
  label: {
    type: String,
    default: '',
  },
  required: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  errors: {
    type: Array as PropType<Array<string>>,
    default: () => [],
  },
  inputClass: {
    type: [String, Object, Array],
    default: '',
  },
  labelClass: {
    type: [String, Object, Array],
    default: '',
  },
  wrapperClass: {
    type: [String, Object, Array],
    default: '',
  },
})

const emits = defineEmits<{(e: 'update:mobilePhone', value: string): void}>()

const { t } = useI18n()
const inputRef = ref<{inputRef: HTMLInputElement | null}>({ inputRef: null })
const caretPosition = ref<number|null>(null)
const phoneRandomName = `phone${Math.random().toString(16).slice(2)}`
const phoneValueWithMask = computed({
  get: () => {
    const { value } = validateValue
    if (value && !value.startsWith('+')) {
      return `+${value}`
    }
    return value
  },
  set: value => {
    if (value) {
      const caretPos = inputRef.value?.inputRef ? inputRef.value.inputRef.selectionStart : null
      const maskedNumber = new AsYouType().input(value)
      caretPosition.value = caretPos ? caretPos + maskedNumber.length - value.length : caretPos
      setValue(maskedNumber)
    } else {
      setValue('')
    }
  },
})

const validatePhone = (phoneNumber: string) => isValidPhoneNumber(phoneNumber)

const setInitPhone = (value: string): string => {
  const isValidNumber = isValidPhoneNumber(value)
  if (!isValidNumber) { return '' }

  const parsedPhoneNumber = parsePhoneNumber(value)
  if (parsedPhoneNumber) {
    return new AsYouType().input(value)
  }
  return ''
}

const { value: validateValue, errorMessage, setErrors, setValue } = useField(phoneRandomName, (value: string) => {
  if (!value) {
    if (!props.required) {
      return true
    }
    return t('validation.messages.required', { label: props.label || t('auth.userPhone.label') })
  }
  if (!validatePhone(value)) {
    return t('auth.userPhone.error')
  }
  return true
}, {
  label: props.label ?? t('auth.userPhone.label'),
  initialValue: setInitPhone(props.mobilePhone || ''),
  standalone: true,
})

watch(phoneValueWithMask, value => {
  if (validatePhone(value)) {
    emits('update:mobilePhone', parsePhoneNumber(value).number)
    return
  }
  if (props.mobilePhone) {
    emits('update:mobilePhone', '')
  }
})
watchEffect(() => {
  if (props.errors) {
    setErrors(props.errors)
  }
})
watch(validateValue, value => {
  nextTick().then(() => {
    if (inputRef.value?.inputRef && caretPosition.value !== null && value.length !== caretPosition.value) {
      inputRef.value.inputRef.setSelectionRange(caretPosition.value, caretPosition.value)
    }
  })
})
watch(() => props.mobilePhone, value => {
  if (value && validatePhone(value)) {
    setValue(setInitPhone(value))
  }
  if (value === null) {
    setValue('')
    emits('update:mobilePhone', '')
  }
})
</script>
