export class ArrayHelper {
  static delete<T>(array: Array<T>, cb: (element: T) => boolean) {
    const index = array.findIndex(cb)
    if (index !== -1) {
      array.splice(index, 1)
    }
  }

  static add<T>(array: Array<T>, ...elements: T[]) {
    elements.forEach(element => {
      if (!array.includes(element)) {
        array.push(element)
      }
    })
  }

  static toggle<T>(array: Array<T>, ...elements: T[]) {
    elements.forEach(element => {
      if (!array.includes(element)) {
        array.push(element)
      } else {
        array.splice(array.indexOf(element), 1)
      }
    })
  }
}
