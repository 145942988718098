<template>
  <div class="flex justify-center gap-2 w-full">
    <flat-building
      class="md:hidden"
      :class="{'hidden': view === FLATS_DISPLAYING_ENTITY.grid}"
      :building="flat.building"
    />
    <flats-btn-remove-from-suggestion
      class="shrink-0"
      :class="buttonClass"
      @click="emits('remove')"
    />
    <custom-dropdown
      :display-dropdown-arrow="false"
      right
      body-class="w-fit ml-auto"
    >
      <template #default>
        <div class="flex gap-x-2">
          <flats-btn-copy-link />
          <flats-btn-book />
          <flats-btn-client-fixation />
        </div>
      </template>
      <template #trigger="{toggle}">
        <custom-button-icon
          :class="buttonClass"
          icon="common/three-dots"
          color="secondary"
          round
          @click="toggle"
        />
      </template>
    </custom-dropdown>
  </div>
</template>
<script setup lang="ts">

import type { Flat } from '~/common/types/flat/Flat'
import FlatsBtnCopyLink from '~/components/flats/buttons/basic/FlatsBtnCopyLink.vue'
import FlatsBtnClientFixation from '~/components/flats/buttons/basic/FlatsBtnClientFixation.vue'
import FlatsBtnBook from '~/components/flats/buttons/basic/FlatsBtnBook.vue'
import FlatsBtnRemoveFromSuggestion from '~/components/flats/buttons/suggestion-management/FlatsBtnRemoveFromSuggestion.vue'
import FlatBuilding from '~/components/flats/flat-list-card/FlatBuilding.vue'
import CustomDropdown from '~/ui/dropdowns/CustomDropdown.vue'
import CustomButtonIcon from '~/ui/buttons/CustomButtonIcon.vue'
import { FLATS_DISPLAYING_ENTITY } from '~/modules/building/components/page/flats/filter/constants/FlatsDisplaying.entity'

const props = defineProps({
  flat: {
    type: Object as PropType<Flat>,
    required: true,
  },
  view: {
    type: String as PropType<typeof FLATS_DISPLAYING_ENTITY.grid | typeof FLATS_DISPLAYING_ENTITY.table >,
    default: FLATS_DISPLAYING_ENTITY.grid,
  },
  buttonClass: {
    type: [String, Object, Array],
    default: '',
  },
})

const emits = defineEmits<{(e: 'remove'): void, }>()

</script>
