<script lang="ts" setup>
import CustomInput from '~/ui/inputs/CustomInput.vue'
import CustomDropdown from '~/ui/dropdowns/CustomDropdown.vue'
import CustomDatePicker from '~/ui/calendars/CustomDatePicker.vue'

const props = defineProps({
  date: {
    type: Date,
    required: true,
    default: null,
  },
  dateError: {
    type: String,
    default: null,
  },
  dateLabel: {
    type: String,
    default: null,
  },
  datePlaceholder: {
    type: String,
    default: null,
  },
  withTimeFrom: {
    type: Boolean,
    default: false,
  },
  timeFrom: {
    type: String,
    default: null,
  },
  timeFromError: {
    type: String,
    default: null,
  },
  withTimeTo: {
    type: Boolean,
    default: false,
  },
  timeTo: {
    type: String,
    default: null,
  },
  timeToError: {
    type: String,
    default: null,
  },
})

type Emits = {
  (e: 'update:date', value: Date | null): void
  (e: 'update:timeFrom', value: string | null): void
  (e: 'update:timeTo', value: string | null): void
}

const emits = defineEmits<Emits>()

const { t, locale } = useI18n()

const inputValue = ref('')
const date = computed({
  get: () => props.date,
  set: value => {
    emits('update:date', value)
  },
})
const timeFrom = computed({
  get: () => props.timeFrom,
  set: value => {
    emits('update:timeFrom', value)
  },
})
const timeTo = computed({
  get: () => props.timeTo,
  set: value => {
    emits('update:timeTo', value)
  },
})

const setInputValue = () => {
  let inputValueInString = ''
  if (date.value) {
    inputValueInString += date.value.toLocaleDateString(locale.value)
  }
  if (props.withTimeFrom && !props.withTimeTo && timeFrom.value) {
    inputValueInString += ` ${t('common.time.at')} ${timeFrom.value}`
  }
  if (props.withTimeFrom && props.withTimeTo) {
    if (timeFrom.value) {
      inputValueInString += ` ${t('common.time.from')} ${timeFrom.value}`
    }

    if (timeTo.value) {
      inputValueInString += ` ${t('common.time.to')} ${timeTo.value}`
    }
  }

  inputValue.value = inputValueInString
}

watch(date, () => {
  setInputValue()
})

watch(timeFrom, () => {
  setInputValue()
})

watch(timeTo, () => {
  setInputValue()
})

onMounted(() => {
  setInputValue()
})
</script>

<template>
  <custom-dropdown
    :close-on-select="false"
  >
    <template #trigger="{toggle}">
      <div @click="toggle">
        <custom-input
          v-model="inputValue"
          :label="dateLabel"
          :placeholder="datePlaceholder"
          :state="dateError || timeFromError || timeToError ? false : null"
          :invalid-feedback="dateError || timeFromError || timeToError"
          readonly
          @click="toggle"
        />
      </div>
    </template>
    <div class="flex flex-col gap-y-6">
      <custom-date-picker
        v-model="date"
        v-model:time-from="timeFrom"
        v-model:time-to="timeTo"
        :with-time-from="withTimeFrom"
        :with-time-to="withTimeTo"
        :time-from-error="timeFromError"
        :time-to-error="timeToError"
      />
    </div>
  </custom-dropdown>
</template>
