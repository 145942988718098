<template>
  <custom-button
    class="text-left ml-auto font-semibold flex items-center"
    color="transparent"
    pill
    @click.stop="isModalVisible = true"
  >
    <slot>
      <nuxt-icon clsas="text-lg" name="entities/decoration" />
      <span class="ml-1">{{ flat && $t(`flat.decorationType.options.${flat.decoration.decorationType}.short`) }}</span>
    </slot>
  </custom-button>
  <modal-or-bottom-sheet
    v-model="isModalVisible"
    :drawer-props="{fullHeight: true}"
    :modal-props="{hideFooter: true, size: 'w-full max-w-[60vw] h-full'}"
    :title="$slots.modalHeader ? '' : $t('flat.decorationType.label')"
  >
    <template #header>
      <slot name="modalHeader" />
    </template>
    <slot name="modal">
      <custom-carousel
        :images="flat.decoration.images"
        max-height="50vh"
        min-height="50vh"
        :default-image="defaultImage"
        :is-open-modal-on-click="false"
      />
    </slot>
  </modal-or-bottom-sheet>
</template>
<script setup lang="ts">

import CustomButton from '~/ui/buttons/CustomButton.vue'
import type { Flat } from '~/common/types/flat/Flat'
import type { Building } from '~/modules/building/types/Building'
import ModalOrBottomSheet from '~/components/common/ModalOrBottomSheet.vue'
import CustomCarousel from '~/ui/carousels/CustomCarousel.vue'
import defaultImage from '~/assets/images/building/decoration-default-image.svg'

const isModalVisible = ref(false)

defineProps({
  flat: {
    type: Object as PropType<Flat & {building: Building}>,
    default: null,
  },
})
</script>
