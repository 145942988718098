<template>
  <slot name="label" />
  <vue-select
    v-bind="$attrs"
    ref="select"
    v-model="selected"
    :placeholder="placeholder"
    :searchable="isSearchable"
    class="w-full"
  >
    <template
      v-for="(_, slot) of $slots"
      #[slot]="scope"
      :key="slot"
    >
      <slot
        :name="slot"
        v-bind="scope"
      />
    </template>
    <template #search="{ attributes, events }">
      <input
        v-if="isShowValues"
        class="h-6 focus:ring-0 flex-shrink-1 !placeholder-primary text-center vs__search"
        :class="inputClass"
        v-bind="attributes"
        v-on="events"
      >
      <custom-button
        v-else
        color="secondary"
        class="flex border-none flex-nowrap justify-between focus:ring-0 font-semibold w-full focus:border-neutral-35"
        :class="buttonClass"
        v-bind="attributes"
        v-on="events"
      >
        <slot name="placeholder">
          {{ placeholder }}
        </slot>
        <client-only>
          <font-awesome-icon
            size="sm"
            :icon="['fas', 'chevron-down']"
            class="ml-2"
          />
        </client-only>
      </custom-button>
    </template>
    <template #no-options>
      {{ $t('common.noOptions') }}
    </template>
    <template #selected-option-container="{option, deselect}">
      <custom-badge
        v-show="isShowValues && (selected.indexOf(option) < selected.length - hiddenOptionsCount)"
        size="xs"
        class="selected-option whitespace-nowrap"
      >
        <template v-if="hiddenOptionsCount && selected.indexOf(option) === selected.length - hiddenOptionsCount - 1">
          +{{ hiddenOptionsCount + 1 }}
        </template>
        <template v-else>
          <span>
            {{ shortenLabel(option.label) }}
          </span>
          <nuxt-icon
            name="common/circle-close-regular"
            class="ml-1 cursor-pointer"
            @click="deselect(option)"
          />
        </template>
      </custom-badge>
    </template>
    <template #open-indicator="{ attributes }">
      <client-only v-if="isShowValues">
        <font-awesome-icon
          v-bind="attributes"
          icon="fa-solid fa-chevron-down"
        />
      </client-only>
      <span v-else />
    </template>
  </vue-select>
  <span
    v-if="errorMessage"
    class="text-danger"
  >
    {{ errorMessage }}
  </span>
</template>
<script setup lang="ts">
import VueSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import CustomBadge from '~/ui/badges/CustomBadge.vue'
import CustomButton from '~/ui/buttons/CustomButton.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

const props = defineProps({
  placeholder: {
    type: String,
    default: null,
  },
  modelValue: {
    type: [Array, Object],
    required: true,
  },
  isShowValues: {
    type: Boolean,
    default: true,
  },
  isSearchable: {
    type: Boolean,
    default: true,
  },
  inputClass: {
    type: [Array, String, Object],
    default: '',
  },
  buttonClass: {
    type: [Array, String, Object],
    default: '',
  },
  errorMessage: {
    type: String,
    default: null,
  },
})
const emits = defineEmits<{(e: 'update:modelValue', value: string | number | Array<any>): void }>()

const selected = computed({
  get: () => (Array.isArray(props.modelValue) ? props.modelValue : [props.modelValue]),
  set(value) {
    emits('update:modelValue', value)
  },
})
const select = ref<typeof VueSelect>()
const isShortLabels = ref(false)
const hiddenOptionsCount = ref(0)

const shortenLabel = (label: string) => (isShortLabels.value ? label[0] : label)

const getHiddenOptionsCount = () => {
  const element = select.value.$refs.selectedOptions as HTMLDivElement
  const optionElements = [...element.querySelectorAll('.selected-option')]
  const hiddenOptions = optionElements.filter(optionElement => optionElement.getBoundingClientRect().right > element.getBoundingClientRect().right)

  return hiddenOptions.length
}
const getHiddenOptions = () => {
  isShortLabels.value = false
  hiddenOptionsCount.value = 0
  nextTick().then(() => {
    const count = getHiddenOptionsCount()
    isShortLabels.value = count !== 0
    if (count !== 0) {
      nextTick().then(() => {
        hiddenOptionsCount.value = getHiddenOptionsCount()
      })
    } else {
      hiddenOptionsCount.value = 0
    }
  })
}

onMounted(getHiddenOptions)
watch(() => selected.value, () => {
  nextTick().then(getHiddenOptions)
}, { deep: true })

</script>
<style lang="scss">
.vs__dropdown-toggle {
  @apply border-none p-0;
}

.vs__selected-options {
  @apply flex-nowrap p-0;
}

.vs__dropdown-menu {
  @apply border-none rounded-[6px] p-6 shadow-card min-w-[250px] right-0 md:right-auto left-auto md:left-0;
}

.vs__dropdown-option {
  @apply py-2  rounded-md;
  &--selected {
    @apply bg-blue-100;
  }
}

.vs__actions {
  @apply p-0;
}

.vs__search {
  margin-top: 0;
  border: none !important;
  font-size: 16px;

  &::placeholder {
    opacity: 0.5;
    font-size: 12px;
  }
}
</style>
