<template>
  <custom-toast
    v-if="isDisplayWarning"
    :toast-title="$t('clients.fixation.warning.title')"
    variant="warning"
    @close="isDisplayWarning = false"
  >
    <p class="font-medium text-neutral-750">
      <span>{{ $t('clients.fixation.warning.text') }}</span> <span class="font-bold text-neutral-950">{{ $t('clients.fixation.warning.fixPeriod') }}</span>
    </p>
  </custom-toast>
  <custom-toast
    :toast-title="$t('clients.fixation.info.title')"
    variant="success"
    :closable="false"
    class="mt-5"
  >
    <p class="font-medium text-neutral-750">
      {{ $t('clients.fixation.info.text') }}
      <a
        href="#"
        class="text-primary"
      >{{ $t('clients.fixation.info.link') }}</a>
    </p>
  </custom-toast>
</template>

<script setup lang="ts">
import CustomToast from '~/ui/toasts/CustomToast.vue'
import type { PropType } from 'vue'
import type { Developer } from '~/common/types/developer/Developer'
import { isEmpty } from '~/common/helpers/checkers'

const props = defineProps({
  developer: {
    type: Object as PropType<Developer | null>,
    default: null,
  },
})

const isDeveloperFixationTermsEmpty = computed(() => props.developer && isEmpty(props.developer.clientFixationTerms))
const isDisplayWarning = ref(isDeveloperFixationTermsEmpty.value)

watch(() => props.developer?.id, () => {
  isDisplayWarning.value = isDeveloperFixationTermsEmpty.value
})
</script>
