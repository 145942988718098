import { useMarketEntitiesActionsStore } from '~/modules/market-entities-actions/store'
import { defaultFilter } from '~/modules/market-entities-actions/constants/filter/Filter.default'
import {
  MarketEntitiesActionsHttpApiService,
} from '~/modules/market-entities-actions/api/MarketEntitiesActionsHttpApiService'
import { MarketEntitiesFilter } from '~/modules/market-entities-actions/types/filter/Filter'
import {
  MarketEntitiesFilterForRequest,
} from '~/modules/market-entities-actions/types/filter/Filter.forSearchRequest'
import { ObjectHelper } from '~/common/helpers/object'
import { ArrayHelper } from '~/common/helpers/arrays'
import { HttpApiService } from '~/services/http/HttpApiService'
import { HttpHandledResponse } from '~/services/http/types/Http.handledResponse'
import { Flat } from '~/common/types/flat/Flat'
import { Building } from '~/modules/building/BuildingModule'
import { Layout } from '~/common/types/layout/Layout'
import { MarketEntitiesSearchResponse } from '~/modules/market-entities-actions/types/search/Search.response'
import { DISPLAYING_ENTITY } from '~/modules/market-entities-actions/constants/displaying/Displaying.entity'
import { MarketEntitiesSearchResponseData } from '~/modules/market-entities-actions/types/search/Search.responseData'
import { EntitiesCount } from '~/common/types/entitiesCount/EntitiesCount'
import SentryLogger from '~/services/sentry/SentryLogger'
import { Country } from '~/modules/address/types'

export class FilterService {
  private store

  private http: MarketEntitiesActionsHttpApiService

  constructor() {
    const nuxtApp = useNuxtApp()

    this.store = useMarketEntitiesActionsStore()
    this.http = new MarketEntitiesActionsHttpApiService(nuxtApp.$qdHttpApiInstance as HttpApiService)
  }

  static transformFilterForRequest(filter: MarketEntitiesFilter): MarketEntitiesFilterForRequest {
    const filterForRequest = JSON.parse(JSON.stringify(filter))
    filterForRequest.buildingFilter.excludedIds = filter.buildingFilter.excluded.map(building => building.id)
    filterForRequest.buildingFilter.includedIds = filter.buildingFilter.included.map(building => building.id)
    filterForRequest.buildingFilter.metro.excludedStationsIds = filter.buildingFilter.metro.excludedStations.map(station => station.id)
    filterForRequest.buildingFilter.metro.includedStationsIds = filter.buildingFilter.metro.includedStations.map(station => station.id)
    filterForRequest.developerFilter.excludedIds = filter.developerFilter.excluded.map(developer => developer.id)
    filterForRequest.developerFilter.includedIds = filter.developerFilter.included.map(developer => developer.id)

    delete filterForRequest.buildingFilter.excluded
    delete filterForRequest.buildingFilter.included
    delete filterForRequest.buildingFilter.metro.includedStations
    delete filterForRequest.buildingFilter.metro.excludedStations
    delete filterForRequest.developerFilter.included
    delete filterForRequest.developerFilter.excluded

    return filterForRequest
  }

  public search(
    entityName = this.store.displayingEntity,
    page = this.store.pagination.currentPage,
    filter = this.store.filter,
    sort = this.store.sort,
  ): Promise<MarketEntitiesSearchResponseData> {
    return new Promise((resolve, reject) => {
      let searchPromise: Promise<HttpHandledResponse<MarketEntitiesSearchResponse<Building | Flat | Layout>>> | null = null
      const filterForRequest = FilterService.transformFilterForRequest(filter)
      switch (entityName) {
        case DISPLAYING_ENTITY.flats:
        {
          searchPromise = this.http.searchFlats(
            filterForRequest,
            page,
            sort,
          )
          break
        }
        case DISPLAYING_ENTITY.layouts:
        {
          searchPromise = this.http.searchLayouts(
            filterForRequest,
            page,
            sort,
          )
          break
        }
        case DISPLAYING_ENTITY.buildings:
        {
          searchPromise = this.http.searchBuildings(
            filterForRequest,
            page,
            sort,
          )
          break
        }
        default: {
          throw new Error('this entityName is not defined')
        }
      }
      searchPromise
        .then(response => {
          switch (entityName) {
            case DISPLAYING_ENTITY.flats:
            {
              resolve({ entityName, ...response.data as MarketEntitiesSearchResponse<Flat> })
              break
            }
            case DISPLAYING_ENTITY.layouts:
            {
              resolve({ entityName, ...response.data as MarketEntitiesSearchResponse<Layout> })
              break
            }
            case DISPLAYING_ENTITY.buildings:
            {
              resolve({ entityName, ...response.data as MarketEntitiesSearchResponse<Building> })
              break
            }
            default: {
              throw new Error('this entityName is not defined')
            }
          }
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Проблема с фильтром',
            entityName,
            page,
            filter,
            sort,
          })
          reject(error)
        })
    })
  }

  public resetFilterValue(path: string, value?: unknown, filter = this.store.filter) {
    const filterValue = ObjectHelper.getNestedValue(filter as Record<string, any>, path)
    if (path === 'buildingFilter.geometriesCoordinates') {
      const filterCopy = ObjectHelper.copy(filter)
      filterCopy.buildingFilter.geometriesCoordinates = []
      this.store.setFilter(filterCopy)
    } else if (Array.isArray(filterValue) && typeof value !== 'undefined') {
      ArrayHelper.delete(filterValue, element => element === value)
    } else {
      ObjectHelper.setNestedValue(filter as Record<string, any>, path, ObjectHelper.getNestedValue(defaultFilter() as Record<string, any>, path))
    }
  }

  public getSelectedFilters(filter = this.store.filter) {
    {
      const deadLineYearPaths = [
        'houseFilter.deadLine.from',
        'houseFilter.deadLine.to',
      ]
      const deadLineGettingKeysYearPaths = [
        'houseFilter.keysGetting.from',
        'houseFilter.keysGetting.to',
      ]
      const liftsPaths = [
        'houseFilter.lifts.hasPassenger',
        'houseFilter.lifts.hasCargo',
        'houseFilter.lifts',
      ]
      const floorNumbersPaths = [
        'flatFilter.floorNumber.from',
        'flatFilter.floorNumber.to',
        'flatFilter.floorNumber',
      ]
      const totalAreaPaths = ['flatFilter.totalArea.from', 'flatFilter.totalArea.to', 'flatFilter.totalArea']
      const kitchenAreaPaths = ['flatFilter.kitchenArea.from', 'flatFilter.kitchenArea.to', 'flatFilter.kitchenArea']
      const flatRoomsCountPath = 'flatFilter.flatRoomsCount'
      const notFirstFloorPath = 'flatFilter.notFirstFloor'
      const lastFloorPath = 'flatFilter.lastFloor'
      const windowViewPath = 'flatFilter.windowsViewTypes'
      const materialPath = 'houseFilter.materialTypes'
      const flatDecorationTypePath = 'flatFilter.decorationTypes'
      const countryPath = 'buildingFilter.country'
      const regionPath = 'buildingFilter.internationalAddress'
      const deadLineYearPath = 'houseFilter.deadLine'
      const deadLineGettingKeysYearPath = 'houseFilter.keysGetting'
      const buildingClassesPath = 'buildingFilter.classes'
      const buildingGeometriesPath = 'buildingFilter.geometriesCoordinates'

      const selected: Array<{path: string, value?: any}> = []
      const filterPaths = ObjectHelper.getNestedPaths(filter, deadLineYearPaths, [countryPath, regionPath, ...deadLineGettingKeysYearPaths, deadLineGettingKeysYearPath])
      const filterGettingKeysPaths = ObjectHelper.getNestedPaths(filter, deadLineGettingKeysYearPaths, [countryPath, regionPath, ...deadLineYearPaths, deadLineYearPath, ...totalAreaPaths, ...kitchenAreaPaths, ...floorNumbersPaths, notFirstFloorPath, lastFloorPath, ...liftsPaths])
      const pathsToSelected = [...new Set([...filterPaths, ...filterGettingKeysPaths])]
        .filter(path => {
          const filterValue = ObjectHelper.getNestedValue(filter as Record<string, any>, path)
          if (path === 'flatFilter.price.currency') { return false }
          return !Array.isArray(filterValue) && !ObjectHelper.isEqual(filterValue, ObjectHelper.getNestedValue(defaultFilter() as Record<string, any>, path))
        })
        .map(path => ({ path, value: ObjectHelper.getNestedValue(filter as Record<string, any>, path) }))
      selected.push(...pathsToSelected)

      filter?.flatFilter.flatRoomsCount.forEach(value => {
        selected.push({ path: flatRoomsCountPath, value })
      })
      filter?.flatFilter.decorationTypes?.forEach(value => {
        selected.push({ path: flatDecorationTypePath, value })
      })
      filter?.flatFilter.windowsViewTypes?.forEach(value => {
        selected.push({ path: windowViewPath, value })
      })
      filter?.houseFilter.materialTypes?.forEach(value => {
        selected.push({ path: materialPath, value })
      })
      filter?.buildingFilter.classes?.forEach(value => {
        selected.push({ path: buildingClassesPath, value })
      })
      if (filter?.buildingFilter.geometriesCoordinates?.length) {
        selected.push({ path: buildingGeometriesPath, value: filter.buildingFilter.geometriesCoordinates.length })
      }
      return selected
    }
  }

  public getCount(
    page = this.store.pagination.currentPage,
    filter = this.store.filter,
    sort = this.store.sort,
  ): Promise<EntitiesCount> {
    return new Promise((resolve, reject) => {
      this.store.isFetchingEntitiesCount = true
      this.http.getCount(
        FilterService.transformFilterForRequest(filter),
        page,
        sort,
      )
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось получить количество примененных фильтров',
            page,
            filter: FilterService.transformFilterForRequest(filter),
            sort,
          })
          reject(error)
        })
        .finally(() => {
          this.store.isFetchingEntitiesCount = false
        })
    })
  }

  public suggest(country: Pick<Country, 'id'>, query: string, limit: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.suggest(
        country,
        query,
        limit,
      )
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось получить ЖК и застройщиков в саджесте',
            country,
            query,
            limit,
          })
          reject(error)
        })
    })
  }
}
