<template>
  <flats-list-card
    v-if="flat"
    :flat="flat"
    :building="building"
    :mini="false"
    class="w-100% xs:w-[60%] md:w-[350px]"
    :display-type="flatDisplayType"
  >
    <template
      v-if="isInsideChessboard || isClientViewChessboard"
      #actions
    >
      <custom-button
        v-if="isInsideChessboard"
        pill
        class="w-full bg-primary-light"
        @click="emits('book')"
      >
        {{ $t('building.actions.booking.label') }}
      </custom-button>
      <custom-button
        v-if="isClientViewChessboard"
        pill
        class="w-full bg-primary-light"
        @click="emits('chooseFlat')"
      >
        {{ $t('clients.views.actions.selectFlat') }}
      </custom-button>
    </template>
  </flats-list-card>
  <div
    v-else-if="isFetching"
    class="grow flex justify-center items-center"
  >
    <custom-cube-spinner />
  </div>
</template>

<script lang="ts" setup>
import DISPLAY_TYPE from '~/common/constants/flat/Flat.displayType'
import { FlatGuestService } from '~/common/services/flat/FlatGuestService'
import { FlatService } from '~/common/services/flat/FlatService'
import { Flat } from '~/common/types/flat/Flat'
import FlatsListCard from '~/components/flats/flat-list-card/FlatsListCard.vue'
import { Building } from '~/modules/building/BuildingModule'
import { useClientsStore } from '~/modules/clients/store'
import CustomButton from '~/ui/buttons/CustomButton.vue'
import CustomCubeSpinner from '~/ui/spinners/CustomCubeSpinner.vue'

const props = defineProps({
  flatId: {
    type: Number,
    required: true,
  },
  building: {
    type: Object as PropType<Building>,
    required: true,
  },
  flatDisplayType: {
    type: String as PropType<DISPLAY_TYPE>,
    default: DISPLAY_TYPE.basic,
  },
})

const emits = defineEmits<{(e: 'book'): void, (e: 'chooseFlat'): void}>()

const clientsStore = useClientsStore()
const flatService = inject<FlatService | FlatGuestService>('flatService', new FlatService())

const flat = ref<Flat | null>(null)
const isFetching = ref(false)

const isInsideChessboard = computed(() => clientsStore.chessboardOpen)

const isClientViewChessboard = computed(() => clientsStore.selectedViewBuilding)

onMounted(() => {
  isFetching.value = true
  flatService.getFlat(props.flatId)
    .then(res => {
      flat.value = res
      flat.value.building = props.building
    })
    .finally(() => {
      isFetching.value = false
    })
})
</script>
