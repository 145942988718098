<template>
  <div>
    <template v-if="flats.length">
      <div
        class="grid grid-cols-1 gap-4 h-full overflow-y-auto scrollbar-thin md:bg-neutral-100 p-4"
        :class="wrapperClass"
      >
        <div
          v-for="(flat, i) of flats"
          :key="i"
          class="relative"
        >
          <flats-list-card
            :flat="flat"
            class="h-full max-h-[528px]"
            :display-type="displayType"
            :mini="mini"
            :building="building"
          >
            <template #actions>
              <slot
                name="actions"
                :flat="flat"
              />
            </template>
            <template #building="{building}">
              <slot
                name="building"
                :building="building"
                :flat="flat"
              />
            </template>

          </flats-list-card>
        </div>
      </div>
      <div
        v-if="$slots.showAvailable"
        class="flex justify-center p-[22px] md:bg-neutral-100"
      >
        <slot name="showAvailable" />
      </div>
    </template>
    <div
      v-else
      class="h-full flex justify-center items-center text-2xl"
    >
      <slot name="emptyMessage">
        {{ $t('suggestions.content.buildingEmpty') }}
      </slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import FlatsListCard from '~/components/flats/flat-list-card/FlatsListCard.vue'
import type { Flat } from '~/common/types/flat/Flat'
import type { Building } from '~/modules/building/BuildingModule'
import DISPLAY_TYPE from '~/common/constants/flat/Flat.displayType'
import { useAppStateStore } from '~/store/app'

const props = defineProps({
  flats: {
    type: Array as PropType<Array<Flat & { building: Building }>>,
    required: true,
  },
  displayType: {
    type: String as PropType<DISPLAY_TYPE>,
    default: DISPLAY_TYPE.basic,
  },
  mini: {
    type: Boolean,
    default: null,
  },
  wrapperClass: {
    type: [String, Object, Array],
    default: '',
  },
  building: {
    type: Object as PropType<Building>,
    required: true,
  },
})

const isMd = computed(() => useAppStateStore().breakpoints.isMd)

</script>
