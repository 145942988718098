<template>
  <div class="flex nowrap bg-white p-[2px] rounded-full border-[2px] border-neutral-150  ">
    <custom-button
      v-for="(option) of options"
      :key="option.value"
      variant="outline"
      class="border-none"
      :class="[
        selectedClasses(option.value),
        buttonClass
      ]"
      :size="size"
      :with-default-message="option.inactive ?? false"
      @click="selectedOption = option.value"
    >
      <template
        v-if="option.icon"
      >
        <nuxt-icon
          :name="option.icon"
          class="inline"
          :class="option.iconClass || ''"
          :filled="option.iconFilled ?? false"
        />
      </template>
      <span
        :class="[{'hidden sm:inline-block': isAdaptive, 'ml-2': option.icon}, spanClass]"
      >
        {{ option.label }}
      </span>
    </custom-button>
  </div>
</template>
<script setup lang="ts">
import CustomButton from '~/ui/buttons/CustomButton.vue'
import { SIZE } from '~/ui/constants/size'
import { Size } from '~/ui/types/types'

type OptionValue = any

type ButtonGroup<T> = {
  value: T
  label: string
  icon?: string
  iconClass?: string
  iconFilled?: boolean
  inactive?: boolean
}

const props = defineProps({
  options: {
    type: Array as PropType<Array<ButtonGroup<OptionValue>>>,
    required: true,
  },
  modelValue: {
    type: [String, Number, Object, Array] as PropType<any>,
    default: null,
  },
  isAdaptive: {
    type: Boolean,
    default: true,
  },
  spanClass: {
    type: [Array, String, Object],
    default: '',
  },
  buttonClass: {
    type: [Array, String, Object],
    default: '',
  },
  size: {
    type: String as PropType<Size>,
    default: SIZE.sm,
  },
})

const emits = defineEmits<{(e: 'update:modelValue', value: any): void}>()

const selectedOption = customRef((track, trigger) => ({
  get() {
    return props.modelValue
  },
  set(value) {
    emits('update:modelValue', value)
  },
}))

const selectedClasses = (optionValue: OptionValue) => ({
  '!bg-blue-100 !rounded-[19px] !text-blue-900': optionValue === selectedOption.value,
})

</script>
