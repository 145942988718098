export class TmpStorage implements Storage {
  [name: string]: any;

  private readonly STORAGE_NAME = 'tmpStorage'

  private windowReplacer: Record<string, any> = process.client ? window : { [this.STORAGE_NAME]: undefined }

  constructor() {
    if (this.windowReplacer[this.STORAGE_NAME] === undefined) {
      this.windowReplacer[this.STORAGE_NAME] = {}
    }
  }

  get length(): number {
    return Object.keys(this.windowReplacer[this.STORAGE_NAME]).length
  }

  clear(): void {
    this.windowReplacer[this.STORAGE_NAME] = {}
  }

  getItem<T>(key: string): T | null {
    const storedValue = this.windowReplacer[this.STORAGE_NAME][key]
    if (storedValue === undefined) {
      return null
    }
    try {
      return JSON.parse(storedValue) as T
    } catch (error) {
      return null
    }
  }

  key(index: number): string | null {
    const keys = Object.keys(this.windowReplacer[this.STORAGE_NAME])
    return index >= 0 && index < keys.length ? keys[index] : null
  }

  removeItem(key: string): void {
    delete this.windowReplacer[this.STORAGE_NAME][key]
  }

  setItem(key: string, value: any): void {
    const storedValue = JSON.stringify(value)
    this.windowReplacer[this.STORAGE_NAME][key] = storedValue
  }
}
