<template>
  <div
    :class="['flex', 'items-center', 'h-[5em]', 'mt-2', 'overflow-hidden', 'cursor-pointer', {'rounded-t-lg': isFirst, 'rounded-b-lg': isLast}]"
    @click="emit('update:selectedDecoration', decoration)"
  >
    <img
      :src="decoration.images?.[0]?.link ?? defaultImage"
      :alt="t('`flat.decorationType.label')"
      class="w-1/2 h-full object-cover"
    >
    <div
      class="flex items-center justify-center flex-col w-1/2 h-full pl-3 font-semibold border-2 border-l-0 border-primary-light"
      :class="decoration.id === selectedDecoration?.id ? 'bg-primary-light text-primary' : 'text-neutral-900 bg-white'"
    >
      <span>{{ decorationName }}</span>
      <span class="text-xs">{{ t(`flat.decorationType.options.${decoration.decorationType}.full`) }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import defaultImage from 'assets/images/building/decoration-default-image.svg'
import { Decoration } from '~/common/types/decoration/Decoration'
import { getEntityName } from '~/common/helpers/getEntityName'
import { PropType } from 'vue'
import { AvailableCountry } from '~/common/types/country/Country.available'

const props = defineProps({
  decoration: {
    type: Object as PropType<Decoration>,
    required: true,
  },
  selectedDecoration: {
    type: Object as PropType<Decoration | null | undefined>,
    required: true,
  },
  isFirst: {
    type: Boolean,
    default: false,
  },
  isLast: {
    type: Boolean,
    default: false,
  },
  countryCode: {
    type: String as PropType<AvailableCountry>,
    required: true,
  },
})
const { t } = useI18n()
const emit = defineEmits(['update:selectedDecoration'])

const decorationName = computed(() => getEntityName({ name: props.decoration.name, internationalName: props.decoration.internationalName, entityCountry: props.countryCode }))
</script>
