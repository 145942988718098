<template>
  <div
    v-show="isMapLoading"
    class="w-full h-full flex items-center justify-center"
  >
    <custom-cube-spinner />
  </div>
  <div
    v-show="!isMapLoading"
    class="h-full w-full"
    :class="wrapperClass"
  >
    <yandex-custom-map
      ref="yandexMapRef"
      :service="mapService"
      @on-map-loaded="isMapLoading = false"
    />
  </div>
</template>

<script setup lang="ts">
import YandexCustomMap from '~/components/map/YandexCustomMap.vue'
import { BuildingListMapService } from '~/modules/building/services/BuildingListMapService'
import CustomCubeSpinner from '~/ui/spinners/CustomCubeSpinner.vue'
import type { SuggestionBuilding } from '~/modules/suggestions/types/Suggestion.building'
import { Building } from '~/modules/building/BuildingModule'

const props = defineProps({
  buildings: {
    type: Array as PropType<Array<SuggestionBuilding | Building>>,
    required: true,
  },
  isCardRecreateOnChange: {
    type: Boolean,
    default: false,
  },
  withoutFlatsCount: {
    type: Boolean,
    default: false,
  },
  wrapperClass: {
    type: [String, Object, Array],
    default: '',
  },
  withCloseButton: {
    type: Boolean,
    default: false,
  },
})

const emits = defineEmits<{(e: 'closeMapModal'): void}>()

const isMapLoading = ref(true)
const yandexMapRef = ref<{ recreateMap:() => Promise<void> } | null>(null)

const mapService = new BuildingListMapService({ state: {}, options: {} }, {}, { onCloseClick: props.withCloseButton ? () => { emits('closeMapModal') } : undefined }, props.buildings, props.withoutFlatsCount)

watch(() => props.buildings, value => {
  if (props.isCardRecreateOnChange) {
    isMapLoading.value = true
    yandexMapRef.value?.recreateMap().then(() => {
      mapService.displayPointsOnMap(value)
    })
  }
})
</script>
