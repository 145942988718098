<template>
  <div class="w-full">
    <custom-button
      class="w-full font-semibold flex"
      size="none"
      pill
    >
      <label class="flex gap-x-2 w-full items-center justify-center h-[38px] px-4 cursor-pointer">
        <nuxt-icon name="actions/upload" class="text-[24px]" />
        <span>{{ buttonTitle || $t('common.upload') }}</span>
        <input
          ref="inputRef"
          class="hidden"
          type="file"
          :multiple="multiple"
          :accept="accept"
          @change="handleUpload"
        >
      </label>
    </custom-button>
    <custom-table
      v-if="files?.length || alreadyUploadedFiles?.length"
      class="mt-[5px]"
      :with-color="false"
    >
      <tbody>
        <slot name="tablePrepend">
          <tr
            v-for="(uploadedFile, inx) in alreadyUploadedFiles"
            :key="inx"
            class="bg-white"
          >
            <td>
              <div class="flex justify-between items-center">
                <div class="flex gap-x-3 items-center">
                  <img
                    v-if="withImagePreview"
                    class="h-[40px] w-[40px] object-cover"
                    :src="uploadedFile[uploadedFilesLinkKey]"
                  >
                  <h3 class="font-semibold text-base">
                    {{ uploadedFile[uploadedFilesNameKey] }}
                  </h3>
                </div>
                <custom-button-icon
                  color="danger"
                  icon="actions/trash"
                  class="text-xl bg-white"
                  round
                  @click="emits('deleteAlreadyUploadedFile', inx)"
                />
              </div>
            </td>
          </tr>
        </slot>
        <tr
          v-for="(file, inx) in files"
          :key="inx"
          class="bg-white"
        >
          <td>
            <div class="flex justify-between items-center">
              <slot
                name="fileName"
                :file="file"
              >
                <div class="flex gap-x-3 items-center">
                  <img
                    v-if="withImagePreview"
                    class="h-[40px] w-[40px] object-cover"
                    :src="getLinkFromFile(file)"
                  >
                  <h3 class="font-semibold text-base">
                    {{ file.name }}
                  </h3>
                </div>
              </slot>
              <custom-button-icon
                color="danger"
                icon="actions/trash"
                class="text-xl bg-white"
                round
                @click="deleteFile(inx)"
              />
            </div>
          </td>
        </tr>
      </tbody>
    </custom-table>
  </div>
</template>

<script setup lang="ts">
import CustomButton from '~/ui/buttons/CustomButton.vue'
import CustomTable from '~/ui/tables/CustomTable.vue'
import CustomButtonIcon from '~/ui/buttons/CustomButtonIcon.vue'

const props = defineProps({
  files: {
    type: Object as PropType<FileList | null>,
    required: true,
  },
  buttonTitle: {
    type: String,
    default: null,
  },
  multiple: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String as PropType<'image'>,
    default: null,
  },
  withImagePreview: {
    type: Boolean,
    default: null,
  },
  alreadyUploadedFiles: {
    type: Array as PropType<Array<any>>,
    default: () => [] as Array<any>,
  },
  uploadedFilesLinkKey: {
    type: String,
    default: 'link',
  },
  uploadedFilesNameKey: {
    type: String,
    default: 'name',
  },
})

type Emits = {
  (e: 'update:files', value: FileList | null): void,
  (e: 'deleteAlreadyUploadedFile', inx?: number): void
}

const emits = defineEmits<Emits>()

const inputRef = ref<null | HTMLInputElement>(null)

const files = computed({
  get: () => props.files,
  set: value => { emits('update:files', value) },
})

const accept = computed(() => {
  switch (props.type) {
    case 'image':
      return 'image/*'
    default:
      return ''
  }
})

const handleUpload = (e: Event) => {
  const fileList = (e.target as HTMLInputElement).files
  if (fileList) {
    if (!props.multiple && props.alreadyUploadedFiles?.length) {
      emits('deleteAlreadyUploadedFile', 0)
    }

    files.value = fileList
  }
}

const getLinkFromFile = (file: File) => URL.createObjectURL(file)

const deleteFile = (inx: number) => {
  if (!inputRef.value) {
    return
  }
  const dt = new DataTransfer()
  const { files: inputFiles } = inputRef.value

  if (!inputFiles) {
    return
  }

  for (let i = 0; i < inputFiles.length; i += 1) {
    const file = inputFiles[i]
    if (inx !== i) {
      dt.items.add(file)
    }
  }

  inputRef.value.files = dt.files
  files.value = dt.files
}
</script>
