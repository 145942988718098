import { defineStore } from 'pinia'
import { DisplayingEntity } from '~/modules/market-entities-actions/types/displaying/Displaying.entity'
import { DISPLAYING_ENTITY } from '~/modules/market-entities-actions/constants/displaying/Displaying.entity'
import { Pagination } from '~/common/types/pagination/Pagination'
import { defaultPagination } from '~/modules/market-entities-actions/constants/pagination/Pagination.default'
import { defaultFilter } from '~/modules/market-entities-actions/constants/filter/Filter.default'
import { MarketEntitiesFilter } from '~/modules/market-entities-actions/types/filter/Filter'
import { MarketEntitiesSort } from '~/modules/market-entities-actions/types/sort/Sort'
import { MARKET_ENTITIES_SORT } from '~/modules/market-entities-actions/constants/sort/Sort'
import { ObjectHelper } from '~/common/helpers/object'
import { FilterService } from '~/modules/market-entities-actions/services/FilterService'
import { Building } from '~/modules/building/BuildingModule'
import { EntitiesCount } from '~/common/types/entitiesCount/EntitiesCount'
import { Country } from '~/modules/address/types'
import { Region } from '~/modules/address/types/Region'
import { City } from '~/modules/address/types/City'

type UserState = {
  displayingEntity: DisplayingEntity
  pagination: Pagination,
  filter: MarketEntitiesFilter
  sort: MarketEntitiesSort
  entitiesCount: EntitiesCount
  isFetchingEntitiesCount: boolean
  toSearch: boolean
  mapBuildingsCount: EntitiesCount
  mapBuildings: Building[]
  isMapBuildingFetching: boolean
  mapPagination: Pagination
}

export const useMarketEntitiesActionsStore = defineStore('marketEntitiesActions', {
  state: () => ({
    displayingEntity: DISPLAYING_ENTITY.buildings,
    pagination: { ...defaultPagination },
    filter: null,
    sort: MARKET_ENTITIES_SORT.descAgentMotivation,
    entitiesCount: {
      buildingsCount: null,
      flatsCount: null,
    },
    isFetchingEntitiesCount: false,
    toSearch: false,
    mapBuildingsCount: {
      buildingsCount: null,
      flatsCount: null,
    },
    mapBuildings: [],
    isMapBuildingFetching: false,
    mapPagination: { ...defaultPagination },
  }) as UserState,
  actions: {
    setDisplayingEntity(displayingType: DisplayingEntity): void {
      this.displayingEntity = displayingType
    },

    // Пагинация
    setPagination(pagination: Pagination): void {
      this.pagination = pagination
    },
    setPaginationCurrentPage(pageNumber: number): void {
      this.pagination.currentPage = pageNumber
    },
    setFilter(filter: MarketEntitiesFilter) {
      this.filter = filter
    },
    setFilterValueByKey(path: string, value: any): void {
      ObjectHelper.setNestedValue(this.filter, path, value)
    },
    resetFilterValue(path: string, value?: unknown) {
      const service = new FilterService()
      service.resetFilterValue(path, value)
    },
    resetFilter(country?: Country | null, region?: Region | null, city?: City | null) {
      this.filter = defaultFilter(country, region, city)
    },
    // Сортировка
    setSortOption(sort: MarketEntitiesSort): void {
      this.sort = sort
    },

    setEntitiesCount(count: EntitiesCount): void {
      this.entitiesCount = count
    },
    setMapBuildingsCount(count: EntitiesCount): void {
      this.mapBuildingsCount = count
    },
    setMapBuildings(buildings: Building[]): void {
      this.mapBuildings = buildings
    },
    setIsMapBuildingsFetching(value: boolean): void {
      this.isMapBuildingFetching = value
    },
    setMapPagination(pagination: Pagination): void {
      this.mapPagination = pagination
    },
  },
})
