import { defineStore } from 'pinia'
import {ORGANISATION_ACCESS_STATUS} from '~/common/constants/organisation/Organisation.accessStatus'
import { Organisation } from '~/common/types/organisation/Organisation'
import { User } from '~/modules/user/types/user'
import { defaultPagination } from '~/modules/market-entities-actions/constants/pagination/Pagination.default'
import { Pagination } from '~/common/types/pagination/Pagination'

export type OrganisationState = {
  organisation: Organisation | null,
  isDisplayOrganisationSettingsModal: boolean
  organisationUsers: Array<User>
  pagination: Pagination

  isDisplayAccessStatusBlockingModal: null | boolean
}
const useOrganisationStore = defineStore('organisationStore', {
  state: () => ({
    organisation: null,
    isDisplayOrganisationSettingsModal: false,
    organisationUsers: [],
    pagination: { ...defaultPagination },
    isDisplayAccessStatusBlockingModal: null,
  }) as OrganisationState,
  getters: {
    isOrganisationStatusGranted(state) {
      return state.organisation?.emAccessStatus === ORGANISATION_ACCESS_STATUS.granted
    },
    hasContentManagementAccessRight(state) {
      return !!state.organisation?.hasContentManagementAccessRight
    }
  },
  actions: {
    setOrganisation(organisation: Organisation):void {
      this.organisation = organisation
    },
    setDisplayOrganisationSettingsModal(value: boolean):void {
      this.isDisplayOrganisationSettingsModal = value
    },
    setOrganisationUsers(users: Array<User>): void {
      this.organisationUsers = users
    },
    setPagination(pagination: Pagination): void {
      this.pagination = pagination
    },
    setInvitationSecret(secret: string): void {
      if (this.organisation) {
        this.organisation = { ...this.organisation, invitationSecret: secret }
      }
    },
    setIsDisplayAccessStatusBlockingModal(value: boolean): void {
      this.isDisplayAccessStatusBlockingModal = value
    },
  },
})
export default useOrganisationStore
