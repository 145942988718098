<template>
  <div
    ref="wrapper"
    class="relative"
  >
    <slot
      name="trigger"
      :toggle="toggle"
    >
      <custom-button
        :variant="variant"
        :color="color"
        :size="size"
        class="flex flex-nowrap"
        :class="[buttonClass, {'flex-row-reverse': placement === Placement.left, 'justify-between': displayDropdownArrow}]"
        :disabled="disabled"
        :pill="buttonIsPill"
        @click="toggle"
      >
        <span class="flex items-center">
          <slot name="triggerContent">
            {{ triggerText }}
          </slot>
        </span>
        <client-only>
          <font-awesome-icon
            v-if="displayDropdownArrow"
            size="sm"
            :icon="['fas', dropdownArrow]"
            class="ml-2"
            :class="dropdownArrowClass"
          />
        </client-only>
      </custom-button>
    </slot>
    <teleport
      to="body"
      :disabled="!withPopper"
    >
      <div
        v-show="isVisible"
        ref="dropBody"
        class="absolute z-[1000] min-w-[240px]"
        :class="[alignClass, dropdownClass]"
        :style="alignStyle"
      >
        <custom-card
          v-if="!lazy || isVisible"
          :class="bodyClass"
          class-name="p-3"
          @click="onClickInside"
        >
          <slot />
        </custom-card>
      </div>
    </teleport>
  </div>
</template>
<script setup lang="ts">
import CustomButton from '~/ui/buttons/CustomButton.vue'
import { MaybeElement, MaybeRef, onClickOutside } from '@vueuse/core'
import CustomCard from '~/ui/cards/CustomCard.vue'
import { Size, Variant } from '~/ui/types/types'
import { VARIANT } from '~/ui/constants/variant'
import { COLOR } from '~/ui/constants/color'
import { SIZE } from '~/ui/constants/size'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { createPopper } from '@popperjs/core'
import { useAppStateStore } from '~/store/app'

enum Placement {
  'top' = 'top',
  'right' = 'right',
  'bottom' = 'bottom',
  'left' = 'left'
}

const props = defineProps({
  variant: {
    type: String as PropType<Variant>,
    default: VARIANT.default,
  },
  color: {
    type: String as PropType<keyof typeof COLOR>,
    default: COLOR.dropdown,
  },
  size: {
    type: String as PropType<Size>,
    default: SIZE.sm,
  },
  placement: {
    type: String as PropType<Placement>,
    default: 'bottom',
  },
  right: {
    type: Boolean,
    default: false,
  },
  displayDropdownArrow: {
    type: Boolean,
    default: true,
  },
  triggerText: {
    type: String,
    default: '',
  },
  buttonClass: {
    type: [Array, String, Object],
    default: '',
  },
  buttonIsPill: {
    type: Boolean,
    default: false,
  },
  bodyClass: {
    type: [Array, String, Object],
    default: '',
  },
  dropdownClass: {
    type: [Array, String, Object],
    default: '',
  },
  dropdownArrowClass: {
    type: [Array, String, Object],
    default: '',
  },
  closeOnSelect: {
    type: Boolean,
    default: true,
  },
  closeIgnoreElements: {
    type: Array as PropType<Array<MaybeRef<MaybeElement> | string>>,
    default: () => [] as Array<MaybeRef<MaybeElement> | string>,
  },
  lazy: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  parentId: {
    type: String,
    default: null,
  },
  withPopper: {
    type: Boolean,
    default: false,
  },
  isDropdownAndTriggerEqualWidth: {
    type: Boolean,
    default: false,
  },
})

const appStateStore = useAppStateStore()

const isVisible = ref(false)
const wrapper = ref<HTMLDivElement>()
const dropBody = ref<HTMLDivElement>()
const alignRight = ref(props.right)
const alignBottom = ref(false)
const alignStyle = ref<Record<string, string> | null>(null)
const popperDestroyFunc = ref<(() => void) | null>(null)

const alignClass = computed(() => {
  const alignClassArr: string[] = []
  if (alignRight.value) {
    alignClassArr.push('right-0')
  }
  if (alignBottom.value) {
    alignClassArr.push('bottom-full')
  }
  return alignClassArr.join(' ')
})
const dropdownArrow = computed(() => {
  switch (props.placement) {
    case 'top':
      return !isVisible.value ? 'chevron-up' : 'chevron-down'
    case 'right':
      return !isVisible.value ? 'chevron-right' : 'chevron-left'
    case 'left':
      return !isVisible.value ? 'chevron-left' : 'chevron-right'
    default:
      return !isVisible.value ? 'chevron-down' : 'chevron-up'
  }
})

const toggle = () => {
  isVisible.value = !isVisible.value
}

const onClickInside = () => {
  if (props.closeOnSelect) {
    isVisible.value = false
  }
}

onClickOutside(wrapper, () => {
  isVisible.value = false
}, { ignore: props.closeIgnoreElements })

const baseZIndex = 999

const popperCreate = () => {
  const dropdownElement = dropBody.value as HTMLDivElement

  if (props.isDropdownAndTriggerEqualWidth) {
    dropdownElement.style.width = `${wrapper.value?.firstElementChild?.clientWidth || 0}px`
  }

  dropdownElement.style.zIndex = `${baseZIndex + appStateStore.openElementsCount.modals * 2 + 2}`

  const popper = createPopper(wrapper.value?.firstElementChild as Element, dropdownElement, {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 1],
        },
      },
    ],
  })

  return () => popper.destroy()
}

watch(() => isVisible.value, visible => {
  if (visible) {
    if (props.withPopper) {
      popperDestroyFunc.value = popperCreate()
      return
    }

    nextTick().then(async() => {
      const element = dropBody.value as HTMLDivElement
      const boundingClientRect = element.getBoundingClientRect()
      const parentElement = props.parentId ? element.closest(`#${props.parentId}`) : null
      if (!alignRight.value && boundingClientRect.right > (parentElement ? parentElement.getBoundingClientRect().right : document.body.offsetWidth)) {
        alignRight.value = true
      }
      await nextTick()
      if (alignRight.value && element.getBoundingClientRect().left < 0) {
        alignRight.value = false
      }
      await nextTick()
      if (!alignRight.value && boundingClientRect.right > (parentElement ? parentElement.getBoundingClientRect().right : document.body.offsetWidth)) {
        const wrapperBoundingClientRect = wrapper.value?.getBoundingClientRect().left

        alignStyle.value = { left: `-${wrapperBoundingClientRect}px` }
      }
      if (!alignBottom.value && element.getBoundingClientRect().bottom > (parentElement ? parentElement.getBoundingClientRect().bottom : document.documentElement.clientHeight)) {
        alignBottom.value = true
      }
      await nextTick()
      if (alignBottom.value && element.getBoundingClientRect().top < 0) {
        alignBottom.value = false
      }
    })
    return
  }

  if (props.withPopper && popperDestroyFunc.value) {
    popperDestroyFunc.value()
    popperDestroyFunc.value = null
  }
})
</script>
