import QuickDealHttpApiService from '~/services/http/quick-deal/QuickDealHttpApiService'
import { InterceptorsHandler } from '~/services/http/quick-deal/interceptors'
import { useAppStateStore } from '~/store/app'

export default defineNuxtPlugin(nuxt => {
  const qdHttpApiInstance = new QuickDealHttpApiService()
  const interceptorsHandler = new InterceptorsHandler()

  nuxt.hook('app:beforeMount', () => {
    qdHttpApiInstance.setQueryParams({ currency: useAppStateStore().currency })
  })

  qdHttpApiInstance.interceptors.response.use(
    response => response,
    interceptorsHandler.handleRejected,
  )

  return {
    provide: {
      qdHttpApiInstance,
    },
  }
})
