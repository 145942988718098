<template>
  <div
    class="flex items-center text-neutral-500"
  >
    <custom-tooltip>
      <template #content>
        <span>
          {{ $t('popularity.suggestionsAdditionsCount', { count: actualityMonths }) }}
        </span>
        <span
          v-if="totalSuggestionsAdditionsCount"
          class="block"
        >
          {{ $t('popularity.totalSuggestionsAdditionsCount', { count: totalSuggestionsAdditionsCount}) }}
        </span>
      </template>
      <nuxt-icon
        v-if="!suggestionsAdditionsCount"
        name="common/fire"
        class="text-[24px] "
      />
      <nuxt-icon
        v-else
        name="common/full-fire"
        class="text-[24px] text-warning "
      />
      <span class="block ml-2 font-medium">
        {{ suggestionsAdditionsCount }}
      </span>
    </custom-tooltip>
  </div>
</template>

<script setup lang="ts">
import CustomTooltip from '~/ui/tooltips/CustomTooltip.vue'

defineProps({
  suggestionsAdditionsCount: {
    type: Number,
    default: null,
  },
  totalSuggestionsAdditionsCount: {
    type: Number,
    default: null,
  },
})

const actualityMonths = 3
</script>
