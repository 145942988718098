<template>
  <template v-if="sections.length">
    <div class="flex flex-col h-full overflow-hidden">
      <div class="mb-4 pt-6">
        <custom-scrollbar
          class="gap-[20px]"
        >
          <chessboard-grid-section-item
            v-for="(sectionName, index) in sectionNames"
            :key="index"
            :section-name="sectionName"
            :is-current="selectedSection === sectionName"
            @click="selectedSection = sectionName"
          />
        </custom-scrollbar>
      </div>
      <custom-table
        v-if="!isHouseEmpty"
        ref="table"
        variant="transparent"
        responsive
        class="relative !w-auto h-full !overflow-y-auto"
      >
        <thead>
          <tr>
            <th
              ref="floorNumberLabelRef"
              class="text-neutral-900 font-semibold min-w-[80px] text-lg sticky left-0 top-0 z-10 bg-white lg:bg-neutral-100 !pl-0 text-center"
            >
              {{ $t('chessboard.floorNumber.label') }}
            </th>
            <th
              v-for="sectionName in sectionNames"
              :id="sectionName"
              :key="sectionName"
              ref="sectionsRefs"
              class="text-start text-neutral-900 font-semibold text-lg sticky top-0 z-[5] bg-white md:bg-neutral-100"
            >
              <span
                class="sticky z-[5] top-0"
                :style="{left: `calc(0.8rem + ${floorCellWidth}px)`}"
              >
                {{ $t('chessboard.section.label') }} {{ sectionName }}
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-for="floor in flatsForDisplaying">
            <tr
              v-if="!floor.isEmpty"
              :key="floor.floorNumber"
            >
              <th class="text-neutral-900 font-semibold text-center sticky left-0 bg-white lg:bg-neutral-100 !pl-0">
                {{ floor.floorNumber }}
              </th>
              <td
                v-for="(section, sectionName) of floor.sections"
                :key="sectionName"
                class="!pt-0 !pr-0"
              >
                <div class="flex">
                  <chessboard-grid-item
                    v-for="flat in section.flats"
                    :key="flat.id"
                    :flat="flat"
                    class="mr-[10px] mt-[10px]"
                    @click="selectFlat(flat, {name: sectionName, internationalName: section.internationalName})"
                  />
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </custom-table>
      <div
        v-else
        class="h-[70vh] flex justify-center items-center"
      >
        <p class="text-xl">
          {{ $t('chessboard.empty') }}
        </p>
      </div>
    </div>
  </template>
  <template v-else>
    <div class="h-[70vh] flex justify-center items-center">
      <p class="text-xl">
        {{ $t('chessboard.house.empty') }}
      </p>
    </div>
  </template>
  <modal-or-bottom-sheet
    v-model="isModalOpened"
    :modal-props="{size: 'fit-content', centered: false}"
    :drawer-props="{fullHeight: true, contentClass: 'flex justify-center items-center'}"
    :title="$t('chessboard.modal.title')"
  >
    <chessboard-grid-flat-card
      v-if="selectedFlat"
      :flat-id="selectedFlat.id"
      :flat-display-type="flatDisplayType"
      :building="building"
      @book="book"
      @choose-flat="chooseFlat"
    />

  </modal-or-bottom-sheet>
  <custom-modal
    v-if="selectedFlat"
    v-model="isClientBookingModalVisible"
    size="fullscreen"
  >
    <client-booking-form
      :flat="selectedFlat"
      @submit="isClientBookingModalVisible = false"
    />
  </custom-modal>
</template>

<script setup lang="ts">
import CustomTable from '~/ui/tables/CustomTable.vue'
import ChessboardGridItem from '~/modules/chessboard/components/grid/ChessboardGridItem.vue'
import { useScroll } from '@vueuse/core'
import ChessboardGridSectionItem from '~/modules/chessboard/components/grid/ChessboardGridSectionItem.vue'
import ChessboardGridFlatCard from '~/modules/chessboard/components/grid/ChessboardGridFlatCard.vue'
import CustomScrollbar from '~/ui/scrollbars/CustomScrollbar.vue'
import ModalOrBottomSheet from '~/components/common/ModalOrBottomSheet.vue'
import { ChessboardSection } from '~/modules/chessboard/types/Chessboard.section'
import { ChessboardFlat } from '~/modules/chessboard/types/Chessboard.flat'
import type { Flat } from '~/common/types/flat/Flat'
import DISPLAY_TYPE from '~/common/constants/flat/Flat.displayType'
import { getEntityName } from '~/common/helpers/getEntityName'
import ClientBookingForm from '~/modules/clients/components/forms/clients-booking/ClientBookingForm.vue'
import CustomModal from '~/ui/modals/CustomModal.vue'
import { Building } from '~/modules/building/BuildingModule'
import { useClientsStore } from '~/modules/clients/store'

type FlatsForDisplaying = Array<{
  floorNumber: number
  isEmpty: boolean
  sections: Record<string, {
    flats: Array<ChessboardFlat>
    isEmpty: boolean
    internationalName: string | null
  }>
}>

const props = defineProps({
  sections: {
    type: Array as PropType<Array<ChessboardSection>>,
    required: true,
  },
  building: {
    type: Object as PropType<Building>,
    required: true,
  },
  flatDisplayType: {
    type: String as PropType<DISPLAY_TYPE>,
    default: DISPLAY_TYPE.basic,
  },
})

const clientsStore = useClientsStore()

const table = ref<HTMLElement | null>(null)
const sectionsRefs = ref<Array<HTMLElement>>([])
const floorNumberLabelRef = ref<HTMLTableElement | null>(null)
const floorCellWidth = ref(0)

const { x: tableX } = useScroll(table, { throttle: 50 })

const isClientBookingModalVisible = ref(false)

const selectedFlat = ref<Flat | null>(null)

const isModalOpened = computed<boolean>({
  set(value) {
    if (!value) {
      selectedFlat.value = null
    }
  },
  get() {
    return selectedFlat.value !== null
  },
})
const book = () => {
  isClientBookingModalVisible.value = true
}
const chooseFlat = () => {
  clientsStore.selectedViewFlat = selectedFlat.value
}

const selectFlat = (flat: ChessboardFlat, section: Pick<ChessboardSection, 'name' | 'internationalName'>) => {
  selectedFlat.value = { ...flat, section: section as ChessboardSection, building: props.building }
}
const sectionNames = computed(() => props.sections?.map(section => getEntityName({
  name: section.name,
  internationalName: section.internationalName,
  entityCountry: section.address?.countryIsoCode,
})))

const flatsForDisplaying: ComputedRef<FlatsForDisplaying> = computed(() => {
  const handledFlats: Record<number, {
    floorNumber: number
    isEmpty: boolean

    sections: Record<string, {
      flats: Array<ChessboardFlat>
      isEmpty: boolean
    }>
  }> = {}

  const createEmptyHandledFlat = (floorNumber: number) => {
    handledFlats[floorNumber] = {
      isEmpty: true,
      floorNumber,
      sections: {},
    }
  }

  const createEmptyHandledFlatSection = (floorNumber: number, sectionName: string) => {
    handledFlats[floorNumber].sections[sectionName] = {
      flats: [],
      isEmpty: true,
    }
  }

  const maxFloor = Math.max(...props.sections.map(section => section.groundFloorsCount))
  const minFloor = Math.max(...props.sections.map(section => section.undergroundFloorsCount))

  for (let floor = maxFloor; floor >= -minFloor; floor -= 1) {
    createEmptyHandledFlat(floor)
  }

  props.sections?.forEach(section => {
    section.flats.sort((a, b) => a.totalArea - b.totalArea)

    Object.values(handledFlats).forEach(flat => {
      createEmptyHandledFlatSection(flat.floorNumber, section.name)
    })

    section.flats.forEach(flat => {
      if (!handledFlats[flat.floorNumber]) {
        createEmptyHandledFlat(flat.floorNumber)
        createEmptyHandledFlatSection(flat.floorNumber, section.name)
      }

      handledFlats[flat.floorNumber].sections[section.name].isEmpty = false
      handledFlats[flat.floorNumber].sections[section.name].internationalName = section.internationalName
      handledFlats[flat.floorNumber].isEmpty = false
      handledFlats[flat.floorNumber].sections[section.name].flats.push({ ...flat, address: section.address })
    })
  })

  return Object.values(handledFlats).sort((a, b) => b.floorNumber - a.floorNumber)
})

const isHouseEmpty = computed(() => Object.values(flatsForDisplaying.value).every(({ isEmpty }) => isEmpty))

const scrollToSection = (sectionName: string): void => {
  const tableElement = table.value.$el as HTMLTableElement

  const section = sectionsRefs.value.find(sectionHtmlElement => sectionHtmlElement.id === sectionName)

  if (section) {
    tableElement.scrollTo({
      left: section.offsetLeft - floorCellWidth.value,
      behavior: 'smooth',
    })
  }
}

const displayingSectionName = computed(() => {
  const displayingSection = sectionsRefs.value.find(sectionElement => {
    const elementLeftCoordinate = sectionElement.offsetLeft
    const elementRightCoordinate = sectionElement.offsetLeft + sectionElement.offsetWidth

    return tableX.value + floorCellWidth.value >= elementLeftCoordinate && tableX.value + floorCellWidth.value < elementRightCoordinate
  })
  return displayingSection?.id
})

const selectedSection = ref(displayingSectionName.value)

watchEffect(() => {
  selectedSection.value = displayingSectionName.value
})
watch(() => selectedSection.value, value => {
  if (value && displayingSectionName.value !== value) {
    scrollToSection(value)
  }
})
onMounted(() => {
  if (floorNumberLabelRef.value) {
    floorCellWidth.value = floorNumberLabelRef.value.offsetWidth
  }
})
</script>
