import { useBuildingStore } from '~/modules/building/store'
import { HouseHttpApiService } from '~/modules/house/api/HouseHttpApiService'
import type { HouseExpanded } from '~/common/types/house/House.expanded'
import SentryLogger from '~/services/sentry/SentryLogger'
import { HttpApiService } from '~/services/http/HttpApiService'
import { FlatFilterForRequest } from '~/modules/building/components/page/flats/filter/types/FlatsFilter'
import { HouseInfo } from '~/modules/house/types/HouseInfo'
import { HttpPaginatedResponseData } from '~/services/http/types/Http.paginatedResponseData'

export class HouseService {
  http: HouseHttpApiService
  private store

  constructor() {
    const nuxtApp = useNuxtApp()
    const httpInstance = nuxtApp.$qdHttpApiInstance
    this.http = new HouseHttpApiService(httpInstance as HttpApiService)
    this.store = useBuildingStore()
  }

  public search(filter: FlatFilterForRequest) {
    return new Promise<Array<HouseExpanded>>((resolve, reject) => {
      this.http.search(filter)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось выполнить серч домов',
            filter,
          })
          reject(error)
        })
    })
  }

  public searchInfo(filter: FlatFilterForRequest, sort = this.store.flatsSortOption) {
    return new Promise<HttpPaginatedResponseData<HouseInfo>>((resolve, reject) => {
      this.http.searchInfo(filter, sort)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось выполнить серч информации по домам',
            filter,
          })
          reject(error)
        })
    })
  }
}
