<template>
  <div>
    <div
      class="flex gap-4"
      :class="{'justify-between': getDisplayingColumnsCount() === 3}"
    >
      <div
        v-if="salesStart.year"
        class="flex-grow"
      >
        <h5 class="text-lg font-semibold flex flex-wrap">
          {{ $t('building.timeConstraints.salesStart.label') }}
        </h5>
        <div class="text-neutral-500">
          {{ $t(`building.timeConstraints.quarter.options.${salesStart.quarter}`) }} {{ $t('building.timeConstraints.quarter.label.short') }} {{ salesStart.year }}
        </div>
      </div>
      <div
        v-if="deadLine.from.year && deadLine.to.quarter"
        class="flex-grow"
      >
        <h5 class="text-lg font-semibold">
          {{ $t('building.timeConstraints.deadLine.label') }}
        </h5>
        <div
          class="text-neutral-500"
        >
          {{ $t(`building.timeConstraints.quarter.options.${deadLine.from.quarter}`) }} {{ $t('building.timeConstraints.quarter.label.short') }} {{ deadLine.from.year }}
          —
          {{ $t(`building.timeConstraints.quarter.options.${deadLine.to.quarter}`) }} {{ $t('building.timeConstraints.quarter.label.short') }} {{ deadLine.to.year }}
        </div>
      </div>
      <div
        v-if="keysGetting.from.year && keysGetting.from.quarter"
        class="flex-grow"
      >
        <h5 class="text-lg font-semibold flex flex-wrap">
          {{ $t('building.timeConstraints.keysGetting.label') }}
        </h5>
        <div
          class="text-neutral-500"
        >
          <span
            v-if="QuarterUtils.isQuarterStarted(keysGetting.from.quarter, keysGetting.from.year)"
            class="text-success font-semibold"
          >
            {{ $t('building.timeConstraints.keysGetting.gettingNow') }}
          </span>
          <template v-else>
            {{ $t(`building.timeConstraints.quarter.options.${keysGetting.from.quarter}`) }} {{ $t('building.timeConstraints.quarter.label.short') }} {{ keysGetting.from.year }}
            —
            {{ $t(`building.timeConstraints.quarter.options.${keysGetting.to.quarter}`) }} {{ $t('building.timeConstraints.quarter.label.short') }} {{ keysGetting.to.year }}
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { TimeConstraints } from '~/common/types/base/Period'
import { QuarterUtils } from '~/common/helpers/date/QuarterUtils'
import { YearQuarterObject } from '~/common/types/base/YearQuarterObject'

const props = defineProps({
  keysGetting: {
    type: Object as PropType<TimeConstraints>,
    required: true,
  },
  salesStart: {
    type: Object as PropType<YearQuarterObject>,
    required: true,
  },
  deadLine: {
    type: Object as PropType<TimeConstraints>,
    required: true,
  },
})

const getDisplayingColumnsCount = (): number => {
  let count = 0
  if (props.keysGetting?.from.year) {
    count += 1
  }
  if (props.salesStart?.year) {
    count += 1
  }
  if (props.deadLine?.from.year) {
    count += 1
  }

  return count
}
</script>
