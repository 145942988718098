import { QuillEditor, Quill } from '@vueup/vue-quill'

export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.vueApp.component('quill-editor', QuillEditor)

  return {
    provide: {
      Quill,
    },
  }
})
