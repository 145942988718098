<template>
  <custom-dropdown
    :close-on-select="false"
    color="secondary"
    dropdown-class="min-w-[375px]"
    :button-class="[{'!bg-blue-100': countSelectedOptions}, 'w-full font-semibold']"
  >
    <template #triggerContent>
      <filter-counter v-if="countSelectedOptions">
        {{ countSelectedOptions }}
      </filter-counter>
      {{ $t('entityActions.filter.flatFilter.price.label') }}
    </template>
    <filter-from-to
      v-model:from="priceFilterFrom"
      v-model:to="priceFilterTo"
      v-model:selectedHint="priceFilterCurrency"
      :placeholder-from="$t('common.from')"
      :placeholder-to="$t('common.to')"
      :selecting-values-from="selectingValues"
      :selecting-values-to="selectingValues"
      :hint-options="currencyOptions"
    />
  </custom-dropdown>
</template>

<script setup lang="ts">
import FilterFromTo from '~/components/filters/from-to/FilterFromTo.vue'
import CustomDropdown from '~/ui/dropdowns/CustomDropdown.vue'
import { MarketEntitiesFilter } from '~/modules/market-entities-actions/types/filter/Filter'
import { FromToSelectingValue } from '~/common/types/filters/from-to/FromTo.selectingValue'
import type { FromTo } from '~/common/types/filters/from-to/FromTo'
import { Currency } from '~/common/types/money/Currency'
import { CURRENCY } from '~/common/constants/money/Currency'
import FilterCounter from '~/components/filters/FilterCounter.vue'

const props = defineProps({
  from: {
    type: [Number, String] as PropType<FromTo['from']>,
    default: null,
  },
  to: {
    type: [Number, String] as PropType<FromTo['to']>,
    default: null,
  },
  currency: {
    type: String as PropType<Currency>,
    default: CURRENCY.rub,
  },
})
const emits = defineEmits<{(e: 'update:from', value: MarketEntitiesFilter['flatFilter']['price']['from']): void,
  (e: 'update:to', value: MarketEntitiesFilter['flatFilter']['price']['to']): void,
  (e: 'update:currency', value: Currency): void,
}>()

const { n, t } = useI18n()

const priceFilterFrom = computed({
  get: () => props.from,
  set: value => {
    emits('update:from', value)
  },
})

const priceFilterTo = computed({
  get: () => props.to,
  set: value => {
    emits('update:to', value)
  },
})

const priceFilterCurrency = computed({
  get: () => props.currency,
  set: value => {
    emits('update:currency', value)
  },
})

const countSelectedOptions = computed(() => {
  if (priceFilterTo.value && priceFilterFrom.value) {
    return 2
  }
  if (priceFilterTo.value || priceFilterFrom.value) {
    return 1
  }
  return 0
})

const selectingValues: Array<FromToSelectingValue> = [300, 1000, 2000, 3000, 4000, 5000].map(number => ({
  value: number * 1000,
  label: n(number * 1000),
}))

const currencyOptions = Object.values(CURRENCY).map(currency => ({ label: t(`currency.options.${currency}`), value: currency }))

</script>
