import { ClientsHttpApiService } from '~/modules/clients/api/ClientsHttpApiService'
import type { Client } from '~/modules/clients/types/Client'
import SentryLogger from '~/services/sentry/SentryLogger'
import { useClientsStore } from '~/modules/clients/store'
import { ClientsFiltersOptions } from '~/modules/clients/types/Clients.filtersOptions'
import { ClientFixation } from '~/modules/clients/types/ClientFixation'
import { HttpApiService } from '~/services/http/HttpApiService'
import { ClientBooking } from '~/modules/clients/types/ClientBooking'
import { ClientBookingForRequest } from '~/modules/clients/types/ClientBooking.forRequest'
import { ClientFixationForRequest } from '~/modules/clients/types/ClientFixation.forRequest'
import { MarketEntitiesSearchResponse } from '~/modules/market-entities-actions/types/search/Search.response'
import { ClientView } from '~/modules/clients/types/ClientView'
import { User } from '~/modules/user/types/user'

export class ClientsService {
  http: ClientsHttpApiService
  store
  constructor() {
    const nuxtApp = useNuxtApp()
    const httpInstance = nuxtApp.$qdHttpApiInstance
    this.http = new ClientsHttpApiService(httpInstance as HttpApiService)
    this.store = useClientsStore()
  }

  public getClients(query?: string): Promise<Client[]> {
    return new Promise((resolve, reject) => {
      this.http.getClients(this.store.pagination.currentPage, query)
        .then(response => {
          resolve(response.data.data)
          this.store.setPagination({
            currentPage: response.data.currentPage,
            lastPage: response.data.lastPage,
            perPage: response.data.perPage,
            total: response.data.total,
            nextPageUrl: response.data.nextPageUrl,
          })
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось получить клиентов',
          })
          reject(error)
        })
    })
  }

  public getFixationsClients(): Promise<MarketEntitiesSearchResponse<ClientFixation>> {
    return new Promise((resolve, reject) => {
      this.http.getFixationsClients(this.store.pagination.currentPage, this.store.filter)
        .then(response => {
          resolve(response.data)
          this.store.setPagination({
            currentPage: response.data.currentPage,
            lastPage: response.data.lastPage,
            perPage: response.data.perPage,
            total: response.data.total,
            nextPageUrl: response.data.nextPageUrl,
          })
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось получить фиксации',
          })
          reject(error)
        })
    })
  }

  public getBookingsClients(): Promise<MarketEntitiesSearchResponse<ClientBooking>> {
    return new Promise((resolve, reject) => {
      this.http.getBookingsClients(this.store.pagination.currentPage, this.store.filter)
        .then(response => {
          resolve(response.data)
          this.store.setPagination({
            currentPage: response.data.currentPage,
            lastPage: response.data.lastPage,
            perPage: response.data.perPage,
            total: response.data.total,
            nextPageUrl: response.data.nextPageUrl,
          })
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось получить бронирования',
          })
          reject(error)
        })
    })
  }

  public getViewsClients(): Promise<MarketEntitiesSearchResponse<ClientView>> {
    return new Promise((resolve, reject) => {
      this.http.getViewsClients(this.store.pagination.currentPage, this.store.filter)
        .then(response => {
          resolve(response.data)
          this.store.setPagination({
            currentPage: response.data.currentPage,
            lastPage: response.data.lastPage,
            perPage: response.data.perPage,
            total: response.data.total,
            nextPageUrl: response.data.nextPageUrl,
          })
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось получить просмотры',
          })
          reject(error)
        })
    })
  }

  public getClient(clientId: number): Promise<Client> {
    return new Promise((resolve, reject) => {
      this.http.getClient(clientId)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось получить данные клиента по id',
            clientId,
          })
          reject(error)
        })
    })
  }

  public createClient(fullName: string, phone: string, email: string, assigned: User): Promise<Client> {
    return new Promise((resolve, reject) => {
      this.http.createClient(fullName, phone, email, assigned)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось создать клиента',
            fullName,
            phone,
            email,
            assigned: JSON.stringify(assigned),
          })
          reject(error)
        })
    })
  }

  public editClient(clientId: number, fullName: string, phone: string, email: string, assigned: User): Promise<Client> {
    return new Promise((resolve, reject) => {
      this.http.editClient(clientId, fullName, phone, email, assigned)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось редактировать клиента',
            fullName,
            phone,
            email,
            assigned: JSON.stringify(assigned),
            clientId,
          })
          reject(error)
        })
    })
  }

  public deleteClient(clientId: number): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http.deleteClient(clientId)
        .then(() => {
          resolve()
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось удалить клиента',
            clientId,
          })
          reject(error)
        })
    })
  }

  public suggest(query: string, limit: number, options: ClientsFiltersOptions[]): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.suggest(
        query,
        limit,
        options,
      )
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось получить список доступный бронирований и фиксаций по query параметру',
            query,
            limit,
          })
          reject(error)
        })
    })
  }

  public fixClient(fixation: ClientFixationForRequest): Promise<ClientFixation> {
    return new Promise((resolve, reject) => {
      this.http.fixClient(fixation)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось создать фиксацию клиента',
            fixation,
          })
          reject(error)
        })
    })
  }

  public updateFixationClient(fixationId: number, fixation: ClientFixationForRequest): Promise<ClientFixation> {
    return new Promise((resolve, reject) => {
      this.http.updateFixationClient(fixationId, fixation)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось отредактировать фиксацию клиента',
            fixationId,
            fixation,
          })
          reject(error)
        })
    })
  }

  public bookClient(booking: ClientBookingForRequest): Promise<ClientBooking> {
    return new Promise((resolve, reject) => {
      this.http.bookClient(booking)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось создать бронирование клиента',
            booking,
          })
          reject(error)
        })
    })
  }

  public updateBookingClient(bookingId: number, booking: ClientBookingForRequest): Promise<ClientBooking> {
    return new Promise((resolve, reject) => {
      this.http.updateBookingClient(bookingId, booking)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось отредактировать бронирование клиента',
            bookingId,
            booking,
          })
          reject(error)
        })
    })
  }

  public createView(payload: ClientView): Promise<ClientView> {
    return new Promise((resolve, reject) => {
      this.http.createView(payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось создать просмотр',
            ...payload,
          })
          reject(error)
        })
    })
  }

  public updateView(payload: ClientView): Promise<ClientView> {
    return new Promise((resolve, reject) => {
      this.http.updateView(payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось обновить просмотр',
            ...payload,
          })
          reject(error)
        })
    })
  }
}
