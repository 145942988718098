<template>
  <div class="flex flex-col h-full">
    <div class="flex items-center border-b-2 border-neutral-150 pb-6">
      <slot name="filterButton" />
      <chessboard-house-list
        v-model="innerSelectedHouse"
        :houses="houses"
        class="relative -bottom-[2px]"
        :building="building"
      />
      <slot name="closeButton" />
    </div>
    <div
      v-if="isFetching"
      class="grow flex justify-center items-center"
    >
      <custom-cube-spinner />
    </div>
    <chessboard-grid
      v-else-if="innerSelectedHouse"
      :sections="sections"
      :building="building"
      :flat-display-type="flatDisplayType"
    />
    <div
      v-else
      class="flex h-full items-center justify-center"
    >
      <p class="text-xl">
        {{ $t('chessboard.section.empty') }}
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import ChessboardGrid from '~/modules/chessboard/components/grid/ChessboardGrid.vue'
import ChessboardHouseList from '~/modules/chessboard/components/ChessboardHouseList.vue'
import CustomCubeSpinner from '~/ui/spinners/CustomCubeSpinner.vue'
import { HouseExpanded } from '~/common/types/house/House.expanded'
import { ChessboardSection } from '~/modules/chessboard/types/Chessboard.section'
import DISPLAY_TYPE from '~/common/constants/flat/Flat.displayType'
import { Building } from '~/modules/building/types/Building'

const props = defineProps({
  building: {
    type: Object as PropType<Building>,
    required: true,
  },
  houses: {
    type: Array as PropType<Array<{ house: HouseExpanded, order: number }>>,
    required: true,
  },
  selectedHouse: {
    type: Object as PropType<{ house: HouseExpanded, order: number }>,
    required: true,
  },
  sections: {
    type: Array as PropType<Array<ChessboardSection>>,
    required: true,
  },
  isFetching: {
    type: Boolean,
    required: true,
  },
  flatDisplayType: {
    type: String as PropType<DISPLAY_TYPE>,
    default: DISPLAY_TYPE.basic,
  },
})

const emits = defineEmits<{(e:'update:selectedHouse', value: { house: HouseExpanded, order: number }): void}>()

const innerSelectedHouse = computed({
  get: () => props.selectedHouse,
  set: value => { emits('update:selectedHouse', value) },
})
</script>
