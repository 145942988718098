<template>
  <component
    :is="tag"
    class="shadow-card"
    :class="classes"
  >
    <slot />
  </component>
</template>

<script setup lang="ts">

const props = defineProps({
  className: {
    type: String,
    default: '',
  },
  tag: {
    type: String,
    default: 'div',
  },
})

const classes = computed(() => {
  const defaultRoundedClass = 'rounded-[12px]'
  const defaultBgClass = 'bg-white'
  const defaultPaddingClass = 'p-6'
  const selectedClasses: Array<string> = []

  if (!props.className) {
    return `${defaultRoundedClass} ${defaultBgClass} ${defaultPaddingClass}`
  }

  selectedClasses.push(props.className)

  if (!props.className.includes('p-')) {
    selectedClasses.push(defaultPaddingClass)
  }
  if (!props.className.includes('bg-')) {
    selectedClasses.push(defaultBgClass)
  }
  if (!props.className.includes('rounded-')) {
    selectedClasses.push(defaultRoundedClass)
  }

  return selectedClasses.join(' ')
})
</script>
