<template>
  <div class="text-white px-4 h-[38px] leading-4 bg-black/30 backdrop-blur-lg rounded-full font-semibold flex items-center">
    {{ dateTimeString }}
  </div>
  <div
    v-if="!event.promotion"
    class="text-white px-2 h-[38px] leading-4 bg-black/30 backdrop-blur-lg rounded-full font-semibold flex items-center"
  >
    <nuxt-icon
      :name="isRegistrationEnded ? 'common/circle-close-regular' : 'common/people'"
      :class="isRegistrationEnded ? 'text-[22px]' : 'text-[24px] stroke-0'"
    />
    <span
      v-if="event.registrationInfo.participantsLimit && !isRegistrationEnded"
      class="ml-2"
    >
      {{ event.registrationInfo.participantsLimit - (event.registrationInfo.participantsCount ?? 0) }}
    </span>
  </div>
  <div
    v-else-if="isPromoEnded"
    class="text-white px-2 h-[38px] leading-4 bg-black/30 backdrop-blur-lg rounded-full font-semibold flex items-center"
  >
    <nuxt-icon
      name="common/circle-close-regular"
      class="text-[22px]"
    />
  </div>
</template>

<script lang="ts" setup>
import { DateTimeHelper } from '~/common/helpers/date/dateTime'
import { JournalNewsEvent } from '~/modules/journal/types/JournalNews.event'

const props = defineProps({
  event: {
    type: Object as PropType<JournalNewsEvent>,
    required: true,
  },
})
const dateTimeString = computed(() => (props.event.promotion ? DateTimeHelper.getDateTimeStringWithMonthInterval(props.event.date) : DateTimeHelper.getDateTimeStringWithMonth(props.event.date.start, 'genitive')))
const isPromoEnded = computed(() => props.event.promotion && props.event.date.end && new Date() >= new Date(props.event.date.end))
const isRegistrationEnded = computed(() => new Date() >= new Date(props.event.registrationInfo.endDate))
</script>
