<template>
  <div class="w-full overflow-x-hidden h-full">
    <custom-scrollbar
      class="overflow-x-hidden gap-[10px] h-full"
    >
      <chessboard-house-list-item
        v-for="(house, index) in houses"
        :key="index"
        :house="house"
        :selected="house.house.id === selectedHouse.house.id"
        :building="building"
        @click="selectedHouse = house"
      />
    </custom-scrollbar>
  </div>

</template>

<script setup lang="ts">

import CustomScrollbar from '~/ui/scrollbars/CustomScrollbar.vue'
import ChessboardHouseListItem from '~/modules/chessboard/components/ChessboardHouseListItem.vue'
import { HouseExpanded } from '~/common/types/house/House.expanded'
import { Building } from '~/modules/building/types/Building'

const props = defineProps({
  building: {
    type: Object as PropType<Building>,
    required: true,
  },
  houses: {
    type: Array as PropType<Array<{ house: HouseExpanded, order: number }>>,
    required: true,
  },
  modelValue: {
    type: Object as PropType<{ house: HouseExpanded, order: number }>,
    required: true,
  },
})

const emits = defineEmits<{(e: 'update:modelValue', value: { house: HouseExpanded, order: number }): void}>()

const selectedHouse = computed({
  get: () => props.modelValue,
  set: value => {
    emits('update:modelValue', value)
  },
})

</script>
