<template>
  <div
    class="rounded-lg overflow-hidden p-3"
    :class="cardBackgroundColorClass"
  >
    <div
      class="flex text-neutral-500"
    >
      <div
        class="w-1/4 shrink-0 flex justify-center items-center"
        :class="labelsBackgroundColor"
      >
        <custom-carousel
          :images="images"
          min-height="80px"
          max-height="80px"
          carousel-class="flex items-center h-full"
          :controls="images.length > 1"
          :default-image="defaultLayoutImage"
        />
      </div>
      <div
        class="flex w-full justify-between"
        @click="isModalOpen = true"
      >
        <div class="ml-3">
          <h3 class="font-semibold text-neutral-900 text-base">
            {{ $n(flat.price.amount) }} {{ $t(`currency.options.${flat.price.currency}`) }}
          </h3>
          <p class="font-semibold text-neutral-500">
            {{ $t(`building.flatsInfo.flatRoomsCount.${flat.flatRoomsCount || 'notIndicated.label'}`) }} {{ $t('flat.card.flatNumber', {value: flat.flatNumber}) }}
          </p>
          <div class="font-semibold text-neutral-500">
            {{ $t('flat.floorNumber.value', {value: flat.floorNumber}) }},
            {{ $t('flat.section', {value: sectionName}) }}
          </div>
        </div>
        <div class="flex flex-col items-end">
          <div
            v-if="flat.decoration"
            class="font-semibold text-neutral-900 text-base"
          >
            {{ $t(`flat.decorationType.options.${flat.decoration.decorationType}.short`).toLowerCase() }}
          </div>
          <div
            class="font-semibold text-neutral-500"
          >
            {{ flat.totalArea }} {{ $t('common.unitsOfMeasurement.options.squareMeter') }}
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-between mt-4">
      <slot name="actions" />
    </div>
  </div>
  <modal-or-bottom-sheet
    v-if="isModalOpen"
    v-model="isModalOpen"
  >
    <flats-list-card-view
      :building="building"
      :flat="flat"
    >
      <template #actions>
        <slot
          name="actions"
          :bg-normal="true"
        />
      </template>
    </flats-list-card-view>
  </modal-or-bottom-sheet>
</template>
<script setup lang="ts">
import defaultLayoutImage from 'assets/images/building/layout-default-image.svg'
import CustomCarousel from '~/ui/carousels/CustomCarousel.vue'
import type { Flat } from '~/common/types/flat/Flat'
import type { Image } from '~/common/types/image/Image'
import ModalOrBottomSheet from '~/components/common/ModalOrBottomSheet.vue'
import DISPLAY_TYPE from '~/common/constants/flat/Flat.displayType'
import { FLAT_STATUS_CLASSES } from '~/common/constants/flat/Flat.statusClasses'
import { HouseExpanded } from '~/common/types/house/House.expanded'
import { AvailableCountry } from '~/common/types/country/Country.available'
import { getEntityName } from '~/common/helpers/getEntityName'
import { Building } from '~/modules/building/types/Building'

const props = defineProps({
  flat: {
    type: Object as PropType<Flat>,
    required: true,
  },
  displayType: {
    type: String as PropType<DISPLAY_TYPE>,
    default: DISPLAY_TYPE.basic,
  },
  house: {
    type: Object as PropType<HouseExpanded>,
    default: null,
  },
  building: {
    type: Object as PropType<Building>,
    required: true,
  },
})

const images = computed<Array<Image>>(() => [...(props.flat?.layout?.images ?? [])])

const cardBackgroundColorClass = computed(() => FLAT_STATUS_CLASSES[props.flat?.status]?.bg ?? '')

const isModalOpen = ref(false)

const injectedFlatAddressCountryCode: AvailableCountry | undefined = inject('flatAddressCountryCode')
const flatAddressCountryCode = props.flat?.building?.address?.countryIsoCode || props.flat?.address?.countryIsoCode || injectedFlatAddressCountryCode

if (!flatAddressCountryCode) {
  throw new Error('Не передан flatAddressCountryCode')
}

const sectionName = computed(() => getEntityName({
  name: props.flat?.section?.name,
  internationalName: props.flat.section.internationalName,
  entityCountry: flatAddressCountryCode,
}))
</script>
