<template>
    <custom-tooltip placement="bottom" >
      <template #content>
        {{ $t('entityActions.filter.savedFilters.label.full') }}
      </template>
    <custom-button-icon
      class="font-semibold"
      :class="buttonClass"
      icon="actions/saved-filters-new"
      icon-class="text-[1.3rem]"
      round
      @click="isModalVisible = true"
    >
    </custom-button-icon>
    </custom-tooltip>
  <modal-or-bottom-sheet
    v-model="isModalVisible"
    :title="$t('entityActions.filter.savedFilters.modal')"
  >
    <slot :close="close">
      {{ $t('common.inDevelopment') }}
    </slot>
  </modal-or-bottom-sheet>
</template>
<script setup lang="ts">
import ModalOrBottomSheet from '~/components/common/ModalOrBottomSheet.vue'
import CustomButtonIcon from '~/ui/buttons/CustomButtonIcon.vue'
import CustomTooltip from "~/ui/tooltips/CustomTooltip.vue";

defineProps({
  buttonClass: {
    type: [String, Object, Array],
    default: '',
  },
})

const isModalVisible = ref(false)
const close = () => { isModalVisible.value = false }

</script>
