<template>
  <label
    class="cursor-pointer block"
    :class="{'text-danger': errors.length}"
  >
    <custom-checkbox
      v-model="isTermsOfUseConfirmed"
      :class="{'!border-danger': errors.length}"
    />
    {{ $t('auth.signup.iAgreeText') }}
    <nuxt-link
      :to="{ name: ROUTES_NAMES.privatePolicy }"
      target="_blank"
      class="text-blue-600"
    >
      {{ $t('auth.signup.privatePolicy') }}
    </nuxt-link>
    {{ $t('common.and') }}
    <nuxt-link
      :to="{ name: ROUTES_NAMES.licenseAgreement }"
      target="_blank"
      class="text-blue-600"
    >
      {{ $t('auth.signup.licenseAgreement') }}
    </nuxt-link>
  </label>
</template>

<script lang="ts" setup>
import CustomCheckbox from '~/ui/checkbox/CustomCheckbox.vue'
import { ROUTES_NAMES } from '~/router/constants/Router.routesNames'
import { useField } from 'vee-validate'

const props = defineProps({
  value: {
    type: Boolean,
    default: false,
  },
})

const emits = defineEmits<{(e: 'update:modelValue', value: boolean): void}>()

const { value: isTermsOfUseConfirmed, errors } = useField<boolean>('isTermsOfUseConfirmed', { required: true })

</script>
