<template>
  <custom-tooltip placement="bottom">
    <template #content>
      {{ $t('building.actions.fixingTheClient.label') }}
    </template>
    <custom-button-icon
      icon="common/user-check"
      color="secondary"
      round
      @click="showAuthModalOrCallback(fix)"
    />
  </custom-tooltip>
  <modal-or-bottom-sheet
    v-model="isClientFixationModalVisible"
    size="fullscreen"
  >
    <client-fixation-form
      :initial-developer="building.developer"
      @submit="isClientFixationModalVisible=false"
    />
  </modal-or-bottom-sheet>
</template>

<script setup lang="ts">
import CustomTooltip from '~/ui/tooltips/CustomTooltip.vue'
import { Building } from '~/modules/building/types/Building'
import CustomButtonIcon from '~/ui/buttons/CustomButtonIcon.vue'
import { showAuthModalOrCallback } from '~/modules/auth/helpers/showAuthModalOrCallback'
import ClientFixationForm from '~/modules/clients/components/forms/clients-fixation/ClientFixationForm.vue'
import ModalOrBottomSheet from '~/components/common/ModalOrBottomSheet.vue'

defineProps({
  building: {
    type: Object as PropType<Building>,
    required: true,
  },
})

const isClientFixationModalVisible = ref(false)

const fix = () => {
  isClientFixationModalVisible.value = true
}
</script>
