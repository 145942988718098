<template>
  <slot name="input">
    <div class="flex relative">
      <custom-input
        v-model="innerValue"
        type="number"
        :placeholder="placeholder"
        class="placeholder:font-medium"
        :class="{'filter-from-to-element__wrapper': selectingValues.length}"
        :input-class="[inputClass, {'!rounded-r-none' : hintOptions.length}, typeNumberClass]"
      >
        <template #suffixInner>
          {{ innerInputSuffix }}
        </template>
      </custom-input>

      <custom-dropdown
        v-if="hintOptions.length > 1"
        :trigger-text="selectedHintLabel"
        variant="withoutBorders"
        button-class=" bg-neutral-100 !rounded-l-none"
        dropdown-class="min-w-[80px]"
        body-class="!p-[10px]"
      >
        <ul>
          <li
            v-for="(hint, index) in hintOptions"
            :key="index"
            class="text-center py-[10px] px-[15px] rounded-[4px] text-primary cursor-pointer hover:bg-blue-50"
            :class="{'bg-primary-light': hint.value === value}"
            @click="$emit('update:selectedHint', hint.value)"
          >
            {{ hint.label }}
          </li>
        </ul>
      </custom-dropdown>

    </div>
  </slot>
  <filter-from-to-selecting-values
    :inner-value="value"
    :selecting-values="selectingValues"
    @select-value="onSelectValue"
  />
</template>

<script setup lang="ts">

import CustomInput from '~/ui/inputs/CustomInput.vue'
import CustomDropdown from '~/ui/dropdowns/CustomDropdown.vue'
import FilterFromToSelectingValues from '~/components/filters/from-to/FilterFromToSelectingValues.vue'
import { HintOption } from '~/common/types/filters/from-to/FromTo.hintOption'
import { FromToSelectingValue } from '~/common/types/filters/from-to/FromTo.selectingValue'

const props = defineProps({
  value: {
    type: [String, Number, Object, null],
    default: null,
  },
  selectedHint: {
    type: [String, null],
    default: null,
  },
  placeholder: {
    type: String,
    default: '',
  },
  hintOptions: {
    type: Array <PropType<HintOption>>,
    default: () => ([]),
  },
  selectingValues: {
    type: Array as PropType<Array<FromToSelectingValue>>,
    default: () => ([]),
  },
  selectValueHandler: {
    type: Function,
    default: null,
  },
  innerInputSuffix: {
    type: String,
    default: '',
  },
  inputClass: {
    type: [String, Object, Array],
    default: '',
  },
})

const emits = defineEmits<{(e: 'update:value', value: number | string | null,): void,
  (e: 'update:selectedHint', value: number | string | null,): void,
  (e: 'selectValue', value: number | string | null, object): void,
}>()

const innerValue = computed({
  get: () => props.value,
  set: value => emits('update:value', value),
})

const selectedHintLabel = computed(() => {
  if (props.hintOptions.length) {
    const selectedHint = props.hintOptions.find(option => option.value === props.selectedHint)
    return selectedHint.label
  }
})

const onSelectValue = (value: string | number | null | object): void => {
  if (props.selectValueHandler) {
    props.selectValueHandler(value)
  } else {
    innerValue.value = value
  }
}

const typeNumberClass = '[-moz-appearance:_textfield] [&::-webkit-inner-spin-button]:m-0 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:m-0 [&::-webkit-outer-spin-button]:appearance-none'

</script>

<style lang="scss">
.filter-from-to-element__wrapper {
  @apply relative after:absolute after:content-[''] after:bottom-0 after:left-0 after:w-[calc(100%+2rem)] after:h-[1px] pb-2;
}
</style>
