<template>
  <custom-button
    :disabled="disabled"
    class="!rounded-[19px]"
  >
    <template
      v-if="slots.prefix"
      #prefix
    >
      <slot name="prefix" />
    </template>
    <div
      class="relative whitespace-nowrap"
      :class="{'!pr-5' : displaySpinner}"
    >
      <custom-simple-spinner
        v-if="displaySpinner"
        class="absolute -right-3 font-semibold"
      />
      <slot />
    </div>
  </custom-button>
</template>
<script setup lang="ts">
import CustomButton from '~/ui/buttons/CustomButton.vue'
import CustomSimpleSpinner from '~/ui/spinners/CustomSimpleSpinner.vue'

defineProps({
  displaySpinner: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
})

const slots = useSlots()
</script>
