export const YANDEX_MAP_DEFAULT_CONFIG = {
  state: {
    center: [55.76, 37.64],
    zoom: 10,
    controls: ['trafficControl', 'typeSelector', 'zoomControl'],
  },
  options: {
    minZoom: 4,
    maxZoom: 19,
  },
}
