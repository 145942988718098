<template>
  <template v-if="appStore.isFetchingApp">
    <app-loader />
  </template>
  <nuxt-layout v-else>
    <nuxt-page />
  </nuxt-layout>
</template>

<script setup lang="ts">
import { breakpointsTailwind } from '@vueuse/core'
import { useAppStateStore } from '~/store/app'
import { useGoBackMobilePlatformListener } from '~/mobile-platforms/common/useGoBackMobilePlatformListener'

const appStore = useAppStateStore()
const appStateStore = useAppStateStore()

if (!appStateStore.isWeb) {
  useGoBackMobilePlatformListener()
}

manageScroll()
onMounted(() => {
  watchEffect(() => {
    const breakpoints = useBreakpoints()
    const sizeVariants: Array<keyof typeof breakpointsTailwind> = ['2xl', 'xl', 'lg', 'md', 'sm']
    appStore.size = sizeVariants.find(sizeVariant => breakpoints.greaterOrEqual(sizeVariant)) ?? null
    appStore.breakpoints = {
      isMd: breakpoints.greaterOrEqual('md').value,
      isLg: breakpoints.greaterOrEqual('lg').value,
      isXl: breakpoints.greaterOrEqual('xl').value,
    }
  })
})

</script>
