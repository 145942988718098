import { defineStore } from 'pinia'
import { defaultPagination } from '~/modules/market-entities-actions/constants/pagination/Pagination.default'
import { Pagination } from '~/common/types/pagination/Pagination'
import { ClientsFilters } from '~/modules/clients/types/Clients.filters'
import { Client } from '~/modules/clients/types/Client'
import { Building } from '~/modules/building/types/Building'
import { Flat } from '~/common/types/flat/Flat'

export type ClientsState = {
  pagination: Pagination
  filter: ClientsFilters | null
  selectedClient: null | Client
  chessboardOpen: boolean
  selectedViewBuilding: null | Building
  selectedViewFlat: null | Flat
}
export const useClientsStore = defineStore('clients', {
  state: () => ({
    pagination: { ...defaultPagination },
    filter: null,
    selectedClient: null,
    chessboardOpen: false,
    selectedViewBuilding: null,
    selectedViewFlat: null,
  }) as ClientsState,
  actions: {
    setPagination(pagination: Pagination): void {
      this.pagination = pagination
    },
    setFilter(filter: ClientsFilters | null): void {
      this.filter = filter
    },
  },
})
