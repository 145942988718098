<template>
  <div
    :class="[statusBackgroundColorClass, statusTextColorClass]"
    class="py-2 px-4 text-sm font-semibold rounded-[5px]"
  >
    <slot>
      {{ $t('flat.flatNumber', {value: flatNumber}) }}
    </slot>
  </div>
</template>
<script setup lang="ts">
import CustomBadge from '~/ui/badges/CustomBadge.vue'
import type { FlatStatus } from '~/common/types/flat/Flat.status'
import type { Flat } from '~/common/types/flat/Flat'
import { FLAT_STATUS } from '~/common/constants/flat/Flat.status'
import { FLAT_STATUS_CLASSES } from '~/common/constants/flat/Flat.statusClasses'

const props = defineProps({
  status: {
    type: String as PropType<FlatStatus>,
    default: null,
  },
  flatNumber: {
    type: String as PropType<Flat['flatNumber']>,
    default: null,
  },
})

const statusBackgroundColorClass = computed(() => FLAT_STATUS_CLASSES[props.status]?.bg ?? '')

const statusTextColorClass = computed(() => FLAT_STATUS_CLASSES[props.status]?.text ?? '')

</script>
