import {ROUTES_NAMES} from '~/router/constants/Router.routesNames'

const redirectPageQueryParamsEntityType = {
  developer: ROUTES_NAMES.developerPage,
  building: ROUTES_NAMES.buildingPage,
}
export default defineNuxtRouteMiddleware((to, from) => {
  const entityType: keyof typeof redirectPageQueryParamsEntityType = to.query['redirectPage[entityType]'] as keyof typeof redirectPageQueryParamsEntityType

  const entityId = to.query['redirectPage[id]']

  if (entityType && to.name !== ROUTES_NAMES.buildingPage) {
    return navigateTo({name: redirectPageQueryParamsEntityType[entityType], params: {id: entityId}}, {replace: true})
  }
})
