<script lang="ts" setup>
import {useAppStateStore} from '~/store/app'
import BankMortgageInputPrice from '~/modules/bank/components/mortgage/BankMortgageInputPrice.vue'
import BankMortgageInputInitialPayment from '~/modules/bank/components/mortgage/BankMortgageInputInitialPayment.vue'
import BankMortgageInputTerm from '~/modules/bank/components/mortgage/BankMortgageInputTerm.vue'
import BankMortgageInputStake from '~/modules/bank/components/mortgage/BankMortgageInputStake.vue'
import {MortgageDisplayingType} from '~/modules/bank/types/Mortgage.displayingType'
import {MORTGAGE_DISPLAYING_TYPE} from '~/modules/bank/constants/Mortgage.displayingType'

const props = defineProps({
  price: {
    type: Number,
    required: true,
  },
  initialPayment: {
    type: Number,
    required: true,
  },
  term: {
    type: Number,
    required: true,
  },
  stake: {
    type: Number,
    required: true,
  },
  displayingType: {
    type: String as PropType<MortgageDisplayingType>,
    default: MORTGAGE_DISPLAYING_TYPE.page,
  },
})

type Emits = {
  (e: 'update:price', value: number): void
  (e: 'update:initialPayment', value: number): void
  (e: 'update:term', value: number): void
  (e: 'update:stake', value: number): void
}

const emits = defineEmits<Emits>()

const appStateStore = useAppStateStore()

const price = computed({
  get: () => props.price,
  set: value => {
    emits('update:price', value)
  },
})
const initialPayment = computed({
  get: () => props.initialPayment,
  set: value => {
    emits('update:initialPayment', value)
  },
})
const term = computed({
  get: () => props.term,
  set: value => {
    emits('update:term', value)
  },
})
const stake = computed({
  get: () => props.stake,
  set: value => {
    emits('update:stake', value)
  },
})

const currency = computed(() => appStateStore.currency)
</script>

<template>
  <div
    class="flex flex-col gap-x-6 gap-y-8"
    :class="{'2xl:flex-row': displayingType === MORTGAGE_DISPLAYING_TYPE.page }"
  >
    <div class="flex flex-col md:flex-row gap-x-6 gap-y-8 xl:flex-1">
      <bank-mortgage-input-price
        v-model="price"
        :currency="currency"
        :displaying-type="displayingType"
      />
      <bank-mortgage-input-initial-payment
        v-model="initialPayment"
        :currency="currency"
        :full-price="price"
        :displaying-type="displayingType"
      />
    </div>
    <div class="flex flex-col md:flex-row gap-x-6 gap-y-8 xl:flex-1">
      <bank-mortgage-input-term
        v-model="term"
      />
      <bank-mortgage-input-stake
        v-model="stake"
      />
    </div>
  </div>
</template>
