<template>
  <custom-dropdown
    :button-class="['w-full items-center px-4 py-3', buttonClass]"
    body-class="!p-0"
    color="secondary"
    size="none"
  >
    <template #triggerContent>
      <div class="flex items-center">
        <span class="font-semibold">
          {{ houseName }}
        </span>
        <span class="ml-4 font-semibold">
          {{ states }}
        </span>
        <span
          class="mx-2 font-semibold"
        >
          —
        </span>
        <span class="font-semibold">
          {{ selectedHouseDeadline }}
        </span>
      </div>
    </template>

    <custom-list-group class="w-full">
      <custom-list-group-item
        @click="emits('update:selectedHouse', null)"
      >
        {{ $t('building.houses.allHouses') }}
      </custom-list-group-item>
      <custom-list-group-item
        v-for="(house, index) in houses"
        :key="index"
        @click="selectHouse(house)"
      >
        {{ getHouseName(house) }}
      </custom-list-group-item>
    </custom-list-group>
  </custom-dropdown>
</template>

<script setup lang="ts">

import CustomDropdown from '~/ui/dropdowns/CustomDropdown.vue'
import CustomListGroup from '~/ui/list-group/CustomListGroup.vue'
import CustomListGroupItem from '~/ui/list-group/CustomListGroupItem.vue'
import { HouseExpanded } from '~/common/types/house/House.expanded'
import { QUARTER } from '~/common/constants/date/Quarter'
import { BuildingHouseInfo } from '~/modules/building/types/Building.HouseInfo'
import { getEntityName } from '~/common/helpers/getEntityName'
import { Building } from '~/modules/building/types/Building'
import { AvailableLanguages } from '~/modules/app-language/types/language'

const props = defineProps({
  building: {
    type: Object as PropType<Building>,
    required: true,
  },
  houses: {
    type: Array as PropType<Array<HouseExpanded>>,
    default: () => ([]),
  },
  selectedHouse: {
    type: Object as PropType<HouseExpanded | null>,
    required: true,
  },
  houseInfo: {
    type: Object as PropType<BuildingHouseInfo>,
    required: true,
  },
  buttonClass: {
    type: [String, Object, Array],
    default: '',
  },
})

const emits = defineEmits<{(e: 'update:selectedHouse', value: HouseExpanded | null): void
}>()

const i18n = useI18n()

const selectHouse = (house: HouseExpanded | null) => {
  emits('update:selectedHouse', house)
}

const getHouseName = (house: HouseExpanded): string => {
  const { name, internationalName } = house
  return getEntityName({
    name,
    defaultName: i18n.t('building.name.default'),
    internationalName,
    entityCountry: props.building?.address?.countryIsoCode
  }) || ''
}

const selectedHouseDeadline = computed(() => {
  const { from: { year: deadLineYear, quarter: deadLineQuarter } } = props.houseInfo.deadLine
  const yearQuarter = []
  if (deadLineQuarter !== null) {
    yearQuarter.push(`${QUARTER[deadLineQuarter]} ${i18n.t('building.timeConstraints.quarter.label.short')}`)
  }
  if (deadLineYear !== null) {
    yearQuarter.push(`${deadLineYear}`)
  }
  return yearQuarter.join(' ')
})
const houseName = computed(() => {
  if (!props.selectedHouse) {
    return i18n.t('building.houses.allHouses')
  }
  return getHouseName(props.selectedHouse)
})
const states = computed(() => props.houseInfo.states.map(state => i18n.t(`building.state.options.${state}`)).join(', '))

</script>
