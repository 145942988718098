<template>
  <div class="flex gap-x-6">
    <div
      v-if="lastPage > maxLengthAdaptive && isMd"
      class="flex-grow flex-shrink basis-0"
    />
    <div class="flex items-center gap-[10px]">
      <template v-if="contents[0] === ellipsis">
        <custom-button-icon
          class="bg-white text-primary hover:bg-primary-light active:bg-primary active:text-white"
          color="none"
          round
          :disabled="isFetching"
          @click="goToFirst"
        >
          <nuxt-icon
            name="common/arrows/chevron-double-left"
            class="flex justify-center"
          />
        </custom-button-icon>
      </template>
      <template v-if="contents[0] === ellipsis || !isMd && currentPage === 2 && lastPage === 2">
        <custom-button-icon
          class="bg-white text-primary hover:bg-primary-light active:bg-primary active:text-white"
          color="none"
          round
          :disabled="isFetching"
          @click="goToPrevious"
        >
          <nuxt-icon
            name="common/arrows/chevron-left"
            class="flex justify-center"
          />
        </custom-button-icon>
      </template>
      <template v-if="isMd">
        <custom-button-icon
          v-for="(content, index) in contents"
          :key="`${content}_${index}`"
          variant="withoutBorders"
          color="none"
          :disabled="isFetching || content === ellipsis || content === currentPage"
          :class="[{'bg-primary text-white': content === currentPage, 'hover:text-primary hover:bg-primary-light active:bg-primary active:text-white': content !== currentPage && content !== ellipsis}, 'font-semibold']"
          round
          @click="goToPage(content)"
        >
          {{ content }}
        </custom-button-icon>
      </template>
      <template v-else>
        <div class="flex items-center gap-x-[10px]">
          <custom-input
            v-model="inputPageNumber"
            :placeholder="maxPages ? String(maxPages) : ''"
            :disabled="isFetching"
            class="max-w-[54px] h-[38px] text-xl"
            input-class="h-full"
            @keyup.enter="handleInput"
          />
          <span class=" text-blue-950 font-semibold  ">{{ $t('common.of') }}</span>
          <div class="w-[54px] h-[38px] flex items-center justify-center text-lg font-semibold bg-blue-200 text-blue-950 rounded-[5px]">
            {{ maxPages }} {{ pageNumber }}
          </div>
        </div>
      </template>
      <template v-if="contents[contents.length - 1] === ellipsis || !isMd && currentPage === 1 && lastPage === 2">
        <custom-button-icon
          class="bg-white text-primary hover:bg-primary-light active:bg-primary active:text-white"
          :disabled="isFetching"
          color="none"
          round
          @click="goToNext"
        >
          <nuxt-icon
            name="common/arrows/chevron-right"
            class="flex justify-center"
          />
        </custom-button-icon>
      </template>
      <template v-if="contents[contents.length - 1] === ellipsis">
        <custom-button-icon
          class="bg-white text-primary hover:bg-primary-light active:bg-primary active:text-white"
          :disabled="isFetching"
          color="none"
          round
          @click="goToLast"
        >
          <nuxt-icon
            name="common/arrows/chevron-double-right"
            class="flex justify-center"
          />
        </custom-button-icon>
      </template>
    </div>
    <div
      v-if="lastPage > maxLengthAdaptive && isMd && withMdInput"
      class="flex-grow flex-shrink basis-0"
    >
      <div class="flex gap-[10px] justify-end">
        <custom-input
          v-model="pageNumber"
          :placeholder="maxPages ? String(maxPages) : ''"
          class="max-w-[54px] h-[38px]"
          input-class="h-full !px-2"
        />
        <custom-button
          class="font-semibold"
          :disabled="isFetching"
          pill
          @click="goToPage(Number(pageNumber))"
        >
          {{ $t('pagination.goToPage') }}
        </custom-button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import CustomButtonIcon from '~/ui/buttons/CustomButtonIcon.vue'
import CustomInput from '~/ui/inputs/CustomInput.vue'
import CustomButton from '~/ui/buttons/CustomButton.vue'
import { Pagination } from '~/common/types/pagination/Pagination'
import { useAppStateStore } from '~/store/app'

const props = defineProps({
  isFetching: {
    type: Boolean,
    default: false,
  },
  pagination: {
    type: Object as PropType<Pagination>,
    required: true,
  },
  withMdInput: {
    type: Boolean,
    default: true,
  },
})

const emits = defineEmits<{(e: 'onPageChange', value: number): void}>()

const isMd = computed(() => useAppStateStore().breakpoints.isMd)
const maxLengthAdaptive = computed(() => (isMd.value ? 7 : 2))
const ellipsis = '…'

const pageNumber = ref('')
const currentPage = computed(() => props.pagination.currentPage)
const inputPageNumber = ref(currentPage.value)
const lastPage = computed(() => props.pagination.lastPage)
const maxPages = computed(() => {
  const { total } = props.pagination
  const { perPage } = props.pagination
  return Math.ceil(total / perPage)
})

const contents = computed(() => {
  const maxLength = maxLengthAdaptive.value
  const length = lastPage.value >= maxLength ? maxLength : lastPage.value
  return Array.from(
    { length },
    (v, k) => {
      if (lastPage.value <= maxLength) {
        return k + 1
      }
      if (k === 0 && currentPage.value > Math.floor(maxLength / 2) && lastPage.value > maxLength) {
        return ellipsis
      }
      if (lastPage.value - Math.ceil(maxLength / 2) < currentPage.value) {
        return lastPage.value - (maxLength - 1 - k)
      }
      if (lastPage.value <= maxLength) {
        return k + 1
      }
      if (currentPage.value + Math.ceil(maxLength / 2) > lastPage.value) {
        return currentPage.value + Math.floor(maxLength / 2) - (maxLength - 1 - k)
      }
      if (k + 1 === length) {
        return ellipsis
      }
      if (currentPage.value < Math.ceil(length / 2)) {
        return k + 1
      }
      return currentPage.value + Math.floor((length - 2) / 2) - ((length - 2) - k)
    },
  )
})

const goToPage = (page: number | string): void => {
  if (typeof page === 'string') {
    return
  }

  if (maxPages.value >= page) {
    emits('onPageChange', page)
  }
  pageNumber.value = ''
}
const goToFirst = () => {
  goToPage(1)
}

const goToLast = () => {
  goToPage(lastPage.value)
}

const goToPrevious = () => {
  goToPage(currentPage.value - 1)
}

const goToNext = () => {
  goToPage(currentPage.value + 1)
}

const handleInput = () => {
  goToPage(inputPageNumber.value)
}

watch(() => currentPage.value, page => {
  if (page !== 0) {
    inputPageNumber.value = page
  }
})
</script>
