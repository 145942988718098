import { defineStore } from 'pinia'
import { Country } from '~/modules/address/types'
import { Region } from '~/modules/address/types/Region'
import { City } from '~/modules/address/types/City'
import { Address } from '~/common/types/address/Address'
import { QdStorage } from '~/common/storage/interfaces/QdStorage'
import { useNuxtApp } from '#app'

type AddressState = {
  country: Country | null,
  countries: Array<Country> | null
  region: Region | null
  regions: Array<Region> | null
  city: City | null
  cities: Array<City> | null
  isAvailableCountriesFetching: boolean
}

export const useAddressStore = defineStore('address', {
  state: () => ({
    country: null,
    countries: [],
    region: null,
    regions: [],
    city: null,
    cities: [],
    isAvailableCountriesFetching: false,
  }) as AddressState,
  getters: {
    getLowestToponymAddress: (state): Address | null => {
      const { city, region } = state
      if (city) {
        return city
      } if (region) {
        return region.region
      }
      return null
    },
  },
  actions: {
    setCountry(country: Country | null) {
      this.country = country
      const storage: QdStorage = useNuxtApp().$appStorage
      storage.setItem('country', country)
    },
    setCountries(countries: Array<Country> | null) {
      this.countries = countries
    },
    setRegion(region: Region | null) {
      this.region = region
      const storage: QdStorage = useNuxtApp().$appStorage

      storage.setItem('region', region)
    },
    setRegions(regions: Array<Region> | null) {
      this.regions = regions
    },
    setCity(city: City | null) {
      this.city = city
      const storage: QdStorage = useNuxtApp().$appStorage
      storage.setItem('city', city)
    },
    setCities(cities: Array<City> | null) {
      this.cities = cities
    },
    onClickByShowButton(): void {
    //   Пустой метод, используется чтобы его подписчики в любой части приложения знали, что произошло нажатие на кнопку поиска
    },
  },
})
