<template>
  <div
    :class="sizeClasses[props.size]"
  >
    <div
      class="sk-folding-cube"
    >
      <div
        class="sk-cube sk-cube-1"
        :class="colorClasses[color]"
      />
      <div
        class="sk-cube sk-cube-2"
        :class="colorClasses[color]"
      />
      <div
        class="sk-cube sk-cube-4"
        :class="colorClasses[color]"
      />
      <div
        class="sk-cube sk-cube-3"
        :class="colorClasses[color]"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Color, Size } from '~/ui/types/types'
import { SIZE } from '~/ui/constants/size'
import { COLOR } from '~/ui/constants/color'

const props = defineProps({
  size: {
    type: String as PropType<Exclude<Size, 'none' | 'xs'>>,
    default: SIZE.lg,
  },
  color: {
    type: String as PropType<Exclude<Color, 'dropdown' | 'none' | 'transparent' | 'neutral'>>,
    default: COLOR.primary,
  },
})
const sizeClasses = {
  [SIZE.sm]: 'w-4 h-4 my-1',
  [SIZE.md]: 'w-20 h-20 my-5',
  [SIZE.lg]: 'w-40 h-40 my-12',
}
const colorClasses = {
  [COLOR.primary]: 'text-primary',
  [COLOR.success]: 'text-success',
  [COLOR.danger]: 'text-danger',
  [COLOR.warning]: 'text-warning',
  [COLOR.secondary]: 'text-secondary',
  [COLOR.info]: 'text-info',
  [COLOR.light]: 'text-light',
}
</script>

<style lang="scss">
.sk-folding-cube {
  $cubeCount: 4;
  $animationDuration: 2.4s;
  $delayRange: calc($animationDuration / 2);

  width: 100%;
  height: 100%;
  position: relative;
  margin: auto;
  transform: rotateZ(45deg);

  .sk-cube {
    float: left;
    width: 50%;
    height: 50%;
    position: relative;
    transform: scale(1.1);
  }

  .sk-cube:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: currentColor;
    animation: sk-folding-cube-angle $animationDuration infinite linear both;
    transform-origin: 100% 100%;
  }

  @for $i from 2 through $cubeCount {
    .sk-cube-#{$i} {
      transform: scale(1.1) rotateZ((90deg * ($i - 1)));
    }
  }

  @for $i from 2 through $cubeCount {
    .sk-cube-#{$i}:before {
      animation-delay: calc($delayRange / $cubeCount * ($i - 1));
    }
  }
}

@keyframes sk-folding-cube-angle {
  0%, 10% {
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%, 75% {
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%, 100% {
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}
</style>
