import { Capacitor } from '@capacitor/core'
import { PLATFORMS } from '~/common/constants/platform/Platforms'
import QdStorage from '~/common/storage/QdStorage'
import { MobilePlatformStorage } from '~/common/storage/MobilePlatformStorage'

export default defineNuxtPlugin(() => {
  const appStorage = Capacitor.getPlatform() === PLATFORMS.web ? new QdStorage() : new MobilePlatformStorage()
  return {
    provide: {
      appStorage,
    },
  }
})
