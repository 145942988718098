<template>
  <ul
    v-if="selectingValues.length"
    class="max-h-[200px] overflow-y-auto scrollbar-thin"
  >
    <li
      v-for="(value, index) in selectingValues"
      :key="index"
      class="text-center p-[8px] rounded-[4px] cursor-pointer hover:bg-blue-50 scrollbar-red-100"
      :class="[selectedClasses(value.value)]"
      @click="$emit('selectValue', value.value)"
    >
      {{ value.label || value.value }}
    </li>
  </ul>
</template>

<script setup lang="ts">
import { FromToSelectingValue } from '~/common/types/filters/from-to/FromTo.selectingValue'

const props = defineProps({
  selectingValues: {
    type: Array as PropType<Array<FromToSelectingValue>>,
    default: () => ([]),
  },
  innerValue: {
    type: [String, Number, Object, null],
    default: null,
  },
})

const emits = defineEmits<{(e: 'selectValue', value: number | string | null | object,): void,
}>()

const selectedClasses = (value: [string, number, object, null]) => {
  const styles = 'bg-primary-light text-primary'

  if (props.innerValue && typeof props.innerValue === 'object') {
    return { [styles]: Object.keys({ ...value, ...props.innerValue }).every(key => value[key] === props.innerValue[key]) }
  }
  return { [styles]: (value as string | number) === props.innerValue }
}

</script>
