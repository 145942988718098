import { HttpHandledResponse } from '~/services/http/types/Http.handledResponse'

export class InterceptorManager {
  public handlers: {
    onFulfilled: <T>(response: HttpHandledResponse<T>) => Promise<HttpHandledResponse<T>>
    onRejected: <T>(response: HttpHandledResponse) => Promise<HttpHandledResponse<T>>
  } = {
      onFulfilled: <T>(response: HttpHandledResponse<T>) => Promise.resolve(response),
      onRejected: (response: HttpHandledResponse) => Promise.reject(response),
    }

  public use(onFulfilled: <T>(response: HttpHandledResponse<T>) => Promise<HttpHandledResponse<T>>, onRejected: <T>(response: HttpHandledResponse) => Promise<HttpHandledResponse<T>>): void {
    this.handlers = {
      onFulfilled,
      onRejected,
    }
  }
}
