<template>
  <div class="flex flex-col">
    <div class="w-full flex gap-x-6">
      <flats-prices-statistics-table-view
        :statistics-for-table="statisticsFirstPart"
        :currency="currency"
      />
      <flats-prices-statistics-table-view
        v-if="isNeedTwoTable"
        :statistics-for-table="statisticsSecondPart"
        :currency="currency"
      />
    </div>
    <custom-button
      v-if="isMoreButtonShow"
      pill
      class="mx-auto font-semibold"
      @click="isModalOpen = true"
    >
      {{ $t('flat.statistics.price.table.button') }}
    </custom-button>
    <modal-or-bottom-sheet
      v-model="isModalOpen"
      :title="$t('flat.statistics.price.title')"
    >
      <flats-prices-statistics-table-view
        :statistics-for-table="statisticsForTable"
        :currency="currency"
      />
    </modal-or-bottom-sheet>
  </div>
</template>

<script setup lang="ts">
import FlatsPricesStatisticsTableView from '~/components/flats/statistics/prices/FlatsPricesStatisticsTableView.vue'
import CustomButton from '~/ui/buttons/CustomButton.vue'
import ModalOrBottomSheet from '~/components/common/ModalOrBottomSheet.vue'
import { Currency } from '~/common/types/money/Currency'
import { CURRENCY } from '~/common/constants/money/Currency'

const props = defineProps({
  statisticsForTable: {
    type: Array as PropType<Array<{ date: string; price: number; persentage: number }>>,
    required: true,
  },
  isMoreButtonShow: {
    type: Boolean,
    deafult: false,
  },
  isNeedTwoTable: {
    type: Boolean,
    deafult: false,
  },
  currency: {
    type: String as PropType<Currency>,
    default: CURRENCY.usd,
  },
})

const statisticsFirstPart = computed(() => props.statisticsForTable.slice(0, 4))

const statisticsSecondPart = computed(() => props.statisticsForTable.slice(4, 8))

const isModalOpen = ref(false)
</script>
