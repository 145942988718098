<template>
  <client-form-template
    :button-title="$t('clients.booking.form.submit')"
    :progress="progress"
    :is-fetching="isFetching"
    @submit="onSubmit"
  >
    <template
      v-if="motivation"
      #progressInfo
    >
      <div class="border-2 border-neutral-150 rounded-[12px] p-6 flex gap-x-6 mb-6">
        <div class="flex flex-col">
          <h4 class="font-semibold text-neutral-500 text-sm">
            {{ $t('clients.booking.form.progress.yourCommission') }}
          </h4>
          <span class="flex font-semibold mt-1">{{ motivation }}%</span>
        </div>
      </div>
    </template>
    <template #mobileAbout>
      <client-booking-form-about-toast class="mx-6" />
      <template
        v-if="motivation"
      >
        <custom-card
          class-name="rounded-lg"
          class="flex gap-x-6"
        >
          <div class="flex flex-col">
            <h4 class="font-semibold text-neutral-500 text-sm">
              {{ $t('clients.booking.form.progress.yourCommission') }}
            </h4>
            <span class="flex font-semibold mt-1">{{ motivation }}%</span>
          </div>
        </custom-card>
      </template>
      <template
        v-if="flat"
      >
        <custom-card
          class-name="rounded-lg"
        >
          <custom-button
            class="flex justify-between items-center w-full !text-neutral-950"
            size="none"
            variant="withoutBorders"
            @click="isClientDrawerOpen=true"
          >
            <span class="font-medium text-[16px]">{{ $t('clients.booking.form.about.flat') }}</span>
            <nuxt-icon name="common/arrows/chevron-right" />
          </custom-button>
        </custom-card>
        <custom-drawer
          v-model="isClientDrawerOpen"
        >
          <client-booking-form-about-flat :flat="flat" />
        </custom-drawer>
      </template>
      <template
        v-if="manager"
      >
        <custom-card
          class-name="rounded-lg"
        >
          <custom-button
            class="flex justify-between items-center w-full !text-neutral-950"
            size="none"
            variant="withoutBorders"
            @click="isContactsDrawerOpen=true"
          >
            <span class="font-medium text-[16px]">{{ $t('clients.fixation.contacts.mobileTitle') }}</span>
            <nuxt-icon name="common/arrows/chevron-right" />
          </custom-button>
        </custom-card>
        <custom-drawer
          v-model="isContactsDrawerOpen"
        >
          <client-form-template-about-contacts :user="manager" />
        </custom-drawer>
      </template>
    </template>
    <template #default>
      <client-form-field-client
        :progress-step-done="progressSteps.client"
        :progress-step-percent="progressStepsPercents.client"
      />
      <client-booking-form-deal
        :progress-step-done="progressSteps.deal"
        :progress-step-percent="progressStepsPercents.deal"
      />
      <client-form-field-user
        :progress-step-done="progressSteps.user"
        :progress-step-percent="progressStepsPercents.user"
      />
    </template>
    <template #about>
      <client-booking-form-about
        :flat="flat"
        :manager="manager"
      />
    </template>
  </client-form-template>
</template>

<script setup lang="ts">
import ClientBookingFormAbout from '~/modules/clients/components/forms/clients-booking/ClientBookingFormAbout.vue'
import CustomCard from '~/ui/cards/CustomCard.vue'
import CustomButton from '~/ui/buttons/CustomButton.vue'
import ClientFormFieldUser from '~/modules/clients/components/forms/ClientFormFieldUser.vue'
import { Flat } from '~/common/types/flat/Flat'
import CustomDrawer from '~/ui/drawers/CustomDrawer.vue'
import ClientBookingFormAboutToast
  from '~/modules/clients/components/forms/clients-booking/ClientBookingFormAboutToast.vue'
import ClientFormTemplate from '~/modules/clients/components/forms/ClientFormTemplate.vue'
import ClientFormFieldClient from '~/modules/clients/components/forms/ClientFormFieldClient.vue'
import ClientBookingFormDeal from '~/modules/clients/components/forms/clients-booking/ClientBookingFormDeal.vue'
import ClientBookingFormAboutFlat
  from '~/modules/clients/components/forms/clients-booking/ClientBookingFormAboutFlat.vue'
import { User } from '~/modules/user/UserModule'
import ClientFormTemplateAboutContacts from '~/modules/clients/components/forms/ClientFormTemplateAboutContacts.vue'
import { ClientsService } from '~/modules/clients/services/ClientsService'
import { ClientBooking } from '~/modules/clients/types/ClientBooking'

type Entity = 'deal' | 'user' | 'client'

const props = defineProps({
  manager: {
    type: Object as PropType<User | null>,
    default: null,
  },
  motivation: {
    type: Number,
    default: null,
  },
  flat: {
    type: Object as PropType<Flat>,
    required: true,
  },
})

const emits = defineEmits<{(e: 'submit', booking: ClientBooking): void}>()

const clientsService = new ClientsService()

const { t } = useI18n()

const { values, handleSubmit, setErrors, handleReset } = useForm<ClientBooking>()

const isClientDrawerOpen = ref(false)

const isContactsDrawerOpen = ref(false)

const isFetching = ref(false)

const progressStepsPercents: Record<Entity, number> = {
  deal: 50,
  user: 10,
  client: 40,
}

const progressSteps = computed<Record<Entity, boolean>>(() => ({
  deal: !!values.paymentType,
  user: !!values.assigned,
  client: !!values.client,
}))

const progress = computed(() => Object.entries(progressSteps.value).reduce(
  (sum: number, [step, done]) => (done ? sum + progressStepsPercents[step as Entity] : sum),
  0,
))

const onSubmit = handleSubmit(() => {
  isFetching.value = true
  clientsService.bookClient({ ...values, flat: props.flat })
    .then(res => {
      emits('submit', res)
    })
    .then(() => {
      handleReset()
      toast(t('clients.toasts.booking.success'), { variant: 'success' })
    })
    .catch(response => {
      toast(t('clients.toasts.booking.error'), { variant: 'danger' })
      if (response.status === 422) {
        const { errors } = response.data
        setErrors(errors)
      }
    })
    .finally(() => {
      isFetching.value = false
    })
})
</script>
