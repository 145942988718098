<template>
  <custom-button
    class="flex items-center font-semibold justify-center h-[38px] px-4"
    size="none"
    pill
    @click="isChessboardOpen = true"
  >
    <nuxt-icon
      name="entities/chessboard"
      class="inline text-[24px]"
    />
    <span
      class="ml-2 hidden lg:inline-block"
    >
      {{ $t('flat.chessboard.entityName') }}
    </span>
  </custom-button>
  <modal-or-bottom-sheet
    v-model="isChessboardOpen"
    :drawer-props="{fullHeight: true, contentClass: 'max-h-full pb-[60px]', bodyClass: '!overflow-hidden'}"
    :modal-props="{hideFooter: true, hideHeader: true, size: 'fullscreen', contentClass: '!bg-neutral-100'}"
  >
    <building-chessboard
      v-if="isChessboardOpen"
      v-model:filter="filter"
      :building="props.building"
      :flat-display-type="flatDisplayType"
    >
      <template #closeButton>
        <custom-button
          class="text-black bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg self-start"
          size="none"
          color="none"
          @click="isChessboardOpen = false"
        >
          <nuxt-icon
            name="actions/close"
            class="text-[2rem]"
          />
        </custom-button>
      </template>
    </building-chessboard>
  </modal-or-bottom-sheet>
</template>

<script setup lang="ts">
import DISPLAY_TYPE from '~/common/constants/flat/Flat.displayType'
import ModalOrBottomSheet from '~/components/common/ModalOrBottomSheet.vue'
import { Building, useBuildingStore } from '~/modules/building/BuildingModule'
import BuildingChessboard from '~/modules/building/components/building-chessboard/BuildingChessboard.vue'
import CustomButton from '~/ui/buttons/CustomButton.vue'

const props = defineProps({
  building: {
    type: Object as PropType<Building>,
    required: true,
  },
  flatDisplayType: {
    type: String as PropType<DISPLAY_TYPE>,
    default: DISPLAY_TYPE.basic,
  },
})

const isChessboardOpen = ref(false)
const store = useBuildingStore()

const filter = computed({
  get: () => store.filter,
  set: value => {
    store.setBuildingFlatFilter(value)
  },
})

</script>
