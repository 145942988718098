<template>
  <div class="flex md:gap-x-10">
    <slot v-if="isMd" name="label">
      {{ label }}
    </slot>
    <div class="grow flex flex-col">
      <slot name="info" />
      <div class="w-full">
        <div class="flex justify-between">
          <div v-for="(_, i) in 100 / step + 1" :key="i" class="text-neutral-500 text-sm font-semibold">
            {{ `${i * step}%` }}
          </div>
        </div>
        <div class="mt-4 bg-gray-300 h-3 rounded-full overflow-hidden">
          <div class="bg-success h-3 rounded-full" :style="{width: barWidth}" />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useAppStateStore } from '~/store/app'

const props = defineProps({
  progress: {
    type: Number,
    default: 0,
    validator(value: number) {
      return value >= 0 && value <= 100
    },
  },
  label: {
    type: String,
    default: null,
  },
})

const isMd = computed(() => useAppStateStore().breakpoints.isMd)
const step = computed(() => (isMd.value ? 20 : 50))

const barWidth = computed(() => (props.progress ? `${props.progress}%` : '5px'))
</script>
