<template>
  <custom-card
    v-if="!isInvitationFetching"
    class="relative"
    v-bind="$attrs"
  >
    <select-language
      v-if="formDisplayingType === FORM_DISPLAYING_TYPE.page"
      class="!absolute top-4 md:top-2 right-4 md:right-2"
    />
    <h2
      v-if="!isFiredUser"
      class="text-blue-700 text-center mt-4 mb-8"
    >
      {{ t('auth.description.label') }}
    </h2>
    <template v-if="isFiredUser">
      <auth-fired-user-blocker
        @set-user-data="setUserData"
      />
    </template>
    <template v-else-if="!organisations.length">
      <div class="flex flex-col items-center">
        <h4 class="mb-4 font-normal">
          {{ t('auth.description.welcome') }}
        </h4>
        <p class="mb-6">
          {{ t('auth.description.into') }}
        </p>
      </div>
      <form @submit="login">
        <auth-form-username-input
          v-model="username"
          :disabled="isFetching"
          username-type="email"
          :errors="backendValidationErrors"
        />

        <auth-password-input
          v-model="password"
          class="mt-2"
          :disabled="isFetching"
        />
        <p v-if="store.invitationSecret" class="mt-4 text-sm text-neutral-300">
          {{ $t('auth.invitationSignup.loginInfoMessage', {value: store.invitationOrganisation}) }}
        </p>
        <custom-button
          type="submit"
          variant="filled"
          class="w-full mt-4 font-semibold"
          pill
          :disabled="isFetching"
        >
          {{ $t('auth.login.label') }}
        </custom-button>
      </form>
    </template>
    <template v-else>
      <auth-select-or-create-organisation-form
        :organisations="organisations"
        :auth-service="authService"
        @set-organisation="setUserData"
      />
    </template>
    <div
      class="flex justify-between items-center mt-4"
    >
      <nuxt-link
        v-for="(link, i) in links"
        :key="i"
        class="inline-block cursor-pointer font-semibold"
        @click="onLinkClick(link.name, link.emit, link.query)"
      >
        <p class="text-blue-700">
          {{ link.tag }}
        </p>
      </nuxt-link>
    </div>
  </custom-card>
  <div v-else class="min-h-[calc(100vh-2rem)] flex items-center justify-center">
    <custom-cube-spinner />
  </div>
</template>

<script setup lang="ts">
import AuthPasswordInput from '~/modules/auth/components/AuthPasswordInput.vue'
import CustomCard from '~/ui/cards/CustomCard.vue'
import CustomButton from '~/ui/buttons/CustomButton.vue'
import AuthFormUsernameInput from '~/modules/auth/components/AuthFormUsernameInput.vue'
import { useForm } from 'vee-validate'
import { AuthService } from '~/modules/auth/service/AuthService'
import { LoginResponse } from '~/modules/auth/types/auth'
import { ROUTES_NAMES } from '~/router/constants/Router.routesNames'
import { useAuthStore } from '~/modules/auth/store'
import AuthSelectOrCreateOrganisationForm from '~/modules/auth/components/AuthSelectOrCreateOrganisationForm.vue'
import AuthFiredUserBlocker from '~/modules/auth/components/AuthFiredUserBlocker.vue'
import { Organisation } from '~/common/types/organisation/Organisation'
import CustomCubeSpinner from '~/ui/spinners/CustomCubeSpinner.vue'
import { FormDisplayingType } from '~/modules/auth/types/Auth.formDisplayingType'
import { FORM_DISPLAYING_TYPE } from '~/modules/auth/constants/Auth.formDisplayingType'
import SelectLanguage from '~/modules/app-language/components/SelectLanguage.vue'

type RouteName = Pick<typeof ROUTES_NAMES, 'signup' | 'forgotPassword'>[keyof typeof ROUTES_NAMES]

const { t } = useI18n()
const authService = new AuthService()
const store = useAuthStore()
const route = useRoute()

const links = computed(() => [
  {
    name: store.invitationSecret ? ROUTES_NAMES.signupInvitation : ROUTES_NAMES.signup,
    tag: t('auth.signup.label'),
    query: store.invitationSecret ? { invitationSecret: store.invitationSecret } : undefined,
    emit: () => emits('goToSignup'),
  },
  {
    name: ROUTES_NAMES.forgotPassword,
    tag: t('auth.forgotPassword.label'),
    emit: () => emits('goToForgotPassword'),
  },
] as Array<{name: RouteName, tag: string, query?: Record<string, string>, emit: () => void}>)

const props = defineProps({
  formDisplayingType: {
    type: String as PropType<FormDisplayingType>,
    default: FORM_DISPLAYING_TYPE.page,
  },
})
const emits = defineEmits<{(e: 'onLogin', value: LoginResponse): void,
  (e: 'goToSignup'): void,
  (e: 'goToForgotPassword'): void
}>()

const organisations = ref<Array<Organisation>>([])
const isFetching = ref(false)
const isFiredUser = ref(false)
const isInvitationFetching = ref(false)
const userData = ref<LoginResponse | null>(null)
const password = ref<string | null>(null)
const username = ref<string | null>(null)

const backendValidationErrors = ref([])

const setUserData = () => {
  authService.setAccessToken(userData.value
    ? {
        token: userData.value.tokens.accessToken,
        expiresAtTime: userData.value.tokens.accessTokenExpiresAt,
      }
    : null)
  authService.setRefreshToken(userData.value
    ? {
        token: userData.value.tokens.refreshToken,
        expiresAtTime: userData.value.tokens.refreshTokenExpiresAt,
      }
    : null)
  emits('onLogin', userData.value as LoginResponse)
}

const onLinkClick = (name: RouteName, emit: () => void, query?: Record<string, string>) => {
  if (props.formDisplayingType === FORM_DISPLAYING_TYPE.page) {
    useRouter().replace({ name, query })
  } else {
    emit()
  }
}

const { handleSubmit, setErrors } = useForm()
const login = handleSubmit(() => {
  isFetching.value = true
  authService.login(username.value as string, password.value as string, store.invitationSecret)
    .then(response => {
      userData.value = response
      const organisationLength = response.organisations.length
      if (!organisationLength) {
        isFiredUser.value = true
      } else if (organisationLength > 1) {
        organisations.value = [...response.organisations]
      } else {
        setUserData()
      }
    })
    .catch(response => {
      if (response.status === AuthService.ERROR_STATUS) {
        backendValidationErrors.value = [t('auth.backendValidation.error')]
      }
    })
    .finally(() => {
      isFetching.value = false
    })
})

onBeforeMount(() => {
  if (store.isInvitationSecretChecked) {
    return
  }

  if (route.query.invitationSecret) {
    isInvitationFetching.value = true
    authService.getOrganisationInfo(route.query.invitationSecret as string)
      .then(response => {
        if (response.name) {
          store.setInvitationSecret(route.query.invitationSecret as string)
          store.setInvitationOrganisation(response.name as string)
          store.setIsInvitationSecretChecked(true)
        } else {
          navigateTo({ path: '/auth/invitation', query: { invitationSecret: route.query.invitationSecret } })
        }
      })
      .catch(() => {
        navigateTo({ path: '/auth/invitation', query: { invitationSecret: route.query.invitationSecret } })
      }).finally(() => {
        isInvitationFetching.value = false
      })
  }
})
</script>
<style scoped>

</style>
