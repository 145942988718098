import { useAppStateStore } from '~/store/app'

function manageScroll() {
  const store = useAppStateStore()
  const toggleScroll = (scrollEnabled: boolean) => {
    if (process.client) {
      document.body.classList.toggle('overflow-y-hidden', !scrollEnabled)
    }
  }

  const allClosed = computed(() => store.openElementsCount.modals === 0)
  watchEffect(() => {
    toggleScroll(allClosed.value)
  })
}
export default manageScroll
