<template>
  <selected-filters
    :selected-filters="selectedFilters"
    @deselect="deselectFilter"
  />
</template>
<script setup lang="ts">
import SelectedFilters from '~/modules/market-entities-actions/components/filter/SelectedFilters.vue'
import { ObjectHelper } from '~/common/helpers/object'
import { QUARTER } from '~/common/constants/date/Quarter'
import { BuildingFlatFilter } from '~/modules/building/components/page/flats/filter/types/BuildingFlatFilter'
import {
  defaultBuildingFlatFilter,
} from '~/modules/building/components/page/flats/filter/constants/DefaultBuildingFlatFilter'

const props = defineProps({
  filter: {
    type: Object as PropType<BuildingFlatFilter>,
    required: true,
  },
})
const emits = defineEmits<{(e: 'deselect', path: string, value?: any): void}>()

const { t, n } = useI18n()

const defaultFilter = defaultBuildingFlatFilter() as Record<string, any>
const checkIsFilterPathExcludedFromDisplay = (path: string): boolean => {
  const excludedFromDisplayFiltersPaths = ['flatFilter.price.currency', 'price.currency', 'status']

  return excludedFromDisplayFiltersPaths.includes(path)
}
const deselectFilter = (path: string, value: string | number) => {
  emits('deselect', path, value)
}

function removeWordBeforeFirstDot(inputString: string): string {
  const indexOfFirstDot = inputString.indexOf('.')

  if (indexOfFirstDot !== -1) {
    const wordsBeforeFirstDot = inputString.substring(0, indexOfFirstDot)
    const lastSpaceIndex = wordsBeforeFirstDot.lastIndexOf(' ')

    if (lastSpaceIndex !== -1) {
      const wordToRemove = wordsBeforeFirstDot.substring(lastSpaceIndex + 1)
      const resultString = inputString.replace(wordToRemove, '').replace('  ', ' ').trim()
      return resultString
    }
    const resultString = inputString.substring(indexOfFirstDot + 1).trim()
    return resultString
  }

  return inputString
}

const getFilterLabel = (filter: {value: any, path: string}) => {
  const prefix = 'flat.'

  filter.path = removeWordBeforeFirstDot(filter.path)
  switch (filter.path) {
    case 'deadLine.from':
      return t(
        'entityActions.filter.houseFilter.deadLineYear.from',
        { year: filter.value.year, quarter: QUARTER[filter.value.quarter] },
      )
    case 'deadLine.to':
      return t(
        'entityActions.filter.houseFilter.deadLineYear.to',
        { year: filter.value.year, quarter: QUARTER[filter.value.quarter] },
      )
    case 'kitchenArea.from':
      return t(`entityActions.filter.flatFilter.${filter.path}`, { value: filter.value })
    case 'kitchenArea.to':
      return t(`entityActions.filter.flatFilter.${filter.path}`, { value: filter.value })
    case 'decorationTypes':
      return t(`${prefix}decorationType.options.${filter.value}.full`)
    case 'windowsViewTypes':
      return t(`${prefix}windowViewType.options.${filter.value}.label`)
    case 'lastFloor':
      return t(`${prefix}${filter.path}.label`)
    case 'notFirstFloor':
      return t(`${prefix}${filter.path}.label`)
    case 'status':
      return t(`${prefix}${filter.path}.options.${filter.value}`)
    case 'flatRoomsCount':
      return t(`entityActions.filter.flatFilter.flatRoomsCount.${filter.value}`)
    case 'price.from':
      return t(
        `${prefix}${filter.path}`,
        { amount: n(props.filter.flatFilter.price.from as number), currency: t(`currency.options.${props.filter.flatFilter.price.currency}`) },
      )
    case 'price.to':
      return t(
        `${prefix}${filter.path}`,
        { amount: n(props.filter.flatFilter.price.to as number), currency: t(`currency.options.${props.filter.flatFilter.price.currency}`) },
      )
    default:
      return t(`${prefix}${filter.path}`, { value: filter.value })
  }
}

const selectedFilters = computed(() => {
  const selected: Array<{ value: any, path: string}> = []
  const flatDeadlineFilterPaths = ['houseFilter.deadLine.from', 'houseFilter.deadLine.to']
  const filterPaths = ObjectHelper.getNestedPaths(props.filter, flatDeadlineFilterPaths)
    .map(path => ({ path, value: ObjectHelper.getNestedValue(props.filter as Record<string, any>, path) }))
    .filter(({ path, value }) => {
      if (checkIsFilterPathExcludedFromDisplay(path)) {
        return false
      }
      if (Array.isArray(value)) {
        return value.length
      }
      return !ObjectHelper.isEqual(ObjectHelper.getNestedValue(defaultFilter, path), value)
    })
  filterPaths.forEach(({ path, value }) => {
    if (!Array.isArray(value)) {
      selected.push({ path, value })
    } else {
      value.forEach(option => {
        selected.push({ path, value: option })
      })
    }
  })
  return selected.map(selectedFilter => ({ ...selectedFilter, label: getFilterLabel(selectedFilter) }))
})
</script>
