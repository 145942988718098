import { UsersHttpApiService } from '~/modules/user/api/UsersHttpApiService'
import useOrganisationStore from '~/modules/organisation/store'
import { User } from '~/modules/user/types/user'
import { UserFilter } from '~/modules/user/types/User.filter'
import SentryLogger from '~/services/sentry/SentryLogger'
import { useUserStore } from '~/modules/user/UserModule'
import { EntryUserData } from '~/modules/user/types/EntryUserData'
import { EntryPasswordData } from '~/modules/user/types/EntryPasswordData'
import { UserRemoveAccount } from '~/modules/user/types/User.removeAccount'

export class UserService {
  private http: UsersHttpApiService

  private store: ReturnType<typeof useUserStore>
  private organisationStore: ReturnType<typeof useOrganisationStore>

  constructor() {
    const nuxtApp = useNuxtApp()
    const httpInstance = nuxtApp.$qdHttpApiInstance
    this.http = new UsersHttpApiService(httpInstance)
    this.store = useUserStore()
    this.organisationStore = useOrganisationStore()
  }

  search(filter: UserFilter, page = 1) {
    const { organisation } = useOrganisationStore()
    return new Promise<Array<User>>((resolve, reject) => {
      this.http.getUsers(organisation.id, filter, page)
        .then(response => {
          resolve(response.data)
        }).catch(reject)
    })
  }

  public setUser(user: User, isOrganisationUser = false): Promise<User> {
    return new Promise((resolve, reject) => {
      this.http.setUser(user)
        .then(response => {
          if (!isOrganisationUser) {
            this.store.setUser(response.data)
          } else {
            this.organisationStore.organisationUsers = this.organisationStore.organisationUsers.map(orgUser => (orgUser.id === user.id ? response.data : orgUser))
          }
          resolve(response.data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось обновить информацию о пользователе',
            user,
          })
          reject(error)
        })
    })
  }

  public getUserEntryData(user: User): Promise<EntryUserData> {
    return new Promise((resolve, reject) => {
      this.http.getUserEntryData(user)
        .then(response => {
          this.store.setUserEntryData(response.data)
          resolve(response.data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось получить информацию о входных данных пользователя',
          })
          reject(error)
        })
    })
  }

  public setUserEntryData(user: User, values: EntryUserData & { currentPassword: string }): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.setUserEntryData(user, values)
        .then(response => {
          this.store.setUserEntryData(response.data)
          resolve(response.data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось обновить информацию о входных данных пользователя',
          })
          reject(error)
        })
    })
  }

  public changePassword(user: User, values: EntryPasswordData): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.changePassword(user, values)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось обновить пароль пользователя',
          })
          reject(error)
        })
    })
  }

  public getRemoveAccountInfo(userId: number): Promise<UserRemoveAccount> {
    return new Promise((resolve, reject) => {
      this.http.getRemoveAccountInfo(userId)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось получить информацию о удалении аккаунта',
            userId,
          })
          reject(error)
        })
    })
  }

  public removeAccount(userId: number, reason: string): Promise<UserRemoveAccount> {
    return new Promise((resolve, reject) => {
      this.http.removeAccount(userId, reason)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось отправить запрос на удаление аккаунта',
          })
          reject(error)
        })
    })
  }
}
