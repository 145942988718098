<template>
  <div class="flex flex-wrap gap-3 min-w-[300px]">
    <custom-button
      v-for="(decoration, i) of options"
      :key="decoration + i"
      pill
      size="xs"
      class="font-semibold !border-neutral-200 !text-black"
      variant="outline"
      :class="{'!border-primary !text-primary ': selectedOptions.includes(decoration)}"
      @click="toggleSelected(decoration)"
    >
      {{ $t(`flat.decorationType.options.${decoration}.full`) }}
    </custom-button>
  </div>
</template>
<script setup lang="ts">
import { defaultFilter } from '~/modules/market-entities-actions/constants/filter/Filter.default.ts'
import CustomButton from '~/ui/buttons/CustomButton.vue'
import { ArrayHelper } from '~/common/helpers/arrays'
import { DecorationType } from '~/common/types/decoration/Decoration.type'

const props = defineProps({
  modelValue: {
    type: Array as PropType<Array<DecorationType>>,
    default: () => defaultFilter().flatFilter.decorationTypes,
  },
})
const emits = defineEmits<{(e: 'update:modelValue', value: Array<DecorationType>): void }>()

const options: Array<DecorationType> = ['withoutPreparation', 'preparation', 'preFinishing', 'fine', 'furnished', 'partlyFurnished']
const selectedOptions = computed({
  get: () => props.modelValue,
  set: value => emits('update:modelValue', value),
})

function toggleSelected(option: DecorationType) {
  const selectedOptionsCopy = [...selectedOptions.value]
  ArrayHelper.toggle(selectedOptionsCopy, option)
  selectedOptions.value = selectedOptionsCopy
}
</script>
