<template>
  <custom-dropdown
    :close-on-select="false"
    color="secondary"
    :button-class="[isEmpty(selectedOptions) ? 'bg-inherit' : '!bg-blue-100', 'w-full font-semibold text-primary']"
  >
    <template #triggerContent>
      <filter-counter v-if="countSelectedOptions">
        {{ countSelectedOptions }}
      </filter-counter>
      {{ $t('building.flatsInfo.flatRoomsCount.label') }}
    </template>

    <div class="p-[11px] ">
      <div class="w-[310px] flex flex-wrap gap-x-2 gap-y-[10px]">
        <custom-button
          v-for="(count) of FLAT_ROOMS_COUNT"
          :key="count"
          class="border-none"
          pill
          variant="outline"
          :class="{'bg-blue-100': selectedOptions.includes(count)}"
          @click="toggleSelected(count)"
        >
          {{ t(`building.flatsInfo.flatRoomsCount.${count}`) }}
        </custom-button>
      </div>
      <div
        v-if="isFreePlanVisible"
        class="flex items-center gap-x-3 pl-[10.5px] "
      >
        <custom-checkbox
          v-model="selectedOptionsWithOpenPlan"
          disabled
        />
        <p class="text-neutral-200">
          {{ t('building.flatsInfo.flatRoomsCount.openPlan') }}
        </p>
      </div>
    </div>
  </custom-dropdown>

</template>
<script setup lang="ts">
import CustomDropdown from '~/ui/dropdowns/CustomDropdown.vue'
import { defaultFilter } from '~/modules/market-entities-actions/constants/filter/Filter.default.ts'
import CustomButton from '~/ui/buttons/CustomButton.vue'
import type { MarketEntitiesFilter } from '~/modules/market-entities-actions/types/filter/Filter'
import { ArrayHelper } from '~/common/helpers/arrays'
import { isEmpty } from '~/common/helpers/checkers'
import CustomCheckbox from '~/ui/checkbox/CustomCheckbox.vue'
import { FLAT_ROOMS_COUNT } from '~/common/constants/flat/Flat.roomsCount'
import FilterCounter from '~/components/filters/FilterCounter.vue'

type FlatRoomsCount = MarketEntitiesFilter['flatFilter']['flatRoomsCount'][number]

const props = defineProps({
  modelValue: {
    type: Array as PropType<Array<FlatRoomsCount>>,
    default: () => defaultFilter().flatFilter.flatRoomsCount,
  },
})

const { t } = useI18n()

const emits = defineEmits<{(e: 'update:modelValue', value: Array<FlatRoomsCount>): void }>()

const countSelectedOptions = computed(() => selectedOptions.value.length)

const selectedOptions = computed({
  get: () => props.modelValue,
  set: value => emits('update:modelValue', value),
})

const isFreePlanVisible = false

const selectedOptionsWithOpenPlan = computed({
  get: () => selectedOptions.value,
  set: newValue => {
    if (newValue) {
      toggleSelected('studio')
    } else {
      selectedOptions.value = selectedOptions.value.filter(option => option !== 'studio')
    }
  },
})

const toggleSelected = (option: FlatRoomsCount) => {
  const selectedOptionsCopy = [...selectedOptions.value]
  ArrayHelper.toggle(selectedOptionsCopy, option)
  selectedOptions.value = selectedOptionsCopy
}

</script>
