<template>
  <custom-tooltip placement="bottom">
    <template #content>
      {{ $t('building.actions.chessboard.label') }}
    </template>
    <custom-button-icon
      icon="entities/chessboard"
      icon-class="text-[1.43rem]"
      color="secondary"
      round
      @click="showAuthModalOrCallback(openChessboard)"
    />
  </custom-tooltip>
  <modal-or-bottom-sheet
    v-model="isChessboardOpen"
    :drawer-props="{fullHeight: true, contentClass: 'max-h-full pb-[60px]', bodyClass: '!overflow-hidden'}"
    :modal-props="{hideFooter: true, hideHeader: true, size: 'fullscreen', contentClass: '!bg-neutral-100'}"
  >
    <building-chessboard
      v-if="isChessboardOpen"
      v-model:filter="filter"
      :building="building"
    >
      <template #closeButton>
        <custom-button
          class="text-black bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg self-start"
          size="none"
          color="none"
          @click="closeChessboard"
        >
          <nuxt-icon
            name="actions/close"
            class="text-[2rem]"
          />
        </custom-button>
      </template>
    </building-chessboard>
  </modal-or-bottom-sheet>
</template>

<script setup lang="ts">
import ModalOrBottomSheet from '~/components/common/ModalOrBottomSheet.vue'
import CustomTooltip from '~/ui/tooltips/CustomTooltip.vue'
import { type Building } from '~/modules/building/types/Building'
import BuildingChessboard from '~/modules/building/components/building-chessboard/BuildingChessboard.vue'
import CustomButton from '~/ui/buttons/CustomButton.vue'
import CustomButtonIcon from '~/ui/buttons/CustomButtonIcon.vue'
import { useMarketEntitiesActionsStore } from '~/modules/market-entities-actions/MarketEntitiesActionsModule'
import { ObjectHelper } from '~/common/helpers/object'
import { BuildingFlatFilter } from '~/modules/building/components/page/flats/filter/types/BuildingFlatFilter'
import { showAuthModalOrCallback } from '~/modules/auth/helpers/showAuthModalOrCallback'
import {ROUTES_NAMES} from '~/router/constants/Router.routesNames'

const props = defineProps({
  building: {
    type: Object as PropType<Building>,
    required: true,
  },
})

const store = useMarketEntitiesActionsStore()

const isChessboardOpen = ref(false)

const router = useRouter()

const openChessboard = () => {
  isChessboardOpen.value = true
  if (router.currentRoute.value.name === ROUTES_NAMES.buildingPage) {
    router.push({query: {isChessboardOpen: 'true'}})
  }
}

const closeChessboard = () => {
  isChessboardOpen.value = false
  router.push({params: undefined, query: undefined})
}
const filter = computed<BuildingFlatFilter>({
  get: () => ({
    flatFilter: store.filter.flatFilter,
    houseFilter: {
      deadLine: store.filter.houseFilter.deadLine,
    },
  }),
  set: value => {
    const copiedFilter = ObjectHelper.copy(store.filter)
    copiedFilter.flatFilter = value.flatFilter
    copiedFilter.houseFilter.deadLine = value.houseFilter.deadLine

    store.setFilter(copiedFilter)
  },
})

onMounted(() => {
  if (router.currentRoute.value.name === ROUTES_NAMES.buildingPage && router.currentRoute.value.query.isChessboardOpen === 'true') {
    isChessboardOpen.value = true
  }
})

</script>
