import type { BuildingImage } from '~/modules/building/types/Building.image'
import type { Building } from '~/modules/building/types/Building'
import type { Flat } from '~/common/types/flat/Flat'
import { copyToClipboard } from '~/common/helpers/copy'
import { btoa } from '~/common/helpers/window'

export class BuildingActionsService {
  public static getGeneralPlanImageIndex(images: Array<BuildingImage>, isCheckDefaultImage = false): number {
    const generalPlanIndex = images.findIndex(image => image.generalPlan)
    if (isCheckDefaultImage) {
      const defaultImageIndex = images.findIndex(image => image.default)
      if (defaultImageIndex > -1 && generalPlanIndex > -1 && defaultImageIndex > generalPlanIndex) {
        return generalPlanIndex + 1
      }
      return generalPlanIndex
    }
    return generalPlanIndex
  }

  public static addToSuggestions(building: Building): void {
    console.log('addToSuggestions')
  }

  public static copyPresentationLink(buildingId: number, managerId: number, organisationId: number, successCopyText: string): void {
    const { estateMarketAppUrl } = useRuntimeConfig().public
    const linkToCopy = `${estateMarketAppUrl}/p/b/${btoa(`${buildingId}`)}/${btoa(`${managerId}`)}/${btoa(`${organisationId}`)}`

    copyToClipboard(linkToCopy, successCopyText)
  }

  public static displayOnTheMap(building: Building): void {
    console.log('displayOnTheMap')
  }

  public static book(building: Building): void {
    console.log('book')
  }

  public static fixateClient(building: Building): void {
    console.log('fixateClient')
  }

  public static displayBuildingProcess(building: Building): void {
    console.log('displayBuildingProcess')
  }

  public static displayAward(building: Building): void {
    console.log('displayAward')
  }

  public static addToFavorite(building: Building): void {
    console.log('addToFavorite')
  }

  public static addToComparison(building: Building): void {
    console.log('addToComparison')
  }

  public static getBuildingFlats(building: Pick<Building, 'houses'>) {
    const flats: (Flat & {houseName: string})[] = []
    building.houses.forEach(house => {
      const { address } = house
      house.sections.forEach(section => {
        flats.push(...section.flats.map(flat => ({ ...flat, section, address, houseName: house.name, building: { promotions: building.promotions } })))
      })
    })
    return flats
  }
}
