import { ApexOptions } from 'apexcharts'
import en from 'apexcharts/dist/locales/en.json'
import ka from 'apexcharts/dist/locales/ka.json'
import ru from 'apexcharts/dist/locales/ru.json'
import kk from '~/common/constants/charts/locales/kk.json'

export const APEX_DEFAULT_OPTIONS = {
  chart: {
    height: 350,
    type: 'area',
    locales: [en, ru, ka, kk],
    defaultLocale: 'en',
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: 'smooth',
  },
  fill: {
    type: 'solid',
    opacity: 0,
  },
  tooltip: {
    x: {
      format: 'dd/MM/yy',
    },
  },
  legend: {
    show: false,
  },
} as ApexOptions
