<template>
  <custom-select
    v-model="selectedOptions"
    :options="options"
    multiple
    :close-on-select="false"
    :reduce="(option: Option<FlatStatus>) => option.value"
    deselect-from-dropdown
    :is-show-values="false"
    :is-searchable="false"
    :button-class="{'!bg-blue-100': countSelectedOptions}"
    input-class="font-semibold !text-left"
    class="h-full"
  >
    <template #placeholder>
      <filter-counter v-if="countSelectedOptions">
        {{ countSelectedOptions }}
      </filter-counter>
      {{ $t('flat.status.label') }}
    </template>
  </custom-select>
</template>
<script setup lang="ts">
import CustomSelect from '~/ui/selects/CustomSelect.vue'
import type { FlatsFilter } from '~/modules/building/components/page/flats/filter/types/FlatsFilter'
import type { FlatStatus } from '~/common/types/flat/Flat.status'
import { FLAT_STATUS } from '~/common/constants/flat/Flat.status'
import { Option } from '~/ui/selects/types/Select.option'
import FilterCounter from '~/components/filters/FilterCounter.vue'

const props = defineProps({
  modelValue: {
    type: Array as PropType<FlatsFilter['status']>,
    required: true,
  },
})
const emits = defineEmits<{(e: 'update:modelValue', value: FlatsFilter['status']): void}>()

const { t } = useI18n()

const optionFromStatus = (status: FlatStatus) => {
  const labels = {
    [FLAT_STATUS.free]: t('flat.status.options.free'),
    [FLAT_STATUS.sold]: t('flat.status.options.sold'),
    [FLAT_STATUS.booked]: t('flat.status.options.booked'),
  } as Record<FlatStatus, string>
  return { value: status, label: labels[status] }
}

const options: Array<Option<FlatStatus>> = [
  FLAT_STATUS.free,
  FLAT_STATUS.sold,
  FLAT_STATUS.booked,
].map(optionFromStatus)

const selectedOptions = computed({
  get() {
    return (props.modelValue as FlatsFilter['status']).map(optionFromStatus)
  },
  set(statuses) {
    emits('update:modelValue', statuses)
  },
})
const countSelectedOptions = computed(() => selectedOptions.value.length)

</script>
