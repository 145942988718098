import { MapBuildingFeatureObject } from '~/common/types/map/ymaps.buildingFeatureObject'
import { FeatureDrawerEventsHandlers } from '~/common/types/map/yandex/Map.featureDrawerEventsHandlers'

export abstract class MapFeatureDrawerServiceAbstract {
  protected map: ymaps.Map
  protected objectManagerOptions: ymaps.IObjectManagerOptions
  protected eventsHandlers: FeatureDrawerEventsHandlers

  protected constructor(map: ymaps.Map, objectManagerOptions: ymaps.IObjectManagerOptions, eventsHandlers: FeatureDrawerEventsHandlers = {}) {
    this.map = map
    this.objectManagerOptions = objectManagerOptions
    this.eventsHandlers = eventsHandlers
  }

  protected abstract drawFeatures(featureCollection: ymaps.IFeatureCollection<MapBuildingFeatureObject>): void
  protected abstract createObjectManager(featureCollection: ymaps.IFeatureCollection<MapBuildingFeatureObject>): ymaps.ObjectManager
  protected abstract deleteObjectManager(): void
  protected abstract customizeObjectManager(): void
}
