import {HttpHandledResponse} from '~/services/http/types/Http.handledResponse'

function downloadFile(response: HttpHandledResponse<Blob>) {
  const a = document.createElement('a')

  a.setAttribute('style', 'display: none')
  document.body.appendChild(a)

  const url = window.URL.createObjectURL(response.data)

  a.href = url

  const desposition = response.headers.get('content-disposition')
  if (desposition) {
    [, a.download] = desposition.split('"')
  }
  a.click()
  a.remove()
  window.URL.revokeObjectURL(url)
}

export default downloadFile
