<template>

  <client-form-field-template
    :title="$t('clients.views.table.dateView')"
    :progress-step-percent="progressStepPercent"
    :progress-step-done="progressStepDone"
  >
    <labeled-wrapper
      label-class="font-medium text-lg inter"
      :label="$t('clients.views.table.dateView')"
    >
      <template #content>
        <custom-date-time-input v-model:date="date" />
      </template>
    </labeled-wrapper>
  </client-form-field-template>
</template>
<script setup lang="ts">
import CustomDateTimeInput from '~/ui/inputs/CustomDateTimeInput.vue'
import ClientFormFieldTemplate from '~/modules/clients/components/forms/ClientFormFieldTemplate.vue'
import LabeledWrapper from '~/components/common/LabeledWrapper/LabeledWrapper.vue'

const props = defineProps({
  progressStepPercent: {
    type: Number,
    default: 0,
  },
  progressStepDone: {
    type: Boolean,
    default: false,
  },
  date: {
    type: String,
    default: new Date(),
  },
})

const { t } = useI18n()

const { value: date, errorMessage: errorDate } = useField<Date>(
  'date',
  { required: true },
  { initialValue: props.date ? new Date(props.date) : new Date(), label: t('clients.views.table.dateView') },
)
</script>
