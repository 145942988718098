<template>
  <custom-dropdown
    :close-on-select="false"
    color="secondary"
    dropdown-class="min-w-[200px]"
    :button-class="[{'!bg-blue-100': countSelectedOptions}, 'w-full font-semibold']"
  >
    <template #triggerContent>
      <filter-counter v-if="countSelectedOptions">
        {{ countSelectedOptions }}
      </filter-counter>
      {{ $t('flat.decorationType.label') }}
    </template>
    <div class="flex flex-col">
      <custom-button
        v-for="option in options"
        :key="option.value"
        class="flex justify-start bg-white"
        :class="{'!bg-blue-100': selectedOptions.includes(option.value)}"
        @click="toggleSelected(option.value)"
      >
        {{ option.label }}
      </custom-button>
    </div>
  </custom-dropdown>
</template>
<script setup lang="ts">
import type { DecorationType } from '~/common/types/decoration/Decoration.type'
import { DECORATION_TYPE } from '~/common/constants/decoration/Decoration.type'
import FilterCounter from '~/components/filters/FilterCounter.vue'
import CustomDropdown from '~/ui/dropdowns/CustomDropdown.vue'
import CustomButton from '~/ui/buttons/CustomButton.vue'
import { ArrayHelper } from '~/common/helpers/arrays'

const { t } = useI18n()

const props = defineProps({
  modelValue: {
    type: Array as PropType<Array<DecorationType>>,
    required: true,
  },
})
const emits = defineEmits<{(e: 'update:modelValue', value: Array<DecorationType>): void }>()

const options = Object.values(DECORATION_TYPE).map(decorationType => ({
  value: decorationType,
  label: t(`flat.decorationType.options.${decorationType}.full`),
}))

const selectedOptions = computed({
  get() {
    return props.modelValue
  },
  set(decorationTypes) {
    emits('update:modelValue', decorationTypes)
  },
})

const toggleSelected = (option: DecorationType) => {
  const selectedOptionsCopy = [...selectedOptions.value]
  ArrayHelper.toggle(selectedOptionsCopy, option)
  selectedOptions.value = selectedOptionsCopy
}

const countSelectedOptions = computed(() => selectedOptions.value.length)
</script>
