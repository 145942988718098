import { defineStore } from 'pinia'
import type { Building } from '~/modules/building/types/Building'
import type { ChessboardFlat } from '~/modules/chessboard/types/Chessboard.flat'
import { FlatsDisplayingEntity } from '~/modules/building/components/page/flats/filter/types/FlatsDisplaying.entity'
import { FLATS_SORT } from '~/modules/building/constants/Flat.sort'
import { FLATS_DISPLAYING_ENTITY } from '~/modules/building/components/page/flats/filter/constants/FlatsDisplaying.entity'
import { FlatsSort } from '~/modules/building/types/Flat.sort'
import { BuildingPresentation } from '~/modules/building/types/Building.presentation'
import { SuggestionsFilter } from '~/modules/suggestions/types/Suggestions.filter'
import { ObjectHelper } from '~/common/helpers/object'
import { BuildingFlatFilter } from '~/modules/building/components/page/flats/filter/types/BuildingFlatFilter'
import {
  defaultBuildingFlatFilter,
} from '~/modules/building/components/page/flats/filter/constants/DefaultBuildingFlatFilter'
import { Client } from '~/modules/clients/types/Client'
import { QdStorage as IQdStorage, QdStorage } from '~/common/storage/interfaces/QdStorage'

type BuildingActionsState = {
  isDisplaySuggestionsModal: boolean,
  buildingsToAddToSuggestion: Array<Building>,
  clientToAddToSuggestion: Client | null,
  flatsToAddToSuggestion: Array<ChessboardFlat>,
  flatsDisplayingEntity: FlatsDisplayingEntity,
  filter: BuildingFlatFilter,
  flatsSortOption: FlatsSort,
  presentation: BuildingPresentation | null,
  suggestionsFilter: SuggestionsFilter
  lastErrorBuildingsTime: number | null
  lastErrorSuggestionsTime: number | null
  lastErrorClientsTime: number | null
}

const getInitialFilter = (): BuildingFlatFilter => {
  const storage: IQdStorage = useNuxtApp().$appStorage

  const filter = storage.getItem('buildingFlatFilter')
  const defaultFilter = defaultBuildingFlatFilter()

  if (filter) {
    const syncedFilter = ObjectHelper.syncObjects<BuildingFlatFilter>(filter, defaultFilter)
    storage.setItem('flatsFilter', syncedFilter)
    return syncedFilter
  }
  return defaultFilter
}
export const useBuildingStore = defineStore('buildingStore', {
  state: () => ({
    buildingsToAddToSuggestion: [],
    clientToAddToSuggestion: null,
    flatsToAddToSuggestion: [],
    isDisplaySuggestionsModal: false,
    flatsDisplayingEntity: FLATS_DISPLAYING_ENTITY.table,
    filter: getInitialFilter(),
    flatsSortOption: FLATS_SORT.descAgentMotivation,
    presentation: null,
    suggestionsFilter: null,
    lastErrorBuildingsTime: null,
    lastErrorSuggestionsTime: null,
    lastErrorClientsTime: null,
  }) as BuildingActionsState,

  actions: {
    clearBuildingsList() {
      this.buildingsToAddToSuggestion = []
    },
    clearFlatsList() {
      this.flatsToAddToSuggestion = []
    },
    setFlatsDisplayingEntity(value: FlatsDisplayingEntity) {
      this.flatsDisplayingEntity = value
    },
    setBuildingFlatFilter(value: BuildingFlatFilter) {
      this.filter = value
      const storage: QdStorage = useNuxtApp().$appStorage

      storage.setItem('buildingFlatFilter', value)
    },
    setFilterValueByKey(path: string, value: any): void {
      ObjectHelper.setNestedValue(this.filter, path, value)
    },
    setFlatsSortOption(value: FlatsSort) {
      this.flatsSortOption = value
    },
    setPresentation(value: BuildingPresentation) {
      this.presentation = value
    },
    resetFilter() {
      this.filter = defaultBuildingFlatFilter()
    },
    setSuggestionsFilter(value: SuggestionsFilter) {
      this.suggestionsFilter = value
      const storage: QdStorage = useNuxtApp().$appStorage

      storage.setItem('suggestionsFilter', value)
    },
  },
})
