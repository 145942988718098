import { HttpApiService } from '~/services/http/HttpApiService'
import { HttpHandledResponse } from '~/services/http/types/Http.handledResponse'
import { AuthHttpApi } from '~/modules/auth/interfaces/authHttpApi'
import { InviteRegisterData, RegisterData } from '~/modules/auth/types/auth'
import { Service } from '~/services/http/Service'

export class AuthHttpApiService extends Service implements AuthHttpApi {
  private endPoints = {
    firstMarketPart: '/v4/estate-market',
    firstPart: '/auth',
    login: '/login',
    loginByInvitation: '/login-by-invite',
    register: '/register',
    invitationRegister: '/invitation',
    logout: '/logout',
    recoverPassword: '/recover-password',
    resetPassword: '/reset-password',
    setUserOrganisation: '/set-user-organisation',
    createOrganisation: '/create-organisation',
    getOrganisationInfo: '/organisation-info-by-invitation-secret',
    getExchangeToken: '/get-exchange-token',
    updateTokens: '/update-tokens',
  }

  constructor(httpInstance: HttpApiService) {
    super(httpInstance)
  }

  login<LoginResponse>(username: string, password: string): Promise<HttpHandledResponse<LoginResponse>> {
    return this.httpInstance.post<LoginResponse>(`${this.endPoints.firstMarketPart}${this.endPoints.firstPart}${this.endPoints.login}`, { username, password })
  }

  loginByInvitation<LoginResponse>(username: string, password: string, invitationSecret: string): Promise<HttpHandledResponse<LoginResponse>> {
    return this.httpInstance.post<LoginResponse>(`${this.endPoints.firstMarketPart}${this.endPoints.firstPart}${this.endPoints.loginByInvitation}`, { username, password, invitationSecret })
  }

  createOrganisation<LoginResponse>(): Promise<HttpHandledResponse<LoginResponse>> {
    return this.httpInstance.post<LoginResponse>(`${this.endPoints.firstMarketPart}${this.endPoints.firstPart}${this.endPoints.createOrganisation}`)
  }

  setUserOrganisation<LoginResponse>(organisationId: number): Promise<HttpHandledResponse<LoginResponse>> {
    return this.httpInstance.post<LoginResponse>(`${this.endPoints.firstMarketPart}${this.endPoints.firstPart}${this.endPoints.setUserOrganisation}`, { organisationId })
  }

  register<LoginResponse>(registerData: RegisterData): Promise<HttpHandledResponse<LoginResponse>> {
    return this.httpInstance.post<LoginResponse>(`${this.endPoints.firstMarketPart}${this.endPoints.firstPart}${this.endPoints.register}`, registerData)
  }

  invitationRegister<LoginResponse>(registerData: InviteRegisterData): Promise<HttpHandledResponse<LoginResponse>> {
    return this.httpInstance.post<LoginResponse>(`${this.endPoints.firstMarketPart}${this.endPoints.firstPart}${this.endPoints.invitationRegister}`, registerData)
  }

  logout<LogoutResponse>(): Promise<HttpHandledResponse<LogoutResponse>> {
    return this.httpInstance.get<LogoutResponse>(`${this.endPoints.firstMarketPart}${this.endPoints.firstPart}${this.endPoints.logout}`)
  }

  recoverPassword<LoginResponse>(email: string): Promise<HttpHandledResponse<LoginResponse>> {
    return this.httpInstance.post<LoginResponse>(`${this.endPoints.firstMarketPart}${this.endPoints.firstPart}${this.endPoints.recoverPassword}`, { email })
  }

  resetPassword<LoginResponse>(email: string, password: string, resetToken: string): Promise<HttpHandledResponse<LoginResponse>> {
    return this.httpInstance.post<LoginResponse>(`${this.endPoints.firstPart}${this.endPoints.resetPassword}`, {
      email,
      password,
      resetToken,
    })
  }

  getOrganisationInfo<LoginResponse>(invitationSecret: string): Promise<HttpHandledResponse<LoginResponse>> {
    return this.httpInstance.post<LoginResponse>(`${this.endPoints.firstPart}${this.endPoints.getOrganisationInfo}`, { invitationSecret })
  }

  getExchangeToken(): Promise<HttpHandledResponse<string>> {
    return this.httpInstance.get<string>(`${this.endPoints.firstPart}${this.endPoints.getExchangeToken}`)
  }

  updateTokens<AuthTokenResponse>(refreshToken: string): Promise<HttpHandledResponse<AuthTokenResponse>> {
    return this.httpInstance.post<AuthTokenResponse>(`${this.endPoints.firstPart}${this.endPoints.updateTokens}`, { refreshToken })
  }
}
