<template>
  <custom-table class="w-full">
    <thead>
      <tr>
        <td>
          <span class="font-semibold">
            {{ $t('flat.statistics.price.table.date') }}
          </span>
        </td>
        <td class="text-right">
          <span class="font-semibold">
            {{ $t('flat.statistics.price.table.newBuildingPerSquareMeter') }}
          </span>
        </td>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(statistic, index) in statisticsForTable"
        :key="index"
      >
        <td class="!py-6">
          <span class="font-semibold text-neutral-500">
            {{ statistic.date }}
          </span>
        </td>
        <td class="!py-6 text-right">
          <span>
            {{ $t('common.from') }} {{ statistic.price }} {{ $t(`currency.options.${currency}`) }} <span
              :class="getPersentageAndColor(statistic.persentage).color"
            >
              {{ getPersentageAndColor(statistic.persentage).persentage }}
            </span>
          </span>
        </td>
      </tr>
    </tbody>
  </custom-table>
</template>

<script setup lang="ts">
import { CURRENCY } from '~/common/constants/money/Currency'
import { Currency } from '~/common/types/money/Currency'
import CustomTable from '~/ui/tables/CustomTable.vue'

defineProps({
  statisticsForTable: {
    type: Array as PropType<Array<{ date: string; price: number; persentage: number }>>,
    required: true,
  },
  currency: {
    type: String as PropType<Currency>,
    default: CURRENCY.usd,
  },
})

const getPersentageAndColor = (number: number) => {
  if (number > 0) {
    return {
      color: 'text-danger',
      persentage: `- ${number.toFixed(2)}%`,
    }
  }
  if (number < 0) {
    return {
      color: 'text-success',
      persentage: `+ ${Math.abs(number).toFixed(2)}%`,
    }
  }
  return {
    color: '',
    persentage: '',
  }
}
</script>
