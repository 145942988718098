<template>
  <custom-tooltip placement="bottom">
    <template #content>
      {{ $t('entityActions.filter.allFilters.modal') }}
    </template>
    <custom-button-icon
      icon="actions/additional-filters"
      icon-class="text-[1.65rem]"
      round
      @click="isModalVisible = true"
    />
  </custom-tooltip>
  <modal-or-bottom-sheet
    v-model="isModalVisible"
    :modal-props="{size: 'w-full max-w-[800px]', centered: false, withOutBodyOverflow: true}"
    :drawer-props="{bodyClass: 'min-h-[100vh]', footerClass: 'w-full bottom-0 sticky py-[22px] px-2  border-t border-neutral-35 bg-white gap-x-0'}"
    :title="t('entityActions.filter.allFilters.modal')"
  >
    <div class="flex flex-col gap-y-3 mt-3 md:mt-0 ">
      <div class="flex flex-col  gap-y-3">
        <labeled-wrapper
          :label="`${t('entityActions.filter.flatFilter.kitchenArea.label')} ${t('common.unitsOfMeasurement.options.squareMeter')}`"
          class="w-full md:w-1/3"
        >
          <template #content>
            <filter-from-to
              v-model:from="filter.flatFilter.kitchenArea.from"
              v-model:to="filter.flatFilter.kitchenArea.to"
              :placeholder-from="t('common.from')"
              :placeholder-to="t('common.to')"
              general-class="!p-0"
              is-general
            />
          </template>
        </labeled-wrapper>
        <labeled-wrapper
          :label="t(`flat.windowViewType.label`)"
          class="w-full"
        >
          <template #content>
            <flat-window-view-filter v-model="filter.flatFilter.windowsViewTypes" />
          </template>
        </labeled-wrapper>
      </div>
      <div class="flex gap-y-3 md:gap-x-6">
        <labeled-wrapper
          :label="t(`flat.floorNumber.label`)"
          class="w-full flex"
        >
          <template #content>
            <div class="flex flex-col md:flex-row items-start md:items-center gap-x-2">
              <div class="w-full md:w-1/3 mb-3 md:mb-0">
                <filter-from-to
                  v-model:from="filter.flatFilter.floorNumber.from"
                  v-model:to="filter.flatFilter.floorNumber.to"
                  :placeholder-from="t('common.from')"
                  :placeholder-to="t('common.to')"
                  general-class="!p-0"
                  is-general
                />
              </div>
              <flat-floor-filter
                v-model:notFirstFloor="filter.flatFilter.notFirstFloor"
                v-model:lastFloor="filter.flatFilter.lastFloor"
              />
            </div>
          </template>
        </labeled-wrapper>
      </div>
      <labeled-wrapper
        :label="$t(`flat.decorationType.label`)"
        class="w-full "
      >
        <template #content>
          <flat-decorations-filter v-model="filter.flatFilter.decorationTypes" />
        </template>
      </labeled-wrapper>
    </div>
    <template #footer>
      <div class="w-full">
        <slot name="footer" :close="close" />
      </div>
    </template>
  </modal-or-bottom-sheet>
</template>
<script setup lang="ts">
import ModalOrBottomSheet from '~/components/common/ModalOrBottomSheet.vue'
import FlatWindowViewFilter from '~/modules/market-entities-actions/components/filter/filters/FlatWindowViewFilter.vue'
import LabeledWrapper from '~/components/common/LabeledWrapper/LabeledWrapper.vue'
import FlatFloorFilter from '~/modules/market-entities-actions/components/filter/filters/FlatFloorFilter.vue'
import FlatDecorationsFilter
  from '~/modules/market-entities-actions/components/filter/filters/FlatDecorationsFilter.vue'
import FilterFromTo from '~/components/filters/from-to/FilterFromTo.vue'
import CustomButtonIcon from '~/ui/buttons/CustomButtonIcon.vue'
import CustomTooltip from '~/ui/tooltips/CustomTooltip.vue'
import { BuildingFlatFilter } from '~/modules/building/components/page/flats/filter/types/BuildingFlatFilter'

defineProps({
  filter: {
    type: Object as PropType<BuildingFlatFilter>,
    required: true,
  },
})

const { t } = useI18n()

const isModalVisible = ref(false)
const close = () => {
  isModalVisible.value = false
}

</script>
