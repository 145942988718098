<template>
  <div class="flex w-full justify-between">
    <div class="flex gap-2 flex-wrap">
      <div class="flex gap-x-2">
        <flats-btn-price-statistics
          v-if="!isLayout"
          :button-class="buttonClass"
          :tooltip-name="$t('building.actions.priceStatistics.label')"
          @click="isFlatPriceStatisticsModalVisible = true"
        />
        <flats-btn-add-to-suggestions
          :flat="flat"
          :with-default-click="withAddButtonDefaultClick"
          :button-class="buttonClass"
          @click="showAuthModalOrCallback(() => emits('add-to-suggestion'))"
        />
        <flats-btn-copy-link
          :button-class="buttonClass"
        />
      </div>
      <div class="flex gap-x-2">
        <flats-btn-book
          :button-class="buttonClass"
          @click="isClientBookingModalVisible = true"
        />
        <flats-btn-client-fixation
          :button-class="buttonClass"
          @click="isClientFixationModalVisible = true"
        />
        <flats-btn-client-view
          :button-class="buttonClass"
          :flat="flat"
          @click="isClientViewModalVisible = true"
        />
      </div>
    </div>
    <popularity-icon
      v-if="!appStateStore.clientMode"
      :suggestions-additions-count="flat?.suggestionsAdditionsCount"
      :total-suggestions-additions-count="flat.totalSuggestionsAdditionsCount"
    />
    <modal-or-bottom-sheet
      v-model="isClientBookingModalVisible"
      size="fullscreen"
    >
      <client-booking-form
        :flat="flat"
        @submit="isClientBookingModalVisible = false"
      />
    </modal-or-bottom-sheet>
    <modal-or-bottom-sheet
      v-model="isClientFixationModalVisible"
      size="fullscreen"
    >
      <client-fixation-form
        :initial-developer="developer || flat?.building?.developer"
        @submit="isClientFixationModalVisible = false"
      />
    </modal-or-bottom-sheet>
    <modal-or-bottom-sheet
      v-model="isClientViewModalVisible"
      size="fullscreen"
    >
      <client-view-form
        :flat="flat"
        @submit="isClientViewModalVisible = false"
      />
    </modal-or-bottom-sheet>
    <modal-or-bottom-sheet
      v-model="isFlatPriceStatisticsModalVisible"
      :modal-props="{size: 'w-full max-w-[800px]'}"
      :drawer-props="{hideHeader: false}"
    >
      <template #header>
        {{ t('building.actions.priceStatistics.label') }}
      </template>
      <flats-prices-statistics
        :flat="flat"
        :is-toggle-visible="false"
      />
    </modal-or-bottom-sheet>
  </div>
</template>

<script setup lang="ts">
import type { Flat } from '~/common/types/flat/Flat'
import FlatsBtnAddToSuggestions from '~/components/flats/buttons/basic/FlatsBtnAddToSuggestions.vue'
import FlatsBtnCopyLink from '~/components/flats/buttons/basic/FlatsBtnCopyLink.vue'
import FlatsBtnBook from '~/components/flats/buttons/basic/FlatsBtnBook.vue'
import FlatsBtnClientFixation from '~/components/flats/buttons/basic/FlatsBtnClientFixation.vue'
import FlatsBtnClientView from '~/components/flats/buttons/basic/FlatsBtnClientView.vue'
import { showAuthModalOrCallback } from '~/modules/auth/helpers/showAuthModalOrCallback'
import ClientBookingForm from '~/modules/clients/components/forms/clients-booking/ClientBookingForm.vue'
import ClientFixationForm from '~/modules/clients/components/forms/clients-fixation/ClientFixationForm.vue'
import ClientViewForm from '~/modules/clients/components/forms/client-view/ClientViewForm.vue'
import ModalOrBottomSheet from '~/components/common/ModalOrBottomSheet.vue'
import { Developer } from '~/common/types/developer/Developer'
import { useAppStateStore } from '~/store/app'
import PopularityIcon from '~/components/common/PopularityIcon.vue'

defineProps({
  flat: {
    type: Object as PropType<Flat>,
    default: null,
  },
  withAddButtonDefaultClick: {
    type: Boolean,
    default: true,
  },
  buttonClass: {
    type: [String, Object, Array],
    default: '',
  },
  developer: {
    type: Object as PropType<Developer>,
    default: null,
  },
  isLayout: {
    type: Boolean,
    default: false,
  },
})
const emits = defineEmits<{(e: 'add-to-suggestion'): void }>()

const { t } = useI18n()

const isClientBookingModalVisible = ref(false)

const isClientFixationModalVisible = ref(false)

const isClientViewModalVisible = ref(false)

const isFlatPriceStatisticsModalVisible = ref(false)

const appStateStore = useAppStateStore()
</script>
