<template>
  <Badge
    :size="size"
    :href="href"
    :class="[roundedClasses, sizeClasses]"
    :style="{backgroundColor: badgeColor}"
    class="w-auto inline-flex rounded-[6px] py-[5px] px-[10px] !text-black"
  >
    <template #prefix>
      <slot name="prefix" />
    </template>
    <slot />
    <template #icon>
      <slot name="icon" />
    </template>
  </Badge>
</template>
<script setup lang="ts">
import { Badge } from 'flowbite-vue'
import { COLOR } from '~/ui/constants/color'
import { SIZE } from '~/ui/constants/size'
import { colors } from '~/config/tailwind/colors/colors'

const props = defineProps({
  color: {
    type: String as PropType<keyof typeof COLOR>,
    default: COLOR.neutral,
  },
  pill: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String as PropType<'sm' | 'md'>,
    default: SIZE.sm,
  },
  href: {
    type: String,
    default: '',
  },
})

const badgeColor = computed(() => {
  switch (props.color) {
    case 'primary': {
      return colors.blue[100]
    }
    case 'success': {
      return colors.green[100]
    }
    case 'danger': {
      return colors.red[100]
    }
    default: {
      return colors.neutral[100]
    }
  }
})

const roundedClasses = computed(() => ({
  '!rounded-[12px]': props.pill,
}))

const sizeClasses = computed(() => ({
  'py-[10px] px-[15px]': props.size === SIZE.md,
}))
</script>
