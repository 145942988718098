<template>
  <custom-card class="flex flex-col max-h-full">
    <img
      class="rounded-[6px]"
      :src="building.images[0]?.link || buildingDefaultImage"
      :alt="$t('building.image.alt')"
    >
    <div class="flex justify-between items-start mt-5">
      <div>
        <div class="flex items-center max-lg:flex-wrap gap-x-4 text-primary">
          <nuxt-link
            class="cursor-pointer font-semibold text-2xl"
            :to="{ name: ROUTES_NAMES.buildingPage, params: { id: props.building.id }}"
            :target="appStateStore.isWeb ? '_blank': ''"
            @click="openBuildingOrDisplayAuthModal"
          >
            {{
              getEntityName({
                name: building.name,
                internationalName: building.internationalName,
                entityCountry: building.address?.countryIsoCode
              })
            }}
          </nuxt-link>
          <span
            v-if="building.houses"
            class="flex items-center font-semibold"
          >
            <nuxt-icon
              name="entities/houses"
              class="mr-1"
            />
            {{ $t('building.houses.label.short', building.houses.length) }}
          </span>
        </div>
        <p class="text-neutral-500">
          {{ building.address?.fullAddress }}
        </p>
      </div>
      <div class="shrink-0">
        <slot name="showMap" />
      </div>
    </div>
    <building-metro-info
      v-if="building.metro?.length"
      :metro-info="building.metro"
      class="mt-5"
    />
    <div
      v-if="building.advantages"
      class="mt-5 flex flex-wrap"
    >
      <div
        v-for="(advantage, i) of building.advantages"
        :key="i"
        class="w-1/2 p-2 flex items-start"
      >
        <client-only>
          <font-awesome-icon
            icon="fa-solid fa-check"
            class="text-primary mt-1 mr-2"
          />
        </client-only>
        <div>{{ advantage }}</div>
      </div>
    </div>
  </custom-card>
</template>

<script lang="ts" setup>
import BuildingMetroInfo from '~/modules/building/components/grid-item/metro/BuildingMetroInfo.vue'
import CustomCard from '~/ui/cards/CustomCard.vue'
import { ROUTES_NAMES } from '~/router/constants/Router.routesNames'
import { Building } from '~/modules/building/types/Building'
import { showAuthModalOrCallback } from '~/modules/auth/helpers/showAuthModalOrCallback'
import { getEntityName } from '~/common/helpers/getEntityName'
import buildingDefaultImage from '~/assets/images/building/building-default-image.png'
import { useAppStateStore } from '~/store/app'

const props = defineProps({
  building: {
    type: Object as PropType<Building>,
    required: true,
  },
})

const appStateStore = useAppStateStore()
const openBuildingOrDisplayAuthModal = (event: Event): void => {
  showAuthModalOrCallback(undefined, () => event.preventDefault())
}
</script>
