<template>
  <input
    v-model="checkedValue"
    type="checkbox"
    class="text-primary  border-[2px] border-neutral-150  focus:ring-offset-0 focus:ring-0 w-[24px] h-[24px] rounded-[5px] disabled:bg-neutral-150 cursor-pointer"
    :class="{'bg-white': !checkedValue}"
    :disabled="disabled"
  >
</template>
<script setup lang="ts">

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
})
const emits = defineEmits<{(e: 'update:modelValue', value: boolean): void}>()

const checkedValue = computed({
  get() {
    return props.modelValue
  },
  set(newValue: boolean) {
    return emits('update:modelValue', newValue)
  },
})
</script>
