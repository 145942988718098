import { CURRENCY } from '~/common/constants/money/Currency'
import type { FlatsFilter } from '~/modules/building/components/page/flats/filter/types/FlatsFilter'

export const defaultFlatsFilter = (): FlatsFilter => ({
  flatNumber: [],
  floorNumber: {
    from: null,
    to: null,
  },
  windowsViewTypes: [],
  lastFloor: null,
  kitchenArea: {
    from: null,
    to: null,
  },
  notFirstFloor: null,
  flatRoomsCount: [],
  totalArea: {
    from: null,
    to: null,
  },
  price: {
    from: null,
    to: null,
    currency: CURRENCY.rub,
  },
  decorationTypes: [],
  status: [],
})
