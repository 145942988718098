<script lang="ts" setup>
import { DateTimeHelper } from '~/common/helpers/date/dateTime'
import CustomDropdown from '~/ui/dropdowns/CustomDropdown.vue'
import CustomInput from '~/ui/inputs/CustomInput.vue'

const props = defineProps({
  modelValue: {
    type: String,
    default: '',
  },
  isTimeSelecting: {
    type: Boolean,
    default: null,
  },
  label: {
    type: String,
    default: null,
  },
  timeError: {
    type: String,
    default: null,
  },
})

const emits = defineEmits<{(e: 'update:modelValue', value: string): void,
  (e: 'update:isTimeSelecting', value: boolean): void
}>()

const time = computed({
  get: () => props.modelValue,
  set: value => {
    emits('update:modelValue', value)
  },
})

const isTimeSelecting = computed({
  get: () => props.isTimeSelecting,
  set: value => {
    emits('update:isTimeSelecting', value)
  },
})

const toggleSelecting = (toggleCb: () => void) => {
  if (typeof isTimeSelecting.value === 'boolean') {
    isTimeSelecting.value = !isTimeSelecting.value
  } else {
    toggleCb()
  }
}
</script>

<template>
  <custom-dropdown>
    <template #trigger="{toggle}">
      <div
        class="qm-time-input"
        @click="toggleSelecting(toggle)"
      >
        <custom-input
          v-model="time"
          :label="label"
          :state="timeError ? false : null"
          :invalid-feedback="timeError"
          type="time"
          @click="toggleSelecting(toggle)"
        />
      </div>
    </template>
    <ul
      v-if="typeof isTimeSelecting === 'boolean'"
      class="max-h-[200px] overflow-y-auto scrollbar-thin"
    >
      <li
        v-for="(value, index) in DateTimeHelper.getArrayTime()"
        :key="index"
        class="text-center p-[8px] rounded-[4px] cursor-pointer hover:bg-blue-50 scrollbar-red-100"
        :class="{'bg-primary-light text-primary': value === time}"
        @click="time = value"
      >
        {{ value }}
      </li>
    </ul>
  </custom-dropdown>
</template>

<style lang="scss">
.qm-time-input {
  input[type="time"]::-webkit-calendar-picker-indicator {
    background: none;
    display: none;
  }
}
</style>
