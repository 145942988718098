<template>
  <div class="flex items-center overflow-x-hidden">
    <client-only>
      <custom-button
        v-if="isMd && !hideControls && withControls && !$slots.controls"
        class="w-[38px] h-[38px] self-start"
        :class="controlsClass"
        size="none"
        pill
        @click="scrollLeft"
      >
        <font-awesome-icon icon="fa-solid fa-chevron-left" />
      </custom-button>
    </client-only>
    <div
      v-bind="$attrs"
      ref="scrollable"
      class="overflow-x-auto scrollbar-thin flex pb-[10px]"
    >
      <slot />
    </div>
    <client-only>
      <custom-button
        v-if="isMd && !hideControls && withControls && !$slots.controls"
        class="w-[38px] h-[38px] self-start"
        :class="controlsClass"
        size="none"
        pill
        @click="scrollRight"
      >
        <font-awesome-icon icon="fa-solid fa-chevron-right" />
      </custom-button>
    </client-only>
    <slot
      v-if="$slots.controls && withControls"
      name="controls"
      :scroll-right="scrollRight"
      :scroll-left="scrollLeft"
    />
  </div>
</template>
<script setup lang="ts">
import { useAppStateStore } from '~/store/app'
import CustomButton from '~/ui/buttons/CustomButton.vue'

// TODO: set scroll direction in props

defineProps({
  controlsClass: {
    type: [String, Object, Array],
    default: '',
  },
  hideControls: {
    type: Boolean,
    default: false,
  },
})
const emits = defineEmits<{(e: 'scrollRight'): void, (e: 'scrollLeft'): void}>()

const scrollable = ref<HTMLElement | null>(null)
const isMd = computed(() => useAppStateStore().breakpoints.isMd)
const withControls = ref(false)

const scrollRight = () => {
  const element = scrollable.value as HTMLElement
  element.scrollLeft += element.offsetWidth
}
const scrollLeft = () => {
  const element = scrollable.value as HTMLElement
  element.scrollLeft -= element.offsetWidth
}

onMounted(() => {
  const element = scrollable.value as HTMLElement
  withControls.value = element.scrollWidth > element.offsetWidth
})
</script>
