type ButtonCreatingArgs = {
  image?: string
  content?: string
  layout: string
  float?: 'right' | 'left'
  floatIndex?: number
  selectOnClick?: boolean
  onClick?: () => void
  onMouseEnter?: () => void
}

export class ButtonService {
  public static createButton({ image, content, layout, float, floatIndex, onClick, onMouseEnter, selectOnClick }: ButtonCreatingArgs): ymaps.control.Button {
    const button = new ymaps.control.Button({
      data: {
        image,
        content,
      },
      options: {
        layout: ymaps.templateLayoutFactory.createClass(layout),
        maxWidth: 200,
        selectOnClick: selectOnClick || false,
        floatIndex,
        float: float || 'left',
      },
    })

    onClick && button.events.add('click', onClick)
    onMouseEnter && button.events.add('mouseenter', onMouseEnter)

    return button
  }
}
