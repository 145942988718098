<template>
  <custom-tooltip placement="bottom">
    <template #content>
      {{ $t('building.actions.map.label') }}
    </template>
    <custom-button-icon
      class="font-semibold flex items-center"
      icon="common/map"
      v-bind="{...$attrs}"
      color="transparent"
      round
      @click="isModalOpen = true"
    />
  </custom-tooltip>
  <building-list-map-modal
    v-if="isModalOpen"
    v-model="isModalOpen"
    :buildings="buildingForMap"
    without-flats-count
    with-close-on-history-back
  />
</template>

<script setup lang="ts">
import { Building } from '~/modules/building/BuildingModule'
import BuildingListMapModal from '~/modules/building/components/map/BuildingListMapModal.vue'
import CustomButtonIcon from '~/ui/buttons/CustomButtonIcon.vue'
import CustomTooltip from '~/ui/tooltips/CustomTooltip.vue'

const props = defineProps({
  building: {
    type: Object as PropType<Building>,
    required: true,
  },
})

const isModalOpen = ref(false)
const buildingForMap = computed(() => [props.building])
</script>
