<script lang="ts" setup>
import CustomButton from '~/ui/buttons/CustomButton.vue'
import BankMortgageScheduleInfoBlock
  from '~/modules/bank/components/mortgage/schedule/BankMortgageScheduleInfoBlock.vue'
import BankMortgageScheduleTable from '~/modules/bank/components/mortgage/schedule/BankMortgageScheduleTable.vue'
import {BankService} from '~/modules/bank/services/BankService'
import {MortgageForRequest} from '~/modules/bank/types/Mortgage.forRequest'
import {MortgageSchedule} from '~/modules/bank/types/Mortgage.schedule'
import {useAppStateStore} from '~/store/app'
import CustomCubeSpinner from '~/ui/spinners/CustomCubeSpinner.vue'
import {Mortgage} from '~/modules/bank/types/Mortgage'
import {COLOR} from '~/ui/constants/color'
import {BankGuestService} from '~/modules/bank/services/BankGuestService'

const props = defineProps({
  bankService: {
    type: Object as PropType<BankService | BankGuestService>,
    default: null,
  },
  realtyPrice: {
    type: Number,
    default: 0,
  },
  mortgageSum: {
    type: Number,
    default: 0,
  },
  mortgageForRequest: {
    type: Object as PropType<MortgageForRequest | Mortgage>,
    required: true,
  },
})

const {t} = useI18n()
const bankService = props.bankService ?? new BankService()
const appStateStore = useAppStateStore()

const schedules = ref<Array<MortgageSchedule>>([])
const isFetching = ref(false)
const isDownloading = ref(false)

const currency = computed(() => appStateStore.currency)

const overpayment = computed(() => (schedules.value[0]?.total || 0) * (props.mortgageForRequest.termInMonths as number) - props.mortgageSum)

const downloadSchedule = () => {
  isDownloading.value = true

  bankService.downloadSchedule({
    sum: {
      amount: props.mortgageSum,
      currency: currency.value,
    },
    termInMonths: props.mortgageForRequest.termInMonths,
    stake: props.mortgageForRequest.stake,
  })
    .catch(() => {
      toast(t('toast.error'), {variant: COLOR.danger})
    })
    .finally(() => {
      isDownloading.value = false
    })
}

onMounted(() => {
  bankService.getPaymentSchedule({
    sum: {
      amount: props.mortgageSum,
      currency: currency.value,
    },
    termInMonths: props.mortgageForRequest.termInMonths,
    stake: props.mortgageForRequest.stake,
  })
    .then(res => {
      schedules.value = res
    })
})
</script>

<template>
  <div class="flex flex-col gap-y-6">
    <div
      v-if="isFetching"
      class="flex justify-center items-center w-full"
    >
      <custom-cube-spinner/>
    </div>
    <template v-else-if="schedules.length">
      <div class="flex flex-col md:flex-row gap-1">
        <bank-mortgage-schedule-info-block
          class="flex-1"
          :title="$t('mortgage.schedule.price')"
          :text="`${$n(realtyPrice)} ${$t(`currency.options.${currency}`)}`"
        />
        <bank-mortgage-schedule-info-block
          class="flex-1"
          :title="$t('mortgage.schedule.creditAmount')"
          :text="`${$n(mortgageSum)} ${$t(`currency.options.${currency}`)}`"
          :caption="`${Math.round(mortgageSum / realtyPrice * 100)}%`"
        />
        <bank-mortgage-schedule-info-block
          class="flex-1"
          :title="$t('mortgage.schedule.term')"
          :text="`${(mortgageForRequest.termInMonths as number) / 12} ${$t('mortgage.calculator.inputs.term.suffix', (mortgageForRequest.termInMonths as number) / 12)}`"
          :caption="`${mortgageForRequest.stake}% ${$t('mortgage.calculator.inputs.stake.suffix')}`"
        />
        <bank-mortgage-schedule-info-block
          class="flex-1"
          :title="$t('mortgage.schedule.overpayment')"
          :text="`${$n(overpayment)} ${$t(`currency.options.${currency}`)}`"
          :caption="`${Math.round(overpayment / mortgageSum * 100)}%`"
        />
      </div>
      <custom-button
        class="font-semibold flex items-center self-center"
        variant="withoutBorders"
        :disabled="isDownloading"
        @click="downloadSchedule"
      >
        <nuxt-icon name="actions/upload" class="text-[22px]"/>
        <span class="ml-2">{{ $t('common.download') }}</span>
      </custom-button>
      <bank-mortgage-schedule-table :schedules="schedules"/>
    </template>
  </div>
</template>
