<template>
  <flat-card-template
    class="cursor-pointer"
    :images="layout.images"
    :address="layout.address"
    price-wrapper-class="flex-col"
    :price="layout.price"
    :price-per-square-meter="layout.pricePerSquareMeter"
    @click="isModalOpen=true"
  >
    <template #status>
      <flat-status
        :status="layout.flats[0].status"
      >
        {{ $t('entityActions.search.flat', {count: layout.flats.length}) }}
      </flat-status>
    </template>
    <template #title>
      <h4 class="font-semibold">
        {{ layout.title }}
      </h4>
    </template>
    <template
      v-if="layout.totalArea"
      #totalArea
    >
      <div>
        <span class="text-neutral-500 font-semibold">
          {{ $t('flat.totalArea.label') }}
        </span>
        <span class="block font-semibold text-neutral-900 text-sm">
          {{ layout.totalArea }}
        </span>
      </div>
    </template>
    <template
      v-if="layout.kitchenArea"
      #kitchenArea
    >
      <div>
        <span class="text-neutral-500 font-semibold">
          {{ $t('flat.totalArea.label') }}
        </span>
        <span class="block font-semibold text-neutral-900 text-sm">
          {{ layout.kitchenArea }}
        </span>
      </div>
    </template>
    <template
      #decoration
    >
      <flat-decoration>
        <span class="font-semibold">
          {{ $t('flat.decorationType.label') }}
        </span>
        <template #modalHeader>
          <div class="flex items-center ">
            <h3 class="flex pr-4 text-neutral-900 text-2xl">
              {{ $t('flat.decorationType.label') }}
            </h3>
            <div
              v-if="layout.decorations?.length"
              class="flex items-end gap-x-5 ml-2 pl-4 border-l-neutral-35 border-l"
            >
              <h4 class="text-neutral-500 text-xl">
                {{ $t(`flat.decorationType.options.${selectedDecoration?.decorationType}.full`) }}
              </h4>
            </div>
          </div></template>
        <template #modal>
          <div class="flex flex-col lg:flex-row h-full gap-6 overflow-y-auto scrollbar-thin">
            <div class="basis-3/4">
              <custom-carousel
                :images="selectedDecoration?.images ?? []"
                max-height="50vh"
                min-height="50vh"
                :default-image="defaultImage"
                :is-open-modal-on-click="false"
              />
            </div>
            <template v-if="layout.decorations">
              <div class="flex flex-col gap-y-4 basis-1/4">
                <h3 class="text-neutral-600 text-xl">
                  {{ $t('flat.decorationType.options.label') }}
                </h3>
                <div class="h-full overflow-y-auto scrollbar-thin">
                  <flat-decoration-list-item
                    v-for="(decoration, id) in layout.decorations"
                    :key="id"
                    v-model:selected-decoration="selectedDecoration"
                    :decoration="decoration"
                    :is-first="id === 0"
                    :is-last="id === layout.decorations.length - 1"
                    :country-code="building.address.countryIsoCode"
                  />
                </div>
              </div>
            </template>
          </div>
        </template>
      </flat-decoration>
    </template>
    <template
      v-if="!appStateStore.clientMode"
      #agentMotivation
    >
      <building-carousel-btn-motivation
        :building="building"
        :layout="layout"
        :entity="AGENT_MOTIVATION_ENTITY.layout"
      />
    </template>
    <template #statisticAction>
      <flats-btn-price-statistics
        :tooltip-name="$t('building.actions.priceStatistics.label')"
        @click="isFlatPriceStatisticsModalVisible = true"
      />
    </template>
    <template #actions>
      <slot
        name="actions"
        :open-modal="openModal"
      />
    </template>
  </flat-card-template>
  <modal-or-bottom-sheet
    v-model="isFlatPriceStatisticsModalVisible"
    :modal-props="{size: 'w-full max-w-[800px]'}"
    :drawer-props="{hideHeader: false}"
  >
    <template #header>
      {{ $t('building.actions.priceStatistics.label') }}
    </template>
    <flats-prices-statistics
      :layout="layout"
      :is-toggle-visible="false"
    />
  </modal-or-bottom-sheet>
  <modal-or-bottom-sheet
    v-model="isModalOpen"
    :modal-props="{size: 'xxl', hideFooter: true}"
    :drawer-props="{fullHeight: true}"
  >
    <template
      v-if="isMd"
      #header
    >
      <div class="flex flex-col md:flex-row text-xl md:text-3xl md:font-bold font-medium">
        <div class="flex gap-x-10 pr-10">
          <span class="text-neutral-900 block">{{
            $t(`building.flatsInfo.flatRoomsCountBuildingCard.${layout.flats[0].flatRoomsCount || 'notIndicated.label'}`)
          }}</span>
          <span class="text-secondary">{{ $t('entityActions.search.flat', {count: layout.flats.length}) }}</span>
        </div>
        <div class="flex gap-x-10">
          <span
            class="block text-secondary"
          >
            {{ $t('common.from') }} {{ layout.priceMin }}
          </span>
          <span class="block text-secondary">
            {{ $t('building.flatsInfo.totalArea.from', {value: layout.totalAreaMin}) }}
          </span>
        </div>
      </div>
    </template>
    <template v-if="!isMd">
      <div class="flex flex-row text-xl font-medium mb-5">
        <div class="flex gap-x-10 flex-wrap">
          <span class="text-neutral-900 block">{{
            $t(`building.flatsInfo.flatRoomsCountBuildingCard.${layout.flats[0].flatRoomsCount || 'notIndicated.label'}`)
          }}</span>
          <span class="text-secondary">{{ $t('entityActions.search.flat', {count: layout.flats.length}) }}</span>
          <span
            class="block text-secondary"
          >
            {{ $t('common.from') }} {{ layout.priceMin }}
          </span>
          <span class="block text-secondary">
            {{ $t('building.flatsInfo.totalArea.from', {value: layout.totalAreaMin}) }}
          </span>
        </div>
      </div>
    </template>
    <div class="flex flex-col h-full">
      <div class="flex gap-x-4 justify-between mb-4">
        <flats-btns-display-table-grid-or-layouts
          v-model="flatsDisplayingEntity"
          :with-layouts="false"
        />
        <flats-sort
          v-model:sort-option="flatsSortOption"
        />
      </div>
      <component
        :is="flatsListOrTable"
        :flats="buildingPageFlatsCombine.sortFlatsAndReturn(layout.flats, flatsSortOption)"
        :mini="mini"
        :display-type="displayType"
        class="max-h-full overflow-y-auto scrollbar-thin"
        wrapper-class="md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
      />
    </div>
  </modal-or-bottom-sheet>
</template>

<script setup lang="ts">
import FlatStatus from '~/components/flats/flat-list-card/FlatStatus.vue'
import FlatDecoration from '~/components/flats/flat-list-card/FlatDecoration.vue'
import FlatCardTemplate from '~/components/flats/templates/FlatCardTemplate.vue'
import ModalOrBottomSheet from '~/components/common/ModalOrBottomSheet.vue'
import FlatsBtnsDisplayTableGridOrLayouts from '~/components/flats/buttons/FlatsBtnsDisplayTableGridOrLayouts.vue'
import CustomCarousel from '~/ui/carousels/CustomCarousel.vue'
import FlatsSort from '~/modules/building/components/page/flats/sort/FlatsSort.vue'
import FlatsList from '~/components/flats/FlatsList.vue'
import FlatsTableView from '~/components/flats/table/FlatsTableView.vue'
import defaultImage from '~/assets/images/building/decoration-default-image.svg'
import FlatDecorationListItem from '~/components/flats/decoration/FlatDecorationListItem.vue'
import BuildingCarouselBtnMotivation from '~/modules/building/components/carousel/BuildingCarouselBtnMotivation.vue'
import { FlatsDisplayingEntity } from '~/modules/building/components/page/flats/filter/types/FlatsDisplaying.entity'
import { FLATS_DISPLAYING_ENTITY } from '~/modules/building/components/page/flats/filter/constants/FlatsDisplaying.entity'
import { useAppStateStore } from '~/store/app'
import { FlatsSort as FlatsSortType } from '~/modules/building/types/Flat.sort'
import { FLATS_SORT } from '~/modules/building/constants/Flat.sort'
import { BuildingPageFlatsCombine } from '~/modules/building/services/BuildingPageFlatsCombine'
import { FlatLayoutItem } from '~/common/types/flat/Flat.layoutItem'
import { Decoration } from '~/common/types/decoration/Decoration'
import { Building } from '~/modules/building/types/Building'
import { AGENT_MOTIVATION_ENTITY } from '~/modules/building/constants/AgentMotivation.entity'
import DISPLAY_TYPE from '~/common/constants/flat/Flat.displayType'

const props = defineProps({
  layout: {
    type: Object as PropType<FlatLayoutItem>,
    required: true,
  },
  building: {
    type: Object as PropType<Building>,
    required: true,
  },
  displayType: {
    type: String as PropType<DISPLAY_TYPE>,
    default: DISPLAY_TYPE.basic,
  },
})

const appStateStore = useAppStateStore()

const buildingPageFlatsCombine = new BuildingPageFlatsCombine()
const selectedDecoration = ref<Decoration | undefined>(props.layout.decorations?.[0])
const flatsSortOption = ref<FlatsSortType>(FLATS_SORT.descAgentMotivation)
const isModalOpen = ref(false)
const isFlatPriceStatisticsModalVisible = ref(false)

const flatsDisplayingEntity = ref<FlatsDisplayingEntity>(FLATS_DISPLAYING_ENTITY.table)
const isMd = computed(() => appStateStore.breakpoints.isMd)
const mini = computed(() => {
  if (isMd.value) {
    return null
  }
  return flatsDisplayingEntity.value === FLATS_DISPLAYING_ENTITY.table
})
const flatsListOrTable = computed(() => {
  if (isMd.value) {
    switch (flatsDisplayingEntity.value) {
      case FLATS_DISPLAYING_ENTITY.grid:
        return FlatsList
      case FLATS_DISPLAYING_ENTITY.table:
        return FlatsTableView
      default:
        return FlatsList
    }
  }
  return FlatsList
})

const openModal = () => {
  isModalOpen.value = true
}
</script>
