<template>
  <slot
    name="houseHeader"
  >
    <flats-house-header
      :order="order"
      :house="house"
      :building="building"
    />
  </slot>
  <template
    v-if="houseFlatsInfoWithoutEmpty.length"
  >
    <div
      v-for="(flatsInfoByRoom, id) in houseFlatsInfoWithoutEmpty"
      :key="id"
      class="mt-2 first-of-type:mt-0"
    >
      <flats-house-room-count-accordion
        :building="building"
        :display-type="displayType"
        :flats-info="flatsInfoByRoom"
        :house="house"
        is-layout
      >
        <template #showAvailable>
          <slot name="showAvailable" />
        </template>
      </flats-house-room-count-accordion>
    </div>
  </template>
  <div
    v-else
    class="flex justify-center items-center px-4 py-3 w-full bg-neutral-100 rounded-md"
  >
    <h4 class="font-semibold text-base text-neutral-500">
      {{ $t('entityActions.search.noFlat') }}
    </h4>
  </div>
</template>

<script setup lang="ts">
import FlatsHouseHeader from '~/modules/building/components/page/flats/house/FlatsHouseHeader.vue'
import type { House } from '~/common/types/house/House'
import DISPLAY_TYPE from '~/common/constants/flat/Flat.displayType'
import { Building } from '~/modules/building/types/Building'
import FlatsHouseRoomCountAccordion from '~/modules/building/components/page/flats/house/FlatsHouseRoomCountAccordion.vue'
import { HouseFlatsInfo } from '~/modules/house/types/HouseFlatsInfo'

const props = defineProps({
  house: {
    type: Object as PropType<House>,
    required: true,
  },
  order: {
    type: Number,
    required: true,
  },
  houseFlatsInfo: {
    type: Array as PropType<Array<HouseFlatsInfo>>,
    required: true,
  },
  displayType: {
    type: String as PropType<DISPLAY_TYPE>,
    default: DISPLAY_TYPE.basic,
  },
  building: {
    type: Object as PropType<Building>,
    required: true,
  },
})

const houseFlatsInfoWithoutEmpty = computed(() => props.houseFlatsInfo.filter(houseFlatsInfo => houseFlatsInfo.count))
</script>

<style lang="scss">
.flat-building-icon {
  svg {
    width: 1.29rem;
    height: 1.14rem;
  }
}

.accordion-header-grid {
  grid-template-columns: 1fr 3fr 3fr 3fr;

  @media (min-width: 1280px) {
    grid-template-columns: 1fr 2fr 3fr 3fr;
  }
}
</style>
