<template>
  <div
    class="flex nowrap justify-between"
  >
    <div
      ref="container"
      class="flex flex-wrap"
      :class="isShowingMore ? 'gap-y-2' : `max-h-[35px] gap-y-2 overflow-y-hidden`"
    >
      <custom-badge
        v-for="(selected, index) of selectedFilters"
        :key="index"
        class="selected-filters__selected font-semibold"
        pill
      >
        {{ selected.label }}
        <nuxt-icon
          name="actions/close"
          class="ml-2 cursor-pointer text-lg"
          @click="resetFilterValue(selected.path, selected.value)"
        />
      </custom-badge>
    </div>
    <div>
      <custom-button
        v-if="isDisplayButton"
        variant="outline"
        size="xs"
        class="whitespace-nowrap ml-auto"
        @click="isShowingMore = !isShowingMore"
      >
        {{ isShowingMore ? $t('common.hide') : $t('entityActions.filter.selectedFilters.show') }}
        <client-only>
          <font-awesome-icon
            :icon="['fas', showAllFilters ? 'chevron-up' : 'chevron-down']"
            size="xs"
          />
        </client-only>
      </custom-button>
    </div>
  </div>
</template>
<script setup lang="ts">
import CustomBadge from '~/ui/badges/CustomBadge.vue'
import CustomButton from '~/ui/buttons/CustomButton.vue'
import { FilterService } from '~/modules/market-entities-actions/services/FilterService'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

const props = defineProps({
  selectedFilters: {
    type: Object as PropType<Array<SelectedFilter>>,
    required: true,
  },
})

type SelectedFilter = {path: string, value?: any, label: string}

const service = new FilterService()

const emits = defineEmits<{(e: 'deselect', path: string, valueIndex?: number): void}>()
const resetFilterValue = (path: string, valueIndex?: number) => {
  emits('deselect', path, valueIndex)
}

const selectedFilters = computed(() => (props.selectedFilters as Array<SelectedFilter>))

const container = ref<HTMLDivElement | null>(null)
const isShowingMore = ref(false)
const isDisplayButton = ref(false)
const hiddenOptionsCount = ref(0)

const getHiddenLabelsCount = () => {
  if (!container.value) { return 0 }
  const element = container.value
  const labels = [...element.querySelectorAll('.selected-filters__selected')]

  const firstHiddenIndex = labels.findIndex((label: HTMLElement) => label.getBoundingClientRect().bottom > element.getBoundingClientRect().bottom)

  if (firstHiddenIndex === -1) { return 0 }

  return labels.length - firstHiddenIndex
}

const findHiddenOptionsCount = () => {
  const count = getHiddenLabelsCount()
  hiddenOptionsCount.value = count

  if (count === 0) {
    isDisplayButton.value = false
    isShowingMore.value = false
  } else {
    isDisplayButton.value = true
  }
}

watch(() => selectedFilters.value, () => {
  nextTick().then(findHiddenOptionsCount)
}, { deep: true })

watch(() => isShowingMore.value, value => {
  if (!value) {
    (container.value as HTMLDivElement).scrollTop = 0
  }
})
onMounted(findHiddenOptionsCount)

</script>
