<template>
  <custom-button
    class="leading-none flex items-center justify-center w-[38px] h-[38px]"
    :disabled="disabled"
    :type="type"
    :with-default-message="withDefaultMessage"
    :variant="variant"
    :color="color"
    :pill="round"
    size="none"
    @click="emits('click')"
  >
    <nuxt-icon
      v-if="icon"
      :name="icon"
      :class="[iconClass ? iconClass : 'text-[1.29rem]', 'stroke-0']"
      :filled="filled"
    />
    <slot />
  </custom-button>
</template>

<script setup lang="ts">
import { Variant } from '~/ui/types/types'
import { VARIANT } from '~/ui/constants/variant'
import { COLOR } from '~/ui/constants/color'
import CustomButton from '~/ui/buttons/CustomButton.vue'

defineProps({
  icon: {
    type: String,
    default: null,
  },
  type: {
    type: String as PropType<'button' | 'submit' | 'reset'>,
    default: 'button',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  variant: {
    type: String as PropType<Variant>,
    default: VARIANT.default,
  },
  color: {
    type: String as PropType<keyof typeof COLOR>,
    default: COLOR.primary,
  },
  withDefaultMessage: {
    type: Boolean,
    default: false,
  },
  iconClass: {
    type: [String, Object, Array],
    default: '',
  },
  filled: {
    type: Boolean,
    default: false,
  },
  round: {
    type: Boolean,
    default: false,
  },
})

const emits = defineEmits(['click'])
</script>

<style lang="scss">
.nuxt-icon {
  svg {
    margin-bottom: 0;
  }
}
</style>
