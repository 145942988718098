import { AvailableCountry } from '~/common/types/country/Country.available'
import { AvailableLanguages } from '~/modules/app-language/types/language'
import { AVAILABLE_COUNTRY } from '~/common/constants/country/Country'
import { AVAILABLE_LANGUAGES } from '~/modules/app-language/LanguageModule'

export const getAvailableLanguageByCountryName = (countryName: AvailableCountry): AvailableLanguages | undefined => {
  if (!countryName) {
    return undefined
  }
  const availableLanguages: Partial<Record<AvailableCountry, AvailableLanguages>> = {
    [AVAILABLE_COUNTRY.russia]: AVAILABLE_LANGUAGES.RUSSIAN,
    [AVAILABLE_COUNTRY.georgia]: AVAILABLE_LANGUAGES.GEORGIAN,
    [AVAILABLE_COUNTRY.kazakhstan]: AVAILABLE_LANGUAGES.KAZAKH,
  }

  return availableLanguages[countryName]
}
