<template>
  <div
    class="flex items-center h-[89px] mt-3 overflow-hidden cursor-pointer"
    @click="emit('update:selectedQuarterId',quarter.id)"
  >
    <img
      :src="quarter.images?.[0]?.link || defaultImage"
      :alt="t('`flat.decorationType.label')"
      class="w-1/2 h-full object-cover"
    >
    <div
      class="flex flex-col  justify-center w-1/2 h-full pl-3 border-2 border-l-0 border-primary-light"
      :class="{'bg-primary-light': quarter.id === selectedQuarterId, 'rounded-t-md': isFirst, 'rounded-b-md': isLast}"
    >
      <div
        class="font-semibold"
        :class="{'text-primary': quarter.id === selectedQuarterId}"
      >
        {{ t('building.constructionProgress.quarter.label', {value: QUARTER[quarter.quarterName]} ) }}
      </div>
      <div
        v-if="quarter.name"
        class="text-neutral-500 text-[12px]"
      >
        {{ quarter.name }}
      </div>
      <div
        v-if="quarter.videoUrl"
        class="text-neutral-500 text-[12px]"
      >
        {{ t('building.constructionProgress.video.label.full', 1) }}
      </div>
      <div class="text-neutral-500 text-[12px]">
        {{ t('building.constructionProgress.photos.label.full', quarter.images.length) }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import defaultImage from 'assets/images/building/decoration-default-image.svg'
import { QUARTER } from '~/common/constants/date/Quarter'
import { ConstructionProgressQuarter } from '~/common/types/construction-progress/ConstructionProgressQuarter'
import { Quarter } from '~/common/types/date/Quarter'

defineProps({
  quarter: {
    type: Object as PropType<ConstructionProgressQuarter & { name: string, quarterName: Quarter }>,
    required: true,
  },
  selectedQuarterId: {
    type: Number,
    required: true,
  },
  isFirst: {
    type: Boolean,
    required: true,
  },
  isLast: {
    type: Boolean,
    required: true,
  },
})
const { t } = useI18n()
const emit = defineEmits(['update:selectedQuarterId'])

</script>
