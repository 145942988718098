<template>
  <custom-button
    class="font-semibold text-sm !rounded-[19px] flex"
    color="transparent"
    @click="emits('set-index')"
  >
    <nuxt-icon
      name="entities/master-plan"
      class="text-base stroke-0"
    />
    <span class="ml-1">{{ $t('building.actions.generalPlan.label') }}</span>
  </custom-button>
</template>
<script setup lang="ts">
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import CustomButton from '~/ui/buttons/CustomButton.vue'

const emits = defineEmits<{(e: 'set-index'): void}>()

</script>
