import type { DefaultColors } from 'tailwindcss/types/generated/colors'

export const colors: Record<Extract<keyof DefaultColors, 'neutral' | 'blue' | 'green' | 'yellow' | 'red'>, any> = {
  neutral: {
    100: '#F6F6F6',
    150: '#E3E3E3',
    200: '#CCCCCC',
    500: '#848484',
    750: '#4F4F4F',
    900: '#000000',
  },
  red: {
    100: '#FFF1F1',
    900: '#FF8B7D',
  },
  yellow: {
    100: '#FFFCF1',
    900: '#F4C31D',
  },
  green: {
    100: '#E8FFE8',
    900: '#4EC74D',
  },
  blue: {
    100: '#EFF3FF',
    900: '#6482E6',
    950: '#002A6B',
  },
}
