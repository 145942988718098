export const MARKET_ENTITIES_SORT = {
  ascPrice: 'price',
  descPrice: '-price',
  ascDeadLine: 'deadLine',
  descDeadLine: '-deadLine',
  ascPopularity: 'popularity',
  descPopularity: '-popularity',
  ascAgentMotivation: 'agentMotivation',
  descAgentMotivation: '-agentMotivation',
} as const
