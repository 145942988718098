import { useAppStateStore } from '~/store/app'
import { CURRENCY } from '~/common/constants/money/Currency'
import { Currency } from '~/common/types/money/Currency'
import { QdStorage as IQdStorage } from '~/common/storage/interfaces/QdStorage'
import { useAddressStore } from '~/modules/address/AddressModule'
import { useBuildingStore } from '~/modules/building/store'
import { useMarketEntitiesActionsStore } from '~/modules/market-entities-actions/MarketEntitiesActionsModule'
import { MarketEntitiesFilter } from '~/modules/market-entities-actions/types/filter/Filter'
import { defaultFilter } from '~/modules/market-entities-actions/constants/filter/Filter.default'
import { ObjectHelper } from '~/common/helpers/object'
import { MEASURE } from '~/common/constants/measure/Measure'
import { Measure } from '~/common/types/measure/Measure'

const getInitialMarketEntitiesFilter = async(): Promise<MarketEntitiesFilter> => {
  const storage: IQdStorage = useNuxtApp().$appStorage

  const filterFromStorage: MarketEntitiesFilter | undefined | null = await storage.getItem<MarketEntitiesFilter>('marketEntitiesFilter')
  const filter = defaultFilter()
  if (filterFromStorage) {
    const syncedFilter = ObjectHelper.syncObjects<MarketEntitiesFilter>(filterFromStorage, filter)
    storage.setItem('marketEntitiesFilter', syncedFilter)

    return syncedFilter
  }
  return filter
}

export default defineNuxtPlugin(async nuxtApp => {
  const storage: IQdStorage = nuxtApp.$appStorage

  const appStateStore = useAppStateStore()
  const addressStore = useAddressStore()
  const buildingStore = useBuildingStore()
  const marketEntitiesStore = useMarketEntitiesActionsStore()

  appStateStore.currency = await storage.getItem<Currency>('currency') || CURRENCY.usd
  appStateStore.measure = await storage.getItem<Measure>('measure') || MEASURE.squareMeter
  appStateStore.clientMode = await storage.getItem<boolean>('clientMode') || false

  addressStore.country = await storage.getItem('country') || null
  addressStore.region = await storage.getItem('region') || null
  addressStore.city = await storage.getItem('city') || null

  buildingStore.suggestionsFilter = await storage.getItem('suggestionsFilter') || 'all'
  buildingStore.lastErrorBuildingsTime = await storage.getItem('lastErrorBuildingsTime') || null
  buildingStore.lastErrorSuggestionsTime = await storage.getItem('lastErrorSuggestionsTime') || null
  buildingStore.lastErrorClientsTime = await storage.getItem('lastErrorClientsTime') || null

  marketEntitiesStore.filter = await getInitialMarketEntitiesFilter()
})
