<template>
  <div class="flex flex-col ">
    <div class="flex items-center">
      <h4 class="mb-4 font-normal">
        {{ t('auth.organisation.description.selectOrganisation') }}
      </h4>
    </div>
    <div class="flex start">
      <p class="mb-4">
        {{ t('auth.organisation.description.someOrganisation') }}
      </p>
    </div>
  </div>
  <form @submit="selectOrCreateOrganisation">
    <custom-autocomplete
      v-model="selectedOrganisation"
      :options="options"
      option-label="label"
      value-label="id"
    />
    <div
      v-if="!selectedOrganisation"
      class="bg-red-300 mt-4 rounded-[5px] text-red-700 p-3"
    >
      <span class="text-red-950 font-semibold"> {{ t('auth.organisation.description.attention') }}</span>
      {{ t('auth.organisation.description.warning') }}
    </div>
    <custom-button-with-spinner
      type="submit"
      class="w-full mt-4 font-semibold"
      :disabled="isFetching"
      :display-spinner="isFetching"
    >
      {{ $t('auth.login.label') }}
    </custom-button-with-spinner>
  </form>
</template>
<script setup lang="ts">
import CustomAutocomplete from '~/ui/selects/CustomAutocomplete.vue'
import { Organisation } from '~/common/types/organisation/Organisation'
import { useForm } from 'vee-validate'
import { AuthService } from '~/modules/auth/service/AuthService'
import useOrganisationStore from '~/modules/organisation/store'
import CustomButtonWithSpinner from '~/ui/buttons/CustomButtonWithSpinner.vue'
import { Option } from '~/ui/selects/types/Select.option'
import { Nullable } from '~/common/types/utils/Nullable'
import SentryLogger from '~/services/sentry/SentryLogger'

const props = defineProps({
  organisations: {
    type: Array as PropType<Array<Organisation>>,
    required: true,
  },
  authService: {
    type: Object as PropType<AuthService>,
    required: true,
  },
})

const { t } = useI18n()

const authService: AuthService = props.authService ?? new AuthService()

const emits = defineEmits<{(e: 'setOrganisation', value: Organisation): void}>()

const store = useOrganisationStore()

const isFetching = ref(false)

const selectedOrganisation = ref(props.organisations[0])

const options = computed(() => props.organisations?.map(organisation => ({
  label: organisation.name,
  value: organisation,
})).concat({
  label: t('auth.organisation.actions.createOrganisation.label'),
  value: null,
}))

const { handleSubmit, setErrors } = useForm()

const selectOrCreateOrganisation = handleSubmit(() => {
  isFetching.value = true
  if (!selectedOrganisation.value) {
    authService.createOrganisation()
      .then(organisation => {
        store.setOrganisation(organisation)
        emits('setOrganisation', null)
      })
      .catch(error => {
        toast(t('auth.organisation.actions.createOrganisation.error'), { variant: 'danger' })
      })
      .finally(() => {
        isFetching.value = false
      })
    return
  }
  authService.setUserOrganisation(selectedOrganisation.value)
    .then(organisation => {
      store.setOrganisation(organisation)
      emits('setOrganisation', organisation)
    })
    .catch(error => {
      toast(t('auth.organisation.actions.selectOrganisation.error'), { variant: 'danger' })
    })
    .finally(() => {
      isFetching.value = false
    })
})
</script>
