<template>
  <div
    v-for="(house, id) in housesWithOrder"
    :key="id"
  >
    <flats-house
      :house="house.house"
      :house-flats-info="houseFlatsInfoById[house.house.id] || []"
      :order="house.order"
      :display-type="displayType"
      :building="building"
    >
      <template #showAvailable>
        <slot name="showAvailable" />
      </template>
    </flats-house>
  </div>
</template>

<script setup lang="ts">
import FlatsHouse from '~/modules/building/components/page/flats/house/FlatsHouse.vue'
import { House } from '~/common/types/house/House'
import DISPLAY_TYPE from '~/common/constants/flat/Flat.displayType'
import { Building } from '~/modules/building/types/Building'
import { orderHouses } from '~/modules/building/helpers/Building.orderHouses'
import { HouseFlatsInfo } from '~/modules/house/types/HouseFlatsInfo'

const props = defineProps({
  houses: {
    type: Object as PropType<Array<House>>,
    required: true,
  },
  houseFlatsInfoById: {
    type: Object as PropType<Record<number, Array<HouseFlatsInfo>>>,
    required: true,
  },
  displayType: {
    type: String as PropType<DISPLAY_TYPE>,
    default: DISPLAY_TYPE.basic,
  },
  building: {
    type: Object as PropType<Building>,
    required: true,
  },
})
const housesWithOrder = computed(() => orderHouses<House>(props.houses))
</script>
