import { ObjectHelper } from '~/common/helpers/object'
import { FLAT_ROOMS_COUNT } from '~/common/constants/flat/Flat.roomsCount'
import type { FlatRoomsCount } from '~/common/types/flat/Flat.flatRoomsCount'
import type { Flat } from '~/common/types/flat/Flat'
import { FlatLayoutItem } from '~/common/types/flat/Flat.layoutItem'
import { FlatsSort } from '~/modules/building/types/Flat.sort'
import { FLATS_SORT } from '~/modules/building/constants/Flat.sort'
import { Decoration } from '~/common/types/decoration/Decoration'
import { CURRENCY } from '~/common/constants/money/Currency'
import { getEntityName } from '~/common/helpers/getEntityName'
import { Building } from '~/modules/BuildingModule'

type FlatsByRooms = {
  roomsCount: string
  flats: Flat[]
  layouts?: FlatLayoutItem[]
}

type GetFlatsByRoomsOptions = {
  sortOption?: FlatsSort
  withLayouts?: boolean
  building?: Building
}

type MinAndInterval = {min: string, interval: string}

export class BuildingPageFlatsCombine {
  private i18

  constructor() {
    this.i18 = useNuxtApp().$i18n
  }

  private createLocalizedInterval(a: string, b: string) {
    return `${this.i18.t('common.from')} ${a} ${this.i18.t('common.to')} ${b}`
  }

  private createLocalizedAmount(amount: number, compact = true): string {
    return new Intl.NumberFormat(this.i18.locale, { notation: compact ? 'compact' : undefined }).format(amount)
  }

  private getCurrency(currency: string): string {
    return this.i18.t(`currency.options.${currency}`)
  }

  private getUnitsOfMeasurement(): string {
    return this.i18.t('common.unitsOfMeasurement.options.squareMeter')
  }

  private getMinAmount(nums: Array<number>): number {
    return Math.min(...nums)
  }

  private getMaxAmount(nums: Array<number>): number {
    return Math.max(...nums)
  }

  private checkEqualCompactWord(a: string, b: string): boolean {
    const matchCompactWordA = a.match(/\D/g)
    const matchCompactWordB = b.match(/\D/g)
    return !!(matchCompactWordA && matchCompactWordB && matchCompactWordA.join('') === matchCompactWordB.join(''))
  }

  private getLocalizedMinMaxPrices(flats: Flat[], key: 'price' | 'pricePerSquareMeter', compact = true) {
    const amounts = flats.map(flat => flat[key]?.amount)
    const existedAmounts = amounts.filter(amount => amount)
    return {
      min: this.createLocalizedAmount(this.getMinAmount(existedAmounts), compact),
      max: this.createLocalizedAmount(this.getMaxAmount(existedAmounts), compact),
    }
  }

  private getAmountIntervals(nums: number[]): string {
    const sortedAmounts = nums.sort((a, b) => {
      if (a > b) { return 1 }
      if (a < b) { return -1 }
      return 0
    })
    // @ts-ignore
    return sortedAmounts.reduce((acc, cur, idx, arr) => {
      if ((cur - 1) === arr[idx - 1]) {
        if ((cur + 1) === arr[idx + 1]) {
          return `${acc}`
        }
        return `${acc}-${cur}`
      }
      return `${acc},${cur}`
    })
  }

  private sortFlatsByPrice(flats: Flat[], asc: boolean) {
    flats.sort((a, b) => {
      if (a.price.amount > b.price.amount) {
        return asc ? 1 : -1
      }
      if (a.price.amount < b.price.amount) {
        return asc ? -1 : 1
      }
      return 0
    })
  }

  private sortFlatsByArea(flats: Flat[], asc: boolean) {
    flats.sort((a, b) => {
      if (a.totalArea > b.totalArea) {
        return asc ? 1 : -1
      }
      if (a.totalArea < b.totalArea) {
        return asc ? -1 : 1
      }
      return 0
    })
  }

  private sortFlatsByFloor(flats: Flat[], asc: boolean) {
    flats.sort((a, b) => {
      if (a.floorNumber > b.floorNumber) {
        return asc ? 1 : -1
      }
      if (a.floorNumber < b.floorNumber) {
        return asc ? -1 : 1
      }
      return 0
    })
  }

  private sortFlatsByAgentMotivation(flats: Flat[], asc: boolean) {
    flats.sort((a, b) => {
      if (a.agentMotivation.amount > b.agentMotivation.amount) {
        return asc ? 1 : -1
      }
      if (a.agentMotivation.amount < b.agentMotivation.amount) {
        return asc ? -1 : 1
      }
      return 0
    })
  }

  private sortFlats(flats: Flat[], sortOption: FlatsSort) {
    if (sortOption.includes(FLATS_SORT.ascPrice)) {
      this.sortFlatsByPrice(flats, !sortOption.includes('-'))
    }
    if (sortOption.includes(FLATS_SORT.ascArea)) {
      this.sortFlatsByArea(flats, !sortOption.includes('-'))
    }
    if (sortOption.includes(FLATS_SORT.ascFloor)) {
      this.sortFlatsByFloor(flats, !sortOption.includes('-'))
    }
    if (sortOption.includes(FLATS_SORT.ascAgentMotivation)) {
      this.sortFlatsByAgentMotivation(flats, !sortOption.includes('-'))
    }
  }

  public sortFlatsAndReturn(flats: Flat[], sortOption: FlatsSort) {
    const flatsCopy = [...flats]
    this.sortFlats(flatsCopy, sortOption)
    return flatsCopy
  }

  public flatsToLayouts(flats: Flat[], decorations?: Decoration[]): FlatLayoutItem[] {
    const flatsByLayoutsObject: {[key in string]: Flat[]} = {}

    flats.forEach(flat => {
      const existedKey = flatsByLayoutsObject[flat.layout?.id ?? 'noLayout']
      if (existedKey) {
        existedKey.push(flat)
      } else {
        flatsByLayoutsObject[flat.layout?.id ?? 'noLayout'] = [flat]
      }
    })

    return Object.keys(flatsByLayoutsObject)
      .map(i => {
        const totalArea = this.getAreaSquareMeter(flatsByLayoutsObject[i], 'totalArea', true)
        const kitchenArea = this.getAreaSquareMeter(flatsByLayoutsObject[i], 'kitchenArea')
        const price = this.getPriceInterval(flatsByLayoutsObject[i], true, true)

        return {
          layoutId: i,
          flats: flatsByLayoutsObject[i],
          images: flatsByLayoutsObject[i][0].layout?.images ?? [],
          address: flatsByLayoutsObject[i][0].address?.fullAddress,
          totalArea: typeof totalArea !== 'string' ? totalArea.interval : '',
          totalAreaMin: typeof totalArea !== 'string' ? `${totalArea.min}` : '',
          kitchenArea: typeof kitchenArea === 'string' ? kitchenArea : '',
          title: this.getTitle(flatsByLayoutsObject[i]),
          price: price.interval,
          priceMin: price.min,
          pricePerSquareMeter: this.getPricePerSquareMeterInterval(flatsByLayoutsObject[i]),
          decorations: decorations || this.getDecorationTypes(flatsByLayoutsObject[i]),
        }
      })
  }

  public getPriceInterval<B extends boolean>(flats: Flat[], compact?: boolean, withMin?: B): B extends true ? MinAndInterval : string {
    const { min, max } = this.getLocalizedMinMaxPrices(flats, 'price', compact)
    const currencyCode = flats[0].price.currency
    const isEqualDigit = compact === false ? false : this.checkEqualCompactWord(min, max)

    const localizedInterval = this.createLocalizedInterval(
      isEqualDigit ? (min.match(/\d/g) as RegExpMatchArray).join('') : min,
      `${max} ${this.getCurrency(currencyCode)}`,
    )

    return (withMin ? { min: `${min} ${this.getCurrency(currencyCode)}`, interval: localizedInterval } : localizedInterval) as B extends true ? MinAndInterval : string
  }

  public getPricePerSquareMeterInterval(flats: Flat[]) {
    const { min, max } = this.getLocalizedMinMaxPrices(flats, 'pricePerSquareMeter', false)
    const currencyCode = flats.find(flat => flat.pricePerSquareMeter?.currency)?.pricePerSquareMeter.currency || CURRENCY.usd

    return this.createLocalizedInterval(
      min,
      `${max} ${this.getCurrency(currencyCode)}/${this.getUnitsOfMeasurement()}`,
    )
  }

  public getAreaSquareMeter(flats: Flat[], key: string, withMin?: boolean) {
    const areas = flats.map(f => (f[key] ? f[key] : undefined)).filter(f => f !== undefined)

    if (!areas.length) {
      return ''
    }

    const min = this.getMinAmount(areas)
    const max = this.getMaxAmount(areas)

    const interval = this.createLocalizedInterval(
      `${min}`,
      `${max} ${this.getUnitsOfMeasurement()}`,
    )

    return withMin
      ? {
          min,
          interval,
        }
      : interval
  }

  public getDecorationTypes(flats: Flat[]): Decoration[] {
    const decorations = flats.map(f => f.decoration).filter(i => i)

    return decorations.filter((decoration, id, arr) => id === arr.findIndex(dec => (
      dec.decorationType === decoration.decorationType
    )))
  }

  private getTitle(flats: Flat[]): string {
    const roomsCount = flats[0].flatRoomsCount
    const floors = [...new Set(flats.map(f => f.floorNumber))]
    const sections = [...new Set(flats.map(f => getEntityName({
      name: f.section.name,
      internationalName: f.section.internationalName,
      entityCountry: f.address?.countryIsoCode,
    })))]
    return `${this.i18.t(`building.flatsInfo.flatRoomsCountBuildingCard.${roomsCount || 'notIndicated.full'}`)},${this.i18.t('flat.floorNumber.value', { value: this.getAmountIntervals(floors) })}, ${
      this.i18.t('flat.section', { value: sections.join(', ') })}`
  }

  public getFlatsByRooms(flats: Flat[], options?: GetFlatsByRoomsOptions): FlatsByRooms[] {
    const flatsByRoomsObject: {[key in FlatRoomsCount]?: Flat[]} = {}
    const flatsCopy = ObjectHelper.copy(flats)

    if (options?.sortOption) {
      this.sortFlats(flatsCopy, options.sortOption)
    }

    flatsCopy.forEach(flat => {
      const flatWithBuilding = { ...flat, building: options?.building }
      const existedKey = flatsByRoomsObject[flatWithBuilding.flatRoomsCount]
      if (existedKey) {
        existedKey.push(flatWithBuilding)
      } else {
        flatsByRoomsObject[flatWithBuilding.flatRoomsCount] = [flatWithBuilding]
      }
    })

    const flatsByRoomsArray = (Object.keys(flatsByRoomsObject) as FlatRoomsCount[]).map(i => ({ roomsCount: i, flats: flatsByRoomsObject[i] }))

    const arrayOfRoomsCount = Object.values(FLAT_ROOMS_COUNT)
    flatsByRoomsArray.sort((a, b) => {
      if (arrayOfRoomsCount.indexOf(a.roomsCount) > arrayOfRoomsCount.indexOf(b.roomsCount)) { return 1 }
      if (arrayOfRoomsCount.indexOf(a.roomsCount) < arrayOfRoomsCount.indexOf(b.roomsCount)) { return -1 }
      return 0
    })

    if (options?.withLayouts) {
      const flatsByRoomsWithLayouts = flatsByRoomsArray.map(flatsByRoom => ({
        roomsCount: flatsByRoom.roomsCount,
        flats: flatsByRoom.flats,
        layouts: this.flatsToLayouts(flatsByRoom.flats as Flat[]),
      }))
      return (flatsByRoomsWithLayouts as FlatsByRooms[])
    }

    return (flatsByRoomsArray as FlatsByRooms[])
  }
}
