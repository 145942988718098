<script lang="ts" setup>
import {useAppStateStore} from '~/store/app'

defineProps({
  monthPayment: {
    type: Number,
    default: 0,
  },
  recommendedIncome: {
    type: Number,
    default: 0,
  },
})

const appStateStore = useAppStateStore()

const currency = computed(() => appStateStore.currency)
const isMd = computed(() => appStateStore.breakpoints.isMd)
</script>

<template>
  <div class="flex gap-x-[4px]">
    <div class="flex flex-1 gap-x-3 items-center bg-success-light p-6 rounded-lg">
      <span class="hidden md:flex items-center justify-center w-[38px] h-[38px] bg-white rounded-full">
        <nuxt-icon
          name="common/time"
          class="text-success text-[24px]"
        />
      </span>
      <div class="flex flex-col max-md:gap-y-1">
        <h3
          class="text-[10px] leading-[12px] md:text-base font-bold md:font-medium text-neutral-500"
          :class="{'inter': isMd}"
        >
          {{ $t('mortgage.calculator.info.monthlyPayment') }}
        </h3>
        <span class="font-semibold text-2xl">
          {{ $n(monthPayment) }} {{ $t(`currency.options.${currency}`) }}
        </span>
      </div>
    </div>
    <div class="flex flex-1 gap-x-3 items-center bg-warning-light p-6 rounded-lg">
      <span class="hidden md:flex items-center justify-center w-[38px] h-[38px] bg-white rounded-full">
        <nuxt-icon
          name="common/circle-exclamation"
          class="text-warning text-[24px]"
        />
      </span>
      <div class="flex flex-col max-md:gap-y-1">
        <h3
          class="text-[10px] leading-[12px] md:text-base font-bold md:font-medium text-neutral-500"
          :class="{'inter': isMd}"
        >
          {{ $t('mortgage.calculator.info.recommendedIncome') }}
        </h3>
        <span class="font-semibold text-2xl">
          {{ $n(recommendedIncome) }} {{ $t(`currency.options.${currency}`) }}
        </span>
      </div>
    </div>
  </div>
</template>
