import { Service } from '~/services/http/Service'
import { HttpApiService } from '~/services/http/HttpApiService'
import { InitAppData } from '~/common/types/app/init'
import { HttpHandledResponse } from '~/services/http/types/Http.handledResponse'

export class AppHttpApiService extends Service {
  constructor(httpInstance: HttpApiService) {
    super(httpInstance)
  }

  private endPoints = {
    init: '/v4/estate-market/init',
  }

  public init(): Promise<HttpHandledResponse<InitAppData>> {
    return this.httpInstance.post(this.endPoints.init)
  }
}
