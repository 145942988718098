<template>
  <apex-charts
    ref="apexRef"
    class="w-full qm-apex-charts"
    type="area"
    :height="height"
    :options="firstLocalizedOptions"
    :series="series"
  />
</template>

<script setup lang="ts">
import { ApexOptions } from 'apexcharts'

const props = defineProps({
  series: {
    type: Object as PropType<ApexOptions['series']>,
    required: true,
  },
  options: {
    type: Object as PropType<ApexOptions>,
    required: true,
  },
  height: {
    type: [String, Number],
    default: '350',
  },
})

const { locale } = useI18n()
const firstLocale = locale.value

const apexRef = ref<ApexCharts | null>(null)

const firstLocalizedOptions = computed(() => ({
  ...props.options,
  chart: {
    ...props.options.chart,
    defaultLocale: firstLocale,
  },
}))

watch(locale, value => {
  apexRef.value?.setLocale(value === 'kk' ? 'en' : value)
})
</script>

<style lang="scss">
.qm-apex-charts {
  & .apexcharts-xaxistooltip, .apexcharts-yaxistooltip {
    @apply bg-neutral-150 border-0 rounded-[6px] text-neutral-950 font-semibold;
  }

  & .apexcharts-yaxistooltip {
    &::before, &::after {
      @apply border-l-neutral-150;
    }
  }

  & .apexcharts-xaxistooltip {
    &::before, &::after {
      @apply border-b-neutral-150;
    }
  }

  & .apexcharts-tooltip {
    @apply border-0 rounded-[6px] #{!important};

    & .apexcharts-tooltip-title {
      @apply border-b-0 bg-neutral-150 font-semibold #{!important};
    }

    & .apexcharts-tooltip-marker {
      @apply flex-shrink-0;
    }

    & .apexcharts-tooltip-y-group {
      @apply flex;
      & .apexcharts-tooltip-text-y-label {
        @apply mr-3;
      }

      & .apexcharts-tooltip-text-y-value {
        @apply ml-auto flex;
      }
    }

    & .apexcharts-tooltip-text {
      @apply w-full;
    }
  }
}
</style>
