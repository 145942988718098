import { Flat } from '~/common/types/flat/Flat'
import SentryLogger from '~/services/sentry/SentryLogger'
import { MarketEntitiesFilterForRequest } from '~/modules/market-entities-actions/types/filter/Filter.forSearchRequest'
import { RecursivePartial } from '~/common/types/utils/RecursivePartial'
import { HttpApiService } from '~/services/http/HttpApiService'
import { FlatPriceStatisticsQueryRequest } from '~/common/types/flat/Flat.priceStatisticsQueryRequest'
import { FlatPriceStatistics } from '~/common/types/flat/Flat.priceStatistics'
import { FlatGuestHttpApiService } from '~/common/api/flat/FlatGuestHttpApiService'
import { FlatFilterForRequest } from '~/modules/building/components/page/flats/filter/types/FlatsFilter'
import { HttpPaginatedResponseData } from '~/services/http/types/Http.paginatedResponseData'
import { FlatsSort } from '~/modules/building/types/Flat.sort'
import { FLATS_SORT } from '~/modules/building/constants/Flat.sort'
import { Layout } from '~/common/types/layout/Layout'
import { ChessboardSection } from '~/modules/chessboard/types/Chessboard.section'

export class FlatGuestService {
  private http: FlatGuestHttpApiService
  constructor() {
    const nuxtApp = useNuxtApp()
    const httpInstance = nuxtApp.$qdHttpApiInstance as HttpApiService
    this.http = new FlatGuestHttpApiService(httpInstance)
  }

  public getFlat(id: number) {
    return new Promise<Flat>((resolve, reject) => {
      this.http.getFlat(id)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось получить квартиру по id (гостевой запрос)',
          })
          reject(error)
        })
    })
  }

  public search(filter: FlatFilterForRequest, page = 1) {
    return new Promise<HttpPaginatedResponseData<Flat>>((resolve, reject) => {
      this.http.search(filter, page)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось выполнить серч квартир (гостевой запрос)',
            filter,
          })
          reject(error)
        })
    })
  }

  public searchForBuildingPage(filter: FlatFilterForRequest, page = 1, sort: FlatsSort = FLATS_SORT.ascPrice) {
    return new Promise<HttpPaginatedResponseData<Flat>>((resolve, reject) => {
      this.http.searchForBuildingPage(filter, page, sort)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось выполнить серч квартир для страницы ЖК (гостевой запрос)',
            filter,
          })
          reject(error)
        })
    })
  }

  public searchLayoutsForBuildingPage(filter: FlatFilterForRequest, page = 1, sort: FlatsSort = FLATS_SORT.ascPrice) {
    return new Promise<HttpPaginatedResponseData<Layout>>((resolve, reject) => {
      this.http.searchLayoutsForBuildingPage(filter, page, sort)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось выполнить серч планировок для страницы ЖК (гостевой запрос)',
            filter,
          })
          reject(error)
        })
    })
  }

  public searchSectionsForChessboard(filter: FlatFilterForRequest) {
    return new Promise<Array<ChessboardSection>>((resolve, reject) => {
      this.http.searchSectionsForChessboard(filter)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось выполнить серч квартир по секциям (гостевой запрос)',
            filter,
          })
          reject(error)
        })
    })
  }

  public count(filter: FlatFilterForRequest) {
    return new Promise<number>((resolve, reject) => {
      this.http.count(filter)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось получить количество квартир (гостевой запрос)',
            filter,
          })
          reject(error)
        })
    })
  }

  public getPopularFlats(filter: RecursivePartial<MarketEntitiesFilterForRequest>, signal: AbortSignal): Promise<Array<Flat>> {
    return new Promise((resolve, reject) => {
      this.http.getPopularFlats(filter, signal)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось получить популярные квартиры',
          })
          reject(error)
        })
    })
  }

  public getStatistics(statisticsQueryRequest: FlatPriceStatisticsQueryRequest): Promise<FlatPriceStatistics> {
    return new Promise((resolve, reject) => {
      this.http.getStatistics(statisticsQueryRequest)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось получить статистику на квартиры(гостевой)',
          })
          reject(error)
        })
    })
  }

  public getFlatStatistics(statisticsQueryRequest: FlatPriceStatisticsQueryRequest & {
    flatId: number
  }): Promise<FlatPriceStatistics> {
    return new Promise((resolve, reject) => {
      this.http.getFlatStatistics(statisticsQueryRequest)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось получить статистику по квартире(гостевой)',
          })
          reject(error)
        })
    })
  }

  public getLayoutStatistics(statisticsQueryRequest: FlatPriceStatisticsQueryRequest & {
    layoutId: number
  }): Promise<FlatPriceStatistics> {
    return new Promise((resolve, reject) => {
      this.http.getLayoutStatistics(statisticsQueryRequest)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось получить статистику по планировке(гостевой)',
          })
          reject(error)
        })
    })
  }
}
