import { useUserStore } from '~/modules/user/store'
import { GUEST_ROUTES } from '~/router/constants/Router.guestRoutes'
import { AUTHENTICATION_ROUTES } from '~/router/constants/Router.authenticationRoutes'
import { ROUTES_NAMES } from '~/router/constants/Router.routesNames'
import { setLayout } from '~/router/helpers/setLayout'
import { AuthService } from '~/modules/auth/service/AuthService'
import { SHARED_ROUTES } from '~/router/constants/Router.authAndGuestRoutes'

export default defineNuxtRouteMiddleware(async(to, from) => {
  const authService = new AuthService()
  setLayout(to.name)

  if (GUEST_ROUTES.includes(to.name) && !AUTHENTICATION_ROUTES.includes(to.name)) {
    return
  }

  const userStore = useUserStore()
  if (userStore.user?.id && from && !GUEST_ROUTES.includes(from.name)) {
    return
  }

  const accessToken = await authService.getAccessToken()
  const token = accessToken ? accessToken.token : null
  if (token) {
    if (AUTHENTICATION_ROUTES.includes(to.name)) {
      navigateTo('/', { replace: true })
      return
    }
    await authService.initApp(token, true, to.name)
    return
  }

  if (to.name === ROUTES_NAMES.home) {
    return
  }
  if (SHARED_ROUTES.includes(to.name)) {
    return
  }

  if (!AUTHENTICATION_ROUTES.includes(to.name)) {
    return navigateTo({ name: ROUTES_NAMES.login }, { replace: true })
  }
})
