<template>
  <custom-input
    v-model="value"
    :type="isPasswordHidden ? 'password' : 'text'"
    :label="$t(confirm ? 'auth.passwordConfirm.label' : 'auth.password.label')"
    :required="true"
    :placeholder="$t(confirm ? 'auth.passwordConfirm.placeholder' : 'auth.password.placeholder')"
    :disabled="disabled"
    :autocomplete="autocomplete"
    :invalid-feedback="errorMessage"
    :state="errorMessage ? false : null"
  >
    <template #suffixInner>
      <client-only>
        <font-awesome-icon
          :icon="['fas', isPasswordHidden ? 'eye' : 'eye-slash']"
          class="ml-2 cursor-pointer text-neutral-500"
          @click="isPasswordHidden = !isPasswordHidden"
        />
      </client-only>
    </template>
  </custom-input>
</template>
<script setup lang="ts">
import CustomInput from '~/ui/inputs/CustomInput.vue'
import { useField, RuleExpression } from 'vee-validate'

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  isHidden: {
    type: Boolean,
    default: true,
  },
  passwordToConfirm: {
    type: String,
    default: null,
  },
  confirm: {
    type: Boolean,
    default: false,
  },
  autocomplete: {
    type: String as PropType<'current-password' | 'new-password' | 'off'>,
    default: 'current-password',
  },
})

const emit = defineEmits<{(e: 'hide', value: boolean): void}>()

const { t } = useI18n()

const isPasswordHidden = ref(props.isHidden)

const rules: RuleExpression<string> = props.confirm
  ? confirmation => {
    if (confirmation === props.passwordToConfirm) {
      return true
    }
    return t('auth.passwordConfirm.error')
  }
  : { required: true, min: 6 }
const { value, errorMessage } = useField(props.confirm ? 'passwordConfirm' : 'password', rules, {
  label: t('auth.password.label'),
})

watch(isPasswordHidden, isHidden => {
  emit('hide', isHidden as boolean)
})
watch(() => props.isHidden, isHidden => {
  isPasswordHidden.value = isHidden
})
</script>
