<template>
  <component
    :is="componentName"
    :disabled="disabled"
    :type="type"
    :class="classes"
    @click.stop="handleClick($event)"
  >
    <span
      v-if="slots.prefix"
      class="mr-2"
    >
      <slot name="prefix" />
    </span>
    <slot />
  </component>
</template>

<script setup lang="ts">
import { Size, Variant } from '~/ui/types/types'
import { VARIANT } from '~/ui/constants/variant'
import { SIZE } from '~/ui/constants/size'
import { COLOR } from '~/ui/constants/color'
import COLOR_CLASSES, { DISABLED_CLASSES, FILLED_COLOR_CLASSES } from '~/ui/utils/classNames'

const { t } = useI18n()
const props = defineProps({
  type: {
    type: String as PropType<'button' | 'submit' | 'reset'>,
    default: 'button',
  },
  disabled: {
    type: Boolean,
    default: undefined,
  },
  variant: {
    type: String as PropType<Variant>,
    default: VARIANT.default,
  },
  color: {
    type: String as PropType<keyof typeof COLOR>,
    default: COLOR.primary,
  },
  size: {
    type: String as PropType<Size>,
    default: SIZE.sm,
  },
  pill: {
    type: Boolean,
    default: false,
  },
  withDefaultMessage: {
    type: Boolean,
    default: false,
  },
  isLink: {
    type: Boolean,
    default: false,
  },
  component: {
    type: String,
    default: null,
  },
})

const emits = defineEmits<{(e: 'click', value: MouseEvent): void }>()

const slots = useSlots()
const componentName = computed(() => {
  if (props.component) {
    return props.component
  }

  if (props.isLink || useAttrs().href) {
    return 'a'
  }
  return 'button'
})

const buttonInactiveClasses = {
  [VARIANT.default]: {
    bg: DISABLED_CLASSES.bg,
    bgActive: '',
    text: DISABLED_CLASSES.text,
    textActive: '',
    border: '',
  },
  [VARIANT.filled]: {
    bg: DISABLED_CLASSES.bg,
    bgActive: '',
    text: DISABLED_CLASSES.text,
    textActive: '',
    border: '',
  },
  [VARIANT.outline]: {
    bg: '',
    bgActive: '',
    text: DISABLED_CLASSES.text,
    textActive: '',
    border: DISABLED_CLASSES.border,
  },
} as Record<keyof typeof VARIANT, { bg: string, bgActive: string, text: string, textActive: string, border: string }>

const getSizeClasses = (): string => {
  switch (props.size) {
    case SIZE.sm:
      return 'px-4 py-3 leading-4'
    case SIZE.xs:
      return 'px-[5px] py-1 leading-4 text-sm'
    default:
      return ''
  }
}

const getTextClasses = () => {
  let textColor: string
  let hoverTextColor: string
  let activeTextColor: string
  let disabledTextColor = ''

  if (props.color !== COLOR.none) {
    disabledTextColor = `disabled:${DISABLED_CLASSES.text}`
  }
  if (props.withDefaultMessage) {
    [textColor, activeTextColor] = [
      buttonInactiveClasses[props.variant]?.text ?? '',
      buttonInactiveClasses[props.variant]?.textActive ?? '',
    ]
    return [textColor, activeTextColor]
  }
  switch (props.variant) {
    case VARIANT.default:
      textColor = COLOR_CLASSES[props.color].text
      hoverTextColor = COLOR_CLASSES[props.color].textHover || ''
      activeTextColor = COLOR_CLASSES[props.color].textActive || ''
      break
    case VARIANT.filled:
      textColor = 'text-white'
      hoverTextColor = ''
      activeTextColor = ''
      break
    case VARIANT.outline:
      textColor = 'text-neutral-900'
      hoverTextColor = props.color === COLOR.primary || props.color === COLOR.dropdown ? 'hover:text-primary' : ''
      activeTextColor = props.color === COLOR.primary || props.color === COLOR.dropdown ? 'active:text-primary-dark' : ''
      break
    case VARIANT.withoutBorders:
      textColor = COLOR_CLASSES[props.color].text
      hoverTextColor = props.color === COLOR.primary || props.color === COLOR.dropdown ? 'hover:text-primary-dark' : ''
      activeTextColor = props.color === COLOR.primary || props.color === COLOR.dropdown ? 'hover:text-primary-dark' : ''
      break
    default:
      textColor = ''
      hoverTextColor = ''
      activeTextColor = ''
  }
  return [textColor, hoverTextColor, activeTextColor, disabledTextColor]
}

const getBackgroundClasses = () => {
  let bgColor: string
  let activeBgColor = ''
  let hoverBgColor = ''
  let disabledBgColor = ''

  if (props.withDefaultMessage) {
    bgColor = buttonInactiveClasses[props.variant]?.bg ?? ''
    activeBgColor = buttonInactiveClasses[props.variant]?.bgActive ?? ''
  } else {
    switch (props.variant) {
      case VARIANT.default:
        bgColor = COLOR_CLASSES[props.color].bg
        activeBgColor = props.color === COLOR.transparent && props.disabled ? '' : COLOR_CLASSES[props.color].bgActive || ''
        hoverBgColor = props.color === COLOR.transparent && props.disabled ? '' : COLOR_CLASSES[props.color].bgHover || ''
        disabledBgColor = props.color === COLOR.transparent ? '' : `disabled:${DISABLED_CLASSES.bg}`
        break
      case VARIANT.filled:
        bgColor = FILLED_COLOR_CLASSES[props.color].bg
        activeBgColor = props.color === COLOR.transparent && props.disabled ? '' : FILLED_COLOR_CLASSES[props.color].bgActive || ''
        hoverBgColor = props.color === COLOR.transparent && props.disabled ? '' : FILLED_COLOR_CLASSES[props.color].bgHover || ''
        disabledBgColor = props.color === COLOR.transparent ? '' : `disabled:${DISABLED_CLASSES.bg}`
        break
      case VARIANT.outline:
        bgColor = ''
        break
      case VARIANT.withoutBorders:
        bgColor = 'inherit'
    }
  }
  return [bgColor, hoverBgColor, activeBgColor, disabledBgColor]
}

const getBorderClasses = () => {
  const classes: string[] = []
  if (props.pill) {
    classes.push('rounded-full')
  } else {
    classes.push('rounded-md')
  }
  if (props.variant !== VARIANT.withoutBorders && props.variant !== VARIANT.default && props.variant !== VARIANT.filled) {
    classes.push('border-2')
    if (props.withDefaultMessage) {
      classes.push(buttonInactiveClasses[props.variant]?.border)
    } else {
      classes.push(COLOR_CLASSES[props.color].border, COLOR_CLASSES[props.color].borderActive || '', COLOR_CLASSES[props.color].borderHover || '', `disabled:${DISABLED_CLASSES.border}`, `disabled:${DISABLED_CLASSES.text}`, `disabled:${DISABLED_CLASSES.bg}`)
    }
  }
  return classes
}

const displayClasses = slots.prefix ? ['flex items-center nowrap'] : []

const classes = computed(() => [...getTextClasses(), getSizeClasses(), ...getBackgroundClasses(), ...getBorderClasses(), ...displayClasses])

const handleClick = (e: MouseEvent) => {
  if (props.withDefaultMessage) {
    toast(t('common.inDevelopment'))
    return
  }
  emits('click', e)
}
</script>
