<template>
  <custom-carousel-view
    v-if="orderedImages.length > 0"
    v-model="currentImageIndex"
    v-model:is-modal-open="isModalOpen"
    :class="[`carousel_size_${size}`, {'carousel_no-bottom-rounded': !bottomRounded}, carouselClass]"
    :images="orderedImages"
    :indicators="indicators"
    :controls="controls"
    :max-height="maxHeight"
    :min-height="minHeight"
    :lazy="lazy"
    :loaded-images-index="loadedImagesIndex"
    :is-images-changing="isImagesChanging"
    :is-open-modal-on-click="isOpenModalOnClick"
    :items-to-show="itemsToShow"
    :without-video="withoutVideo"
    :is-draggable="isDraggable"
    :snap-align="snapAlign"
    :is-full-screen="isFullScreen"
    @on-click-by-slide="emits('onClickBySlide', $event)"
  >
    <template #default>
      <slot />
    </template>
    <template #slide="{image, index}">
      <slot
        name="slide"
        :image="image"
        :index="index"
      />
    </template>
  </custom-carousel-view>
  <div
    v-else
    :class="['relative w-full h-full flex overflow-hidden', {'rounded-b-none': !bottomRounded}, carouselClass]"
    :style="{ maxHeight, minHeight }"
  >
    <img
      :src="defaultImage"
      :style="{ maxHeight, minHeight }"
      class="mx-auto h-full w-full object-cover"
      alt="default image"
    >
    <div class="py-[16px] px-[24px] rounded-full bg-black/30 backdrop-blur-lg absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 text-white text-lg font-medium leading-[19px]">
      {{ $t('common.noPhoto') }}
    </div>
  </div>
  <modal-or-bottom-sheet
    v-if="isOpenModalOnClick && orderedImages.length > 0"
    v-model="isModalOpen"
    :modal-props="{size: 'w-full max-w-[60vw] h-full', contentClass: 'h-[80vh]', hideFooter: true}"
  >
    <div class="h-full flex flex-col gap-y-4">
      <custom-carousel-view
        v-model="currentImageIndex"
        :class="[`carousel_size_${size}`, carouselClass]"
        :images="orderedImages"
        :indicators="indicators"
        :controls="controls"
        :lazy="lazy"
        :loaded-images-index="loadedImagesIndex"
        :is-images-changing="isImagesChanging"
        :snap-align="snapAlign"
      />
      <slot name="modalInner" />
    </div>
  </modal-or-bottom-sheet>
</template>
<script setup lang="ts">
import CustomCarouselView from '~/ui/carousels/CustomCarouselView.vue'
import ModalOrBottomSheet from '~/components/common/ModalOrBottomSheet.vue'
import { Image } from '~/common/types/image/Image'
import { Size } from '~/ui/types/types'

const props = defineProps({
  modelValue: {
    type: Number,
    default: null,
  },
  images: {
    type: Array as PropType<Array<Image>>,
    default() {
      return []
    },
  },
  firstShowImageIndex: {
    type: Number,
    default: 0,
  },
  indicators: {
    type: Boolean,
    default: false,
  },
  controls: {
    type: Boolean,
    default: true,
  },
  maxHeight: {
    type: String,
    default: '100%',
  },
  minHeight: {
    type: String,
    default: '',
  },
  size: {
    type: String as PropType<Size>,
    default: 'sm',
  },
  bottomRounded: {
    type: Boolean,
    default: true,
  },
  lazy: {
    type: Boolean,
    default: true,
  },
  carouselClass: {
    type: [String, Object, Array],
    default: '',
  },
  defaultImage: {
    type: String,
    default: null,
  },
  isOpenModalOnClick: {
    type: Boolean,
    default: true,
  },
  itemsToShow: {
    type: Number,
    default: 1,
  },
  withoutVideo: {
    type: Boolean,
    default: false,
  },
  preferredVideoIndex: {
    type: Number,
    default: 1,
  },
  isDraggable: {
    type: Boolean,
    default: true,
  },
  snapAlign: {
    type: String as PropType<'center' | 'center-even' | 'center-odd' | 'start' |'end'>,
    default: 'center',
  },
  isFullScreen: {
    type: Boolean,
    default: false,
  },
  linkTo: {
    type: Object as PropType<Record<string, any> | null>,
    default: null,
  },
  checkAuthorizationForLinkOpening: {
    type: Boolean,
    default: true,
  },
})

const emits = defineEmits<{(e: 'update:modelValue', index: number): void,
  (e: 'onClickBySlide', index: number): void}>()

const isModalOpen = ref(false)
const loadedImagesIndex = ref<number[]>([])
const orderedImages = computed(() => {
  const rightImages: Image[] = []
  let defaultImage: Image | null = null
  let imageWithVideo: Image | null = null

  props.images.forEach((image, id) => {
    if (id === props.firstShowImageIndex) {
      defaultImage = image
    } else if (image.video) {
      imageWithVideo = image
    } else {
      rightImages.push(image)
    }
  })

  if (defaultImage) {
    rightImages.unshift(defaultImage)
  }

  if (imageWithVideo) {
    rightImages.splice(props.preferredVideoIndex, 0, imageWithVideo)
  }

  return rightImages
})
const currentImageIndexRef = ref(0)
const currentImageIndexModel = computed({
  get: () => props.modelValue,
  set: (index: number) => {
    emits('update:modelValue', index)
  },
})
const currentImageIndex = computed({
  get: () => (props.modelValue !== null ? currentImageIndexModel.value : currentImageIndexRef.value),
  set: (value: number) => {
    if (props.modelValue !== null) {
      currentImageIndexModel.value = value
    } else {
      currentImageIndexRef.value = value
    }
  },
})
const isImagesChanging = ref(false)

const lazyLoad = () => {
  if (orderedImages.value && orderedImages.value.length) {
    orderedImages.value.forEach((image, index) => {
      if (currentImageIndex.value === index && !loadedImagesIndex.value.includes(currentImageIndex.value)) {
        loadedImagesIndex.value.push(currentImageIndex.value)
      }
      if (orderedImages.value.length > 1 && currentImageIndex.value === index) {
        const prevImageIndex = index - 1 >= 0 ? index - 1 : orderedImages.value.length - 1
        const nextImageIndex = index + 1 <= orderedImages.value.length - 1 ? index + 1 : 0
        if (!loadedImagesIndex.value.includes(prevImageIndex)) {
          loadedImagesIndex.value.push(prevImageIndex)
        }
        if (!loadedImagesIndex.value.includes(nextImageIndex)) {
          loadedImagesIndex.value.push(nextImageIndex)
        }
      }
    })
  }
}

watch(() => props.firstShowImageIndex, (value: number) => {
  currentImageIndex.value = value
})
watch(currentImageIndex, () => {
  if (props.lazy) {
    lazyLoad()
  }
})
watch(orderedImages, () => {
  isImagesChanging.value = true
  currentImageIndex.value = 0
  nextTick().then(() => {
    isImagesChanging.value = false
  })
  if (props.lazy) {
    loadedImagesIndex.value = []
    lazyLoad()
  }
}, { deep: true })
onBeforeMount(() => {
  if (props.lazy) {
    lazyLoad()
  }
})
</script>

<style lang="scss">
.carousel {
  @apply rounded-lg overflow-hidden;
  &_size {
    &_sm {
      & .carousel__prev, & .carousel__next {
        @apply w-[43px] max-md:w-[30px];
      }
    }
    &_md {
      & .carousel__prev, & .carousel__next {
        @apply w-[52px] max-md:w-[30px];
      }
    }
  }
  &_no-bottom-rounded {
    @apply rounded-b-none;
    & .carousel__prev {
      @apply rounded-bl-none
    }
    & .carousel__next {
      @apply rounded-br-none
    }
  }
  &__prev {
    @apply rounded-r-[12px]
  }
  &__next {
    @apply rounded-l-[12px]
  }
  &__prev, &__next {
    @apply h-[35%] bg-[#111425]/30 mx-0 backdrop-blur-lg;
  }
  &__icon {
    @apply text-white w-[24px] h-[24px]
  }
  ol {
    max-height: 100%;
  }
  &__viewport {
    display: flex;
    height: 100%;
  }
}
</style>
