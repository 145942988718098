import { FLATS_SORT } from '~/modules/building/constants/Flat.sort'
import { FlatsSortOption } from '~/modules/building/types/Flat.sortOptions'

export const FLATS_SORT_OPTIONS: ComputedRef<Array<FlatsSortOption>> = computed(() => {
  const { t } = useI18n()

  return [
    {
      sortGroupLabel: t('entityActions.sort.options.price.label'),
      sortGroupIcon: 'entities/ruble-sign',
      options: [
        {
          value: FLATS_SORT.ascPrice,
          icon: 'arrow-down-short-wide',
          label: t('entityActions.sort.options.price.order.ascending'),
        },
        {
          value: FLATS_SORT.descPrice,
          icon: 'arrow-down-wide-short',
          label: t('entityActions.sort.options.price.order.descending'),
        },
      ],
    },
    {
      sortGroupLabel: t('entityActions.sort.options.area.label'),
      sortGroupIcon: 'entities/area-sign',
      options: [
        {
          value: FLATS_SORT.descArea,
          icon: 'arrow-down-wide-short',
          label: t('entityActions.sort.options.area.order.descending'),
        },
        {
          value: FLATS_SORT.ascArea,
          icon: 'arrow-down-short-wide',
          label: t('entityActions.sort.options.area.order.ascending'),
        },
      ],
    },
    {
      sortGroupLabel: t('entityActions.sort.options.floor.label'),
      sortGroupIcon: 'entities/layers',
      options: [
        {
          value: FLATS_SORT.descFloor,
          icon: 'arrow-down-wide-short',
          label: t('entityActions.sort.options.floor.order.descending'),
        },
        {
          value: FLATS_SORT.ascFloor,
          icon: 'arrow-down-short-wide',
          label: t('entityActions.sort.options.floor.order.ascending'),
        },
      ],
    },
    {
      sortGroupLabel: t('entityActions.sort.options.percentage.label'),
      sortGroupIcon: 'fire',
      options: [
        {
          value: FLATS_SORT.descAgentMotivation,
          icon: 'arrow-down-wide-short',
          label: t('entityActions.sort.options.percentage.order.descending'),
        },
        {
          value: FLATS_SORT.ascAgentMotivation,
          icon: 'arrow-down-short-wide',
          label: t('entityActions.sort.options.percentage.order.ascending'),
        },
      ],
    },
  ]
})
