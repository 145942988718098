<template>
  <custom-file-input
    v-model:files="docs"
    multiple
    :button-title="$t('clients.booking.form.deal.uploadButton')"
  >
    <template #fileName="{file}">
      <div class="flex items-center gap-x-2">
        <nuxt-icon
          name="common/file"
          class="text-[20px] stroke-0"
        />
        <h3 class="text-base">
          {{ file.name }}
        </h3>
      </div>
    </template>
  </custom-file-input>
</template>

<script setup lang="ts">
import CustomFileInput from '~/ui/inputs/CustomFileInput.vue'

const docs = ref([])

const handleUploadFiles = (files: FileList) => {
  // add api
}
</script>
