<template>
  <div class="flex gap-2">
    <building-action-btn-add-to-suggestions :building="building" />
    <building-action-btn-copy-presentation-link :building="building" />
    <building-action-btn-open-chessboard :building="building" />
    <building-action-btn-book :building="building" />
    <building-action-btn-client-fixation :building="building" />
    <template v-if="!(windowWidth > 1280 && windowWidth <= 1535) && !isMd">
      <building-action-btn-construction-progress :building="building" />
      <building-action-btn-display-award :building="building" />
      <building-action-btn-add-to-favorite :building="building" />
      <building-action-btn-add-to-comparison :building="building" />
    </template>
    <template v-else>
      <custom-dropdown
        variant="withoutBorders"
        :display-dropdown-arrow="false"
        :close-on-select="false"
        button-class="!p-0"
      >
        <template #trigger="{toggle}">
          <custom-tooltip placement="bottom">
            <template #content>
              {{ t('common.more') }}
            </template>
            <custom-button-icon
              icon="common/three-dots"
              icon-class="text-[2.1rem]"
              color="secondary"
              class="p-0"
              round
              @click="toggle"
            />
          </custom-tooltip>
        </template>
        <div class="flex gap-2">
          <building-action-btn-construction-progress :building="building" />
          <building-action-btn-display-award :building="building" />
          <building-action-btn-add-to-favorite :building="building" />
          <building-action-btn-add-to-comparison :building="building" />
        </div>
      </custom-dropdown>
    </template>
  </div>
</template>

<script setup lang="ts">

import BuildingActionBtnAddToSuggestions
  from '~/modules/building/components/actions/BuildingActionBtnAddToSuggestions.vue'
import BuildingActionBtnCopyPresentationLink
  from '~/modules/building/components/actions/BuildingActionBtnCopyPresentationLink.vue'
import BuildingActionBtnBook from '~/modules/building/components/actions/BuildingActionBtnBook.vue'
import BuildingActionBtnClientFixation from '~/modules/building/components/actions/BuildingActionBtnClientFixation.vue'
import BuildingActionBtnDisplayAward from '~/modules/building/components/actions/BuildingActionBtnDisplayAward.vue'
import BuildingActionBtnAddToFavorite from '~/modules/building/components/actions/BuildingActionBtnAddToFavorite.vue'
import BuildingActionBtnAddToComparison
  from '~/modules/building/components/actions/BuildingActionBtnAddToComparison.vue'
import CustomDropdown from '~/ui/dropdowns/CustomDropdown.vue'
import BuildingActionBtnOpenChessboard from '~/modules/building/components/actions/BuildingActionBtnOpenChessboard.vue'
import { Building } from '~/modules/building/types/Building'
import BuildingActionBtnConstructionProgress
  from '~/modules/building/components/actions/BuildingActionBtnConstructionProgress.vue'
import { useWindowSize } from '@vueuse/core'
import CustomTooltip from '~/ui/tooltips/CustomTooltip.vue'
import CustomButtonIcon from '~/ui/buttons/CustomButtonIcon.vue'

defineProps({
  building: {
    type: Object as PropType<Building>,
    required: true,
  },
  isDisplayOnMapShow: {
    type: Boolean,
    default: true,
  },
})

const { t } = useI18n()

const { width: windowWidth } = useWindowSize()

const isMd = ref(false)

watchEffect(() => {
  isMd.value = useBreakpoints().smallerOrEqual('lg').value
})

</script>
