<script lang="ts" setup>
import CustomRange from '@/ui/ranges/CustomRange.vue'
import {CURRENCY} from '~/common/constants/money/Currency'
import {Currency} from '~/common/types/money/Currency'
import BankMortgageInputBtnFastSet from '~/modules/bank/components/mortgage/BankMortgageInputBtnFastSet.vue'
import {MORTGAGE_DISPLAYING_TYPE} from '~/modules/bank/constants/Mortgage.displayingType'
import {MortgageDisplayingType} from '~/modules/bank/types/Mortgage.displayingType'

const props = defineProps({
  modelValue: {
    type: Number,
    default: null,
  },
  currency: {
    type: String as PropType<Currency>,
    default: CURRENCY.usd,
  },
  fullPrice: {
    type: Number,
    default: 0,
  },
  displayingType: {
    type: String as PropType<MortgageDisplayingType>,
    default: MORTGAGE_DISPLAYING_TYPE.page,
  },
})

const emits = defineEmits<{ (e: 'update:modelValue', value: number): void }>()

const fastSetButtons = [5, 10, 25, 50]

const maxPrice = 100 * 1000 * 1000

const initialPayment = computed({
  get: () => props.modelValue,
  set: value => {
    emits('update:modelValue', value)
  },
})

const setPersentageInitialPayment = (num: number) => {
  initialPayment.value = Math.round(Number(props.fullPrice) * num / 100)
}
</script>

<template>
  <custom-range
    v-model="initialPayment"
    :max="maxPrice"
    :label="displayingType === MORTGAGE_DISPLAYING_TYPE.page ? $t('mortgage.calculator.inputs.initialPayment.label.short') : $t('mortgage.calculator.inputs.initialPayment.label.full')"
    with-formating-input
  >
    <template #suffixInner>
      <span class="text-neutral-500 font-medium inter">
        {{ $t(`currency.options.${currency}`) }}
      </span>
    </template>
    <template #labelSuffix>
      <div class="flex items-center">
        <template
          v-for="(fastSetButton, inx) in fastSetButtons"
          :key="fastSetButton"
        >
          <span
            v-if="inx > 0"
            class="mx-3 inter font-semibold text-sm text-neutral-500"
          >
            /
          </span>
          <bank-mortgage-input-btn-fast-set
            @click="setPersentageInitialPayment(fastSetButton)"
          >
            {{ fastSetButton }}%
          </bank-mortgage-input-btn-fast-set>
        </template>
      </div>
    </template>
  </custom-range>
</template>
