<template>
  <div
    v-if="isMd"
    class="grid accordion-header-grid"
  >
    <h4 class="font-semibold text-base text-neutral-900">
      <slot name="title">
        {{
          flatsInfo.flatRoomsCount ? $t(`building.flatsInfo.flatRoomsCountBuildingCard.${flatsInfo.flatRoomsCount}`) : $t(`building.flatsInfo.flatRoomsCountBuildingCard.notIndicated.${isMd ? 'full' : 'short'}`)
        }}
      </slot>
    </h4>
    <h4 class="font-semibold text-base text-neutral-900">
      <slot name="area">
        {{ getAreaInterval() }}
      </slot>
    </h4>
    <h4 class="font-semibold text-base text-neutral-900">
      <slot name="price">
        {{ getPriceInterval() }}
      </slot>
    </h4>
    <h4 class="font-semibold text-base text-neutral-900">
      {{ $t('entityActions.search.flat', {count: flatsInfo.count}) }}
    </h4>
  </div>
  <div
    v-else
    class="flex flex-col gap-y-3"
  >
    <div class="flex justify-between">
      <h4 class="font-semibold text-base text-neutral-900">
        <slot name="title">
          {{
            flatsInfo.flatRoomsCount ? $t(`building.flatsInfo.flatRoomsCountBuildingCard.${flatsInfo.flatRoomsCount}`) : $t(`building.flatsInfo.flatRoomsCountBuildingCard.notIndicated.${isMd ? 'full' : 'short'}`)
          }}
        </slot>
      </h4>
      <h4 class="font-semibold text-base text-neutral-900">
        {{ $t('entityActions.search.flat', {count: flatsInfo.count}) }}
      </h4>
    </div>
    <div class="flex justify-between">
      <h4 class="font-semibold text-xs text-neutral-900">
        <slot name="price">
          {{ getPriceInterval() }}
        </slot>
      </h4>
      <h4 class="font-semibold text-xs text-neutral-900">
        <slot name="area">
          {{ getAreaInterval() }}
        </slot>
      </h4>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { HouseFlatsInfo } from '~/modules/house/types/HouseFlatsInfo'
import { useAppStateStore } from '~/store/app'

const props = defineProps({
  flatsInfo: {
    type: Object as PropType<HouseFlatsInfo>,
    required: true,
  },
})

const { locale, t } = useI18n()

const isMd = computed(() => useAppStateStore().breakpoints.isMd)

const getAreaInterval = () => `${t('common.from')} ${props.flatsInfo.totalArea.from} ${t('common.to')} ${props.flatsInfo.totalArea.to} ${t('common.unitsOfMeasurement.options.squareMeter')}`

const getPriceInterval = () => {
  const intl = new Intl.NumberFormat(locale.value, { notation: 'compact' })
  let fromCompact = intl.format(props.flatsInfo.price.from)
  const toCompact = intl.format(props.flatsInfo.price.to)
  const fromCompactWord = fromCompact.match(/\D/g)
  const toCompactWord = toCompact.match(/\D/g)
  if (fromCompactWord && toCompactWord && fromCompactWord.join('') === toCompactWord.join('')) {
    fromCompact = (fromCompact.match(/\d/g) as RegExpMatchArray).join('')
  }
  return `${t('common.from')} ${fromCompact} ${t('common.to')} ${toCompact} ${t(`currency.options.${props.flatsInfo.price.currency}`)}`
}
</script>
