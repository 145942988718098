import { HttpApiService } from '~/services/http/HttpApiService'
import { HttpHandledResponse } from '~/services/http/types/Http.handledResponse'
import { Service } from '~/services/http/Service'
import type { Client } from '~/modules/clients/types/Client'
import { ClientFixation } from '~/modules/clients/types/ClientFixation'
import { ClientBooking } from '~/modules/clients/types/ClientBooking'
import { ClientBookingForRequest } from '~/modules/clients/types/ClientBooking.forRequest'
import { ClientFixationForRequest } from '~/modules/clients/types/ClientFixation.forRequest'
import { MarketEntitiesSearchResponse } from '~/modules/market-entities-actions/types/search/Search.response'
import { ClientsFiltersOptions } from '~/modules/clients/types/Clients.filtersOptions'
import { ClientsFilters } from '~/modules/clients/types/Clients.filters'
import { ClientView } from '~/modules/clients/types/ClientView'
import { User } from '~/modules/user/types/user'

export class ClientsHttpApiService extends Service {
  private endPoints = {
    initPart: '/v4/estate-market',
    firstPart: '/v4/estate-market/development/clients',
    fixations: '/fixations',
    bookings: '/bookings',
    views: '/viewings',
    search: '/search',
    suggestions: '/query-suggestions',
  }

  constructor(httpInstance: HttpApiService) {
    super(httpInstance)
  }

  suggest(query: string, limit: number, options: ClientsFiltersOptions[]): Promise<HttpHandledResponse<any>> {
    return this.httpInstance.post(`${this.endPoints.initPart}${this.endPoints.suggestions}`, {
      query, limit, options,
    })
  }

  getClients(page: number, query?: string): Promise<HttpHandledResponse<MarketEntitiesSearchResponse<Client>>> {
    return this.httpInstance.post(`${this.endPoints.firstPart}${this.endPoints.search}`, {
      page,
      namePhoneOrEmail: query,
    })
  }

  getFixationsClients(page: number, filter: ClientsFilters | null): Promise<HttpHandledResponse<MarketEntitiesSearchResponse<ClientFixation>>> {
    return this.httpInstance.post(`${this.endPoints.firstPart}${this.endPoints.fixations}${this.endPoints.search}`, {
      page,
      ...filter,
    })
  }

  getBookingsClients(page: number, filter: ClientsFilters | null): Promise<HttpHandledResponse<MarketEntitiesSearchResponse<ClientBooking>>> {
    return this.httpInstance.post(`${this.endPoints.firstPart}${this.endPoints.bookings}${this.endPoints.search}`, {
      page,
      ...filter,
    })
  }

  getViewsClients(page: number, filter: ClientsFilters | null): Promise<HttpHandledResponse<MarketEntitiesSearchResponse<ClientView>>> {
    return this.httpInstance.post(`${this.endPoints.firstPart}${this.endPoints.views}${this.endPoints.search}`, {
      page,
      ...filter,
    })
  }

  createClient(fullName: string, phone: string, email: string, assigned: User): Promise<HttpHandledResponse<Client>> {
    return this.httpInstance.post(`${this.endPoints.firstPart}`, {
      fullName,
      phone,
      email,
      assigned,
    })
  }

  getClient(clientId: number): Promise<HttpHandledResponse<Client>> {
    return this.httpInstance.get(`${this.endPoints.firstPart}/${clientId}`)
  }

  editClient(clientId: number, fullName: string, phone: string, email: string, assigned: User): Promise<HttpHandledResponse<Client>> {
    return this.httpInstance.put(`${this.endPoints.firstPart}/${clientId}`, {
      fullName,
      phone,
      email,
      assigned,
    })
  }

  deleteClient(clientId: number): Promise<HttpHandledResponse<void>> {
    return this.httpInstance.delete(`${this.endPoints.firstPart}/${clientId}`)
  }

  fixClient(fixation: ClientFixationForRequest): Promise<HttpHandledResponse<ClientFixation>> {
    return this.httpInstance.post(`${this.endPoints.firstPart}${this.endPoints.fixations}`, fixation)
  }

  updateFixationClient(fixationId: number, updatedFixation: ClientFixationForRequest): Promise<HttpHandledResponse<ClientFixation>> {
    return this.httpInstance.put(`${this.endPoints.firstPart}${this.endPoints.fixations}/${fixationId}`, updatedFixation)
  }

  bookClient(booking: ClientBookingForRequest): Promise<HttpHandledResponse<ClientBooking>> {
    return this.httpInstance.post(`${this.endPoints.firstPart}${this.endPoints.bookings}`, booking)
  }

  updateBookingClient(bookingId: number, updatedBooking: ClientBookingForRequest): Promise<HttpHandledResponse<ClientBooking>> {
    return this.httpInstance.put(`${this.endPoints.firstPart}${this.endPoints.bookings}/${bookingId}`, updatedBooking)
  }

  createView(payload: ClientView): Promise<HttpHandledResponse<ClientView>> {
    return this.httpInstance.post(`${this.endPoints.firstPart}${this.endPoints.views}`, {
      ...payload,
    })
  }

  updateView(payload: ClientView): Promise<HttpHandledResponse<ClientView>> {
    return this.httpInstance.put(`${this.endPoints.firstPart}${this.endPoints.views}/${payload.id}`, {
      ...payload,
    })
  }
}
