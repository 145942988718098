<template>
  <h3 class="fort-semibold text-[22px]">
    {{ $t('auth.organisation.fired.title') }}
  </h3>
  <custom-toast
    class="mt-6"
    :toast-title="$t('auth.organisation.fired.error')"
    variant="danger"
    :closable="false"
  />
  <custom-button
    pill
    class="mt-6 w-full font-semibold"
    variant="filled"
    :disabled="isFetching"
    @click="createOrganisation"
  >
    {{ $t('auth.organisation.fired.create') }}
  </custom-button>
</template>

<script setup lang="ts">
import CustomToast from '~/ui/toasts/CustomToast.vue'
import CustomButton from '~/ui/buttons/CustomButton.vue'
import { AuthService } from '~/modules/auth/AuthModule'
import useOrganisationStore from '~/modules/organisation/store'

const { t } = useI18n()
const authService = new AuthService()
const store = useOrganisationStore()

const emits = defineEmits<{(e: 'setUserData'): void}>()

const isFetching = ref(false)

const createOrganisation = () => {
  isFetching.value = true
  authService.createOrganisation()
    .then(organisation => {
      store.setOrganisation(organisation)
      emits('setUserData')
    })
    .catch(() => {
      toast(t('auth.organisation.actions.createOrganisation.error'), { variant: 'danger' })
    })
    .finally(() => {
      isFetching.value = false
    })
}
</script>
