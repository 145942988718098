import plugin_vue3_A0OWXRrUgq from "/var/www/quick-deal/qd.estate-market/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/var/www/quick-deal/qd.estate-market/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/var/www/quick-deal/qd.estate-market/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/var/www/quick-deal/qd.estate-market/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_5tzzN0oIVL from "/var/www/quick-deal/qd.estate-market/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import composition_sLxaNGmlSL from "/var/www/quick-deal/qd.estate-market/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_yfWm7jX06p from "/var/www/quick-deal/qd.estate-market/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/var/www/quick-deal/qd.estate-market/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _03_fontawesome_Z3uI7BzGwI from "/var/www/quick-deal/qd.estate-market/plugins/03.fontawesome.ts";
import _05_veeValidateRules_Ndv6g8aW34 from "/var/www/quick-deal/qd.estate-market/plugins/05.veeValidateRules.ts";
import _04_sentry_client_tuXFV7LSkY from "/var/www/quick-deal/qd.estate-market/plugins/04.sentry.client.ts";
import _01_qdHttpApiInstance_nG5CPnOY2e from "/var/www/quick-deal/qd.estate-market/plugins/01.qdHttpApiInstance.ts";
import _02_httpInterceptors_w2WdgsF0qH from "/var/www/quick-deal/qd.estate-market/plugins/02.httpInterceptors.ts";
import _06_initAppStorage_LXHKuc5jKB from "/var/www/quick-deal/qd.estate-market/plugins/06.initAppStorage.ts";
import _07_storeDefaultValuesSetter_HBiFZgXEhF from "/var/www/quick-deal/qd.estate-market/plugins/07.storeDefaultValuesSetter.ts";
import _08_apexCharts_client_1ymxkQm7KJ from "/var/www/quick-deal/qd.estate-market/plugins/08.apexCharts.client.ts";
import _09_quillEditor_client_NxN6TBOlvi from "/var/www/quick-deal/qd.estate-market/plugins/09.quillEditor.client.ts";
import _10_yandexMap_client_1FTsExE9S7 from "/var/www/quick-deal/qd.estate-market/plugins/10.yandexMap.client.ts";
export default [
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  prefetch_client_5tzzN0oIVL,
  composition_sLxaNGmlSL,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  _03_fontawesome_Z3uI7BzGwI,
  _05_veeValidateRules_Ndv6g8aW34,
  _04_sentry_client_tuXFV7LSkY,
  _01_qdHttpApiInstance_nG5CPnOY2e,
  _02_httpInterceptors_w2WdgsF0qH,
  _06_initAppStorage_LXHKuc5jKB,
  _07_storeDefaultValuesSetter_HBiFZgXEhF,
  _08_apexCharts_client_1ymxkQm7KJ,
  _09_quillEditor_client_NxN6TBOlvi,
  _10_yandexMap_client_1FTsExE9S7
]