import { defineRule } from 'vee-validate'
import { email, min_value } from '@vee-validate/rules'

// TODO вынести кастомные правила в отдельный файл. Переписать проверку номера телефона с выбором кода страны
const phoneRegex = /^[+]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/
const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/
const isUserEmail: Parameters<typeof defineRule>[1] = (value: any) => {
  const { $i18n } = useNuxtApp()
  if (!value) {
    return true
  }
  if (emailRegex.test(value)) {
    return true
  }
  return $i18n.t('auth.userEmail.error')
}
const isUserPhone: Parameters<typeof defineRule>[1] = (value: any) => {
  const { $i18n } = useNuxtApp()
  if (phoneRegex.test(value)) {
    return true
  }
  return $i18n.t('auth.userPhone.error')
}
const isUserLogin = (value: any): boolean | string => {
  const { $i18n } = useNuxtApp()
  // Проверка, соответствует ли значение строке номера телефона или адреса электронной почты
  if (phoneRegex.test(value) || emailRegex.test(value)) {
    return true
  }
  return $i18n.t('auth.username.error')
}
const customRequired = (value: string | undefined, _: never, ctx: any): string | boolean => {
  const { $i18n } = useNuxtApp()
  if (!value) {
    return $i18n.t('validation.messages.required', { label: ctx.label })
  }
  return true
}

const requiredIf = (value: unknown, condition: [(value: any) => boolean], ctx: any) => (condition[0](ctx.form) ? customRequired(value, null as never, ctx) : true)
const customMin = (value: string | undefined, params: [number], ctx: any): string | boolean => {
  const { $i18n } = useNuxtApp()
  if (value && value.length < params[0]) {
    return $i18n.t('validation.messages.min', { label: ctx.label, length: params[0] })
  }
  return true
}
export const customMinValue = (value: string | undefined, params: number, ctx: any) => {
  const { $i18n } = useNuxtApp()
  return min_value(value, [params]) || $i18n.t('validation.messages.minValue', { label: ctx.label, min: params[0] })
}
const customMax = (value: string | undefined, params: [number], ctx: any): string | boolean => {
  const { $i18n } = useNuxtApp()
  if (value && value.length > params[0]) {
    return $i18n.t('validation.messages.max', { label: ctx.label, length: params[0] })
  }
  return true
}
const isURL = (value: unknown, _: never, ctx: any) => {
  const { $i18n } = useNuxtApp()
  const urlRegex = /^(http[s]?:\/\/)(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/

  if (!value) {
    return true
  }
  if (urlRegex.test(value)) {
    return true
  }

  return $i18n.t('validation.messages.url', { label: ctx.label })
}
const isURLWithoutProtocol = (value: unknown, _: never, ctx: any) => {
  const { $i18n } = useNuxtApp()
  const urlRegex = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/

  if (!value) {
    return true
  }
  if (urlRegex.test(value)) {
    return true
  }

  return $i18n.t('validation.messages.url', { label: ctx.label })
}
export default defineNuxtPlugin(nuxtApp => {
  defineRule('required', customRequired)
  defineRule('min', customMin)
  defineRule('minValue', customMinValue)
  defineRule('max', customMax)
  defineRule('email', email)
  defineRule('userLogin', isUserLogin)
  defineRule('userEmail', isUserEmail)
  defineRule('userPhone', isUserPhone)
  defineRule('url', isURL)
  defineRule('urlWithoutProtocol', isURLWithoutProtocol)
  defineRule('required_if', requiredIf)
})

// defineRule('required', (value: any) => {
//   if (!value || !value.length) {
//     return 'This field is required'
//   }
//   return true
// })
// defineRule('email', (value: any) => {
//   // Field is empty, should pass
//   if (!value || !value.length) {
//     return true
//   }
//
//   // Check if email
//   if (yup.string().email().test(value)) {
//     return 'This field must be a valid email'
//   }
//   return true
// })
