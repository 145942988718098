import { defineStore } from 'pinia'

type ChangeListState = {
  unreadChangeListCount: number | null
  nextPageChangeListUrl: string | null
}
export const useChangeListStore = defineStore('changeListStore', {
  state: () => ({
    unreadChangeListCount: null,
    nextPageChangeListUrl: null,
  }) as ChangeListState,
  actions: {
    setUnreadChangeListCount(count: number): void {
      this.unreadChangeListCount = count
    },
    setNextPageChangeListUrl(url: string | null): void {
      this.nextPageChangeListUrl = url
    },
  },
})
