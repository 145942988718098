import { defineStore } from 'pinia'
import {ModalFormDisplayType} from '~/modules/auth/types/Auth.modalFormDisplayType'
import {MODAL_FORM_DISPLAY_TYPE} from '~/modules/auth/constants/Auth.modalFormDisplayType'

type AuthState = {
  invitationSecret: string
  isInvitationSecretChecked: boolean,
  invitationOrganisation: string
  isDisplayAuthModal: boolean
  authModalFormType: ModalFormDisplayType
}
export const useAuthStore = defineStore('authStore', {
  state: () => ({
    invitationSecret: '',
    isInvitationSecretChecked: false,
    invitationOrganisation: '',
    isDisplayAuthModal: false,
    authModalFormType: MODAL_FORM_DISPLAY_TYPE.login,
  }) as AuthState,
  actions: {
    setInvitationSecret(invitationSecret: string):void {
      this.invitationSecret = invitationSecret
    },
    setIsInvitationSecretChecked(value: boolean):void {
      this.isInvitationSecretChecked = value
    },
    setInvitationOrganisation(name: string):void {
      this.invitationOrganisation = name
    },
    setIsDisplayAuthModal(value: boolean):void {
      this.isDisplayAuthModal = value
    },
    setAuthModalFormType(value: ModalFormDisplayType): void {
      this.authModalFormType = value
    },
  },
})
