<template>
  <custom-dropdown
    color="secondary"
    :button-class="[{'!bg-blue-100': countSelectedOptions}, 'w-full font-semibold']"
    :close-on-select="false"
  >
    <template #triggerContent>
      <filter-counter v-if="countSelectedOptions">
        {{ countSelectedOptions }}
      </filter-counter>
      {{ triggerText }}
    </template>
    <filter-from-to
      v-model:from="floorFilterFrom"
      v-model:to="floorFilterTo"
      :placeholder-from="$t('common.from')"
      :placeholder-to="$t('common.to')"
    />
  </custom-dropdown>
</template>
<script setup lang="ts">
import CustomDropdown from '~/ui/dropdowns/CustomDropdown.vue'
import FilterFromTo from '~/components/filters/from-to/FilterFromTo.vue'
import type { FromTo } from '~/common/types/filters/from-to/FromTo'
import type { FlatsFilter } from '~/modules/building/components/page/flats/filter/types/FlatsFilter'
import FilterCounter from '~/components/filters/FilterCounter.vue'

const props = defineProps({
  from: {
    type: [Number, String] as PropType<FromTo['from']>,
    default: null,
  },
  to: {
    type: [Number, String] as PropType<FromTo['to']>,
    default: null,
  },
  triggerText: {
    type: String,
    default: '',
  },
})
const emits = defineEmits<{(e: 'update:from', value: FlatsFilter['price']['from']): void,
  (e: 'update:to', value: FlatsFilter['price']['to']): void
}>()

const floorFilterFrom = computed({
  get: () => props.from,
  set: value => {
    emits('update:from', value)
  },
})

const floorFilterTo = computed({
  get: () => props.to,
  set: value => {
    emits('update:to', value)
  },
})

const countSelectedOptions = computed(() => {
  if (floorFilterFrom.value && floorFilterTo.value) {
    return 2
  }
  if (floorFilterFrom.value || floorFilterTo.value) {
    return 1
  }
  return 0
})

</script>
