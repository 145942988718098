<template>
  <custom-dropdown
    button-class="!border-primary-light h-[38px] px-4 flex items-center"
    size="none"
  >
    <template #triggerContent>
      <nuxt-icon
        :name="selectedSortOption.sortGroupIcon"
        class="mr-[6px] stroke-0"
      />
      <client-only>
        <font-awesome-icon
          :icon="selectedSortOption.orderIcon"
        />
      </client-only>
      <span class="font-semibold ml-[10px] hidden md:inline">
        {{ selectedSortOption.sortGroupLabel }}
      </span>
    </template>
    <custom-list-group
      v-for="option in FLATS_SORT_OPTIONS"
      :key="option.sortGroupLabel"
      without-borders
      class="w-full"
    >
      <h5 class="text-primary text-base font-semibold flex items-center">
        <nuxt-icon
          :name="option.sortGroupIcon"
          class="mr-3 stroke-0"
        />
        {{ option.sortGroupLabel }}
      </h5>

      <custom-list-group-item
        v-for="(order, id) in option.options"
        :key="id"
        class="text-primary"
        :active="sortOption === order.value"
        without-borders
        @click="setSortOption(order.value)"
      >
        <template #prefix>
          <client-only>
            <font-awesome-icon
              :icon="order.icon"
              class="mr-2"
            />
          </client-only>
        </template>
        <span class="text-base">
          {{ order.label }}
        </span>
      </custom-list-group-item>
    </custom-list-group>
  </custom-dropdown>
</template>

<script setup lang="ts">
import CustomDropdown from '~/ui/dropdowns/CustomDropdown.vue'
import CustomListGroup from '~/ui/list-group/CustomListGroup.vue'
import CustomListGroupItem from '~/ui/list-group/CustomListGroupItem.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { FLATS_SORT_OPTIONS } from '~/modules/building/constants/Flat.sortOptions'
import { FlatsSort } from '~/modules/building/types/Flat.sort'

type SelectedSortOption = {
  sortGroupIcon: string
  sortGroupLabel: string
  orderIcon: string
}

const props = defineProps({
  sortOption: {
    type: String as PropType<FlatsSort>,
    required: true,
  },
})

const emits = defineEmits<{(e: 'update:sortOption', value: FlatsSort): void}>()

const selectedSortOption: ComputedRef<SelectedSortOption> = computed(() => {
  const selectedOption: SelectedSortOption = {
    sortGroupIcon: '',
    sortGroupLabel: '',
    orderIcon: '',
  }

  FLATS_SORT_OPTIONS.value.forEach(option => {
    option.options.forEach(order => {
      if (order.value === props.sortOption) {
        selectedOption.orderIcon = order.icon
        selectedOption.sortGroupLabel = option.sortGroupLabel
        selectedOption.sortGroupIcon = option.sortGroupIcon
      }
    })
  })

  return selectedOption
})
const setSortOption = (value: FlatsSort): void => {
  emits('update:sortOption', value)
}
</script>
