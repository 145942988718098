<template>
  <custom-input
    v-model="value"
    :label="label"
    :required="true"
    :invalid-feedback="errorMessage"
    :state="errorMessage ? false : null"
    :placeholder="$t('auth.name.placeholder')"
    :disabled="disabled"
  />
</template>

<script setup lang="ts">
import { useField } from 'vee-validate'
import CustomInput from '~/ui/inputs/CustomInput.vue'

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: undefined,
  },
  errors: {
    type: Array as PropType<Array<string>>,
    default: () => [],
  },
})

const { value, errorMessage, setErrors } = useField('name' as string, { required: true, min: 1, max: 30 }, {
  label: props.label,
})

watchEffect(() => {
  setErrors(props.errors)
})
</script>
