<template>
  <custom-tooltip placement="bottom">
    <template #content>
      {{ $t('building.actions.booking.label') }}
    </template>
    <custom-button-icon
      icon="common/calendar-check"
      color="secondary"
      round
      @click="showAuthModalOrCallback(openChessboard)"
    />
  </custom-tooltip>
  <modal-or-bottom-sheet
    v-model="isChessboardOpen"
    :drawer-props="{fullHeight: true, contentClass: 'max-h-full pb-[60px]', bodyClass: '!overflow-hidden'}"
    :modal-props="{hideFooter: true, hideHeader: true, size: 'fullscreen', contentClass: '!bg-neutral-100'}"
  >
    <building-chessboard
      v-if="isChessboardOpen"
      v-model:filter="filter"
      :building="building"
    >
      <template #closeButton>
        <custom-button
          class="text-black bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg self-start"
          size="none"
          color="none"
          @click="closeChessboard"
        >
          <nuxt-icon
            name="actions/close"
            class="text-[2rem]"
          />
        </custom-button>
      </template>
    </building-chessboard>
  </modal-or-bottom-sheet>
</template>

<script setup lang="ts">
import CustomTooltip from '~/ui/tooltips/CustomTooltip.vue'
import { Building } from '~/modules/building/types/Building'
import CustomButtonIcon from '~/ui/buttons/CustomButtonIcon.vue'
import { showAuthModalOrCallback } from '~/modules/auth/helpers/showAuthModalOrCallback'
import BuildingChessboard from '~/modules/building/components/building-chessboard/BuildingChessboard.vue'
import CustomButton from '~/ui/buttons/CustomButton.vue'
import ModalOrBottomSheet from '~/components/common/ModalOrBottomSheet.vue'
import { useMarketEntitiesActionsStore } from '~/modules/market-entities-actions/store'
import { BuildingFlatFilter } from '~/modules/building/components/page/flats/filter/types/BuildingFlatFilter'
import { ObjectHelper } from '~/common/helpers/object'
import { useClientsStore } from '~/modules/clients/store'

defineProps({
  building: {
    type: Object as PropType<Building>,
    required: true,
  },
})

const store = useMarketEntitiesActionsStore()

const clientsStore = useClientsStore()

const isChessboardOpen = ref(false)

const filter = computed<BuildingFlatFilter>({
  get: () => ({
    flatFilter: store.filter.flatFilter,
    houseFilter: {
      deadLine: store.filter.houseFilter.deadLine,
    },
  }),
  set: value => {
    const copiedFilter = ObjectHelper.copy(store.filter)
    copiedFilter.flatFilter = value.flatFilter
    copiedFilter.houseFilter.deadLine = value.houseFilter.deadLine

    store.setFilter(copiedFilter)
  },
})

const openChessboard = () => {
  isChessboardOpen.value = true
  clientsStore.chessboardOpen = true
}

const closeChessboard = () => {
  isChessboardOpen.value = false
  clientsStore.chessboardOpen = false
}

</script>
