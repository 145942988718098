<template>
  <div
    class="flex gap-x-3 items-center p-6 relative border-2 rounded-xl"
    :class="colorClasses"
  >
    <Suspense>
      <slot name="icon">
        <nuxt-icon
          class="text-[38px]"
          :name="`common/toast-${variant}`"
          filled
        />
      </slot>
    </Suspense>
    <div class="flex flex-col" :class="textContainerClass">
      <slot name="title">
        <h3 class="font-semibold text-neutral-900 text-xl">
          {{ toastTitle || $t(`toast.title.${variant}`) }}
        </h3>
      </slot>
      <slot>
        <p class="font-medium text-neutral-750 inter">
          {{ text }}
        </p>
      </slot>
    </div>
    <slot name="close">
      <Suspense v-if="closable">
        <nuxt-icon
          name="actions/close"
          class="cursor-pointer text-[20px]"
          @click="emits('close')"
        />
      </Suspense>
    </slot>
  </div>
</template>
<script setup lang="ts">
import type { Toast } from '~/ui/toasts/Toasts.types'

const props = defineProps({
  text: {
    type: String,
    default: '',
  },
  toastTitle: {
    type: String,
    default: '',
  },
  variant: {
    type: String as PropType<Toast['variant']>,
    default: 'info',
  },
  closable: {
    type: Boolean,
    default: true,
  },
  textContainerClass: {
    type: [String, Object, Array],
    default: '',
  },
})

const emits = defineEmits<{(e: 'close'): void}>()

const colorClasses = computed(() => {
  switch (props.variant) {
    case 'success':
      return 'bg-success-light border-success/50'
    case 'info':
      return 'bg-primary-light border-primary/50'
    case 'danger':
      return 'bg-danger-light border-danger/50'
    case 'warning':
      return 'bg-warning-light border-warning/50'
    default:
      return 'bg-success-light border-success/50'
  }
})
</script>
