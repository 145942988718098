<template>
  <custom-button-group
    v-model="displayingEntity"
    :is-adaptive="false"
    span-class="hidden lg:inline"
    :options="entityOptionsForGroup"
    size="none"
    button-class="flex font-semibold justify-center items-center px-4"
  />
</template>

<script setup lang="ts">
import CustomButtonGroup from '~/ui/buttons/CustomButtonGroup.vue'
import { FlatPriceStatisticsDisplayingRooms } from '~/common/types/flat/Flat.priceStatisticsDisplayingRooms'
import { FLAT_PRICE_STATISTICS_DISPLAYING_ROOMS } from '~/common/constants/flat/Flat.priceStatisticsDisplayingRooms'

const props = defineProps({
  modelValue: {
    type: String as PropType<FlatPriceStatisticsDisplayingRooms>,
    default: FLAT_PRICE_STATISTICS_DISPLAYING_ROOMS.all,
  },
})

const emits = defineEmits<{(e: 'update:modelValue', value: FlatPriceStatisticsDisplayingRooms): void
}>()

const { t } = useI18n()

const entityOptionsForGroup = computed(() => [
  {
    value: FLAT_PRICE_STATISTICS_DISPLAYING_ROOMS.all,
    label: t('flat.statistics.price.toggle.all'),
    icon: 'entities/developer',
    iconClass: 'text-[24px]',
    iconFilled: true,
  },
  {
    value: FLAT_PRICE_STATISTICS_DISPLAYING_ROOMS.studio,
    label: t('flat.statistics.price.toggle.studio'),
    icon: 'common/dot',
    iconClass: 'text-[24px] text-primary',
  },
  {
    value: FLAT_PRICE_STATISTICS_DISPLAYING_ROOMS.one,
    label: t('flat.statistics.price.toggle.one'),
    icon: 'common/dot',
    iconClass: 'text-[24px] text-warning',
  },
  {
    value: FLAT_PRICE_STATISTICS_DISPLAYING_ROOMS.two,
    label: t('flat.statistics.price.toggle.two'),
    icon: 'common/dot',
    iconClass: 'text-[24px] text-danger',
  },
  {
    value: FLAT_PRICE_STATISTICS_DISPLAYING_ROOMS.three,
    label: t('flat.statistics.price.toggle.three'),
    icon: 'common/dot',
    iconClass: 'text-[24px] text-success',
  },
])

const displayingEntity = computed({
  get() {
    return props.modelValue
  },
  set(value: FlatPriceStatisticsDisplayingRooms) {
    emits('update:modelValue', value)
  },
})
</script>
