import { Service } from '~/services/http/Service'
import type { HttpApiService } from '~/services/http/HttpApiService'
import type { User } from '~/modules/user/types/user'
import { UserFilter } from '~/modules/user/types/User.filter'
import { EntryUserData } from '~/modules/user/types/EntryUserData'
import { UserRemoveAccount } from '~/modules/user/types/User.removeAccount'

export class UsersHttpApiService extends Service {
  private endPoints = {
    firstPart: '/v4/estate-market',
    organisations: '/organisations',
    users: '/users',
    search: '/users/search',
    entryData: '/entry-data',
    changePassword: '/change-password',
    removeAccount: '/remove-account',
  }

  constructor(httpInstance: HttpApiService) {
    super(httpInstance)
  }

  getUsers(organisationId: number, filter: UserFilter, page: number) {
    return this.httpInstance.post<Array<User>>(`${this.endPoints.firstPart}/organisations/${organisationId}${this.endPoints.search}`, {
      ...filter,
      page,
    })
  }

  setUser(user: User) {
    return this.httpInstance.put<User>(`${this.endPoints.firstPart}${this.endPoints.users}/${user.id}`, user)
  }

  getUserEntryData(user: User) {
    return this.httpInstance.get<any>(`${this.endPoints.firstPart}${this.endPoints.users}/${user.id}${this.endPoints.entryData}`)
  }

  setUserEntryData(user: User, values: EntryUserData & { currentPassword: string }) {
    return this.httpInstance.put<any>(`${this.endPoints.firstPart}${this.endPoints.users}/${user.id}${this.endPoints.entryData}`, values)
  }

  changePassword(user: User, values: any) {
    return this.httpInstance.put<any>(`${this.endPoints.firstPart}${this.endPoints.users}/${user.id}${this.endPoints.changePassword}`, values)
  }

  getRemoveAccountInfo(userId: number) {
    return this.httpInstance.get<UserRemoveAccount>(`${this.endPoints.firstPart}${this.endPoints.users}/${userId}${this.endPoints.removeAccount}`)
  }

  removeAccount(userId: number, reason: string) {
    return this.httpInstance.post<UserRemoveAccount>(`${this.endPoints.firstPart}${this.endPoints.users}/${userId}${this.endPoints.removeAccount}`, { reason })
  }
}
