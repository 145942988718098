import { HttpApiService } from '~/services/http/HttpApiService'
import { HttpHandledResponse } from '~/services/http/types/Http.handledResponse'
import { Service } from '~/services/http/Service'
import { BuildingExpanded } from '~/modules/building/types/Building.expanded'
import { BuildingPresentation } from '~/modules/building/types/Building.presentation'
import { RecursivePartial } from '~/common/types/utils/RecursivePartial'
import { MarketEntitiesFilterForRequest } from '~/modules/market-entities-actions/types/filter/Filter.forSearchRequest'

export class BuildingHttpApiService extends Service {
  private endPoints = {
    firstPart: '/v4',
    building: '/estate-market/development/buildings',
    presentationBuilding: '/estate-market/guest/development/buildings',
    popularBuildings: '/estate-market/guest/development/buildings/search-popular',
  }

  constructor(httpInstance: HttpApiService) {
    super(httpInstance)
  }

  getBuilding(id: number): Promise<HttpHandledResponse<BuildingExpanded>> {
    return this.httpInstance.get(`${this.endPoints.firstPart}${this.endPoints.building}/${id}`)
  }

  getPresentationBuilding(buildingId: number | string): Promise<HttpHandledResponse<BuildingExpanded & {
    managers: BuildingPresentation[]
  }>> {
    return this.httpInstance.get(`${this.endPoints.firstPart}${this.endPoints.presentationBuilding}/${buildingId}`)
  }

  getPresentationBuildingWithManager(buildingId: number | string, managerId: string, organisationId?: string): Promise<HttpHandledResponse<BuildingExpanded & {
    managers: BuildingPresentation[]
  }>> {
    return this.httpInstance.get(`${this.endPoints.firstPart}${this.endPoints.presentationBuilding}/${buildingId}?managerId=${managerId}${organisationId ? `&organisationId=${organisationId}` : ''}`)
  }

  getPopularBuildings(filter: RecursivePartial<MarketEntitiesFilterForRequest>, signal?: AbortSignal): Promise<HttpHandledResponse<Array<BuildingExpanded>>> {
    return this.httpInstance.post(`${this.endPoints.firstPart}${this.endPoints.popularBuildings}`, filter, { signal })
  }
}
