<template>
  <div class="flex flex-wrap gap-3 w-f min-w-[300px]">
    <custom-button
      v-for="windowType of options"
      :key="windowType"
      pill
      size="xs"
      variant="outline"
      class="font-semibold !border-neutral-200 !text-black"
      :class="{'!border-primary !text-primary': selectedOptions.includes(windowType)}"
      @click="toggleSelected(windowType)"
    >
      {{ $t(`flat.windowViewType.options.${windowType}.label`) }}
    </custom-button>
  </div>
</template>
<script setup lang="ts">
import { defaultFilter } from '~/modules/market-entities-actions/constants/filter/Filter.default.ts'
import CustomButton from '~/ui/buttons/CustomButton.vue'
import { ArrayHelper } from '~/common/helpers/arrays'
import { WindowsViewType } from '~/common/types/windowsVIew/WindowsView.type'

const props = defineProps({
  modelValue: {
    type: Array as PropType<Array<WindowsViewType>>,
    default: () => defaultFilter().flatFilter.windowsViewTypes,
  },
})
const emits = defineEmits<{(e: 'update:modelValue', value: Array<WindowsViewType>): void }>()

const options: Array<WindowsViewType> = ['street', 'yard', 'mountains', 'yardAndStreet', 'sea']
const selectedOptions = computed({
  get: () => props.modelValue,
  set: value => emits('update:modelValue', value),
})

function toggleSelected(option: WindowsViewType) {
  const selectedOptionsCopy = [...selectedOptions.value]
  ArrayHelper.toggle(selectedOptionsCopy, option)
  selectedOptions.value = selectedOptionsCopy
}
</script>
