import { defaultFlatsFilter } from '~/modules/building/components/page/flats/filter/constants/FlatsFilter.default'
import { BuildingFlatFilter } from '~/modules/building/components/page/flats/filter/types/BuildingFlatFilter'

export const defaultBuildingFlatFilter = (): BuildingFlatFilter => ({
  flatFilter: defaultFlatsFilter(),
  houseFilter: {
    deadLine: {
      from: {
        quarter: null,
        year: null,
      },
      to: {
        quarter: null,
        year: null,
      },
    },
  },
})
