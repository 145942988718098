<template>
  <div
    v-if="store.presentation"
    class="flex gap-2 w-full justify-end"
    :class="{'md:flex-col xl:flex-row': store.flatsDisplayingEntity === FLATS_DISPLAYING_ENTITY.table}"
  >
    <flats-layout-btn-book
      v-if="store.presentation?.contactsSettings?.messengers?.whatsapp?.phoneNumber"
      :class="buttonClass"
      :layout="layout"
      :mobile-phone="store.presentation?.contactsSettings?.messengers?.whatsapp?.phoneNumber"
    />
    <flats-layout-btn-book-viewing
      v-if="store.presentation?.contactsSettings?.messengers?.whatsapp?.phoneNumber"
      :class="buttonClass"
      :layout="layout"
      :mobile-phone="store.presentation?.contactsSettings?.messengers?.whatsapp?.phoneNumber"
    />
  </div>
</template>
<script setup lang="ts">
import { FlatLayoutItem } from '~/common/types/flat/Flat.layoutItem'
import FlatsLayoutBtnBook from '~/components/flats/layout/buttons/presentation/FlatsLayoutBtnBook.vue'
import FlatsLayoutBtnBookViewing from '~/components/flats/layout/buttons/presentation/FlatsLayoutBtnBookViewing.vue'
import { FLATS_DISPLAYING_ENTITY } from '~/modules/building/components/page/flats/filter/constants/FlatsDisplaying.entity'
import { useBuildingStore } from '~/modules/building/store'
import { useAppStateStore } from '~/store/app'

const store = useBuildingStore()

const props = defineProps({
  layout: {
    type: Object as PropType<FlatLayoutItem>,
    required: true,
  },
  buttonClass: {
    type: [String, Object, Array],
    default: '',
  },
})

const isMd = computed(() => useAppStateStore().breakpoints.isMd)
const buttonClass = computed(() => {
  if (store.flatsDisplayingEntity === FLATS_DISPLAYING_ENTITY.table && isMd) {
    return 'bg-white shrink-0'
  }
  return props.buttonClass
})
</script>
