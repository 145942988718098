<template>
  <svg
    width="76"
    height="76"
    viewBox="0 0 76 76"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_5126_88670)">
      <circle
        class="west-circle"
        cx="6"
        cy="38"
        r="4"
        fill="#F4C31D"
      />
    </g>
    <g filter="url(#filter1_d_5126_88670)">
      <circle
        class="northwest-circle"
        cx="15.3726"
        cy="15.3726"
        r="4"
        transform="rotate(45 15.3726 15.3726)"
        fill="#F4C31D"
      />
    </g>
    <g filter="url(#filter2_d_5126_88670)">
      <circle
        class="north-circle"
        cx="38"
        cy="6"
        r="4"
        transform="rotate(90 38 6)"
        fill="#F4C31D"
      />
    </g>
    <g filter="url(#filter3_d_5126_88670)">
      <circle
        class="northeast-circle"
        cx="60.6273"
        cy="15.3726"
        r="4"
        transform="rotate(135 60.6273 15.3726)"
        fill="#F4C31D"
      />
    </g>
    <rect
      x="13"
      y="13"
      width="50"
      height="50"
      rx="25"
      fill="black"
    />
    <path
      d="M23.425 40.5398L23.8045 39.6815C24.0756 39.8682 24.3707 40.0128 24.69 40.1152C25.0153 40.2115 25.3375 40.2627 25.6568 40.2688C25.976 40.2748 26.2651 40.2386 26.5242 40.1603C26.7892 40.082 27 39.9646 27.1566 39.808C27.3132 39.6514 27.3915 39.4556 27.3915 39.2207C27.3915 38.9376 27.2741 38.7267 27.0392 38.5882C26.8103 38.4436 26.4971 38.3714 26.0995 38.3714H24.6267V37.4497H26.0091C26.3645 37.4497 26.6386 37.3775 26.8314 37.2329C27.0301 37.0883 27.1295 36.8926 27.1295 36.6456C27.1295 36.4408 27.0633 36.2721 26.9307 36.1396C26.8043 36.0071 26.6296 35.9077 26.4067 35.8415C26.1899 35.7752 25.9429 35.7451 25.6658 35.7511C25.3947 35.7511 25.1116 35.7933 24.8165 35.8776C24.5213 35.9559 24.2412 36.0734 23.9762 36.23L23.6058 35.2813C24.0033 35.0705 24.4189 34.9229 24.8526 34.8385C25.2923 34.7542 25.717 34.7301 26.1266 34.7663C26.5362 34.8024 26.9036 34.8958 27.2289 35.0464C27.5602 35.1969 27.8252 35.3957 28.024 35.6427C28.2228 35.8836 28.3222 36.1697 28.3222 36.501C28.3222 36.8022 28.2409 37.0703 28.0782 37.3052C27.9216 37.5341 27.7048 37.7118 27.4277 37.8383C27.1506 37.9648 26.8283 38.028 26.4609 38.028L26.5061 37.7389C26.9217 37.7389 27.2831 37.8112 27.5903 37.9557C27.9035 38.0943 28.1445 38.287 28.3132 38.534C28.4878 38.781 28.5752 39.0671 28.5752 39.3923C28.5752 39.6995 28.4969 39.9736 28.3403 40.2146C28.1836 40.4495 27.9698 40.6482 27.6987 40.8109C27.4277 40.9675 27.1175 41.085 26.7681 41.1633C26.4187 41.2355 26.0513 41.2626 25.6658 41.2446C25.2803 41.2265 24.8948 41.1602 24.5093 41.0458C24.1298 40.9314 23.7684 40.7627 23.425 40.5398Z"
      fill="white"
    />
    <path
      d="M47.2283 41.25V34.75H50.1347C50.8652 34.75 51.4347 34.8955 51.8433 35.1864C52.2518 35.4712 52.4561 35.8829 52.4561 36.4214C52.4561 36.9538 52.2611 37.3655 51.8711 37.6564C51.4811 37.9412 50.9673 38.0836 50.3297 38.0836L50.4968 37.7493C51.2211 37.7493 51.7814 37.8948 52.1776 38.1857C52.5737 38.4767 52.7718 38.8976 52.7718 39.4486C52.7718 40.0119 52.5645 40.4545 52.1497 40.7764C51.7349 41.0921 51.1221 41.25 50.3111 41.25H47.2283ZM48.4168 40.3214H50.274C50.6949 40.3214 51.0137 40.244 51.2304 40.0893C51.4471 39.9283 51.5554 39.6838 51.5554 39.3557C51.5554 39.0276 51.4471 38.7893 51.2304 38.6407C51.0137 38.486 50.6949 38.4086 50.274 38.4086H48.4168V40.3214ZM48.4168 37.5264H50.0326C50.4226 37.5264 50.7197 37.449 50.924 37.2943C51.1283 37.1333 51.2304 36.9043 51.2304 36.6071C51.2304 36.2976 51.1283 36.0655 50.924 35.9107C50.7197 35.756 50.4226 35.6786 50.0326 35.6786H48.4168V37.5264Z"
      fill="white"
    />
    <path
      d="M38.4333 29.25C37.9518 29.25 37.5034 29.1718 37.0881 29.0153C36.6789 28.8528 36.3208 28.6271 36.0138 28.3382C35.7129 28.0433 35.4782 27.6972 35.3097 27.3C35.1411 26.9028 35.0569 26.4694 35.0569 26C35.0569 25.5306 35.1411 25.0972 35.3097 24.7C35.4782 24.3028 35.7159 23.9597 36.0229 23.6708C36.3298 23.3759 36.6879 23.1502 37.0972 22.9938C37.5064 22.8313 37.9548 22.75 38.4423 22.75C38.9599 22.75 39.4323 22.8403 39.8597 23.0208C40.287 23.1954 40.6481 23.4572 40.943 23.8062L40.1847 24.5194C39.956 24.2727 39.7002 24.0891 39.4173 23.9687C39.1344 23.8424 38.8275 23.7792 38.4965 23.7792C38.1654 23.7792 37.8615 23.8333 37.5847 23.9417C37.3138 24.05 37.0761 24.2035 36.8715 24.4021C36.6729 24.6007 36.5164 24.8354 36.402 25.1063C36.2937 25.3771 36.2395 25.675 36.2395 26C36.2395 26.325 36.2937 26.6229 36.402 26.8937C36.5164 27.1646 36.6729 27.3993 36.8715 27.5979C37.0761 27.7965 37.3138 27.95 37.5847 28.0583C37.8615 28.1667 38.1654 28.2208 38.4965 28.2208C38.8275 28.2208 39.1344 28.1606 39.4173 28.0403C39.7002 27.9139 39.956 27.7243 40.1847 27.4715L40.943 28.1938C40.6481 28.5368 40.287 28.7986 39.8597 28.9792C39.4323 29.1597 38.9569 29.25 38.4333 29.25Z"
      fill="#FF513C"
    />
    <path
      d="M33.6667 53.1597V46.8403H34.8223V49.4313H36.4563V50.4785H34.8223V53.1597H33.6667ZM39.0654 53.25C38.5959 53.25 38.1626 53.1688 37.7654 53.0062C37.3681 52.8438 37.0221 52.6181 36.7272 52.3292C36.4323 52.0403 36.2005 51.6972 36.032 51.3C35.8695 50.9028 35.7883 50.4694 35.7883 50C35.7883 49.5306 35.8695 49.0972 36.032 48.7C36.2005 48.3028 36.4323 47.9597 36.7272 47.6708C37.0221 47.3819 37.3681 47.1563 37.7654 46.9938C38.1626 46.8313 38.5959 46.75 39.0654 46.75C39.5288 46.75 39.9591 46.8313 40.3563 46.9938C40.7596 47.1563 41.1086 47.3819 41.4036 47.6708C41.6985 47.9597 41.9272 48.3028 42.0897 48.7C42.2522 49.0972 42.3334 49.5306 42.3334 50C42.3334 50.4694 42.2522 50.9028 42.0897 51.3C41.9272 51.6972 41.6985 52.0403 41.4036 52.3292C41.1086 52.6181 40.7596 52.8438 40.3563 53.0062C39.9591 53.1688 39.5288 53.25 39.0654 53.25ZM39.0654 52.2208C39.3603 52.2208 39.6341 52.1667 39.8869 52.0583C40.1457 51.95 40.3684 51.7965 40.5549 51.5979C40.7415 51.3993 40.886 51.1676 40.9883 50.9028C41.0966 50.6319 41.1508 50.331 41.1508 50C41.1508 49.669 41.0966 49.3711 40.9883 49.1063C40.886 48.8354 40.7415 48.6007 40.5549 48.4021C40.3684 48.2035 40.1457 48.05 39.8869 47.9417C39.6341 47.8333 39.3603 47.7792 39.0654 47.7792C38.7705 47.7792 38.4966 47.8333 38.2438 47.9417C37.9911 48.05 37.7684 48.2035 37.5758 48.4021C37.3892 48.6007 37.2417 48.8354 37.1334 49.1063C37.0311 49.3711 36.9799 49.669 36.9799 50C36.9799 50.331 37.0311 50.6319 37.1334 50.9028C37.2417 51.1676 37.3892 51.3993 37.5758 51.5979C37.7684 51.7965 37.9911 51.95 38.2438 52.0583C38.4966 52.1667 38.7705 52.2208 39.0654 52.2208Z"
      fill="white"
    />
    <path
      d="M38 14H37V20H38V14Z"
      fill="#FF513C"
    />
    <path
      d="M14 38V39H20V38H14Z"
      fill="white"
    />
    <path
      d="M39 62H38V56H39V62Z"
      fill="white"
    />
    <path
      d="M62 38V37H56V38H62Z"
      fill="white"
    />
    <g opacity="0.6">
      <path
        d="M49.9998 17.2153L49.1338 16.7153L47.6338 19.3134L48.4998 19.8134L49.9998 17.2153Z"
        fill="white"
      />
      <path
        d="M25.9998 17.2153L25.1338 17.7153L26.6338 20.3134L27.4998 19.8134L25.9998 17.2153Z"
        fill="white"
      />
      <path
        d="M17.2158 25.9999L16.7158 26.866L19.3139 28.366L19.8139 27.4999L17.2158 25.9999Z"
        fill="white"
      />
      <path
        d="M17.7158 50.866L17.2158 49.9999L19.8139 48.4999L20.3139 49.366L17.7158 50.866Z"
        fill="white"
      />
      <path
        d="M26.0002 58.7847L26.8662 59.2847L28.3662 56.6866L27.5002 56.1866L26.0002 58.7847Z"
        fill="white"
      />
      <path
        d="M50.8662 58.2847L50.0002 58.7847L48.5002 56.1866L49.3662 55.6866L50.8662 58.2847Z"
        fill="white"
      />
      <path
        d="M58.7842 50.0001L59.2842 49.134L56.6861 47.634L56.1861 48.5001L58.7842 50.0001Z"
        fill="white"
      />
      <path
        d="M58.2842 25.134L58.7842 26.0001L56.1861 27.5001L55.6861 26.634L58.2842 25.134Z"
        fill="white"
      />
    </g>
    <g opacity="0.3">
      <path
        d="M40.5082 14.1314L39.5137 14.0269L39.2001 17.0104L40.1946 17.1149L40.5082 14.1314Z"
        fill="white"
      />
      <path
        d="M35.4916 14.1316L34.4971 14.2361L34.8107 17.2196L35.8052 17.1151L35.4916 14.1316Z"
        fill="white"
      />
      <path
        d="M33.0104 14.5245L32.0322 14.7324L32.656 17.6669L33.6341 17.459L33.0104 14.5245Z"
        fill="white"
      />
      <path
        d="M29.6328 15.4836L30.5839 15.1746L31.5109 18.0278L30.5599 18.3368L29.6328 15.4836Z"
        fill="white"
      />
      <path
        d="M28.2387 16.075L27.3252 16.4817L28.5454 19.2223L29.459 18.8156L28.2387 16.075Z"
        fill="white"
      />
      <path
        d="M23.084 19.1714L23.893 18.5836L25.6564 21.0107L24.8473 21.5984L23.084 19.1714Z"
        fill="white"
      />
      <path
        d="M21.9404 20.1646L21.1973 20.8337L23.2047 23.0632L23.9478 22.394L21.9404 20.1646Z"
        fill="white"
      />
      <path
        d="M19.4951 22.6841L20.1642 21.9409L22.3937 23.9483L21.7246 24.6915L19.4951 22.6841Z"
        fill="white"
      />
      <path
        d="M18.5839 23.8931L17.9961 24.7021L20.4231 26.4655L21.0109 25.6565L18.5839 23.8931Z"
        fill="white"
      />
      <path
        d="M15.668 29.1519L16.0747 28.2383L18.8153 29.4585L18.4086 30.3721L15.668 29.1519Z"
        fill="white"
      />
      <path
        d="M15.1743 30.5836L14.8652 31.5347L17.7184 32.4617L18.0274 31.5107L15.1743 30.5836Z"
        fill="white"
      />
      <path
        d="M14.3164 33.9883L14.5243 33.0101L17.4588 33.6339L17.2508 34.612L14.3164 33.9883Z"
        fill="white"
      />
      <path
        d="M14.1319 35.4913L14.0273 36.4858L17.0109 36.7994L17.1154 35.8049L14.1319 35.4913Z"
        fill="white"
      />
      <path
        d="M14.2363 41.5032L14.1318 40.5087L17.1154 40.1951L17.2199 41.1896L14.2363 41.5032Z"
        fill="white"
      />
      <path
        d="M14.5245 42.9899L14.7324 43.968L17.6669 43.3443L17.459 42.3661L14.5245 42.9899Z"
        fill="white"
      />
      <path
        d="M15.4834 46.3674L15.1744 45.4164L18.0276 44.4893L18.3366 45.4404L15.4834 46.3674Z"
        fill="white"
      />
      <path
        d="M16.0747 47.7617L16.4814 48.6753L19.2221 47.4551L18.8153 46.5415L16.0747 47.7617Z"
        fill="white"
      />
      <path
        d="M19.1709 52.9158L18.5831 52.1068L21.0102 50.3434L21.5979 51.1524L19.1709 52.9158Z"
        fill="white"
      />
      <path
        d="M20.1649 54.0591L20.834 54.8022L23.0634 52.7949L22.3943 52.0517L20.1649 54.0591Z"
        fill="white"
      />
      <path
        d="M22.6836 56.5046L21.9404 55.8355L23.9478 53.6061L24.691 54.2752L22.6836 56.5046Z"
        fill="white"
      />
      <path
        d="M23.8931 57.4164L24.7021 58.0042L26.4655 55.5771L25.6565 54.9893L23.8931 57.4164Z"
        fill="white"
      />
      <path
        d="M29.1523 60.3318L28.2388 59.925L29.459 57.1844L30.3726 57.5912L29.1523 60.3318Z"
        fill="white"
      />
      <path
        d="M30.5831 60.8253L31.5342 61.1343L32.4612 58.2811L31.5102 57.9721L30.5831 60.8253Z"
        fill="white"
      />
      <path
        d="M33.9883 61.6833L33.0101 61.4754L33.6339 58.541L34.612 58.7489L33.9883 61.6833Z"
        fill="white"
      />
      <path
        d="M35.4918 61.8686L36.4863 61.9731L36.7999 58.9896L35.8054 58.8851L35.4918 61.8686Z"
        fill="white"
      />
      <path
        d="M41.5029 61.7639L40.5084 61.8684L40.1948 58.8849L41.1893 58.7803L41.5029 61.7639Z"
        fill="white"
      />
      <path
        d="M42.9896 61.4755L43.9678 61.2676L43.344 58.3331L42.3659 58.541L42.9896 61.4755Z"
        fill="white"
      />
      <path
        d="M46.3672 60.5164L45.4161 60.8254L44.4891 57.9722L45.4401 57.6632L46.3672 60.5164Z"
        fill="white"
      />
      <path
        d="M47.7613 59.925L48.6748 59.5183L47.4546 56.7777L46.541 57.1844L47.7613 59.925Z"
        fill="white"
      />
      <path
        d="M52.916 56.8286L52.107 57.4164L50.3436 54.9893L51.1527 54.4016L52.916 56.8286Z"
        fill="white"
      />
      <path
        d="M54.0596 55.8354L54.8027 55.1663L52.7953 52.9368L52.0522 53.606L54.0596 55.8354Z"
        fill="white"
      />
      <path
        d="M56.5049 53.3159L55.8358 54.0591L53.6063 52.0517L54.2754 51.3085L56.5049 53.3159Z"
        fill="white"
      />
      <path
        d="M57.4161 52.1069L58.0039 51.2979L55.5769 49.5345L54.9891 50.3435L57.4161 52.1069Z"
        fill="white"
      />
      <path
        d="M60.332 46.8481L59.9253 47.7617L57.1847 46.5415L57.5914 45.6279L60.332 46.8481Z"
        fill="white"
      />
      <path
        d="M60.8257 45.4164L61.1348 44.4653L58.2816 43.5383L57.9726 44.4893L60.8257 45.4164Z"
        fill="white"
      />
      <path
        d="M61.6836 42.0117L61.4757 42.9899L58.5412 42.3661L58.7491 41.388L61.6836 42.0117Z"
        fill="white"
      />
      <path
        d="M61.8681 40.5087L61.9727 39.5142L58.9891 39.2006L58.8846 40.1951L61.8681 40.5087Z"
        fill="white"
      />
      <path
        d="M61.7637 34.4968L61.8682 35.4913L58.8846 35.8049L58.7801 34.8104L61.7637 34.4968Z"
        fill="white"
      />
      <path
        d="M61.4755 33.0101L61.2676 32.032L58.3331 32.6557L58.541 33.6339L61.4755 33.0101Z"
        fill="white"
      />
      <path
        d="M60.5166 29.6326L60.8256 30.5836L57.9725 31.5107L57.6634 30.5596L60.5166 29.6326Z"
        fill="white"
      />
      <path
        d="M59.9253 28.2383L59.5186 27.3247L56.7779 28.5449L57.1847 29.4585L59.9253 28.2383Z"
        fill="white"
      />
      <path
        d="M56.8281 23.0842L57.4159 23.8932L54.9889 25.6566L54.4011 24.8476L56.8281 23.0842Z"
        fill="white"
      />
      <path
        d="M55.8351 21.9409L55.166 21.1978L52.9366 23.2051L53.6057 23.9483L55.8351 21.9409Z"
        fill="white"
      />
      <path
        d="M53.3164 19.4954L54.0596 20.1645L52.0522 22.3939L51.309 21.7248L53.3164 19.4954Z"
        fill="white"
      />
      <path
        d="M52.1069 18.5836L51.2979 17.9958L49.5345 20.4229L50.3435 21.0107L52.1069 18.5836Z"
        fill="white"
      />
      <path
        d="M46.8477 15.6682L47.7612 16.0749L46.541 18.8156L45.6274 18.4088L46.8477 15.6682Z"
        fill="white"
      />
      <path
        d="M45.4169 15.1747L44.4658 14.8657L43.5388 17.7189L44.4898 18.0279L45.4169 15.1747Z"
        fill="white"
      />
      <path
        d="M42.0117 14.3167L42.9899 14.5246L42.3661 17.459L41.388 17.2511L42.0117 14.3167Z"
        fill="white"
      />
    </g>
    <g opacity="0.3">
      <rect
        x="33"
        y="38"
        width="11"
        height="1"
        fill="white"
      />
      <rect
        x="39"
        y="33"
        width="11"
        height="1"
        transform="rotate(90 39 33)"
        fill="white"
      />
    </g>
    <g filter="url(#filter4_d_5126_88670)">
      <circle
        class="east-circle"
        cx="70"
        cy="38"
        r="4"
        fill="#F4C31D"
      />
    </g>
    <g filter="url(#filter5_d_5126_88670)">
      <circle
        class="southeast-circle"
        cx="60.6274"
        cy="60.6274"
        r="4"
        transform="rotate(45 60.6274 60.6274)"
        fill="#F4C31D"
      />
    </g>
    <g filter="url(#filter6_d_5126_88670)">
      <circle
        class="south-circle"
        cx="38"
        cy="70"
        r="4"
        transform="rotate(90 38 70)"
        fill="#F4C31D"
      />
    </g>
    <g filter="url(#filter7_d_5126_88670)">
      <circle
        class="southwest-circle"
        cx="15.3727"
        cy="60.6274"
        r="4"
        transform="rotate(135 15.3727 60.6274)"
        fill="#F4C31D"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_5126_88670"
        x="0"
        y="32"
        width="12"
        height="12"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood
          flood-opacity="0"
          result="BackgroundImageFix"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="2"
          operator="dilate"
          in="SourceAlpha"
          result="effect1_dropShadow_5126_88670"
        />
        <feOffset />
        <feComposite
          in2="hardAlpha"
          operator="out"
        />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.956863 0 0 0 0 0.764706 0 0 0 0 0.113725 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_5126_88670"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_5126_88670"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_5126_88670"
        x="9.37256"
        y="9.37256"
        width="12"
        height="12"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood
          flood-opacity="0"
          result="BackgroundImageFix"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="2"
          operator="dilate"
          in="SourceAlpha"
          result="effect1_dropShadow_5126_88670"
        />
        <feOffset />
        <feComposite
          in2="hardAlpha"
          operator="out"
        />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.956863 0 0 0 0 0.764706 0 0 0 0 0.113725 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_5126_88670"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_5126_88670"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_d_5126_88670"
        x="32"
        y="0"
        width="12"
        height="12"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood
          flood-opacity="0"
          result="BackgroundImageFix"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="2"
          operator="dilate"
          in="SourceAlpha"
          result="effect1_dropShadow_5126_88670"
        />
        <feOffset />
        <feComposite
          in2="hardAlpha"
          operator="out"
        />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.956863 0 0 0 0 0.764706 0 0 0 0 0.113725 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_5126_88670"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_5126_88670"
          result="shape"
        />
      </filter>
      <filter
        id="filter3_d_5126_88670"
        x="54.6274"
        y="9.37256"
        width="12"
        height="12"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood
          flood-opacity="0"
          result="BackgroundImageFix"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="2"
          operator="dilate"
          in="SourceAlpha"
          result="effect1_dropShadow_5126_88670"
        />
        <feOffset />
        <feComposite
          in2="hardAlpha"
          operator="out"
        />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.956863 0 0 0 0 0.764706 0 0 0 0 0.113725 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_5126_88670"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_5126_88670"
          result="shape"
        />
      </filter>
      <filter
        id="filter4_d_5126_88670"
        x="64"
        y="32"
        width="12"
        height="12"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood
          flood-opacity="0"
          result="BackgroundImageFix"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="2"
          operator="dilate"
          in="SourceAlpha"
          result="effect1_dropShadow_5126_88670"
        />
        <feOffset />
        <feComposite
          in2="hardAlpha"
          operator="out"
        />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.956863 0 0 0 0 0.764706 0 0 0 0 0.113725 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_5126_88670"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_5126_88670"
          result="shape"
        />
      </filter>
      <filter
        id="filter5_d_5126_88670"
        x="54.6274"
        y="54.6274"
        width="12"
        height="12"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood
          flood-opacity="0"
          result="BackgroundImageFix"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="2"
          operator="dilate"
          in="SourceAlpha"
          result="effect1_dropShadow_5126_88670"
        />
        <feOffset />
        <feComposite
          in2="hardAlpha"
          operator="out"
        />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.956863 0 0 0 0 0.764706 0 0 0 0 0.113725 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_5126_88670"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_5126_88670"
          result="shape"
        />
      </filter>
      <filter
        id="filter6_d_5126_88670"
        x="32"
        y="64"
        width="12"
        height="12"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood
          flood-opacity="0"
          result="BackgroundImageFix"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="2"
          operator="dilate"
          in="SourceAlpha"
          result="effect1_dropShadow_5126_88670"
        />
        <feOffset />
        <feComposite
          in2="hardAlpha"
          operator="out"
        />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.956863 0 0 0 0 0.764706 0 0 0 0 0.113725 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_5126_88670"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_5126_88670"
          result="shape"
        />
      </filter>
      <filter
        id="filter7_d_5126_88670"
        x="9.3728"
        y="54.6274"
        width="12"
        height="12"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood
          flood-opacity="0"
          result="BackgroundImageFix"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="2"
          operator="dilate"
          in="SourceAlpha"
          result="effect1_dropShadow_5126_88670"
        />
        <feOffset />
        <feComposite
          in2="hardAlpha"
          operator="out"
        />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.956863 0 0 0 0 0.764706 0 0 0 0 0.113725 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_5126_88670"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_5126_88670"
          result="shape"
        />
      </filter>
    </defs>
  </svg>

</template>
