<template>
  <nuxt-link
    class="w-full cursor-pointer flex flex-col"
    target="_blank"
    :to="{name: ROUTES_NAMES.journalNews, params: { slug: journalNews.slug || 'slug' }}"
  >
    <div class="relative">
      <img
        class="w-full h-[264px] rounded-lg object-cover"
        :src="imageLink"
      >
      <div class="absolute bottom-6 left-6 flex gap-x-3">
        <div
          v-if="!isPromotionType"
          class="flex pa-2 rounded-full text-white bg-neutral-950/40 h-[38px] px-3 font-semibold items-center justify-center"
        >
          <span>{{ $t(`journal.type.options.${journalNews.type}`, 1) }}</span>
        </div>
        <promotions-labels
          v-else
          :promotions="journalNews.event?.promotion.types"
          container-class="bottom-0 gap-[6px]"
          vertical-alignment
          size="md"
        />
      </div>
      <div class="absolute top-6 right-6 flex gap-x-3">
        <journal-card-event-badges
          v-if="journalNews.event"
          :event="journalNews.event"
        />
        <custom-button
          v-if="journalNews.coverage?.building?.list.length"
          class="max-w-[128px] truncate font-semibold px-4 h-[38px] leading-4"
          color="transparent"
          size="none"
          pill
          @click.prevent
        >
          {{ journalNews.coverage.building.list.map((item) => item.name).join(', ') }}
        </custom-button>
      </div>
      <div
        v-if="journalNews.videoUrl"
        class="bg-neutral-950/40 flex items-center justify-center rounded-full w-[68px] h-[68px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white"
      >
        <nuxt-icon
          class="text-[25px]"
          name="actions/player"
        />
      </div>
    </div>
    <div class="p-6">
      <h3 class="font-medium text-xl text-primary">
        {{ journalNews.title }}
      </h3>
      <span
        v-if="journalNews.createTime"
        class="mt-1 text-neutral-500 font-semibold"
      >
        {{ formatDate(journalNews.createTime) }}
      </span>
      <div class="max-h-[44px] break-all line-clamp-2 mt-3 text-neutral-500 font-medium">
        <span>
          {{ deleteTagsFromHtml(isPromotionType ? journalNews.shortText : journalNews.text) }}
        </span>
      </div>
    </div>
    <nuxt-link
      class="flex mr-auto text-primary hover:text-white active:text-white px-4 py-3 leading-4 bg-primary-light hover:bg-primary active:bg-primary-dark disabled:bg-neutral-100 rounded-full font-semibold"
      target="_blank"
      :to="{name: ROUTES_NAMES.journalNews, params: { slug: journalNews.slug || 'slug' }}"
    >
      {{ $t('common.details') }}
    </nuxt-link>
  </nuxt-link>
</template>

<script setup lang="ts">
import { JournalNews } from '~/modules/journal/types/JournalNews'
import CustomButton from '~/ui/buttons/CustomButton.vue'
import defaultNewsImage from '~/assets/images/journal/news-default-image.jpg'
import defaultArticleImage from '~/assets/images/journal/article-default-image.jpg'
import { JOURNAL_NEWS_TYPE } from '~/modules/journal/constants/JournalNews.type'
import { ROUTES_NAMES } from '~/router/constants/Router.routesNames'
import JournalCardEventBadges from '~/modules/journal/components/cards/JournalCardEventBadges.vue'
import PromotionsLabels from '~/modules/widget/components/PromotionsLabels.vue'
import { deleteTagsFromHtml } from '~/common/helpers/html'

const props = defineProps({
  journalNews: {
    type: Object as PropType<JournalNews>,
    required: true,
  },
})

const isPromotionType = props.journalNews.type === JOURNAL_NEWS_TYPE.promotion

const imageLink = computed(() => {
  if (props.journalNews.previewImageLink) {
    return props.journalNews.previewImageLink
  }

  return props.journalNews.type === JOURNAL_NEWS_TYPE.article ? defaultArticleImage : defaultNewsImage
})

const formatDate = (date: string) => new Date(date).toLocaleDateString('ru-RU', {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
})
</script>
