import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin(nuxtApp => {
  const release = nuxtApp.$config.public.appVersion
  const environment = nuxtApp.$config.public.APP_ENV
  const dsn = nuxtApp.$config.public.SENTRY_FRONTEND_DSN

  if (environment === 'production') {
    Sentry.init({
      dsn,
      release,
      environment,
      integrations: [
        new Sentry.BrowserTracing(),
      ],
      tracesSampleRate: 1.0,
      tracingOptions: {
        trackComponents: true,
      },
      debug: false,
      attachStacktrace: true,
      normalizeDepth: 5,
      ignoreErrors: [
        'TypeError: Failed to fetch',
      ],
    })
  }
})
