import { COLOR } from '~/ui/constants/color'
import { Clipboard } from '@capacitor/clipboard'

export const copyToClipboard = async(textForCopy: string, toastMessage:string) => {
  try {
    await Clipboard.write({ string: textForCopy })
    toast(toastMessage, { variant: COLOR.success })
  } catch (e) {
    toast(`${useI18n().t('toast.error')} ${e}`, { variant: 'danger' })
  }
}
