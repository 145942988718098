import { defineStore } from 'pinia'

type NavMenuState = {
  isDisplayNavMenu: boolean
  isDisplayUnauthorizedNavMenu: boolean
}
export const useNavMenuStore = defineStore('navMenu', {
  state: () => ({
    isDisplayNavMenu: false,
    isDisplayUnauthorizedNavMenu: false,
  }) as NavMenuState,
  actions: {
    toggleNavMenuDisplaying(value: boolean):void {
      this.isDisplayNavMenu = value
    },
    toggleUnauthorizedNavMenuDisplaying(value: boolean): void {
      this.isDisplayUnauthorizedNavMenu = value
    },
  },
})
