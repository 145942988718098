<template>
  <client-only>
    <div :id="mapRandomId" style="height: 100%" />
  </client-only>
</template>

<script setup lang="ts">
import { MapService } from '~/common/services/map/yandex/MapService'

const mapRandomId = `map${Math.random().toString(16).slice(2)}`

const props = defineProps({
  service: {
    type: Object as PropType<MapService>,
    required: true,
  },
})

const emits = defineEmits<{(e: 'onMapLoaded'): void}>()

onMounted(async() => {
  await props.service.createMap(mapRandomId)
  emits('onMapLoaded')
})

const recreateMap = async() => {
  props.service.destroyMap()
  await props.service.createMap(mapRandomId)
  emits('onMapLoaded')
}

onUnmounted(() => {
  props.service.destroyMap()
})

defineExpose({
  recreateMap,
})
</script>

<style lang="scss">
.ymaps-2-1-79-controls__toolbar {
  margin-top: 22px !important;
}
</style>
