<template>
  <div
    class="qd-bottom-fixed-menu"
    :class="{'qd-bottom-fixed-menu_ios': isIos}"
  >
    <slot />
  </div>
</template>

<script setup lang="ts">
import { useAppStateStore } from '~/store/app'

const store = useAppStateStore()
const isIos = store.appPlatform === 'ios'

</script>
<style>
.qd-bottom-fixed-menu {
  @apply fixed bottom-0 left-0 w-full bg-white shadow-card;
  padding-bottom: 1rem;
}

.qd-bottom-fixed-menu_ios {
  padding-bottom: calc(env(safe-area-inset-bottom) - 0.3rem);
}
</style>
