import { ROUTES_LAYOUTS } from '~/router/constants/Router.routesLayouts'
import { LAYOUTS } from '~/router/constants/Router.layouts'
import { ROUTES_NAMES } from '~/router/constants/Router.routesNames'

export const setLayout = (route: (typeof ROUTES_NAMES)[keyof typeof ROUTES_NAMES]) => {
  const layout = ROUTES_LAYOUTS[route] ?? LAYOUTS.authLayout
  setPageLayout(layout)
}

export const setAuthLayout = (): void => {
  setPageLayout(LAYOUTS.authLayout)
}
