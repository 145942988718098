<template>
  <custom-card class="relative w-full">
    <select-language
      v-if="formDisplayingType === FORM_DISPLAYING_TYPE.page"
      class="!absolute top-4 md:top-2 right-4 md:right-2"
    />
    <div class="flex flex-col items-center">
      <h2 class="text-blue-700 mt-4 mb-8">
        {{ t('auth.description.label') }}
      </h2>
      <h4 class="mb-4 font-normal">
        {{ t('auth.register.label') }}
      </h4>
    </div>
    <form @submit="signup">
      <auth-form-name-input
        v-model="userName"
        :disabled="isFetching"
        :label="$t('auth.name.label')"
        :errors="backendValidationErrors.firstName ?? []"
      />
      <auth-form-last-name-input
        v-model="userLastName"
        class="mt-2"
        :disabled="isFetching"
        :label="$t('auth.lastName.label')"
        :errors="backendValidationErrors.firstName ?? []"
      />
      <auth-form-username-input
        v-model="userEmail"
        class="mt-2"
        :disabled="isFetching"
        username-type="email"
        :errors="backendValidationErrors.email ?? []"
      />
      <phone-input
        v-model:mobile-phone="userPhone"
        class="mt-2"
        :label="$t('auth.userPhone.label')"
        :placeholder="$t('auth.userPhone.label')"
        :disabled="isFetching"
        required
        :errors="backendValidationErrors.mobilePhone ?? []"
      />
      <auth-password-input
        v-model="password"
        class="mt-2"
        :disabled="isFetching"
        :is-hidden="isPasswordHidden"
        autocomplete="new-password"
        @hide="isPasswordHidden = $event"
      />

      <auth-password-input
        v-model="passwordConfirm"
        class="mt-2"
        :disabled="isFetching"
        :password-to-confirm="password"
        :confirm="true"
        :is-hidden="isPasswordHidden"
        autocomplete="off"
        @hide="isPasswordHidden = $event"
      />
      <auth-sign-up-confirmation-of-terms-of-use
        v-if="isTermsOfUseConfirmed"
        class="mt-4"
      />
      <p
        v-if="infoMessage"
        class="mt-4 text-sm text-neutral-300"
      >
        {{ infoMessage }}
      </p>
      <custom-button-with-spinner
        class="w-full mt-4 font-semibold"
        type="submit"
        variant="filled"
        :disabled="isFetching"
        :display-spinner="isFetching"
      >
        {{ $t('auth.signup.label') }}
      </custom-button-with-spinner>
    </form>
    <div
      class="flex mt-4 justify-center gap-2"
    >
      <p class="text-center">
        {{ $t('auth.haveAccount.label') }}
        <nuxt-link
          class="cursor-pointer"
          @click="onLinkClick"
        >
          <span class="text-blue-700">
            {{ invitationSecret ? $t('auth.invitationSignup.toLogin') : $t('auth.login.label') }}
          </span>
        </nuxt-link>
      </p>
    </div>
  </custom-card>
</template>

<script setup lang="ts">
import AuthPasswordInput from '~/modules/auth/components/AuthPasswordInput.vue'
import CustomCard from '~/ui/cards/CustomCard.vue'
import AuthFormUsernameInput from '~/modules/auth/components/AuthFormUsernameInput.vue'
import AuthFormNameInput from '~/modules/auth/components/AuthFormNameInput.vue'
import AuthFormLastNameInput from '~/modules/auth/components/AuthFormLastNameInput.vue'
import { useForm } from 'vee-validate'
import CustomButtonWithSpinner from '~/ui/buttons/CustomButtonWithSpinner.vue'
import { AuthService } from '~/modules/auth/service/AuthService'
import { ROUTES_NAMES } from '~/router/constants/Router.routesNames'
import { COLOR } from '~/ui/constants/color'
import { useUserStore } from '~/modules/user/store'
import PhoneInput from '~/components/inputs/PhoneInput.vue'
import { FORM_DISPLAYING_TYPE } from '~/modules/auth/constants/Auth.formDisplayingType'
import { FormDisplayingType } from '~/modules/auth/types/Auth.formDisplayingType'
import SelectLanguage from '~/modules/app-language/components/SelectLanguage.vue'
import AuthSignUpConfirmationOfTermsOfUse from '~/modules/auth/components/AuthSignUpConfirmationOfTermsOfUse.vue'

const props = defineProps({
  invitationSecret: {
    type: String as PropType<string | null>,
    default: null,
  },
  infoMessage: {
    type: String,
    default: null,
  },
  formDisplayingType: {
    type: String as PropType<FormDisplayingType>,
    default: FORM_DISPLAYING_TYPE.page,
  },
})

const emits = defineEmits<{(e: 'goToLogin'): void,
  (e: 'onSignup'): void}>()

const { t } = useI18n()

const store = useUserStore()

const authService = new AuthService()

const isFetching = ref(false)

const password = ref('')
const passwordConfirm = ref('')
const userName = ref('')
const userLastName = ref('')
const userEmail = ref('')
const userPhone = ref('')
const isPasswordHidden = ref(true)
const isTermsOfUseConfirmed = ref('isTermsOfUseConfirmed')

const userOriginApp = ref('estateMarket')
const backendValidationErrors = ref({
  firstName: [],
  email: [],
  mobilePhone: [],
})

const { handleSubmit, setErrors } = useForm()

const onLinkClick = () => {
  if (props.formDisplayingType === FORM_DISPLAYING_TYPE.modal) {
    emits('goToLogin')
  } else {
    useRouter().replace({ name: ROUTES_NAMES.login, query: props.invitationSecret ? { invitationSecret: props.invitationSecret } : undefined })
  }
}
const signup = handleSubmit(() => {
  isFetching.value = true
  let signupPromise: Promise<unknown> | null = null
  if (props.invitationSecret) {
    signupPromise = authService.invitationSignup(
      {
        firstName: userName.value as string,
        lastName: userLastName.value,
        email: userEmail.value as string,
        mobilePhone: userPhone.value as string,
        password: password.value as string,
        userOriginApp: userOriginApp.value as string,
        invitationSecret: props.invitationSecret,
      },
    )
  } else {
    signupPromise = authService.signup(
      {
        firstName: userName.value as string,
        lastName: userLastName.value,
        email: userEmail.value as string,
        mobilePhone: userPhone.value as string,
        password: password.value as string,
        userOriginApp: userOriginApp.value as string,
      },
    )
  }
  signupPromise.then(response => {
    toast(t('auth.register.toast.label.success'), { variant: COLOR.success })
    store.setUser(response.user)
    emits('onSignup')
  })
    .catch(response => {
      setErrors(response.data.errors)
      backendValidationErrors.value = response.data.errors
      toast(t('auth.register.toast.label.error'), { variant: COLOR.danger })
    })
    .finally(() => {
      isFetching.value = false
    })
})
</script>
<style scoped>

</style>
