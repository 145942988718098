import { useAuthStore } from '~/modules/auth/store'
import useOrganisationStore from '~/modules/organisation/store'
import { useUserStore } from '~/modules/user/UserModule'

const checkOrganisationStatusAndShowModal = () => {
  const organisationStore = useOrganisationStore()
  if (organisationStore.isOrganisationStatusGranted) {
    return true
  }
  organisationStore.setIsDisplayAccessStatusBlockingModal(true)
  return false
}

export const showAuthModalOrCallback = (onAuthorizedCallback?: (args?: any) => void, onUnauthorizedCallback?: (args?: any) => void) => {
  if (useUserStore().isUserAuthorized) {
    if (!checkOrganisationStatusAndShowModal()) {
      if (onUnauthorizedCallback) {
        onUnauthorizedCallback()
      }
    } else if (onAuthorizedCallback) {
      onAuthorizedCallback()
    }
  } else {
    if (onUnauthorizedCallback) {
      onUnauthorizedCallback()
    }
    useAuthStore().setIsDisplayAuthModal(true)
  }
}
