<template>
  <custom-dropdown
    body-class="!p-0"
    :button-class="[{'bg-primary-light' : color === 'primary', 'text-primary': color === 'primary', '!bg-transparent' : color === 'default'}, 'whitespace-nowrap items-center']"
    :size="isMd ? 'sm' : 'none'"
    dropdown-class="!fixed"
    :display-dropdown-arrow="isMd"
  >
    <template #triggerContent>
      <client-only>
        <nuxt-icon
          name="actions/select-language"
          class="text-[20px]"
        />
      </client-only>
      <span
        v-if="isMd"
        class="font-semibold ml-2"
      >
        {{ $i18n.locale.toUpperCase() }}
      </span>
    </template>
    <ul>
      <li
        v-for="locale in $i18n.locales"
        :key="locale.code"
        class="p-[8px] rounded-[4px] text-primary cursor-pointer hover:bg-blue-50"
        :class="{'bg-primary-light': $i18n.locale === locale.code}"
        @click="$i18n.setLocale(locale.code)"
      >
        {{ locale.name.toUpperCase() }}
      </li>
    </ul>
  </custom-dropdown>
</template>

<script setup lang="ts">
import { useAppStateStore } from '~/store/app'
import CustomDropdown from '~/ui/dropdowns/CustomDropdown.vue'

defineProps({
  color: {
    type: String as PropType<'default' | 'primary'>,
    default: 'default',
  },
})

const isMd = computed(() => useAppStateStore().breakpoints.isMd)
</script>
