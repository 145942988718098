<template>
  <div
    :class="bodyClass"
    class="flex"
  >
    <div
      v-if="whatsapp"
      class="flex items-center gap-x-3"
    >
      <nuxt-icon
        name="entities/whats-app-circle"
        class="text-[38px]"
        filled
      />
      <span class="font-semibold">{{ whatsapp }}</span>
    </div>
    <div
      v-if="telegram"
      class="flex items-center gap-x-3"
    >
      <nuxt-icon
        name="entities/telegram-circle"
        class="text-[38px]"
        filled
      />
      <span class="font-semibold">{{ telegram }}</span>
    </div>
    <div
      v-if="mobilePhone"
      class="flex items-center gap-x-3"
    >
      <div class="w-[38px] bg-white rounded-full h-[38px] flex items-center justify-center">
        <nuxt-icon
          name="common/telephone-not-filled"
          class="text-[24px]"
        />
      </div>
      <span class="font-semibold">{{ mobilePhone }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Messengers } from '~/common/types/messengers/Messengers'

const props = defineProps({
  messengers: {
    type: Object as PropType<Messengers>,
    default: null,
  },
  mobilePhone: {
    type: String,
    default: null,
  },
  bodyClass: {
    type: [String, Object, Array],
    default: null,
  },
})

const telegram = computed(() => props.messengers?.telegram?.username || props.messengers?.telegram?.phoneNumber)

const whatsapp = computed(() => props.messengers?.whatsapp?.phoneNumber)
</script>
