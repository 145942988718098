import { ROUTES_NAMES } from '~/router/constants/Router.routesNames'

export const GUEST_ROUTES = [
  ROUTES_NAMES.suggestions,
  ROUTES_NAMES.suggestionPage,
  ROUTES_NAMES.presentationBuildingPage,
  ROUTES_NAMES.presentationBuildingPageFromSuggestion,
  ROUTES_NAMES.login,
  ROUTES_NAMES.signup,
  ROUTES_NAMES.signupInvitation,
  ROUTES_NAMES.forgotPassword,
  ROUTES_NAMES.resetPassword,
  ROUTES_NAMES.licenseAgreement,
  ROUTES_NAMES.privatePolicy,
] as const
