<template>
  <custom-input
    v-model="value"
    :type="input.type"
    :label="$t(input.label)"
    :required="true"
    :placeholder="$t(input.placeholder)"
    :disabled="disabled"
    :autocomplete="input.autocomplete"
    :invalid-feedback="errorMessage"
    :state="errorMessage ? false : null"
  />
</template>

<script setup lang="ts">
import { useField } from 'vee-validate'
import CustomInput from '~/ui/inputs/CustomInput.vue'
import type { AuthUsername } from '~/modules/auth/types/authInput'
import type { InputType } from '~/ui/inputs/types/types'

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  usernameType: {
    type: String as PropType<AuthUsername>,
    default: 'emailOrPhone',
  },
  errors: {
    type: Array as PropType<Array<string>>,
    default: () => [],
  },
})

const input = computed<{type: InputType, placeholder: string, label: string, rule: string}>(() => {
  switch (props.usernameType) {
    case 'email':
      return {
        type: 'email',
        placeholder: 'auth.userEmail.label',
        label: 'auth.userEmail.label',
        rule: 'userEmail',
        autocomplete: 'email',
      }
    case 'phone':
      return {
        type: 'tel',
        placeholder: 'auth.userPhone.label',
        label: 'auth.userPhone.label',
        rule: 'userPhone',
        autocomplete: 'tel',
      }
    default:
      return {
        type: 'email',
        placeholder: 'auth.username.label',
        label: 'auth.username.label',
        rule: 'userLogin',
        autocomplete: 'off',
      }
  }
})

const { value, errorMessage, setErrors } = useField<string>(props.usernameType as string, { required: true, [input.value.rule]: true }, {
  label: useI18n().t(input.value.label),
})

watchEffect(() => {
  setErrors(props.errors)
})

</script>
