<template>
  <custom-tooltip
    placement="top"
  >
    <template #content>
      <div
        v-if="motivation"
        class="whitespace-nowrap"
      >
        {{ t('building.actions.motivation.commission', {value: motivation}) }}
      </div>
      <div
        v-if="motivationSum"
        class="text-primary whitespace-nowrap"
      >
        {{ motivationSum }}
      </div>
      <div
        v-if="(building?.commissionContractUrl || flat?.building?.commissionContractUrl) && motivation"
        class="font-normal text-neutral-750 inter"
      >
        <div class="whitespace-nowrap">
          {{ t('building.actions.motivation.linkDescriptionPartOne') }}
        </div>
        <div class="whitespace-nowrap">{{ t('building.actions.motivation.linkDescriptionPartSecond') }}
          <span class="text-primary cursor-pointer">
            <a
              target="_blank"
              :href="building?.commissionContractUrl || flat?.building?.commissionContractUrl"
            >{{ t('building.actions.motivation.link') }}</a>
          </span>
        </div>
      </div>
      <div
        v-if="notFoundVisible"
        class="whitespace-nowrap"
      >
        {{ t('building.actions.motivation.notFound') }}
      </div>
    </template>
    <slot name="button">
      <custom-button-icon
        icon="common/motivation"
        icon-class="text-[1.85rem]"
        color="transparent"
        round
      />
    </slot>
  </custom-tooltip>
</template>

<script setup lang="ts">
import CustomTooltip from '~/ui/tooltips/CustomTooltip.vue'
import CustomButtonIcon from '~/ui/buttons/CustomButtonIcon.vue'
import { BuildingExpanded } from '~/modules/building/types/Building.expanded'
import { Flat } from '~/common/types/flat/Flat'
import { Building } from '~/modules/building/types/Building'
import { AgentMotivationEntity } from '~/modules/building/types/AgentMotivation.entity'
import { AGENT_MOTIVATION_ENTITY } from '~/modules/building/constants/AgentMotivation.entity'
import { FlatLayoutItem } from '~/common/types/flat/Flat.layoutItem'
import { Layout } from '~/common/types/layout/Layout'

const props = defineProps({
  building: {
    type: Object as PropType<BuildingExpanded | Building>,
    default: null,
  },
  flat: {
    type: Object as PropType<Flat>,
    default: null,
  },
  layout: {
    type: Object as PropType<FlatLayoutItem>,
    default: null,
  },
  entity: {
    type: String as PropType<AgentMotivationEntity>,
    default: AGENT_MOTIVATION_ENTITY.building,
  },
})

const { t } = useI18n()
const numMotivations = computed(() => props.building?.houses.filter(house => typeof house.agentMotivation === 'number').map(house => house.agentMotivation))

const selectedLayout = computed(() => {
  if (props.building && props.layout) {
    const layoutId = Number(props.layout?.layoutId)
    return props.building.houses
      .flatMap(house => house.layouts)
      .find(layout => layout.id === layoutId)
  }
  return null
})
const buildingMotivation = computed(() => {
  if (props.building) {
    if (numMotivations.value.length === 1) {
      return `${numMotivations.value[0]}`
    }
    if (numMotivations.value.length > 1) {
      return Math.max(...numMotivations.value as number[])
    }
  }
  return null
})

const flatMotivationSum = computed(() => {
  if (props.flat?.agentMotivation?.amount) {
    return `${t('building.actions.motivation.commissionSum', {
      amount: props.flat.agentMotivation.amount,
      currency: t(`currency.options.${props.flat.agentMotivation.currency}`),
    })}`
  }
  return null
})

const getMotivation = (data: Flat | Layout) => {
  if (data?.agentMotivation?.percent) {
    return data.agentMotivation.percent
  }
  return null
}

const getMotivationSum = (data: Building | Flat | Layout) => {
  if (data?.agentMotivation?.from || data?.agentMotivation?.to) {
    return `${t('building.actions.motivation.commissionSum', {
      amount: data?.agentMotivation.from !== data?.agentMotivation.to ? `${data?.agentMotivation.from} - ${data?.agentMotivation.to}` : (data?.agentMotivation?.from || data?.agentMotivation?.to),
      currency: t(`currency.options.${data?.agentMotivation?.currency}`),
    })}`
  }
  return null
}

const motivation = computed(() => {
  if (props.entity === AGENT_MOTIVATION_ENTITY.building) {
    return buildingMotivation.value
  }
  if (props.entity === AGENT_MOTIVATION_ENTITY.flat) {
    return getMotivation(props.flat)
  }
  if (props.entity === AGENT_MOTIVATION_ENTITY.layout) {
    return getMotivation(selectedLayout.value)
  }
  return null
})

const motivationSum = computed(() => {
  if (props.entity === AGENT_MOTIVATION_ENTITY.building) {
    return getMotivationSum(props.building)
  }
  if (props.entity === AGENT_MOTIVATION_ENTITY.flat) {
    return flatMotivationSum.value
  }
  if (props.entity === AGENT_MOTIVATION_ENTITY.layout) {
    return getMotivationSum(selectedLayout.value)
  }
  return null
})

const notFoundVisible = computed(() => {
  if (props.entity === AGENT_MOTIVATION_ENTITY.building) {
    return !buildingMotivation.value
  }
  if (props.entity === AGENT_MOTIVATION_ENTITY.flat) {
    return !getMotivation(props.flat)
  }
  if (props.entity === AGENT_MOTIVATION_ENTITY.layout) {
    return !getMotivation(selectedLayout.value)
  }
  return false
})
</script>
