import { QUARTER } from '~/common/constants/date/Quarter'
import { House } from '~/common/types/house/House'
import { HouseExpanded } from '~/common/types/house/House.expanded'

export const orderHouses = <T extends House | HouseExpanded>(houses: Array<T>): Array<{house: T, order: number }> => {
  let order = 1

  return houses.sort((a, b) => {
    if (`${a.deadLineYear}-${QUARTER[a.deadLineQuarter]}` < `${b.deadLineYear}-${QUARTER[b.deadLineQuarter]}`) {
      return -1
    }
    if (`${a.deadLineYear}-${QUARTER[a.deadLineQuarter]}` > `${b.deadLineYear}-${QUARTER[b.deadLineQuarter]}`) {
      return 1
    }
    return 0
  }).map((house, index, array) => {
    let houseOrder = 0

    if (index === 0) {
      houseOrder = 1
    } else if (index > 0 && house.deadLineYear === array[index - 1].deadLineYear && house.deadLineQuarter === array[index - 1].deadLineQuarter) {
      houseOrder = order
    } else {
      houseOrder = order + 1
      order += 1
    }

    return { house, order: houseOrder }
  })
}
