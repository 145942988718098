<template>
  <custom-tooltip placement="bottom">
    <template #content>
      {{ $t('building.actions.video.label') }}
    </template>
    <custom-button-icon
      color="transparent"
      icon="entities/video-review"
      round
      filled
      @click="emits('set-index')"
    />
  </custom-tooltip>
</template>
<script setup lang="ts">
import CustomButtonIcon from '~/ui/buttons/CustomButtonIcon.vue'
import CustomTooltip from '~/ui/tooltips/CustomTooltip.vue'

const emits = defineEmits<{(e: 'set-index'): void}>()
</script>
