import * as Sentry from '@sentry/vue'
import { HttpHandledResponse } from '~/services/http/types/Http.handledResponse'

export default class SentryLogger {
  /**
     * Используется для логирования информации о текущем контексте выполнения кода и отправки этой информации в Sentry вместе с ошибкой или событием.
     * Он позволяет добавлять дополнительные данные и контекст к логированию.
     * @param error - Ответ с ошибкой сервера
     * @param data - дополнительные данные
     *               Например, это могут быть параметры запроса к API, идентификаторы сущностей
     *                 или любые другие данные, которые могут быть полезными при анализе события.
     * @param tags - Теги представляют собой метки, которые вы можете присваивать событию или ошибке для организации и категоризации данных.
   **/
  public static captureScopeException(
    error: HttpHandledResponse,
    data: { [key: string]: any } | null = null,
    tags: { [key: string]: any } | null = null,
  ): void {
    Sentry.withScope(scope => {
      this.setScopeData(scope, data)
      this.setScopeTags(scope, tags)
      Sentry.captureException(error)
    })
  }

  /**
   * используется для логирования и отправки информации о JavaScript исключениях (ошибках) в Sentry.
   * Когда происходит ошибка, вы передаете объект ошибки в этот метод, и Sentry сохраняет информацию о стеке вызовов, типе ошибки и другие сведения,
   * что позволяет анализировать и отслеживать ошибки в приложении.
   **/
  public static captureException(error: HttpHandledResponse): void {
    Sentry.captureException(error)
  }

  /**
     * Используется для логирования пользовательских сообщений или событий.
     * Вы можете передать строку в этот метод, и она будет отправлена в Sentry как сообщение.
     * Этот метод полезен, когда нужно сообщить о каком-либо событии в приложении без наличия конкретной ошибки.
     * @param message - Текст сообщения
     * @param data - дополнительные данные
     *               Например, это могут быть параметры запроса к API, идентификаторы сущностей
     *                 или любые другие данные, которые могут быть полезными при анализе события.
     * @param tags - Теги представляют собой метки, которые вы можете присваивать событию или ошибке для организации и категоризации данных.
     **/
  public static captureMessage(
    message: string,
    data: { [key: string]: any } | null = null,
    tags: { [key: string]: any } | null = null,
  ): void {
    Sentry.withScope(scope => {
      this.setScopeData(scope, data)
      this.setScopeTags(scope, tags)
      Sentry.captureMessage(message)
    })
  }

  private static setScopeData(scope: Sentry.Scope, data: { [p: string]: any } | null): void {
    if (!data || !Object.keys(data).length) {
      return
    }

    Object.keys(data).forEach(key => scope.setExtra(key, data[key]))
  }

  private static setScopeTags(scope: Sentry.Scope, tags: { [p: string]: any } | null): void {
    if (!tags || !Object.keys(tags).length) {
      return
    }

    Object.keys(tags).forEach(key => {
      try {
        scope.setTag(key, JSON.stringify(tags[key]))
      } catch (error) {
        scope.setTag(key, String(tags[key]))
      }
    })
  }
}
