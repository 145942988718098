<template>
  <client-form-field-template
    :title="$t('clients.fixation.form.user.title')"
    :progress-step-percent="progressStepPercent"
    :progress-step-done="progressStepDone"
  >
    <labeled-wrapper
      label-class="font-medium text-lg inter"
      :label="$t('clients.fixation.form.user.label')"
    >
      <template #content>
        <select-auto-suggest
          v-model="user"
          :fetch="searchUsersByName"
          :option-label="({firstName, lastName}: User) => `${firstName} ${lastName || ''}`"
          :invalid-feedback="errorUser"
          :placeholder="$t('clients.fixation.form.user.placeholder')"
          infinite-scroll
        />
      </template>
    </labeled-wrapper>
    <contacts-view
      v-if="user"
      class="mt-6 max-xl:flex-col gap-x-6 gap-y-3"
      :messengers="user?.contactsSettings?.messengers"
      :mobile-phone="user?.mobilePhone"
    />
  </client-form-field-template>
</template>

<script setup lang="ts">
import { User, UserService, useUserStore } from '~/modules/user/UserModule'
import ClientFormFieldTemplate from '~/modules/clients/components/forms/ClientFormFieldTemplate.vue'
import LabeledWrapper from '~/components/common/LabeledWrapper/LabeledWrapper.vue'
import SelectAutoSuggest from '~/components/common/AutoSuggest/SelectAutoSuggest.vue'
import ContactsView from '~/components/contacts/ContactsView.vue'

const props = defineProps({
  progressStepPercent: {
    type: Number,
    default: 0,
  },
  progressStepDone: {
    type: Boolean,
    default: false,
  },
  assigned: {
    type: Object as PropType<User | null>,
    default: null,
  },
})

const userService = new UserService()
const userStore = useUserStore()
const { t } = useI18n()

const { value: user, errorMessage: errorUser } = useField<User | null>(
  'assigned',
  { required: true },
  { initialValue: props.assigned || userStore.user, label: t('clients.fixation.form.user.title') },
)

const searchUsersByName = (name: string) => userService.search({ isDeleted: false, firstOrLastName: name })
</script>
