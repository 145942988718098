<template>
  <custom-apex-charts
    :options="chartOptions"
    :series="series"
  />
</template>

<script setup lang="ts">
import { ApexOptions } from 'apexcharts'
import CustomApexCharts from '~/ui/charts/CustomApexCharts.vue'
import { colors } from '~/config/tailwind/colors/colors'
import { FlatPriceStatisticsDisplayingRooms } from '~/common/types/flat/Flat.priceStatisticsDisplayingRooms'
import { FLAT_PRICE_STATISTICS_DISPLAYING_ROOMS } from '~/common/constants/flat/Flat.priceStatisticsDisplayingRooms'
import { FlatPriceStatistics } from '~/common/types/flat/Flat.priceStatistics'
import { APEX_DEFAULT_OPTIONS } from '~/common/constants/charts/Apex.defaultOptions'
import { ObjectHelper } from '~/common/helpers/object'
import { CURRENCY } from '~/common/constants/money/Currency'
import { useAddressStore } from '~/modules/address/store'
import { Currency } from '~/common/types/money/Currency'
import { isEmpty } from '~/common/helpers/checkers'
import { FLAT_ROOMS_COUNT } from '~/common/constants/flat/Flat.roomsCount'
import { FlatGuestService } from '~/common/services/flat/FlatGuestService'
import { FlatService } from '~/common/services/flat/FlatService'
import { Flat } from '~/common/types/flat/Flat'
import { FlatLayoutItem } from '~/common/types/flat/Flat.layoutItem'

const props = defineProps({
  displayingRooms: {
    type: String as PropType<FlatPriceStatisticsDisplayingRooms>,
    default: FLAT_PRICE_STATISTICS_DISPLAYING_ROOMS.all,
  },
  statistics: {
    type: Object as PropType<FlatPriceStatistics | null>,
    required: true,
  },
  currency: {
    type: String as PropType<Currency>,
    default: CURRENCY.usd,
  },
  buildingId: {
    type: Number as PropType<number | null>,
    default: null,
  },
  developerId: {
    type: Number as PropType<number | null>,
    default: null,
  },
  flatService: {
    type: Object as PropType<FlatGuestService | FlatService>,
    required: true,
  },
  flat: {
    type: Object as PropType<Flat | null>,
    default: null,
  },
  layout: {
    type: Object as PropType<FlatLayoutItem | null>,
    default: null,
  },
})

const emits = defineEmits<{(e: 'update:statistics', value: FlatPriceStatistics | null): void,
  (e: 'update:isStatisticsFetching', value: boolean): void
}>()

const colorsInOrder: Array<{ hex: string; class: string }> = [{
  hex: colors.blue[900],
  class: 'text-primary',
}, {
  hex: colors.yellow[900],
  class: 'text-warning',
}, {
  hex: colors.red[900],
  class: 'text-danger',
}, {
  hex: colors.green[900],
  class: 'text-success',
}]

const { t } = useI18n()
const flatService = props.flatService || new FlatService()
const addressStore = useAddressStore()

const today = new Date()
const toDate = today.toISOString().split('T')[0]
today.setMonth(today.getMonth() - 1)
const beginingFromDate = today.toISOString().split('T')[0]

const fromDate = ref<string>(beginingFromDate)

const statistics = computed({
  get: () => props.statistics,
  set: value => {
    emits('update:statistics', value)
  },
})

const checkDisplayingRoom = (chosenDisplaying: FlatPriceStatisticsDisplayingRooms) => {
  const studio: Array<FlatPriceStatisticsDisplayingRooms> = [FLAT_PRICE_STATISTICS_DISPLAYING_ROOMS.studio, FLAT_PRICE_STATISTICS_DISPLAYING_ROOMS.all]
  const one: Array<FlatPriceStatisticsDisplayingRooms> = [FLAT_PRICE_STATISTICS_DISPLAYING_ROOMS.one, FLAT_PRICE_STATISTICS_DISPLAYING_ROOMS.all]
  const two: Array<FlatPriceStatisticsDisplayingRooms> = [FLAT_PRICE_STATISTICS_DISPLAYING_ROOMS.two, FLAT_PRICE_STATISTICS_DISPLAYING_ROOMS.all]
  const three: Array<FlatPriceStatisticsDisplayingRooms> = [FLAT_PRICE_STATISTICS_DISPLAYING_ROOMS.three, FLAT_PRICE_STATISTICS_DISPLAYING_ROOMS.all]

  switch (chosenDisplaying) {
    case FLAT_PRICE_STATISTICS_DISPLAYING_ROOMS.studio:
      return studio.includes(props.displayingRooms)
    case FLAT_PRICE_STATISTICS_DISPLAYING_ROOMS.one:
      return one.includes(props.displayingRooms)
    case FLAT_PRICE_STATISTICS_DISPLAYING_ROOMS.two:
      return two.includes(props.displayingRooms)
    case FLAT_PRICE_STATISTICS_DISPLAYING_ROOMS.three:
      return three.includes(props.displayingRooms)
    default:
      return false
  }
}

const colorForChart = (color: string) => (props.flat || props.layout ? colorsInOrder[3].hex : color)

const series = computed<ApexOptions['series']>(() => {
  const studio = {
    name: t('flat.statistics.price.toggle.studio'),
    color: colorForChart(colorsInOrder[0].hex),
    data: [] as Array<Array<string | number>>,
  }

  const one = {
    name: t('flat.statistics.price.toggle.one'),
    color: colorForChart(colorsInOrder[1].hex),
    data: [] as Array<Array<string | number>>,
  }

  const two = {
    name: t('flat.statistics.price.toggle.two'),
    color: colorForChart(colorsInOrder[2].hex),
    data: [] as Array<Array<string | number>>,
  }

  const three = {
    name: t('flat.statistics.price.toggle.three'),
    color: colorForChart(colorsInOrder[3].hex),
    data: [] as Array<Array<string | number>>,
  }

  const copiedStatistics = ObjectHelper.copy(statistics.value)
  if (copiedStatistics) {
    Object.keys(copiedStatistics).forEach(key => {
      const copiedStudio = copiedStatistics[key].studio
      if (copiedStudio && checkDisplayingRoom(FLAT_PRICE_STATISTICS_DISPLAYING_ROOMS.studio)) {
        studio.data.push([key, copiedStudio.averagePricePerSqMeter])
      }
      const copiedOne = copiedStatistics[key].one
      if (copiedOne && checkDisplayingRoom(FLAT_PRICE_STATISTICS_DISPLAYING_ROOMS.one)) {
        one.data.push([key, copiedOne.averagePricePerSqMeter])
      }
      const copiedTwo = copiedStatistics[key].two
      if (copiedTwo && checkDisplayingRoom(FLAT_PRICE_STATISTICS_DISPLAYING_ROOMS.two)) {
        two.data.push([key, copiedTwo.averagePricePerSqMeter])
      }
      const copiedThree = copiedStatistics[key].three
      if (copiedThree && checkDisplayingRoom(FLAT_PRICE_STATISTICS_DISPLAYING_ROOMS.three)) {
        three.data.push([key, copiedThree.averagePricePerSqMeter])
      }
    })
  }

  const createdSeries = [studio, one, two, three].filter(i => typeof i !== 'number' && i.data.length) as ApexOptions['series']

  return createdSeries
})

const chartOptions = computed<ApexOptions>(() => {
  const categories: Array<string> = []

  if (statistics.value) {
    categories.push(...Object.keys(statistics.value))
  }

  return {
    ...APEX_DEFAULT_OPTIONS,
    chart: {
      ...APEX_DEFAULT_OPTIONS.chart,
      events: {
        zoomed: (_, options) => {
          [fromDate.value] = new Date(options.xaxis.min).toISOString().split('T')
        },
        beforeZoom: (e, { xaxis }) => {
          const minDifference = 172800000
          const zoomDifference = xaxis.max - xaxis.min
          if (zoomDifference <= minDifference) {
            return {
              xaxis: {
                min: e.minX,
                max: e.maxX,
              },
            }
          }
          return {
            xaxis: {
              min: xaxis.min,
              max: xaxis.max,
            },
          }
        },
      },
    },
    xaxis: {
      type: 'datetime',
      categories,
      tickPlacement: 'on',
      labels: {
        format: 'dd MMM yy',
        style: {
          fontSize: '12px',
          fontWeight: 600,
          cssClass: 'fill-current text-neutral-500',
        },
      },
    },
    yaxis: {
      labels: {
        show: true,
        style: {
          fontSize: '12px',
          fontWeight: 600,
          cssClass: 'fill-current text-neutral-500',
        },
        formatter: value => `${Math.round(value)} ${t(`currency.options.${props.currency}`)}`,
      },
      tooltip: {
        enabled: true,
        offsetX: -40,
      },
    },
    tooltip: {
      enabled: true,
      shared: true,
      style: {
        fontSize: '12px',
      },
      x: {
        show: true,
        format: 'dd MMM yy',
      },
      y: {
        formatter: (value, { seriesIndex }) => `<span class="text-neutral-500 font-semibold mr-3 flex">${t('flat.statistics.price.forSquareMeter', { currency: t(`currency.options.${props.currency}`) })}</span><span class="${colorsInOrder[seriesIndex].class} font-semibold flex min-w-[40px]">${value}</span>`,
        title: {
          formatter: value => `<span class="font-semibold flex">${value}</span>`,
        },
      },
    },
  } as ApexOptions
})

const displayingRooms = computed(() => {
  if (props.flat) {
    return FLAT_PRICE_STATISTICS_DISPLAYING_ROOMS[props.flat.flatRoomsCount]
  }
  if (props.layout) {
    return FLAT_PRICE_STATISTICS_DISPLAYING_ROOMS[props.layout.flats[0].flatRoomsCount]
  }

  return FLAT_PRICE_STATISTICS_DISPLAYING_ROOMS.all
})

const transformFlatData = (data: any) => data.reduce((acc, item) => {
  acc[item.date.slice(0, 10)] = {
    [displayingRooms.value]: {
      averagePricePerSqMeter: item.pricePerSqMeter,
    },
  }
  return acc
}, {})

const getStatistics = (changedFromDate?: string) => {
  emits('update:isStatisticsFetching', true)

  if (props.layout) {
    flatService.getLayoutStatistics({
      layoutId: props.layout?.layoutId,
      currency: props.currency,
      fromDate: changedFromDate || fromDate.value,
      toDate,
    })
      .then(res => {
        if (isEmpty(res) && !isEmpty(statistics.value)) {
          getStatistics(beginingFromDate)
        } else {
          statistics.value = transformFlatData(res)
        }
      })
      .finally(() => {
        emits('update:isStatisticsFetching', false)
      })
    return
  }

  if (props.flat) {
    flatService.getFlatStatistics({
      flatId: props.flat?.id,
      currency: props.currency,
      fromDate: changedFromDate || fromDate.value,
      toDate,
    })
      .then(res => {
        if (isEmpty(res) && !isEmpty(statistics.value)) {
          getStatistics(beginingFromDate)
        } else {
          statistics.value = transformFlatData(res)
        }
      })
      .finally(() => {
        emits('update:isStatisticsFetching', false)
      })
    return
  }

  let countryId: null | number = null
  let regionId: null | number = null
  let localityId: null | number = null

  if (addressStore.country?.id) {
    countryId = addressStore.country?.id

    if (addressStore.region?.region?.id) {
      regionId = addressStore.region?.region?.id
    }

    if (addressStore.city?.id) {
      localityId = addressStore.city?.id
    }
  }

  flatService.getStatistics({
    currency: props.currency,
    locality: localityId ? { id: localityId } : null,
    region: regionId ? { id: regionId } : null,
    building: props.buildingId ? { id: props.buildingId } : null,
    developer: props.developerId ? { id: props.developerId } : null,
    fromDate: changedFromDate || fromDate.value,
    toDate,
    country: countryId ? { id: countryId } : null,
    flatRoomsCountList: [FLAT_ROOMS_COUNT.studio, FLAT_ROOMS_COUNT.one, FLAT_ROOMS_COUNT.two, FLAT_ROOMS_COUNT.three],
  }).then(res => {
    if (isEmpty(res) && !isEmpty(statistics.value)) {
      getStatistics(beginingFromDate)
    } else {
      statistics.value = res
    }
  }).finally(() => {
    emits('update:isStatisticsFetching', false)
  })
}

watch(() => ({
  country: addressStore.country,
  region: addressStore.region,
  city: addressStore.city,
}), () => {
  getStatistics()
})

watch(fromDate, value => {
  getStatistics(value)
})

onMounted(() => {
  getStatistics()
})
</script>
