<script setup lang="ts">
import CustomButton from '~/ui/buttons/CustomButton.vue'
import CustomCubeSpinner from '~/ui/spinners/CustomCubeSpinner.vue'
import {AvailableCountry} from '~/common/types/country/Country.available'
import {Mortgage} from '~/modules/bank/types/Mortgage'
import {useAppStateStore} from '~/store/app'
import {MORTGAGE_DISPLAYING_TYPE} from '~/modules/bank/constants/Mortgage.displayingType'
import {MortgageDisplayingType} from '~/modules/bank/types/Mortgage.displayingType'
import ModalOrBottomSheet from '~/components/common/ModalOrBottomSheet.vue'
import BankMortgageSchedule from '~/modules/bank/components/mortgage/schedule/BankMortgageSchedule.vue'
import {BankService} from '~/modules/bank/services/BankService'
import {watchDebounced} from '@vueuse/core'
import {Pagination} from '~/common/types/pagination/Pagination'
import CustomPagination from '~/ui/pagination/CustomPagination.vue'
import BankMortgageInfo from '~/modules/bank/components/mortgage/BankMortgageInfo.vue'
import BankMortgageInputs from '~/modules/bank/components/mortgage/BankMortgageInputs.vue'
import BankMortgageTable from '~/modules/bank/components/mortgage/BankMortgageTable.vue'
import {useUserStore} from '~/modules/user/store'
import {BankGuestService} from '~/modules/bank/services/BankGuestService'

const props = defineProps({
  displayingType: {
    type: String as PropType<MortgageDisplayingType>,
    default: MORTGAGE_DISPLAYING_TYPE.page,
  },
  countryIsoCode: {
    type: String as PropType<AvailableCountry>,
    default: 'RU',
  },
})

const appStateStore = useAppStateStore()
const userStore = useUserStore()
const bankService = userStore.isUserAuthorized ? new BankService() : new BankGuestService()

const price = ref(3000000)
const initialPayment = ref(1000000)
const term = ref(20)
const stake = ref(3)

const isFetching = ref(false)
const isScheduleModalOpen = ref(false)

const mortgages = ref<Array<Mortgage>>([])
const selectedMortgage = ref<Mortgage | null>(null)
const mortgagesPagination = ref<Pagination | null>(null)

const monthPayment = ref(0)
const recommendedIncome = ref(0)

const mortgageSum = computed(() => price.value - initialPayment.value)

const currency = computed(() => appStateStore.currency)

const mortgageForRequest = computed(() => ({
  sum: {
    amount: Number(price.value),
    currency: currency.value,
  },
  maxSum: {
    amount: Number(price.value),
    currency: currency.value,
  },
  country: [props.countryIsoCode],
  initialPayment: price.value ? Number(Math.floor(initialPayment.value / price.value * 100 * 10) / 10) : 0,
  termInMonths: Number(term.value) * 12,
  stake: Number(stake.value),
}))

const onPageChange = (page: number): void => {
  searchAndCalculate(page)
}

const calculate = () => {
  if (price.value === 0 || term.value === 0) {
    mortgages.value = []
    return
  }

  bankService.calculate({
    sum: {
      amount: mortgageSum.value,
      currency: currency.value,
    },
    termInMonths: term.value * 12,
    stake: stake.value,
  })
    .then(res => {
      monthPayment.value = res.monthPayment
      recommendedIncome.value = res.recommendedIncome
    })
    .finally(() => {
    })
}

const searchAndCalculate = (page = 1) => {
  if (price.value === 0 || term.value === 0) {
    mortgages.value = []
    return
  }

  isFetching.value = true

  bankService.searchAndCalculate(mortgageForRequest.value, page)
    .then(res => {
      mortgages.value = res.data
      mortgagesPagination.value = {
        perPage: res.perPage,
        currentPage: res.currentPage,
        nextPageUrl: res.nextPageUrl,
        total: res.total,
        lastPage: res.lastPage,
      }
    })
    .finally(() => {
      isFetching.value = false
    })
}

const sendAllRequests = () => {
  calculate()
  searchAndCalculate()
}

watch(isScheduleModalOpen, value => {
  if (!value && selectedMortgage.value) {
    selectedMortgage.value = null
  }
})

watchDebounced(price, () => {
  sendAllRequests()
}, {debounce: 500})
watchDebounced(initialPayment, () => {
  sendAllRequests()
}, {debounce: 500})
watchDebounced(term, () => {
  sendAllRequests()
}, {debounce: 500})
watchDebounced(stake, () => {
  sendAllRequests()
}, {debounce: 500})
watch(currency, () => {
  sendAllRequests()
})

onMounted(() => {
  searchAndCalculate()
  calculate()
})
</script>

<template>
  <div>
    <bank-mortgage-inputs
      v-model:price="price"
      v-model:initialPayment="initialPayment"
      v-model:term="term"
      v-model:stake="stake"
      :displaying-type="displayingType"
    />
    <bank-mortgage-info
      class="mt-12"
      :month-payment="monthPayment"
      :recommended-income="recommendedIncome"
    />
    <div
      v-if="isFetching"
      class="flex justify-center items-center w-full"
    >
      <custom-cube-spinner/>
    </div>
    <bank-mortgage-table
      v-else-if="mortgages.length"
      :mortgages="mortgages"
      :country-iso-code="countryIsoCode"
      @open-schedule="selectedMortgage = $event; isScheduleModalOpen = true"
    />
    <div
      v-else
      class="mt-12"
    >
      {{ $t('mortgage.mortgageActions.showMortgageList', 0) }}
    </div>
    <custom-pagination
      v-if="mortgagesPagination && mortgagesPagination.lastPage > 1"
      :pagination="mortgagesPagination"
      class="justify-center mt-6"
      :is-fetching="isFetching"
      @on-page-change="onPageChange"
    />
    <div class="flex mt-6">
      <custom-button
        class="font-semibold flex items-center"
        pill
        with-default-message
      >
        <span>
          {{ $t('mortgage.mortgageActions.applyOnline') }}
        </span>
      </custom-button>
      <custom-button
        class="font-semibold flex items-center"
        variant="withoutBorders"
        pill
        @click="isScheduleModalOpen = true"
      >
        <nuxt-icon
          name="common/calendar-check"
          class="text-[20px]"
        />
        <span class="hidden md:inline ml-[5px]">
          {{ $t('mortgage.mortgageActions.showSchedule') }}
        </span>
      </custom-button>
    </div>
    <modal-or-bottom-sheet
      v-model="isScheduleModalOpen"
      :drawer-props="{fullHeight: true, hideHeader: false}"
      :modal-props="{size: 'w-full max-w-[1000px]', centered: false, hideFooter: true}"
      :title="$t('mortgage.schedule.title')"
    >
      <bank-mortgage-schedule
        :bank-service="bankService"
        :realty-price="price"
        :mortgage-sum="mortgageSum"
        :mortgage-for-request="selectedMortgage || mortgageForRequest"
      />
    </modal-or-bottom-sheet>
  </div>
</template>
