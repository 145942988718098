import { defineStore } from 'pinia'

import { defaultPagination } from '~/modules/market-entities-actions/constants/pagination/Pagination.default'
import { Pagination } from '~/common/types/pagination/Pagination'
import { JournalNewsType } from '~/modules/journal/types/JournalNews.type'

type JournalState = {
  promotion: any | null
  developerPromotions: any[] | null
  buildingPromotions: any[] | null
  developerPromotionsPagination: Pagination | null
  buildingPromotionsPagination: Pagination | null
  journalTypeFilter: JournalNewsType | null
}
export const useJournalStore = defineStore('journalStore', {
  state: () => ({
    promotion: null,
    developerPromotions: null,
    buildingPromotions: null,
    developerPromotionsPagination: { ...defaultPagination },
    buildingPromotionsPagination: { ...defaultPagination },
    journalTypeFilter: null,
  }) as JournalState,
  actions: {
    setPromotion(promotion: any) {
      this.promotion = promotion
    },
    resetPromotion() {
      this.promotion = null
    },
    setDeveloperPromotionsPagination(pagination: Pagination): void {
      this.developerPromotionsPagination = pagination
    },
    setBuildingFlatsPagination(pagination: Pagination): void {
      this.buildingPromotionsPagination = pagination
    },
    setDeveloperPromotions(promotions: any[], page = 1): void {
      if (page === 1) {
        this.developerPromotions = promotions
      }
      if (page > 1) {
        this.developerPromotions = [...this.developerPromotions, ...promotions]
      }
    },
    setBuildingPromotions(promotions: any[], page = 1): void {
      if (page === 1) {
        this.buildingPromotions = promotions
      }
      if (page > 1) {
        this.buildingPromotions = [...this.buildingPromotions, ...promotions]
      }
    },
  },
})
