<template>
  <div>
    <div>
      <div
        v-if="isHeaderVisible"
        class="flex items-center"
      >
        <h2 class="font-semibold  text-neutral-900 text-2xl">
          {{ $t('flat.decorationType.label') }}
        </h2>
        <div
          v-if="decorations?.length"
          class="flex items-end gap-x-5 ml-2 pl-2 border-l-gray-200 border-l-2"
        >
          <h4 class="text-neutral-600">
            {{ $t(`flat.decorationType.options.${selectedDecoration?.decorationType}.full`) }}
          </h4>
        </div>
      </div>
      <div class="flex flex-col lg:flex-row gap-x-3 mt-4">
        <div class="w-full lg:w-2/3">
          <building-page-houses-select-house
            v-model:selected-house="house"
            class="flex lg:hidden mb-6"
            :houses="building.houses"
            :house-info="houseInfo"
          />
          <custom-carousel
            :images="selectedDecoration?.images ?? []"
            max-height="450px"
            :default-image="defaultImage"
            is-full-screen
          />
        </div>
        <div class="w-full lg:w-1/3 pl-5">
          <building-page-houses-select-house
            v-model:selected-house="house"
            :houses="building.houses"
            :house-info="houseInfo"
            class="hidden lg:flex"
            :building="building"
          />
          <h4 class="mt-4 mb-3">
            {{ $t('flat.decorationType.options.label') }}
          </h4>
          <div
            v-if="decorations?.length"
            class="max-h-[248px] md:max-h-[265px] xl:max-h-[265px] overflow-y-auto scrollbar-thin"
          >
            <flat-decoration-list-item
              v-for="(decoration, index) in decorations"
              :key="index"
              v-model:selected-decoration="selectedDecoration"
              :decoration="decoration"
              :is-first="index === 0"
              :is-last="index === decorations.length - 1"
              :country-code="building.address.countryIsoCode"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import BuildingPageHousesSelectHouse from '~/modules/building/components/page/houses/BuildingPageHousesSelectHouse.vue'
import CustomCarousel from '~/ui/carousels/CustomCarousel.vue'
import defaultImage from '~/assets/images/building/decoration-default-image.svg'
import { BuildingExpanded } from '~/modules/building/types/Building.expanded'
import { HouseExpanded } from '~/common/types/house/House.expanded'
import { Decoration } from '~/common/types/decoration/Decoration'
import FlatDecorationListItem from '~/components/flats/decoration/FlatDecorationListItem.vue'

const props = defineProps({
  building: {
    type: Object as PropType<BuildingExpanded>,
    required: true,
  },
  isHeaderVisible: {
    type: Boolean,
    default: true,
  },
})

const house = ref<HouseExpanded | null>(props.building.houses?.[0])
const isAllHousesShow = computed<boolean>(() => house.value === null)
const decorations = computed<Array<Decoration> | undefined>(() => {
  if (isAllHousesShow.value) {
    return props.building.houses?.map(buildingHouse => buildingHouse.decorations).flat(1)
  }
  return house.value?.decorations
})
const selectedDecoration = ref<Decoration | undefined>(decorations.value?.[0])
const houseInfo = computed(() => {
  if (isAllHousesShow.value) {
    return props.building.housesInfo
  }
  return house.value?.houseInfo ?? props.building.housesInfo
})

watch(house, () => {
  selectedDecoration.value = decorations.value?.[0]
})

</script>
