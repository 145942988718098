<template>
  <form
    class="flex flex-col md:pl-6 grow gap-y-4 self-start w-full h-full md:w-auto"
    @submit.prevent="!client ? createClient() : updateClient()"
  >
    <div
      class="flex justify-between"
    >
      <h2 class="hidden md:block font-semibold text-neutral-900 text-[1.72rem]">
        {{ !client ? $t('suggestionsActions.client.create.title') : $t('clients.table.actions.edit') }}
      </h2>
      <slot name="closeButton" />
    </div>
    <div class="flex flex-col gap-y-2 p-6 rounded-lg border-2 border-neutral-100">
      <div
        class="min-h-[95px]"
      >
        <custom-input
          v-model="fullName"
          :label="$t('suggestionsActions.client.create.fullName.label')"
          :placeholder="$t('suggestionsActions.client.create.fullName.placeholder')"
          input-class="min-h-[38px]"
          :invalid-feedback="fullNameError"
          :state="fullNameError ? false : null"
          required
        />
      </div>
      <div
        class="min-h-[95px]"
      >
        <phone-input
          v-model:mobile-phone="mobilePhone"
          :label="$t('suggestionsActions.client.create.mobilePhone.label')"
          label-class="form-field__required"
          :placeholder="$t('suggestionsActions.client.create.mobilePhone.placeholder')"
          input-class="min-h-[38px]"
          :errors="[mobilePhoneError as string]"
        />
      </div>
      <div
        class="min-h-[95px]"
      >
        <custom-input
          v-model="email"
          :label="$t('suggestionsActions.client.create.email.label')"
          :placeholder="$t('suggestionsActions.client.create.email.placeholder')"
          input-class="min-h-[38px]"
          :invalid-feedback="emailNameError"
          :state="emailNameError ? false : null"
        />
      </div>
      <div
        class="min-h-[95px]"
      >
        <labeled-wrapper
          label-class="font-medium text-lg inter form-field__required"
          :label="$t('clients.fixation.form.user.title')"
        >
          <template #content>
            <select-auto-suggest
              v-model="assigned"
              :fetch="searchUsersByName"
              :option-label="({firstName, lastName}: User) => `${firstName} ${lastName || ''}`"
              :invalid-feedback="assignedError"
              :placeholder="$t('clients.fixation.form.user.select')"
              infinite-scroll
            />
          </template>
        </labeled-wrapper>
      </div>
      <custom-button
        class="flex w-full h-[38px] items-center justify-center"
        pill
        type="submit"
        :disabled="disableBtnSave"
      >
        <nuxt-icon
          class="text-[20px]"
          name="actions/person-plus"
        />
        <span class="font-semibold ml-2">{{ client ? $t('common.save') : $t('suggestionsActions.client.create.submit') }}</span>
      </custom-button>
    </div>
  </form>
</template>

<script setup lang="ts">
import PhoneInput from '~/components/inputs/PhoneInput.vue'
import CustomButton from '~/ui/buttons/CustomButton.vue'
import CustomInput from '~/ui/inputs/CustomInput.vue'
import type { Client } from '~/modules/clients/types/Client'
import { ClientsService } from '~/modules/clients/services/ClientsService'
import SentryLogger from '~/services/sentry/SentryLogger'
import { User } from '~/modules/user/types/user'
import SelectAutoSuggest from '~/components/common/AutoSuggest/SelectAutoSuggest.vue'
import { UserService } from '~/modules/user/services/UserService'
import LabeledWrapper from '~/components/common/LabeledWrapper/LabeledWrapper.vue'
import { COLOR } from '~/ui/constants/color'

const props = defineProps({
  client: {
    type: Object as PropType<Client | null>,
    default: null,
  },
})

const emits = defineEmits<{(e: 'update:add', value: Client): void,
  (e: 'update:edit', value: Client): void,
}>()

const isFetching = ref(false)
const service = new ClientsService()
const userService = new UserService()
const { t } = useI18n()

const { handleSubmit, resetForm, setErrors } = useForm()
const { value: fullName, errorMessage: fullNameError } = useField<string>('fullName', { required: true, min: 1 }, {
  label: t('suggestionsActions.client.create.fullName.label'), initialValue: props.client ? props.client.fullName : '',
})
const { value: email, errorMessage: emailNameError } = useField<string>('email', { userEmail: true }, {
  label: t('suggestionsActions.client.create.email.label'), initialValue: props.client ? props.client.email : '',
})

const { value: mobilePhone, errorMessage: mobilePhoneError } = useField<string>('phone', { required: true }, { label: t('suggestionsActions.client.create.mobilePhone.label'), initialValue: props.client ? props.client.phone : '' })

const { value: assigned, errorMessage: assignedError } = useField<User>('assigned', { required: true }, { label: t('clients.fixation.form.user.title'), initialValue: props.client ? props.client.assigned : undefined })
const searchUsersByName = (name: string) => userService.search({ isDeleted: false, firstOrLastName: name })

const createClient = handleSubmit(() => {
  isFetching.value = true
  service.createClient(fullName.value, mobilePhone.value, email.value, assigned.value)
    .then(data => {
      emits('update:add', data)
      resetForm({
        values: {
          fullName: '',
          email: '',
          phone: null,
          assigned: undefined,
        },
      })
      toast(t('suggestionsActions.client.create.toast.success'), { variant: COLOR.success })
    })
    .catch(response => {
      SentryLogger.captureScopeException(response)
      if (response.status === 422) {
        const { errors } = response.data
        setErrors(errors)
      } else {
        toast(t('suggestionsActions.client.create.toast.error'), { variant: COLOR.warning })
      }
    })
    .finally(() => {
      isFetching.value = false
    })
})

const updateClient = handleSubmit(() => {
  service.editClient(props.client.id, fullName.value, mobilePhone.value, email.value, assigned.value)
    .then(data => {
      emits('update:edit', data)
    })
    .catch(response => {
      if (response.status === 422) {
        const { errors } = response.data
        setErrors(errors)
      }
    })
})

const disableBtnSave = computed(() => isFetching.value || (!!props.client && props.client.fullName === fullName.value && props.client.email === email.value && props.client.phone === mobilePhone.value && props.client.assigned.id === assigned.value?.id))
</script>
