import { JournalHttpApiService } from '~/modules/journal/api/JournalHttpApiService'
import SentryLogger from '~/services/sentry/SentryLogger'
import { HttpApiService } from '~/services/http/HttpApiService'
import { JournalNews } from '~/modules/journal/types/JournalNews'
import { HttpPaginatedResponseData } from '~/services/http/types/Http.paginatedResponseData'
import { JournalNewsFilter } from '~/modules/journal/types/JournalNews.filter'
import { JournalServiceInterface } from '~/modules/journal/interfaces/JournalServiceInterface'
import { useAddressStore } from '~/modules/address/store'

export class JournalService implements JournalServiceInterface {
  http: JournalHttpApiService

  constructor() {
    const nuxtApp = useNuxtApp()
    const httpInstance = nuxtApp.$qdHttpApiInstance
    this.http = new JournalHttpApiService(httpInstance as HttpApiService)
  }

  public search(filter?: JournalNewsFilter, page = 1) {
    return new Promise<HttpPaginatedResponseData<JournalNews>>((resolve, reject) => {
      const store = useAddressStore()
      const country = store.country && !store.getLowestToponymAddress ? store.country : undefined
      const address = store.getLowestToponymAddress ? [store.getLowestToponymAddress] : undefined

      this.http.search({ ...filter, address, country }, page)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось выполнить поиск новостей',
            filter,
            page,
          })
          reject(error)
        })
    })
  }

  public createNews(news: FormData) {
    return new Promise<JournalNews>((resolve, reject) => {
      this.http.createNews(news)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось создать новость',
            news,
          })
          reject(error)
        })
    })
  }

  public updateNews(newsId: number, news: FormData) {
    return new Promise<JournalNews>((resolve, reject) => {
      this.http.updateNews(newsId, news)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось отредактировать новость',
            news,
          })
          reject(error)
        })
    })
  }

  public getOneNews(slug: string) {
    return new Promise<JournalNews>((resolve, reject) => {
      this.http.getOneNews(slug)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось получить новость',
            slug,
          })
          reject(error)
        })
    })
  }

  public searchDeveloperPromotions(developerId: number) {
    return new Promise<HttpPaginatedResponseData<JournalNews>>((resolve, reject) => {
      this.http.searchDeveloperPromotions(developerId)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось выполнить поиск акций застройщика',
            developerId,
          })
          reject(error)
        })
    })
  }

  public searchBuildingPromotions(buildingId: number) {
    return new Promise<HttpPaginatedResponseData<JournalNews>>((resolve, reject) => {
      this.http.searchBuildingPromotions(buildingId)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось выполнить поиск акций ЖК',
            buildingId,
          })
          reject(error)
        })
    })
  }
}
