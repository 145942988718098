import { TmpStorage } from '~/common/storage/TmtStorage'
import { QdStorage as IQdStorage } from '~/common/storage/interfaces/QdStorage'

export default class QdStorage implements IQdStorage {
  protected _storage: Storage

  constructor(storage?: Storage) {
    if (storage) {
      this._storage = storage
    } else {
      this._storage = this.isAvailableLocalStorage ? localStorage : new TmpStorage()
    }
  }

  private get isAvailableLocalStorage() {
    try {
      localStorage.setItem('test', 'test')
      localStorage.removeItem('test')
      return true
    } catch (e: any) {
      return false
    }
  }

  public async setItem(key: string, value: any): Promise<void> {
    const storedValue = JSON.stringify(value)
    await this._storage.setItem(key, storedValue)
  }

  public getItem<T>(key: string): Promise<T | null> {
    const storedValue = this._storage.getItem(key)
    if (storedValue === null) {
      return Promise.resolve(null)
    }
    try {
      return Promise.resolve(JSON.parse(storedValue))
    } catch (error) {
      return Promise.resolve(null)
    }
  }

  public async clear(): Promise<void> {
    this._storage.clear()
  }

  public async removeItem(key: string): Promise<void> {
    this._storage.setItem(key, JSON.stringify(null))
  }
}
