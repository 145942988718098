<template>
  <div>
    <textarea
      v-model="textareaValue"
      v-bind="$attrs"
      class="resize-none font-medium placeholder:text-neutral-500 border-2 border-neutral-150 focus:ring-0 hover:ring-0 hover:border-neutral-500 focus:border-neutral-950 rounded-lg"
      :class="{'!border-danger': invalidFeedback}"
      :disabled="disabled"
    />
    <div
      v-if="invalidFeedback"
      class="mt-2 text-danger"
    >
      {{ invalidFeedback }}
    </div>
  </div>
</template>
<script setup lang="ts">
const props = defineProps({
  modelValue: {
    type: String as PropType<string | null>,
    default: null,
  },
  invalidFeedback: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
})

const emits = defineEmits<{(e: 'update:modelValue', value: string | null): void}>()

const textareaValue = computed({
  get: () => props.modelValue || '',
  set: (value: string) => { emits('update:modelValue', value) },
})
</script>
