import { MapService } from '~/common/services/map/yandex/MapService'
import { MapModule } from '~/common/interfaces/map/yandex/Map.module'
import buildingIcon from '~/assets/icons/entities/building.svg'
import closeIcon from '~/assets/icons/actions/close.svg'
import { SuggestionBuilding } from '~/modules/suggestions/types/Suggestion.building'
import { YANDEX_MAP_DEFAULT_CONFIG } from '~/common/constants/map/yandex/Map.defaultConfig'
import { Building } from '~/modules/building/BuildingModule'
import { MapFeatureDrawerService } from '~/common/services/map/yandex/MapFeatureDrawerService'
import { MapBuildingFeatureObject } from '~/common/types/map/ymaps.buildingFeatureObject'
import { ButtonService } from '~/common/services/map/yandex/ButtonService'

type MapCallbacks = {
  onFeatureClick?: (id: number) => void
  onCloseClick?: () => void
}

type MapBuilding = Building & { flatsCount?: number} | SuggestionBuilding & { flatsCount?: number}

export class BuildingListMapService extends MapService {
  private callbacks
  private buildings
  private featureDrawerService: MapFeatureDrawerService | undefined
  private withoutFlatsCount

  constructor(
    mapConfig = { state: {}, options: {} },
    modules: Record<string, MapModule> = {},
    callbacks: MapCallbacks = {},
    buildings: Array<MapBuilding> = [],
    withoutFlatsCount = false,
  ) {
    super(mapConfig, modules)

    this.callbacks = callbacks
    this.buildings = buildings
    this.withoutFlatsCount = withoutFlatsCount
  }

  public async createMap(mapId: string) {
    await super.createMap(mapId)
    this.featureDrawerService = new MapFeatureDrawerService(
      this.map,
      {
        clusterize: true,
        clusterOpenBalloonOnClick: false,
        geoObjectOpenBalloonOnClick: false,
      },
      { onFeatureClick: this.callbacks.onFeatureClick },
      buildingIcon,
    )
    this.changeControlsPosition()
    this.callbacks.onCloseClick && this.map.controls.add(this.createCloseButton())

    this.displayPointsOnMap()
  }

  public displayPointsOnMap(externalBuildings?: Array<MapBuilding>): void {
    if (this.isMapExist()) {
      this.featureDrawerService?.drawFeatures(this.createFeatureCollectionFromBuildings(externalBuildings))
      this.changeMapBounds(externalBuildings)
    }
  }

  private createFeatureCollectionFromBuildings(externalBuildings?: Array<MapBuilding>) {
    const buildings = externalBuildings || this.buildings
    const featureCollection: ymaps.IFeatureCollection<MapBuildingFeatureObject> = {
      type: 'FeatureCollection',
      features: [],
    }

    buildings.forEach((building, id) => {
      const coordinates = this.getBuildingCoordinates(building)
      const flatsCount = this.withoutFlatsCount ? 0 : building.flatsCount ?? this.getFlatsCount(building)

      if (coordinates) {
        featureCollection.features.push({
          type: 'Feature',
          id,
          geometry: {
            type: 'Point',
            coordinates,
          },
          properties: {
            object: {
              flatsCount,
              name: building.name,
              coordinates,
              id: building.id,
            },
          },
        })
      }
    })

    return featureCollection
  }

  private createCloseButton(): ymaps.control.Button {
    return ButtonService.createButton({
      image: closeIcon,
      content: this.i18n.t('map.actions.showGrid'),
      layout: '<button class="flex items-center text-primary font-semibold bg-white rounded-full button-shadow" style="height: 38px; width: 38px; justify-content: center;">' +
            '<span class="block w-[25px] h-[25px] bg-contain" style="background-image: url(&quot;{{ data.image }}&quot;)"></span>' +
            '</button>',
      float: 'right',
      floatIndex: 200,
      onClick: this.callbacks.onCloseClick,
    })
  }

  private getBuildingCoordinates(building: MapBuilding): ymaps.Coordinate | null {
    if (building.geoLat && building.geoLon) {
      return [building.geoLat, building.geoLon] as ymaps.Coordinate
    }
    if (building.address?.geoLat && building.address?.geoLon) {
      return [building.address.geoLat, building.address.geoLon] as ymaps.Coordinate
    }
    return null
  }

  private getFlatsCount(building: MapBuilding): number {
    let flatsCount = 0

    building.houses?.forEach(house => {
      house.sections?.forEach(section => {
        flatsCount = section.flats?.length ? flatsCount + section.flats.length : flatsCount + 0
      })
    })

    return flatsCount
  }

  private changeMapBounds(newBuildings?: Array<MapBuilding>) {
    const buildings = newBuildings || this.buildings
    if (buildings.length === 1) {
      super.setMapCenter(this.getBuildingCoordinates(buildings[0]), YANDEX_MAP_DEFAULT_CONFIG.state.zoom)
    } else if (buildings.length) {
      super.setMapBounds(this.featureDrawerService?.objectManager.getBounds() as ymaps.CoordinateBounds | null)
    }
  }

  private changeControlsPosition() {
    const trafficControlObj = this.map.controls.get('trafficControl')
    trafficControlObj && trafficControlObj.options.set('position', { top: '70px', right: '10px' })
    const typeSelectorControlObj = this.map.controls.get('typeSelector')
    typeSelectorControlObj && typeSelectorControlObj.options.set('position', { top: '110px', right: '10px' })
  }
}
