import {MortgageCalculation} from '~/modules/bank/types/Mortgage.calculation'
import {HttpApiService} from '~/services/http/HttpApiService'
import {HttpHandledResponse} from '~/services/http/types/Http.handledResponse'
import {Service} from '~/services/http/Service'
import {MortgageForRequest} from '~/modules/bank/types/Mortgage.forRequest'
import {HttpPaginatedResponseData} from '~/services/http/types/Http.paginatedResponseData'
import {Mortgage} from '~/modules/bank/types/Mortgage'
import {MortgageSchedule} from '~/modules/bank/types/Mortgage.schedule'
import {MortgageForRequestCalculate} from '~/modules/bank/types/Mortgage.forRequestCalculate'

export class BankGuestHttpApiService extends Service {
  private endPoints = {
    firstPart: '/v4/estate-market/guest',
    banks: '/banks',
    mortgages: '/mortgages',
    searchAndCalculate: '/search-and-calculate',
    calculate: '/calculate',
    paymentSchedule: '/payment-schedule',
    downloadSchedule: '/download-payment-schedule',
  }

  constructor(httpInstance: HttpApiService) {
    super(httpInstance)
  }

  searchAndCalculate(mortgage: MortgageForRequest, page: number): Promise<HttpHandledResponse<HttpPaginatedResponseData<Mortgage>>> {
    return this.httpInstance.post(`${this.endPoints.firstPart}${this.endPoints.banks}${this.endPoints.mortgages}${this.endPoints.searchAndCalculate}`, {
      ...mortgage,
      page
    })
  }

  calculate(mortgageRequest: MortgageForRequestCalculate): Promise<HttpHandledResponse<MortgageCalculation>> {
    return this.httpInstance.post(`${this.endPoints.firstPart}${this.endPoints.banks}${this.endPoints.mortgages}${this.endPoints.calculate}`, mortgageRequest)
  }

  getPaymentSchedule(mortgageRequest: MortgageForRequestCalculate): Promise<HttpHandledResponse<Array<MortgageSchedule>>> {
    return this.httpInstance.post(`${this.endPoints.firstPart}${this.endPoints.banks}${this.endPoints.mortgages}${this.endPoints.paymentSchedule}`, mortgageRequest)
  }

  downloadSchedule(mortgageRequest: MortgageForRequestCalculate): Promise<HttpHandledResponse<Blob>> {
    return this.httpInstance.post(`${this.endPoints.firstPart}${this.endPoints.banks}${this.endPoints.mortgages}${this.endPoints.downloadSchedule}`, mortgageRequest)
  }
}
