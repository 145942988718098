<template>
  <div class="md:flex md:gap-x-6">
    <form
      class="grow flex flex-col gap-y-6"
      @submit.prevent="emits('submit')"
    >
      <custom-card
        v-if="progress"
        class-name="rounded-lg"
      >
        <progress-bar :progress="progress">
          <template #label>
            <h3 class="w-1/3 text-neutral-500 text-2xl font-semibold hidden lg:block">
              {{ $t('clients.fixation.form.progress') }}
            </h3>
          </template>
          <template
            v-if="isMd"
            #info
          >
            <slot name="progressInfo" />
          </template>
        </progress-bar>
      </custom-card>
      <slot
        v-if="!isMd"
        name="mobileAbout"
      />
      <slot />
      <custom-card class-name="rounded-lg font-semibold">
        <custom-button
          type="submit"
          class="w-full md:w-auto md:ml-auto block"
          pill
          :disabled="isFetching"
        >
          {{ buttonTitle }}
        </custom-button>
      </custom-card>
    </form>
    <div
      v-if="isMd && $slots.about"
      class="w-1/3 lg:w-1/4"
    >
      <slot name="about" />
    </div>
  </div>
</template>

<script setup lang="ts">
import ProgressBar from '~/components/common/ProgressBar/ProgressBar.vue'
import CustomCard from '~/ui/cards/CustomCard.vue'
import CustomButton from '~/ui/buttons/CustomButton.vue'
import { useAppStateStore } from '~/store/app'

defineProps({
  buttonTitle: {
    type: String,
    required: true,
  },
  progress: {
    type: Number,
    default: 0,
    validator(value: number) {
      return value >= 0 && value <= 100
    },
  },
  isFetching: {
    type: Boolean,
    default: false,
  },
})

const emits = defineEmits<{(e: 'submit'): void}>()

const isMd = computed(() => useAppStateStore().breakpoints.isMd)
</script>
