import { HttpApiService } from '~/services/http/HttpApiService'
import { HttpHandledResponse } from '~/services/http/types/Http.handledResponse'
import { Service } from '~/services/http/Service'
import { JournalNews } from '~/modules/journal/types/JournalNews'
import { JournalNewsFilter } from '~/modules/journal/types/JournalNews.filter'
import { HttpPaginatedResponseData } from '~/services/http/types/Http.paginatedResponseData'

export class JournalHttpApiService extends Service {
  private endPoints = {
    firstPart: '/v4/estate-market',
    news: '/news',
    search: '/search',
    update: '/update',
    development: '/development',
    developers: '/developers',
    buildings: '/buildings',
    promotions: '/promotions',
  }

  constructor(httpInstance: HttpApiService) {
    super(httpInstance)
  }

  public createNews(news: FormData): Promise<HttpHandledResponse<JournalNews>> {
    return this.httpInstance.post(`${this.endPoints.firstPart}${this.endPoints.news}`, news, { headers: { 'Content-Type': 'multipart/form-data' } })
  }

  public updateNews(newsId: number, news: FormData): Promise<HttpHandledResponse<JournalNews>> {
    return this.httpInstance.post(`${this.endPoints.firstPart}${this.endPoints.news}${this.endPoints.update}/${newsId}`, news, { headers: { 'Content-Type': 'multipart/form-data' } })
  }

  public getOneNews(slug: string): Promise<HttpHandledResponse<JournalNews>> {
    return this.httpInstance.get(`${this.endPoints.firstPart}${this.endPoints.news}/${slug}`)
  }

  public search(filter?: JournalNewsFilter, page = 1): Promise<HttpHandledResponse<HttpPaginatedResponseData<JournalNews>>> {
    return this.httpInstance.post(`${this.endPoints.firstPart}${this.endPoints.news}${this.endPoints.search}`, {
      filter,
      page,
    })
  }

  public searchDeveloperPromotions(developerId: number): Promise<HttpHandledResponse<HttpPaginatedResponseData<JournalNews>>> {
    return this.httpInstance.get(`${this.endPoints.firstPart}${this.endPoints.development}${this.endPoints.developers}/${developerId}${this.endPoints.promotions}`)
  }

  public searchBuildingPromotions(buildingId: number): Promise<HttpHandledResponse<HttpPaginatedResponseData<JournalNews>>> {
    return this.httpInstance.get(`${this.endPoints.firstPart}${this.endPoints.development}${this.endPoints.buildings}/${buildingId}${this.endPoints.promotions}`)
  }
}
