<template>

  <client-form-field-template
    :title="$t('clients.views.status')"
    :progress-step-percent="progressStepPercent"
    :progress-step-done="progressStepDone"
  >
    <labeled-wrapper
      label-class="font-medium text-lg inter"
      :label="$t('clients.views.status')"
    >
      <template #content>
        <custom-autocomplete
          v-model="status"
          :options="options"
          value-label="value"
          option-label="label"
          :placeholder="$t('clients.views.actions.selectStatus')"
          :invalid-feedback="errorMessage"
        />
      </template>
    </labeled-wrapper>
  </client-form-field-template>
</template>
<script setup lang="ts">
import ClientFormFieldTemplate from '~/modules/clients/components/forms/ClientFormFieldTemplate.vue'
import LabeledWrapper from '~/components/common/LabeledWrapper/LabeledWrapper.vue'
import { ViewStatus } from '~/modules/clients/types/Clients.viewStatus'
import CustomAutocomplete from '~/ui/selects/CustomAutocomplete.vue'
import { CLIENTS_VIEW_STATUS } from '~/modules/clients/constants/Clients.viewStatus'

const props = defineProps({
  progressStepPercent: {
    type: Number,
    default: 0,
  },
  progressStepDone: {
    type: Boolean,
    default: false,
  },
  status: {
    type: String as PropType<ViewStatus | null>,
    default: null,
  },
})

const { t } = useI18n()

const options = Object.values(CLIENTS_VIEW_STATUS).map(value => ({
  value,
  label: t(`clients.views.statuses.${value}`),
}))

const { value: status, errorMessage } = useField<ViewStatus | null>(
  'status',
  { required: true },
  { initialValue: props.status, label: t('clients.views.status') },
)
</script>
