<template>
  <custom-tooltip placement="bottom">
    <template #content>
      {{ $t('building.actions.fixingTheClient.label') }}
    </template>
    <custom-button-icon
      icon="common/user-check"
      :class="buttonClass"
      color="secondary"
      round
      @click="showAuthModalOrCallback(fix)"
    />
  </custom-tooltip>
</template>

<script setup lang="ts">
import CustomButtonIcon from '~/ui/buttons/CustomButtonIcon.vue'
import CustomTooltip from '~/ui/tooltips/CustomTooltip.vue'
import { showAuthModalOrCallback } from '~/modules/auth/helpers/showAuthModalOrCallback'

defineProps({
  buttonClass: {
    type: [String, Object, Array],
    default: '',
  },
})

const emits = defineEmits<{(e: 'click'): void }>()

const fix = () => {
  emits('click')
}

</script>
