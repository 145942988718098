<template>
  <client-form-field-template
    :title="$t('clients.booking.form.deal.title')"
    :progress-step-percent="progressStepPercent"
    :progress-step-done="progressStepDone"
  >
    <labeled-wrapper
      label-class="font-medium text-lg inter"
      :label="$t('clients.booking.form.deal.label')"
    >
      <template #content>
        <custom-autocomplete
          v-model="paymentType"
          :options="options"
          value-label="value"
          option-label="label"
          :placeholder="$t('clients.booking.form.deal.placeholder')"
          :invalid-feedback="errorPaymentType"
        />
      </template>
    </labeled-wrapper>
    <client-booking-form-deal-upload-input class="mt-6" />
  </client-form-field-template>
</template>

<script setup lang="ts">
import ClientFormFieldTemplate from '~/modules/clients/components/forms/ClientFormFieldTemplate.vue'
import LabeledWrapper from '~/components/common/LabeledWrapper/LabeledWrapper.vue'
import ClientBookingFormDealUploadInput from '~/modules/clients/components/forms/clients-booking/ClientBookingFormDealUploadInput.vue'
import CustomAutocomplete from '~/ui/selects/CustomAutocomplete.vue'
import { CLIENT_BOOKING_PAYMENT_TYPE } from '~/modules/clients/constants/ClientBooking.paymentType'

defineProps({
  progressStepPercent: {
    type: Number,
    default: 0,
  },
  progressStepDone: {
    type: Boolean,
    default: false,
  },
})

const { t } = useI18n()

const options = Object.values(CLIENT_BOOKING_PAYMENT_TYPE).map(value => ({
  value,
  label: t(`clients.booking.paymentType.options.${value}`),
}))

const { value: paymentType, errorMessage: errorPaymentType } = useField<string | null>(
  'paymentType',
  { required: true },
  { initialValue: null, label: t('clients.booking.form.deal.label') },
)
</script>
