import { AvailableLanguages } from '~/modules/app-language/types/language'
import { AvailableCountry } from '~/common/types/country/Country.available'
import {
  getAvailableLanguageByCountryName,
} from '~/modules/app-language/helpers/Language.getAvailableLanguageByCountryName'

type FunctionProps = {
  name: string
  internationalName?: string | null
  defaultName?: string
  entityCountry: AvailableCountry | undefined
}
export const getEntityName = (props: FunctionProps): string | undefined => {
  const {$i18n} = useNuxtApp()
  const { name, internationalName, defaultName, entityCountry } = props
  const appLanguage = computed(() => $i18n.locale.value as AvailableLanguages).value

  const entityLanguage = entityCountry ? getAvailableLanguageByCountryName(entityCountry) : null
  if (!name && !internationalName) { return defaultName }

  if (appLanguage === entityLanguage) {
    return name || defaultName
  }
  return internationalName || name || defaultName
}
