<template>
  <client-only>
    <component
      :is="modal"
      v-model="isModalOpen"
      v-bind="{...$attrs, ...innerProps}"
    >
      <slot />
      <template #header>
        <slot name="header" />
      </template>
      <template #body>
        <slot name="body" />
      </template>
      <template #footer>
        <slot name="footer" />
      </template>
    </component>
  </client-only>
</template>
<script setup lang="ts">
import type { Raw } from 'vue'
import CustomModal from '~/ui/modals/CustomModal.vue'
import CustomDrawer from '~/ui/drawers/CustomDrawer.vue'

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: null,
  },
  drawerProps: {
    type: Object,
    default: () => ({}),
  },
  modalProps: {
    type: Object,
    default: () => ({}),
  },
  footerClass: {
    type: [String, Object, Array],
    default: null,
  },
  bodyClass: {
    type: [String, Object, Array],
    default: null,
  },
})

const emits = defineEmits<{(e: 'update:modelValue', value: boolean): void}>()

const modal = ref<Raw<any>>()
const innerProps = ref<Record<string, any>>({})

const isModalOpen = computed({
  get: () => props.modelValue,
  set: value => { emits('update:modelValue', value) },
})

const sizeEffect = effectScope()
onMounted(() => {
  sizeEffect.run(() => {
    watchEffect(() => {
      const isLg = useBreakpoints().smallerOrEqual('lg').value
      modal.value = isLg ? markRaw(CustomDrawer) : markRaw(CustomModal)
      innerProps.value = isLg ? props.drawerProps : props.modalProps
    })
  })
})

</script>
