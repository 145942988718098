<template>
  <div class="flex gap-5 max-h-[25px] ">
    <custom-button
      pill
      variant="outline"
      size="xs"
      class=" font-semibold !border-neutral-200 !text-black"
      :class="{'!border-primary !text-primary': notFirstFloor}"
      @click="toggleNotFirstFloor"
    >
      {{ t('flat.notFirstFloor.label') }}
    </custom-button>
    <custom-button
      pill
      size="xs"
      variant="outline"
      class=" font-semibold !border-neutral-200 !text-black"
      :class="{'!border-primary !text-primary': lastFloor}"
      @click="toggleLastFloor"
    >
      {{ t('flat.lastFloor.label') }}
    </custom-button>
  </div>

</template>
<script setup lang="ts">

import CustomButton from '~/ui/buttons/CustomButton.vue'
import { defaultFilter } from '~/modules/market-entities-actions/constants/filter/Filter.default'

const props = defineProps({
  notFirstFloor: {
    type: Object as PropType<boolean | null>,
    default: () => defaultFilter().flatFilter.notFirstFloor,
  },
  lastFloor: {
    type: Object as PropType<boolean | null>,
    default: () => defaultFilter().flatFilter.lastFloor,
  },
})

const emits = defineEmits(['update:notFirstFloor', 'update:lastFloor'])

const { t } = useI18n()

const notFirstFloor = ref(props.notFirstFloor)
const lastFloor = ref(props.lastFloor)
const toggleNotFirstFloor = () => {
  notFirstFloor.value = !notFirstFloor.value
  emits('update:notFirstFloor', notFirstFloor.value ? true : null)
}

const toggleLastFloor = () => {
  lastFloor.value = !lastFloor.value
  emits('update:lastFloor', lastFloor.value ? true : null)
}

watchEffect(() => {
  notFirstFloor.value = props.notFirstFloor
  lastFloor.value = props.lastFloor
})
</script>
