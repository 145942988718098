<template>
  <div>
    <h2
      v-if="isHeaderVisible"
      class="font-semibold  text-neutral-900 text-2xl"
    >
      {{ $t('building.page.constructionProgress.label.full') }}
    </h2>
    <div class="flex flex-col md:flex-row lg:flex-row xl:flex-row gap-x-3 mt-[25px]">
      <div class="w-full md:w-2/3 lg:w-2/3 xl:w-2/3">
        <template v-if="isSm">
          <building-page-house-select
            v-model:selected-value="selectedConstructionProgressVariant"
            :variants="constructionProgressSelectVariants"
          />
          <building-page-quarters-select-year
            v-model:selected-year="year"
            :years="constructionProgressList"
            class="mb-6"
          />
        </template>
        <custom-carousel
          :images="quartersImages"
          max-height="450px"
          :min-height="isSm ? '150px' : '450px'"
          :default-image="defaultImage"
          :preferred-video-index="0"
        />
      </div>
      <div class="w-full md:w-1/3 lg:w-1/3 xl:w-1/3 pl-5">
        <template v-if="!isSm">
          <building-page-house-select
            v-model:selected-value="selectedConstructionProgressVariant"
            :variants="constructionProgressSelectVariants"
          />
          <building-page-quarters-select-year
            v-model:selected-year="year"
            :years="constructionProgressList"
            class="mb-7"
          />
        </template>
        <building-page-quarter-item
          v-for="(item, index) in sortedQuarters"
          :key="item.id"
          :quarter="item"
          :selected-quarter-id="selectedQuarterId"
          :is-first="index === 0"
          :is-last="index === sortedQuarters.length - 1"
          @update:selected-quarter-id="selectedQuarterId = $event"
          @click="selectedQuarter = sortedQuarters[index]"
        />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { BuildingExpanded } from '~/modules/building/types/Building.expanded'
import defaultImage from 'assets/images/building/decoration-default-image.svg'
import CustomCarousel from '~/ui/carousels/CustomCarousel.vue'
import BuildingPageQuartersSelectYear
  from '~/modules/building/components/page/constructionProgress/BuildingPageQuartersSelectYear.vue'
import { Image } from '~/common/types/image/Image'
import BuildingPageQuarterItem
  from '~/modules/building/components/page/constructionProgress/BuildingPageQuarterItem.vue'
import { useAppStateStore } from '~/store/app'
import { youtubeLinkParser } from '~/common/helpers/parser'
import { getEntityName } from '~/common/helpers/getEntityName'
import BuildingPageHouseSelect from '~/modules/building/components/page/constructionProgress/BuildingPageHouseSelect.vue'
import { ObjectHelper } from '~/common/helpers/object'
import { House } from '~/common/types/house/House'
import { Quarter } from '~/common/types/date/Quarter'
import { QUARTER } from '~/common/constants/date/Quarter'
import { ConstructionProgress } from '~/common/types/construction-progress/ConstructionProgress'
import { ConstructionProgressQuarter } from '~/common/types/construction-progress/ConstructionProgressQuarter'
import { BuildingPageConstructionProgressSelectVariants } from '~/modules/building/components/page/constructionProgress//BuildingPageConstructionProgressSelectVariants'

const props = defineProps({
  building: {
    type: Object as PropType<BuildingExpanded>,
    required: true,
  },
  isHeaderVisible: {
    type: Boolean,
    default: true,
  },
})

type SelectedValue = { id: number; name: string; } | 'all' | 'building'

const { t } = useI18n()

const isConstructionProgressExist = (progress: ConstructionProgress | []): progress is ConstructionProgress => !Array.isArray(progress)

const isHousesProgressExist = computed(() => props.building.houses.some(house => isConstructionProgressExist(house.constructionProgress)))

const constructionProgressSelectVariants = computed<BuildingPageConstructionProgressSelectVariants>(() => {
  const variants = [] as BuildingPageConstructionProgressSelectVariants

  const isBuildingProgressExist = isConstructionProgressExist(props.building.constructionProgress)

  if (isHousesProgressExist.value) {
    variants.push({ type: 'all', value: 'all' })
  }

  if (isBuildingProgressExist) {
    variants.push({ type: 'building', value: 'building' })
  }

  if (isHousesProgressExist.value) {
    props.building.houses.forEach(house => {
      if (isConstructionProgressExist(house.constructionProgress)) {
        variants.push({
          type: 'house',
          value: {
            id: house.id,
            name: getEntityName({ name: house.name, internationalName: house.internationalName, entityCountry: props.building.address.countryIsoCode }) as string,
          },
        })
      }
    })
  }

  return variants
})

const selectedConstructionProgressVariant = ref<SelectedValue>(isHousesProgressExist.value ? 'all' : 'building')

const createConstructionProgressArrayFromObjects = (progresses: Array<{ constructionProgress: ConstructionProgress, name: string }>) => {
  const constructionProgressForYears: Record<string, Array<ConstructionProgressQuarter & {name: string, quarterName: Quarter}>> = {}

  progresses.forEach(progress => {
    Object.keys(progress.constructionProgress || {}).forEach(year => {
      if (!constructionProgressForYears[year]) {
        constructionProgressForYears[year] = []
      }

      const quarters = progress.constructionProgress[year]

      Object.keys(quarters).forEach(quarterName => {
        const quater = quarters[quarterName as Quarter] as ConstructionProgressQuarter

        const quarterWithAdditional: ConstructionProgressQuarter & {name: string, quarterName: Quarter} =
        {
          ...quater,
          quarterName: quarterName as Quarter,
          name: progress.name,
        }

        constructionProgressForYears[year].push(quarterWithAdditional)
      })
    })
  })

  return constructionProgressForYears
}

const constructionProgressList = computed(() => {
  if (selectedConstructionProgressVariant.value === 'all') {
    const houses = props.building.houses.filter(house => isConstructionProgressExist(house.constructionProgress))

    const progresses = houses.map(house => {
      const constructionProgress = house.constructionProgress as ConstructionProgress
      const name = getEntityName({ name: house.name, internationalName: house.internationalName, entityCountry: props.building.address.countryIsoCode }) as string

      return { constructionProgress, name }
    })

    return createConstructionProgressArrayFromObjects(progresses)
  }

  if (selectedConstructionProgressVariant.value === 'building') {
    const buildingProgress = ObjectHelper.copy(props.building.constructionProgress) as ConstructionProgress

    return createConstructionProgressArrayFromObjects([{ constructionProgress: buildingProgress, name: t('building.constructionProgress.options.building') }])
  }

  const selectedHouseId = selectedConstructionProgressVariant.value.id
  const findedHouse = props.building.houses.find(house => house.id === selectedHouseId) as House

  const houseProgress = ObjectHelper.copy(findedHouse.constructionProgress) as ConstructionProgress

  return createConstructionProgressArrayFromObjects([{ constructionProgress: houseProgress, name: selectedConstructionProgressVariant.value.name }])
})

const isSm = computed(() => !useAppStateStore().breakpoints.isMd)

const maxYear = computed<number>(() => Math.max(...Object.keys(constructionProgressList.value).map(year => Number(year))))

const year = ref<number>(maxYear.value)

const sortedQuarters = computed(() => {
  const quarters = ObjectHelper.copy(constructionProgressList.value[year.value])

  const sorted = quarters.sort((a, b) => (QUARTER[b.quarterName] || 0) - (QUARTER[a.quarterName] || 0)).reverse() as Array<ConstructionProgressQuarter & { quarterName: Quarter, name: string }>

  return sorted
})

const getSelectedQuarter = (selectedYear: number) => constructionProgressList.value[selectedYear].find(quarter => quarter.quarterName === 'fourth' || quarter.quarterName === 'third' || quarter.quarterName === 'second' || quarter.quarterName === 'first')

const selectedQuarter = ref(getSelectedQuarter(year.value))

const selectedQuarterId = ref<number>(selectedQuarter.value?.id || 0)

const quartersImages = computed<Array<Image>>(() => {
  if (!selectedQuarter.value) {
    return []
  }
  const images = []
  const linkId = youtubeLinkParser(selectedQuarter.value.videoUrl)

  images.push(...selectedQuarter.value.images)

  if (linkId) {
    images.push({ link: `https://img.youtube.com/vi/${linkId}/hqdefault.jpg`, alt: 'video', video: `https://www.youtube.com/embed/${linkId}` })
  }

  return images
})

watch(maxYear, newYear => {
  year.value = newYear
})

watch(year, newYear => {
  selectedQuarter.value = getSelectedQuarter(newYear)
  selectedQuarterId.value = selectedQuarter.value?.id || 0
})

watch(selectedConstructionProgressVariant, () => {
  selectedQuarter.value = getSelectedQuarter(year.value)
  selectedQuarterId.value = selectedQuarter.value?.id || 0
})

</script>
