import { MapFeatureDrawerServiceAbstract } from '~/common/interfaces/map/yandex/Map.featureDrawer'
import { FeatureDrawerEventsHandlers } from '~/common/types/map/yandex/Map.featureDrawerEventsHandlers'
import markerIcon from '~/assets/icons/entities/marker-map-icon.svg'
import { MapBuildingFeatureObject } from '~/common/types/map/ymaps.buildingFeatureObject'

export class MapFeatureDrawerService extends MapFeatureDrawerServiceAbstract {
  public objectManager: ymaps.ObjectManager = {} as ymaps.ObjectManager
  private pointIcon

  constructor(map: ymaps.Map, objectManagerOptions: ymaps.IObjectManagerOptions, eventsHandlers: FeatureDrawerEventsHandlers, pointIcon: string) {
    super(map, objectManagerOptions, eventsHandlers)

    this.pointIcon = pointIcon
  }

  public drawFeatures(featureCollection: ymaps.IFeatureCollection<MapBuildingFeatureObject>) {
    this.deleteObjectManager()

    this.objectManager = this.createObjectManager(featureCollection)

    this.addEvents()

    this.customizeObjectManager()

    this.map.geoObjects.add(this.objectManager)
  }

  protected createObjectManager(featureCollection: ymaps.IFeatureCollection<Record<string, any>>): ymaps.ObjectManager {
    const objectManager = new ymaps.ObjectManager(this.objectManagerOptions)

    objectManager.add(featureCollection)

    return objectManager
  }

  private addEvents() {
    const featureCallback = this.eventsHandlers.onFeatureClick

    if (featureCallback) {
      this.objectManager.objects.events.add('click', event => {
        const thisObject = this.objectManager.objects.getById(event.get('objectId')) as {properties: { object: { id: number }}}
        thisObject && featureCallback(thisObject.properties.object.id)
      })
    }
  }

  protected customizeObjectManager() {
    this.objectManager.objects.options.set({
      iconLayout: 'default#imageWithContent',
      iconImageHref: markerIcon,
      iconImageSize: [48, 62],
      iconImageOffset: [-24, -62],
      iconContentOffset: [25, 8],
      iconContentLayout: ymaps.templateLayoutFactory.createClass(
        '<div class="text-neutral-900 bg-white" style="position: relative; z-index: -1; width: fit-content; padding: 7px 17px 7px 36px; white-space: nowrap; border-radius: 9999px; font-weight: 600;">' +
        '{{ properties.object.name }}' +
        '</div>' +
        '{% if properties.object.flatsCount > 0 %}' +
          '<span class="text-primary" style="position: absolute; top: 8px; left: -17px; width: 31px; z-index: 2; font-weight: 600;">' +
            '{{ properties.object.flatsCount }}' +
          '</span>' +
        '{% else %}' +
        `<span style="background-image: url(&quot;${this.pointIcon}&quot;); position: absolute; top: 7px; left: -10px; display: block; width: 17px; height: 18px; background-size: contain;"></span>` +
        '{% endif %}',
      ),
    })

    this.objectManager.clusters.options.set('clusterIconColor', '#6482E6')
  }

  protected deleteObjectManager() {
    this.map.geoObjects.remove(this.objectManager)
  }
}
