export const AVAILABLE_COUNTRY = {
  russia: 'RU',
  georgia: 'GE',
  kazakhstan: 'KZ',
  unitedArabEmirates: 'AE',
  uzbekistan: 'UZ',
  indonesia: 'ID',
  cyprus: 'CY',
  thailand: 'TH',
} as const
