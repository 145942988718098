export const CURRENCY = {
  rub: 'rub',
  /** Доллар */
  usd: 'usd',
  /** Евро */
  eur: 'eur',
  /** Британский фунт */
  gbp: 'gbp',
  /** Казахский тенге */
  kzt: 'kzt',
  /** Таиландский бат */
  thb: 'thb',
} as const
