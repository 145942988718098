<template>
  <custom-dropdown
    button-class="w-full items-center px-4 py-3"
    body-class="!p-0"
    color="secondary"
    size="none"
  >
    <template #triggerContent>
      <div class="font-semibold">
        <span v-if="typeof selectedValue === 'string'">
          {{ $t(`building.constructionProgress.options.${selectedValue}`) }}
        </span>
        <span v-else>
          {{ selectedValue.name }}
        </span>
      </div>
    </template>
    <custom-list-group class="w-full">
      <custom-list-group-item
        v-for="(variant, index) in variants"
        :key="index"
        @click="selectVariant(variant.value)"
      >
        <span v-if="variant.type === 'house'">
          {{ variant.value.name }}
        </span>
        <span v-else>
          {{ $t(`building.constructionProgress.options.${variant.value}`) }}
        </span>
      </custom-list-group-item>
    </custom-list-group>
  </custom-dropdown>
</template>

<script setup lang="ts">
import CustomDropdown from '~/ui/dropdowns/CustomDropdown.vue'
import CustomListGroup from '~/ui/list-group/CustomListGroup.vue'
import CustomListGroupItem from '~/ui/list-group/CustomListGroupItem.vue'
import { BuildingPageConstructionProgressSelectVariants } from '~/modules/building/components/page/constructionProgress//BuildingPageConstructionProgressSelectVariants'

type SelectedValue = { id: number; name: string; } | 'all' | 'building'

defineProps({
  variants: {
    type: Array as PropType<BuildingPageConstructionProgressSelectVariants>,
    required: true,
  },
  selectedValue: {
    type: [Object, String] as PropType<SelectedValue>,
    required: true,
  },
})

const emits = defineEmits<{(e: 'update:selectedValue', value: SelectedValue): void
}>()

const selectVariant = (year: SelectedValue) => {
  emits('update:selectedValue', year)
}

</script>
