<template>
  <flats-filter-template :layout-variant="layoutVariant">
    <template #flatRoomsCount>
      <flat-rooms-count-filter v-model="flatRoomsCount" />
    </template>
    <template #flatTotalArea>
      <filter-total-area
        v-model:from="totalAreaFrom"
        v-model:to="totalAreaTo"
        :trigger-text="$t('entityActions.filter.flatFilter.totalArea.label')"
      />
    </template>
    <template #flatFloor>
      <filter-flat-floor-number
        v-model:from.number="floorNumberFrom"
        v-model:to.number="floorNumberTo"
        :trigger-text="$t('flat.floorNumber.label')"
      />
    </template>
    <template #flatPrice>
      <filter-price
        v-model:from="priceFrom"
        v-model:to="priceTo"
        v-model:currency="priceCurrency"
      />
    </template>
    <template #deadline>
      <filter-deadline-year
        v-model:from="deadLineYearFrom"
        v-model:to="deadLineYearTo"
        :trigger-text="$t('entityActions.filter.houseFilter.deadLineYear.label')"
      />
    </template>
    <template #flatStatus>
      <filter-flat-status v-model="status" />
    </template>
    <template #flatDecoration>
      <filter-flat-decoration v-model="decorationType" />
    </template>
    <template #selectedFilters>
      <selected-flat-filters
        :filter="filter"
        @deselect="resetFilterValue"
      />
    </template>
    <template #savedFilters>
      <saved-filters />
    </template>
    <template #additionalFilters>
      <additional-flats-filters
        v-model:filter="filter"
      >
        <template #footer="{close}">
          <div class="flex justify-between items-center w-full">
            <div class="grow">
              <slot name="resetButton" />
            </div>
            <div>
              <slot
                name="searchModalButton"
                :close="close"
              />
            </div>
          </div>
        </template>
      </additional-flats-filters>
    </template>
    <template #searchButton>
      <slot name="searchButton">
        <search-button
          class="bg-blue-700 text-white"
          variant="withoutBorders"
        >
          {{ $t('entityActions.show.flats', searchCount) }}
        </search-button>
      </slot>
    </template>
    <template #resetFlatsFilterButton>
      <slot name="resetFlatsFilterButton" />
    </template>
    <template #resetButton>
      <button
        class="text-danger font-semibold mr-4"
        type="button"
        @click="emits('resetFilters')"
      >
        {{ $t('entityActions.filter.resetButton') }}
      </button>
    </template>
  </flats-filter-template>
</template>
<script setup lang="ts">
import FlatsFilterTemplate from '~/modules/building/components/page/flats/filter/FlatsFilterTemplate.vue'
import FilterPrice from '~/modules/market-entities-actions/components/filter/filters/FilterPrice.vue'
import FlatRoomsCountFilter from '~/modules/market-entities-actions/components/filter/filters/FlatRoomsCountFilter.vue'
import FilterTotalArea from '~/modules/market-entities-actions/components/filter/filters/FilterTotalArea.vue'
import FilterDeadlineYear from '~/modules/market-entities-actions/components/filter/filters/FilterDeadlineYear.vue'
import SavedFilters from '~/modules/market-entities-actions/components/filter/SavedFilters.vue'
import SearchButton from '~/modules/market-entities-actions/components/filter/SearchButton.vue'
import FilterFlatStatus from '~/modules/building/components/page/flats/filter/FilterFlatStatus.vue'
import FilterFlatFloorNumber from '~/modules/building/components/page/flats/filter/FilterFlatFloorNumber.vue'
import FilterFlatDecoration from '~/modules/building/components/page/flats/filter/FilterFlatDecoration.vue'
import SelectedFlatFilters from '~/modules/building/components/page/flats/filter/SelectedFlatFilters.vue'
import { ObjectHelper } from '~/common/helpers/object'
import { ArrayHelper } from '~/common/helpers/arrays'
import AdditionalFlatsFilters
  from '~/modules/market-entities-actions/components/filter/filters/AdditionalFlatsFilters.vue'
import { BuildingFlatFilter } from '~/modules/building/components/page/flats/filter/types/BuildingFlatFilter'
import {
  defaultBuildingFlatFilter,
} from '~/modules/building/components/page/flats/filter/constants/DefaultBuildingFlatFilter'

const props = defineProps({
  layoutVariant: {
    type: String as PropType<'page' | 'modal'>,
    default: 'page',
  },
  filter: {
    type: Object as PropType<BuildingFlatFilter>,
    required: true,
  },
  searchCount: {
    type: Number,
    default: 0,
  },
})

const emits = defineEmits<{(e: 'resetFilters'): void,
  (e: 'update:filter', filter: BuildingFlatFilter): void
}>()
const filter = computed({ get: () => props.filter, set: value => emits('update:filter', value) })

const flatRoomsCount = computed({
  get: () => props.filter.flatFilter.flatRoomsCount,
  set: value => {
    const filterCopy = ObjectHelper.copy(props.filter)
    filterCopy.flatFilter.flatRoomsCount = value
    emits('update:filter', filterCopy)
  },
})
const totalAreaFrom = computed({
  get: () => props.filter.flatFilter.totalArea.from,
  set: value => {
    const filterCopy = ObjectHelper.copy(props.filter)
    filterCopy.flatFilter.totalArea.from = value
    emits('update:filter', filterCopy)
  },
})
const totalAreaTo = computed({
  get: () => props.filter.flatFilter.totalArea.to,
  set: value => {
    const filterCopy = ObjectHelper.copy(props.filter)
    filterCopy.flatFilter.totalArea.to = value
    emits('update:filter', filterCopy)
  },
})
const floorNumberFrom = computed({
  get: () => props.filter.flatFilter.floorNumber.from,
  set: value => {
    const filterCopy = ObjectHelper.copy(props.filter)
    filterCopy.flatFilter.floorNumber.from = value
    emits('update:filter', filterCopy)
  },
})
const floorNumberTo = computed({
  get: () => props.filter.flatFilter.floorNumber.to,
  set: value => {
    const filterCopy = ObjectHelper.copy(props.filter)
    filterCopy.flatFilter.floorNumber.to = value
    emits('update:filter', filterCopy)
  },
})
const priceFrom = computed({
  get: () => props.filter.flatFilter.price.from,
  set: value => {
    const filterCopy = ObjectHelper.copy(props.filter)
    filterCopy.flatFilter.price.from = value
    emits('update:filter', filterCopy)
  },
})
const priceTo = computed({
  get: () => props.filter.flatFilter.price.to,
  set: value => {
    const filterCopy = ObjectHelper.copy(props.filter)
    filterCopy.flatFilter.price.to = value
    emits('update:filter', filterCopy)
  },
})
const priceCurrency = computed({
  get: () => props.filter.flatFilter.price.currency,
  set: value => {
    const filterCopy = ObjectHelper.copy(props.filter)
    filterCopy.flatFilter.price.currency = value
    emits('update:filter', filterCopy)
  },
})
const deadLineYearFrom = computed({
  get: () => props.filter.houseFilter.deadLine.from,
  set: value => {
    const filterCopy = ObjectHelper.copy(props.filter)
    filterCopy.houseFilter.deadLine.from = value
    emits('update:filter', filterCopy)
  },
})
const deadLineYearTo = computed({
  get: () => props.filter.houseFilter.deadLine.to,
  set: value => {
    const filterCopy = ObjectHelper.copy(props.filter)
    filterCopy.houseFilter.deadLine.to = value
    emits('update:filter', filterCopy)
  },
})
const status = computed({
  get: () => props.filter.flatFilter.status,
  set: value => {
    const filterCopy = ObjectHelper.copy(props.filter)
    filterCopy.flatFilter.status = value
    emits('update:filter', filterCopy)
  },
})
const decorationType = computed({
  get: () => props.filter.flatFilter.decorationTypes,
  set: value => {
    const filterCopy = ObjectHelper.copy(props.filter)
    filterCopy.flatFilter.decorationTypes = value
    emits('update:filter', filterCopy)
  },
})

const resetFilterValue = (path: string, value?: unknown) => {
  const filterCopy = ObjectHelper.copy(props.filter)
  const filterValue = ObjectHelper.getNestedValue(filterCopy as Record<string, any>, path)
  if (Array.isArray(filterValue) && typeof value !== 'undefined') {
    ArrayHelper.delete(filterValue, element => element === value)
  } else {
    ObjectHelper.setNestedValue(filterCopy as Record<string, any>, path, ObjectHelper.getNestedValue(defaultBuildingFlatFilter() as Record<string, any>, path))
  }
  filter.value = filterCopy
}
</script>
