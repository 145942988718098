import { BuildingHttpApiService } from '~/modules/building/api/BuildingHttpApiService'
import { BuildingExpanded } from '~/modules/building/types/Building.expanded'
import { HttpHandledResponse } from '~/services/http/types/Http.handledResponse'
import { BuildingPresentation } from '~/modules/building/types/Building.presentation'
import SentryLogger from '~/services/sentry/SentryLogger'
import { RecursivePartial } from '~/common/types/utils/RecursivePartial'
import { MarketEntitiesFilterForRequest } from '~/modules/market-entities-actions/types/filter/Filter.forSearchRequest'

export class BuildingService {
  http: BuildingHttpApiService

  constructor() {
    const nuxtApp = useNuxtApp()
    const httpInstance = nuxtApp.$qdHttpApiInstance
    this.http = new BuildingHttpApiService(httpInstance)
  }

  public getBuilding(id: number): Promise<BuildingExpanded> {
    return new Promise((resolve, reject) => {
      this.http.getBuilding(id)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось получить данные о жк',
            buildingId: id,
          })
          reject(error)
        })
    })
  }

  public getPresentationBuilding(buildingId: number | string, managerId?: string, organisationId?: string): Promise<BuildingExpanded & {
    managers: BuildingPresentation[]
  }> {
    return new Promise((resolve, reject) => {
      let presentationPromise: Promise<HttpHandledResponse<BuildingExpanded & {managers: BuildingPresentation[]}>> | null = null
      if (managerId) {
        presentationPromise = this.http.getPresentationBuildingWithManager(buildingId, managerId, organisationId)
      } else {
        presentationPromise = this.http.getPresentationBuilding(buildingId)
      }
      presentationPromise.then(response => {
        resolve(response.data)
      })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось получить данные о преззентации жк',
            buildingId,
            managerId,
            organisationId,
          })
          reject(error)
        })
    })
  }

  public getPopularBuildings(filter: RecursivePartial<MarketEntitiesFilterForRequest>, signal?: AbortSignal): Promise<Array<BuildingExpanded>> {
    return new Promise((resolve, reject) => {
      this.http.getPopularBuildings(filter, signal)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось получить популярные жк',
          })
          reject(error)
        })
    })
  }
}
