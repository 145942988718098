import {BankHttpApiService} from '~/modules/bank/api/BankHttpApiService'
import SentryLogger from '~/services/sentry/SentryLogger'
import {HttpApiService} from '~/services/http/HttpApiService'
import {MortgageForRequest} from '~/modules/bank/types/Mortgage.forRequest'
import {HttpPaginatedResponseData} from '~/services/http/types/Http.paginatedResponseData'
import {Mortgage} from '~/modules/bank/types/Mortgage'
import {MortgageSchedule} from '~/modules/bank/types/Mortgage.schedule'
import {MortgageForRequestCalculate} from '~/modules/bank/types/Mortgage.forRequestCalculate'
import {MortgageCalculation} from '~/modules/bank/types/Mortgage.calculation'
import downloadFile from '~/common/helpers/downloadFile'

export class BankService {
  http: BankHttpApiService

  constructor() {
    const nuxtApp = useNuxtApp()
    const httpInstance = nuxtApp.$qdHttpApiInstance
    this.http = new BankHttpApiService(httpInstance as HttpApiService)
  }

  public searchAndCalculate(mortgage: MortgageForRequest, page = 1): Promise<HttpPaginatedResponseData<Mortgage>> {
    return new Promise((resolve, reject) => {
      this.http.searchAndCalculate(mortgage, page)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось выполнить расчет и поиск ипотек',
            mortgage,
          })
          reject(error)
        })
    })
  }

  public calculate(mortgageForRequest: MortgageForRequestCalculate): Promise<MortgageCalculation> {
    return new Promise((resolve, reject) => {
      this.http.calculate(mortgageForRequest)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось получить расчет ипотеки',
            mortgageForRequest,
          })
          reject(error)
        })
    })
  }

  public getPaymentSchedule(mortgageForRequest: MortgageForRequestCalculate): Promise<Array<MortgageSchedule>> {
    return new Promise((resolve, reject) => {
      this.http.getPaymentSchedule(mortgageForRequest)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось получить график платежей',
            mortgageForRequest,
          })
          reject(error)
        })
    })
  }

  public downloadSchedule(mortgageForRequest: MortgageForRequestCalculate): Promise<true> {
    return new Promise((resolve, reject) => {
      this.http.downloadSchedule(mortgageForRequest)
        .then(res => {
          downloadFile(res)
          resolve(true)
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось получить график платежей',
            mortgageForRequest,
          })
          reject(error)
        })
    })
  }
}
