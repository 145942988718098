import { InterceptorManager } from '~/services/http/interceptors/InterceptorManager'
import { HttpHandledResponse } from '~/services/http/types/Http.handledResponse'
import SentryLogger from '~/services/sentry/SentryLogger'
import { useAppStateStore } from '~/store/app'

export class HttpApiService {
  protected baseUrl = ''


  protected mode: RequestMode

  public headers: HeadersInit = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  }

  public interceptors = {
    response: new InterceptorManager(),
  }

  public query: Record<string, string> = {}

  constructor(baseUrl: string, mode: RequestMode = 'cors') {
    this.baseUrl = baseUrl
    this.mode = mode
  }

  public setQueryParams(params: Record<string, string>): void {
    this.query = { ...this.query, ...params }
  }

  public clearQueryParams(): void {
    this.query = {}
  }

  public async fetch<T>(url: string, options: RequestInit): Promise<HttpHandledResponse<T>> {
    const path = this.getRequestPath(url, this.query)
    const response: Response = await fetch(path, options)

    const responseHandledWithInterceptor: HttpHandledResponse<T> = response.ok
      ? await this.interceptors.response.handlers.onFulfilled(await this.getResponse(response, url, options))
      : await this.interceptors.response.handlers.onRejected(await this.getResponse(response, url, options))

    return new Promise<HttpHandledResponse<T>>((resolve, reject) => {
      if (responseHandledWithInterceptor.ok) {
        resolve(responseHandledWithInterceptor)
      } else {
        reject(responseHandledWithInterceptor)
      }
    })
  }

  public get<T = any>(url: string, config: RequestInit = {}): Promise<HttpHandledResponse<T>> {
    const headers: HeadersInit = { ...this.headers, ...config.headers }

    return this.fetch(url, this.getRequestInit('GET', { ...config, headers }))
  }

  public post<T = any>(url: string, data?: any, config: RequestInit = {} as RequestInit):Promise<HttpHandledResponse<T>> {
    const headers: HeadersInit = { ...this.headers, ...config.headers }
    return this.fetch(url, this.getRequestInit('POST', { ...config, headers }, data))
  }

  public put<T = any>(url: string, data: any, config: RequestInit = {} as RequestInit):Promise<HttpHandledResponse<T>> {
    const headers = { ...this.headers, ...config.headers }

    return this.fetch(url, this.getRequestInit('PUT', { ...config, headers }, data))
  }

  public delete<T = any>(url: string, config: RequestInit = {}):Promise<HttpHandledResponse<T>> {
    const headers: HeadersInit = { ...this.headers, ...config.headers }

    return this.fetch(url, this.getRequestInit('DELETE', { ...config, headers }))
  }

  getRequestInit(method: string, options: RequestInit, data?: any): RequestInit {
    const requestInit: RequestInit = {
      ...options,
      method,
      mode: this.mode,
    }
    if (data) {
      if (typeof data === 'string') {
        requestInit.body = data
      } else if (data instanceof FormData) {
        requestInit.body = data
        // @ts-ignore
        delete requestInit.headers['Content-Type']
      } else {
        requestInit.body = JSON.stringify(data)
      }
    }

    return requestInit
  }

  private async getResponse<T>(response: Response, url: string, requestOptions: RequestInit): Promise<HttpHandledResponse<T>> {
    return {
      data: await this.getResponseData<T>(response),
      requestConfig: {
        url,
        options: requestOptions,
      },
      headers: response.headers,
      status: response.status,
      statusText: response.statusText,
      ok: response.ok,
    }
  }

  private async getResponseData<T>(response: Response): Promise<T> {
    const contentType = response.headers.get('Content-Type')
    let responseData

    switch (contentType) {
      case 'application/json':
        responseData = await response.json()
        break
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        responseData = await response.blob()
        break
      case null:
        responseData = null
        break
      default:
        SentryLogger.captureScopeException(response, {
          message: `Content type ${contentType} не поддерживается`,
        })
        throw new Error(`Content type ${contentType} не поддерживается`)
    }

    return responseData
  }

  private getRequestPath(url: string, query: Record<string, string> = {}): string {
    let path = url

    if (Object.keys(query).length > 0) {
      const queryString = Object.entries(query)
        .map(([key, value]) => `${key}=${value}`)
        .join('&')

      path = path.includes('?') ? `${path}&${queryString}` : `${path}?${queryString}`
    }

    try {
      const _ = new URL(path)
    } catch (e) {
      if (e instanceof TypeError) {
        path = `${this.baseUrl}${path}`
      }
    }

    return path
  }
}
