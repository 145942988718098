<template>
  <tr>
    <td />
    <td class="font-semibold text-base text-neutral-500">
      {{ $t('flat.table.section') }}
    </td>
    <td class="font-semibold text-base text-neutral-500">
      {{ $t('flat.table.floor') }}
    </td>
    <td class="font-semibold text-base text-neutral-500">
      {{ $t('flat.table.flatNumber') }}
    </td>
    <td class="font-semibold text-base text-neutral-500">
      {{ $t('flat.table.totalArea') }}
    </td>
    <td class="font-semibold text-base text-neutral-500">
      {{ $t('flat.table.kitchenArea') }}
    </td>
    <td class="font-semibold text-base text-neutral-500">
      {{ $t('flat.table.windowViewType') }}
    </td>
    <td class="font-semibold text-base text-neutral-500">
      {{ $t('flat.table.cardinalDirection') }}
    </td>
    <td class="font-semibold text-base text-neutral-500">
      {{ $t('flat.table.price') }}
    </td>
    <td class="font-semibold text-base text-neutral-500">
      {{ $t('flat.table.pricePerSquareMeter') }}
    </td>
    <td class="font-semibold text-base text-neutral-500">
      {{ forPage === 'building' ? $t('flat.table.status') : $t('flat.table.building') }}
    </td>
    <td
      v-if="isDisplayAgentMotivation"
      class="font-semibold text-base text-neutral-500"
    >
      {{ $t('flat.table.agentMotivation') }}
    </td>
    <td />
  </tr>
</template>

<script setup lang="ts">
import DISPLAYING_TYPE from '~/modules/building/constants/Building.displayingType'
import { ComputedRef } from 'vue'
import { useAppStateStore } from '~/store/app'

defineProps({
  forPage: {
    type: String as PropType<'building' | 'suggestion'>,
    default: 'building',
  },
})

const appStateStore = useAppStateStore()

const displayingType: ComputedRef<DISPLAYING_TYPE> = inject('displayingType', DISPLAYING_TYPE.guest)
const isDisplayAgentMotivation = computed(() => displayingType.value === DISPLAYING_TYPE.basic && !appStateStore.clientMode)

</script>
