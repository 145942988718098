<template>
  <custom-accordion>
    <template #header>
      <flats-house-room-count-accordion-header :flats-info="flatsInfo" />
    </template>
    <template #content>
      <div
        ref="contentRef"
        class="max-h-[600px] bg-white max-w-full w-full overflow-x-auto overflow-y-auto scrollbar-thin"
      >
        <div
          v-if="isFetching"
          class="flex justify-center items-center"
        >
          <custom-cube-spinner />
        </div>
        <template
          v-else-if="isLayoutView"
        >
          <flats-layout
            :layouts="combinedLayouts"
            :building="building"
            :display-type="displayType"
            wrapper-class="md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
          >
            <template
              #actions="{openModal, layout}"
            >
              <flats-layout-btns-building-presentation
                v-if="displayType === DISPLAY_TYPE.buildingPresentation"
                :layout="layout"
              />
              <flats-btns-basic
                v-else
                :developer="building.developer"
                :with-add-button-default-click="false"
                :is-layout="isLayout"
                @add-to-suggestion="openModal"
              />
            </template>
          </flats-layout>
          <div class="flex p-[22px] flex-col-reverse xl:flex-row gap-y-6 w-full justify-center xl:justify-between items-center md:bg-neutral-100">
            <div
              v-if="isPaginationShow"
              class="2xl:flex-1"
            />
            <custom-pagination
              v-if="isPaginationShow && layoutsPagination"
              :pagination="layoutsPagination"
              class="justify-center shrink-0"
              :is-fetching="isFetching"
              :with-md-input="false"
              @on-page-change="searchLayouts"
            />
            <div :class="isPaginationShow ? 'flex 2xl:flex-1 justify-end items-center' : 'ml-auto'">
              <slot name="showAvailable" />
            </div>
          </div>
        </template>
        <component
          :is="flatsDisplayingComponentName"
          v-else
          :flats="flats"
          :building="building"
          :mini="mini"
          wrapper-class="md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
          :display-type="displayType"
        >
          <template #showAvailable>
            <div class="flex flex-col-reverse xl:flex-row gap-y-6 w-full justify-center xl:justify-between items-center">
              <div
                v-if="isPaginationShow"
                class="2xl:flex-1"
              />
              <custom-pagination
                v-if="isPaginationShow && flatsPagination"
                :pagination="flatsPagination"
                class="justify-center shrink-0"
                :is-fetching="isFetching"
                :with-md-input="false"
                @on-page-change="searchFlats"
              />
              <div :class="isPaginationShow ? 'flex 2xl:flex-1 justify-end items-center' : 'ml-auto'">
                <slot name="showAvailable" />
              </div>
            </div>
          </template>
        </component>
      </div>
    </template>
  </custom-accordion>
</template>

<script lang="ts" setup>
import CustomAccordion from '~/ui/accordion/CustomAccordion.vue'
import { useIntersectionObserver } from '@vueuse/core'
import { FLATS_DISPLAYING_ENTITY } from '~/modules/building/components/page/flats/filter/constants/FlatsDisplaying.entity'
import FlatsLayout from '~/components/flats/layout/FlatsLayout.vue'
import FlatsBtnsBasic from '~/components/flats/buttons/FlatsBtnsBasic.vue'
import DISPLAY_TYPE from '~/common/constants/flat/Flat.displayType'
import { Building, useBuildingStore } from '~/modules/building/BuildingModule'
import { useAppStateStore } from '~/store/app'
import FlatsList from '~/components/flats/FlatsList.vue'
import FlatsTableView from '~/components/flats/table/FlatsTableView.vue'
import { Flat } from '~/common/types/flat/Flat'
import { FlatService } from '~/common/services/flat/FlatService'
import { Pagination } from '~/common/types/pagination/Pagination'
import { HouseFlatsInfo } from '~/modules/house/types/HouseFlatsInfo'
import { FlatFilterForRequest } from '~/modules/building/components/page/flats/filter/types/FlatsFilter'
import CustomCubeSpinner from '~/ui/spinners/CustomCubeSpinner.vue'
import CustomPagination from '~/ui/pagination/CustomPagination.vue'
import { Layout } from '~/common/types/layout/Layout'
import { BuildingPageFlatsCombine } from '~/modules/building/services/BuildingPageFlatsCombine'
import { getEntityName } from '~/common/helpers/getEntityName'
import { House } from '~/common/types/house/House'
import FlatsHouseRoomCountAccordionHeader from '~/modules/building/components/page/flats/house/FlatsHouseRoomCountAccordionHeader.vue'
import FlatsLayoutBtnsBuildingPresentation from '~/components/flats/layout/buttons/FlatsLayoutBtnsBuildingPresentation.vue'

const props = defineProps({
  displayType: {
    type: String as PropType<DISPLAY_TYPE>,
    default: DISPLAY_TYPE.basic,
  },
  building: {
    type: Object as PropType<Building>,
    required: true,
  },
  flatsInfo: {
    type: Object as PropType<HouseFlatsInfo>,
    required: true,
  },
  house: {
    type: Object as PropType<House>,
    required: true,
  },
  isLayout: {
    type: Boolean,
    default: false,
  },
})

const store = useBuildingStore()

const buildingPageFlatsCombine = new BuildingPageFlatsCombine()
const flatService = inject<FlatService>('flatService', new FlatService())

const contentRef = ref<HTMLDivElement | null>(null)
const flats = ref<Array<Flat>>([])
const flatsPagination = ref<Pagination | null>(null)
const layouts = ref<Array<Layout>>([])
const layoutsPagination = ref<Pagination | null>(null)

const isAccordionViewing = ref(false)
const isFetching = ref(true)

const flatsDisplayingEntity = computed(() => store.flatsDisplayingEntity)
const isLayoutView = computed(() => flatsDisplayingEntity.value === FLATS_DISPLAYING_ENTITY.layouts)
const isPaginationShow = computed(() => {
  if (isLayoutView.value) {
    return layoutsPagination.value?.total && layoutsPagination.value.total > layoutsPagination.value.perPage
  }
  return flatsPagination.value?.total && flatsPagination.value.total > flatsPagination.value.perPage
})

const combinedLayouts = computed(() => layouts.value.map(layout => {
  const onlyLayout = {
    code: layout.code,
    id: layout.id,
    images: layout.images,
    name: getEntityName({
      name: layout.name,
      internationalName: layout.internationalName,
      entityCountry: props.building.address?.countryIsoCode,
    }),
  }

  const innerFlats = layout.flats.map(flat => ({
    ...flat,
    layout: onlyLayout,
    building: props.building,
    houseName: props.house.name,
    section: props.house?.sections?.[0],
    address: props.house?.construction?.address,
  }))
  return buildingPageFlatsCombine.flatsToLayouts(innerFlats as Flat[], props.house?.decorations)
}).flat(1))
const isMd = computed(() => useAppStateStore().breakpoints.isMd)

const filter = computed({
  get: () => store.filter,
  set: value => {
    store.setBuildingFlatFilter(value)
  },
})

const mini = computed(() => {
  if (isMd.value) {
    return null
  }
  return flatsDisplayingEntity.value === FLATS_DISPLAYING_ENTITY.table
})

const flatsDisplayingComponentName = computed(() => {
  if (isMd.value) {
    switch (flatsDisplayingEntity.value) {
      case FLATS_DISPLAYING_ENTITY.grid:
        return FlatsList
      case FLATS_DISPLAYING_ENTITY.table:
        return FlatsTableView
      default:
        return FlatsList
    }
  }
  return FlatsList
})

const getFilterForRequest: () => FlatFilterForRequest = () => ({
  flatFilter: {
    ...filter.value.flatFilter,
    flatRoomsCount: [props.flatsInfo.flatRoomsCount],
  },
  houseFilter: {
    building: { id: props.building?.id },
    deadLine: filter.value.houseFilter.deadLine,
    includedIds: [props.house.id],
  },
})

const searchFlats = (page = 1) => {
  isFetching.value = true
  flatService.searchForBuildingPage(getFilterForRequest(), page, store.flatsSortOption)
    .then(res => {
      flats.value = res.data.map(flat => ({ ...flat, building: props.building }))
      flatsPagination.value = {
        perPage: res.perPage,
        currentPage: res.currentPage,
        nextPageUrl: res.nextPageUrl,
        total: res.total,
        lastPage: res.lastPage,
      }
    })
    .finally(() => {
      isFetching.value = false
    })
}

const searchLayouts = (page = 1) => {
  isFetching.value = true
  flatService.searchLayoutsForBuildingPage(getFilterForRequest(), page, store.flatsSortOption)
    .then(res => {
      layouts.value = res.data
      layoutsPagination.value = {
        perPage: res.perPage,
        currentPage: res.currentPage,
        nextPageUrl: res.nextPageUrl,
        total: res.total,
        lastPage: res.lastPage,
      }
    })
    .finally(() => {
      isFetching.value = false
    })
}

useIntersectionObserver(
  contentRef,
  ([{ isIntersecting }]) => {
    isAccordionViewing.value = isIntersecting
    if (isIntersecting) {
      if (isLayoutView.value && !layouts.value.length) {
        searchLayouts()
      } else if (!flats.value.length) {
        searchFlats()
      }
    }
  },
)

watch(() => props.flatsInfo, () => {
  if (isAccordionViewing.value) {
    if (isLayoutView.value) {
      searchLayouts()
      return
    }
    searchFlats()
  } else {
    flats.value = []
    layouts.value = []
  }
}, { deep: true })

watch(isLayoutView, value => {
  if (!isAccordionViewing.value) {
    return
  }
  if (value && !layouts.value.length) {
    searchLayouts()
    return
  }
  if (!value && !flats.value.length) {
    searchFlats()
  }
})
</script>
