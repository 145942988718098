import { HttpApiService } from '~/services/http/HttpApiService'
import { HttpHandledResponse } from '~/services/http/types/Http.handledResponse'
import { Service } from '~/services/http/Service'
import { FlatPriceStatistics } from '~/common/types/flat/Flat.priceStatistics'
import { FlatPriceStatisticsQueryRequest } from '~/common/types/flat/Flat.priceStatisticsQueryRequest'
import { FlatFilterForRequest } from '~/modules/building/components/page/flats/filter/types/FlatsFilter'
import { HttpPaginatedResponseData } from '~/services/http/types/Http.paginatedResponseData'
import { Flat } from '~/common/types/flat/Flat'
import { FlatsSort } from '~/modules/building/types/Flat.sort'
import { Layout } from '~/common/types/layout/Layout'
import { ChessboardSection } from '~/modules/chessboard/types/Chessboard.section'

export class FlatHttpApiService extends Service {
  private endPoints = {
    firstPart: '/v4/estate-market/development',
    flats: '/flats',
    layouts: '/layouts',
    sections: '/sections',
    search: '/search',
    searchForBuildingPage: '/search-for-building-page',
    searchForChessboard: '/search-for-chessboard',
    count: '/count',
    getStatistics: '/statistics',
    statistics: '/statistics',
    priceHistory: '/price-history',
  }

  constructor(httpInstance: HttpApiService) {
    super(httpInstance)
  }

  public getFlat(id: number): Promise<HttpHandledResponse<Flat>> {
    return this.httpInstance.get(`${this.endPoints.firstPart}${this.endPoints.flats}/${id}`)
  }

  public search(filter: FlatFilterForRequest, page: number): Promise<HttpHandledResponse<HttpPaginatedResponseData<Flat>>> {
    return this.httpInstance.post(`${this.endPoints.firstPart}${this.endPoints.flats}${this.endPoints.search}`, {
      filter,
      page,
    })
  }

  public searchForBuildingPage(filter: FlatFilterForRequest, page: number, sort: FlatsSort): Promise<HttpHandledResponse<HttpPaginatedResponseData<Flat>>> {
    return this.httpInstance.post(`${this.endPoints.firstPart}${this.endPoints.flats}${this.endPoints.searchForBuildingPage}`, {
      filter,
      page,
      sort,
    })
  }

  public searchLayoutsForBuildingPage(filter: FlatFilterForRequest, page: number, sort: FlatsSort): Promise<HttpHandledResponse<HttpPaginatedResponseData<Layout>>> {
    return this.httpInstance.post(`${this.endPoints.firstPart}${this.endPoints.layouts}${this.endPoints.searchForBuildingPage}`, {
      filter,
      page,
      sort,
    })
  }

  public searchSectionsForChessboard(filter: FlatFilterForRequest): Promise<HttpHandledResponse<Array<ChessboardSection>>> {
    return this.httpInstance.post(`${this.endPoints.firstPart}${this.endPoints.sections}${this.endPoints.searchForChessboard}`, filter)
  }

  public count(flatsFilter: FlatFilterForRequest): Promise<HttpHandledResponse<number>> {
    return this.httpInstance.post(`${this.endPoints.firstPart}${this.endPoints.flats}${this.endPoints.count}`, flatsFilter)
  }

  public getStatistics(statisticsQueryRequest: FlatPriceStatisticsQueryRequest): Promise<HttpHandledResponse<FlatPriceStatistics>> {
    return this.httpInstance.post(`${this.endPoints.firstPart}${this.endPoints.flats}${this.endPoints.getStatistics}`, statisticsQueryRequest)
  }

  public getFlatStatistics(statisticsQueryRequest: FlatPriceStatisticsQueryRequest & {
    flatId: number
  }): Promise<HttpHandledResponse<FlatPriceStatistics>> {
    const { flatId, ...rest } = statisticsQueryRequest

    return this.httpInstance.post(`${this.endPoints.firstPart}${this.endPoints.flats}/${statisticsQueryRequest.flatId}${this.endPoints.priceHistory}`, rest)
  }

  public getLayoutStatistics(statisticsQueryRequest: FlatPriceStatisticsQueryRequest & {
    layoutId: number
  }): Promise<HttpHandledResponse<FlatPriceStatistics>> {
    const { layoutId, ...rest } = statisticsQueryRequest

    return this.httpInstance.post(`${this.endPoints.firstPart}${this.endPoints.layouts}/${statisticsQueryRequest.layoutId}${this.endPoints.statistics}`, rest)
  }
}
