<template>
  <teleport to="body">
    <div
      class="fixed top-5 right-5"
      :style="{zIndex: baseZIndex}"
    >
      <custom-toast
        v-for="(toast, i) of toasts"
        :key="i"
        v-bind="toast"
        class="mb-2"
        @close="closeToast(toast)"
      />
    </div>
  </teleport>
</template>
<script setup lang="ts">
import CustomToast from '~/ui/toasts/CustomToast.vue'
import baseZIndex from '~/ui/toasts/constants/Toasts.zIndex'
import type { Toast } from '~/ui/toasts/Toasts.types'

const props = defineProps({
  toasts: {
    type: Array as PropType<Array<Toast>>,
    default: () => [],
  },
})

const closeToast = (toast: Toast) => {
  props.toasts?.splice(props.toasts?.indexOf(toast), 1)
}
</script>
