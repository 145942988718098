import { MarketEntitiesFilter } from '~/modules/market-entities-actions/types/filter/Filter'
import { CURRENCY } from '~/common/constants/money/Currency'
import { Country } from '~/modules/address/types'
import { Region } from '~/modules/address/types/Region'
import { City } from '~/modules/address/types/City'
import { FLAT_STATUS } from '~/common/constants/flat/Flat.status'

export const defaultFilter = (country?: Country | null, region?: Region | null, city?: City | null) => ({
  buildingFilter: {
    included: [],
    excluded: [],
    name: null,
    address: null,
    country: country || null,
    hasPromotions: false,
    internationalAddress: city || region?.region || null,
    metro: {
      includedStations: [],
      excludedStations: [],
    },
    classes: [],
    geometriesCoordinates: [],
  },
  developerFilter: {
    included: [],
    excluded: [],
  },
  flatFilter: {
    flatRoomsCount: [],
    floorNumber: {
      from: null,
      to: null,
    },
    flatNumber: [],
    totalArea: {
      from: null,
      to: null,
    },
    kitchenArea: {
      from: null,
      to: null,
    },
    price: {
      from: null,
      to: null,
      currency: CURRENCY.rub,
    },
    decorationTypes: [],
    windowsViewTypes: [],
    notFirstFloor: null,
    lastFloor: null,
    status: [FLAT_STATUS.free],
  },
  houseFilter: {
    deadLine: {
      from: {
        quarter: null,
        year: null,
      },
      to: {
        quarter: null,
        year: null,
      },
    },
    keysGetting: {
      from: {
        quarter: null,
        year: null,
      },
      to: {
        quarter: null,
        year: null,
      },
    },
    lifts: {
      hasPassenger: null,
      hasCargo: null,
    },
    materialTypes: [],
  },
}) as MarketEntitiesFilter
