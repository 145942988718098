<script lang="ts" setup>
import CustomRange from '@/ui/ranges/CustomRange.vue'
import BankMortgageInputBtnFastSet from '~/modules/bank/components/mortgage/BankMortgageInputBtnFastSet.vue'

const props = defineProps({
  modelValue: {
    type: Number,
    default: null,
  },
})

const emits = defineEmits<{ (e: 'update:modelValue', value: number): void }>()

const fastSetButtons = [5, 10, 20, 30]

const maxTerm = 30

const term = computed({
  get: () => props.modelValue,
  set: value => {
    emits('update:modelValue', value)
  },
})

const setTerm = (num: number) => {
  term.value = num
}
</script>

<template>
  <custom-range
    v-model="term"
    :max="maxTerm"
    :label="$t('mortgage.calculator.inputs.term.label')"
  >
    <template #suffixInner>
      <span class="text-neutral-500 font-medium inter">
        {{ $t('mortgage.calculator.inputs.term.suffix', Number(term)) }}
      </span>
    </template>
    <template #labelSuffix>
      <div class="flex items-center">
        <template
          v-for="(fastSetButton, inx) in fastSetButtons"
          :key="fastSetButton"
        >
          <span
            v-if="inx > 0"
            class="mx-3 inter font-semibold text-sm text-neutral-500"
          >
            /
          </span>
          <bank-mortgage-input-btn-fast-set
            @click="setTerm(fastSetButton)"
          >
            {{ fastSetButton }}
          </bank-mortgage-input-btn-fast-set>
        </template>
      </div>
    </template>
  </custom-range>
</template>
