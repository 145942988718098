<template>
  <Teleport to="body">
    <!-- Backdrop -->
    <div
      v-if="isVisibleInner"
      class="fixed top-0 left-0 right-0 w-full p-4 overflow-x-hidden overflow-y-auto h-[100vh] max-h-full bg-gray-900 bg-opacity-50 dark:bg-opacity-80"
      :style="{zIndex: zIndex}"
    />
    <div
      ref="modalElement"
      tabindex="-1"
      aria-hidden="true"
      class="fixed top-0 left-0 right-0 w-full overflow-x-hidden overflow-y-auto md:inset-0 h-[100%] max-h-full flex justify-center"
      :class="[{hidden: !isVisibleInner, 'p-4': size !== 'fullscreen'}, centered ? 'items-center' : 'items-start']"
      :style="{zIndex: zIndex + 1}"
      @click.self="!noCloseOnBackdrop && closeModal()"
      @wheel.self.prevent
    >
      <div
        class="relative max-h-full"
        :class="classes"
      >
        <!-- Modal content -->
        <div
          :id="contentId"
          class="relative bg-white shadow dark:bg-gray-700 flex flex-col"
          :class="[{'h-full': size === 'xxl' || size === 'fullscreen', 'rounded-[12px]': size !== 'fullscreen'}, contentClass]"
        >
          <!-- Modal header -->
          <div
            v-if="!hideHeader"
            class="flex items-start justify-between p-5 rounded-t"
            :class="headerClass"
          >
            <h3
              class="text-2xl font-semibold dark:text-white"
            >
              <slot name="header" />
              {{ title }}
            </h3>
            <button
              v-if="closable"
              class="!p-0 text-black bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
              type="button"
              @click="closeModal"
            >
              <slot name="modal-header-close">
                <nuxt-icon
                  name="actions/close"
                  class="text-[2rem]"
                />
              </slot>
            </button>
          </div>
          <!-- Modal body -->
          <slot name="body">
            <div
              v-if="!lazy || isVisibleInner"
              class="grow p-6 space-y-6 max-h-full scrollbar-thin"
              :class="[{'overflow-y-auto': !withOutBodyOverflow}, bodyClass]"
            >
              <slot />
            </div>
          </slot>
          <!-- Modal footer -->
          <div
            v-if="!hideFooter"
            class="flex items-center p-6 space-x-2 rounded-b"
            :class="footerClass"
          >
            <slot name="footer" />
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>
<script setup lang="ts">
import { useAppStateStore } from '~/store/app'
import baseZIndex from '~/ui/drawers/constants/Drawers.zIndex'

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: null,
  },
  hideHeader: {
    type: Boolean,
    default: false,
  },
  hideBody: {
    type: Boolean,
    default: false,
  },
  hideFooter: {
    type: Boolean,
    default: false,
  },
  noCloseOnBackdrop: {
    type: Boolean,
    default: false,
  },
  contentClass: {
    type: [String, Object, Array],
    default: null,
  },
  contentId: {
    type: String,
    default: undefined,
  },
  headerClass: {
    type: [String, Object, Array],
    default: null,
  },
  bodyClass: {
    type: [String, Object, Array],
    default: null,
  },
  footerClass: {
    type: [String, Object, Array],
    default: null,
  },
  centered: {
    type: Boolean,
    default: true,
  },
  closable: {
    type: Boolean,
    default: true,
  },
  size: {
    type: String as PropType<'sm' | 'md' | 'lg' | 'xxl' | 'fit-content' | 'fullscreen' | string>,
    default: 'md',
  },
  withOutBodyOverflow: {
    type: Boolean,
    default: false,
  },
  withCloseOnHistoryBack: {
    type: Boolean,
    default: false,
  },
  hash: {
    type: String,
    default: null,
  },
  lazy: {
    type: Boolean,
    default: true,
  },
})
type DestroyBeforeEachRouteCb = () => void
const randomHash = props.hash || Math.random().toString(16).slice(2)

const emits = defineEmits<{(e: 'update:modelValue', value: boolean): void}>()

const store = useAppStateStore()
const route = useRoute()
const router = useRouter()

const modalElement = ref<HTMLElement | null>(null)
const modalIndex = ref(0)
const zIndex = ref(baseZIndex)
const destroyBeforeEachRouteCb = ref<null | DestroyBeforeEachRouteCb>(null)

const isVisibleInner = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emits('update:modelValue', value)
  },
})

function closeModal() {
  isVisibleInner.value = false
}

const updateIndexes = () => {
  zIndex.value = baseZIndex + store.openElementsCount.modals * 2
  modalIndex.value = store.openElementsCount.modals
}

const isTopmost = computed(() => modalIndex.value === store.openElementsCount.modals)

const classes = computed(() => {
  const classList = []
  switch (props.size) {
    case 'fullscreen':
      classList.push('w-full h-full'); break
    case 'md':
      classList.push('w-full max-w-2xl'); break
    case 'lg':
      classList.push('w-full max-w-[60vw]'); break
    case 'xxl':
      classList.push('w-full h-full'); break
    case 'fit-content':
      classList.push('w-fit'); break
    default:
      classList.push(props.size)
  }
  return classList
})

const keyDownHandler = (e: KeyboardEvent) => {
  const wasTopMost = isTopmost.value
  setTimeout(() => {
    if (e.key === 'Escape' && wasTopMost) {
      e.preventDefault()
      closeModal()
    }
  }, 0)
}

const addHashIfNeed = () => {
  if (router.options.history.state.back && !props.hash) {
    return
  }

  navigateTo({ hash: `#${randomHash}` })
}

const deleteHashIfNeed = () => {
  const historyState = router.options.history.state
  const { current } = historyState
  const isCurrentIncludeHash = (current as string | null)?.includes(randomHash)
  if (isCurrentIncludeHash) {
    navigateTo({ hash: '' }, { replace: true })
  }
}

const historyListen = () => {
  destroyBeforeEachRouteCb.value = router.beforeEach((to, from, next) => {
    if (to.hash !== `#${randomHash}`) {
      isVisibleInner.value = false

      if (from.hash) {
        next({ hash: '' })
        return
      }

      next(false)
    }
    next(true)
  })
}

const destroyHistoryListener = () => {
  if (destroyBeforeEachRouteCb.value) {
    destroyBeforeEachRouteCb.value()
  }
}
const onOpenHandlersCall = () => {
  store.openModal()
  if (props.withCloseOnHistoryBack || props.hash) {
    addHashIfNeed()
  }
  if (props.withCloseOnHistoryBack) {
    historyListen()
  }
}

const onCloseHandlersCall = () => {
  store.closeModal()
  if (props.withCloseOnHistoryBack || props.hash) {
    deleteHashIfNeed()
  }
  if (props.withCloseOnHistoryBack) {
    destroyHistoryListener()
  }
}

watch(() => props.modelValue, isVisible => {
  if (isVisible) {
    onOpenHandlersCall()
  } else {
    onCloseHandlersCall()
  }
  updateIndexes()
})

onMounted(() => {
  document.body.addEventListener('keydown', keyDownHandler)
  if (props.modelValue) {
    onOpenHandlersCall()
    updateIndexes()
    return
  }
  if (props.hash) {
    const routeHash = route.hash ? route.hash.slice(1) : undefined
    if (routeHash) {
      isVisibleInner.value = true
    }
  }
})
onBeforeUnmount(() => {
  document.body.removeEventListener('keydown', keyDownHandler)
  if (props.modelValue) {
    onCloseHandlersCall()
    updateIndexes()
  }
})
</script>
