<template>
  <flats-btns-display-table-grid-or-layouts v-model="displayingEntity" />
  <flats-btn-display-chessboard
    :building="building"
    :flat-display-type="flatDisplayType"
  />
</template>

<script setup lang="ts">
import FlatsBtnsDisplayTableGridOrLayouts from '~/components/flats/buttons/FlatsBtnsDisplayTableGridOrLayouts.vue'
import FlatsBtnDisplayChessboard from '~/modules/building/components/page/actions/FlatsBtnDisplayChessboard.vue'
import { Building } from '~/modules/building/BuildingModule'
import { useBuildingStore } from '~/modules/building/store'
import DISPLAY_TYPE from '~/common/constants/flat/Flat.displayType'

defineProps({
  building: {
    type: Object as PropType<Building>,
    required: true,
  },
  flatDisplayType: {
    type: String as PropType<DISPLAY_TYPE>,
    default: DISPLAY_TYPE.basic,
  },
})

const store = useBuildingStore()

const displayingEntity = computed({
  get: () => store.flatsDisplayingEntity,
  set: value => {
    store.setFlatsDisplayingEntity(value)
  },
})
</script>
