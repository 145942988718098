<template>
  <flat-card-template
    :images="images"
    :address="flat.address?.fullAddress || flat.building?.address?.fullAddress"
    :total-area="flat.totalArea"
    :kitchen-area="flat.kitchenArea"
    :price="$n(flat.price.amount) + ' ' + $t(`currency.options.${flat.price.currency}`)"
    :price-per-square-meter="$t('flat.pricePerSquareMeter', {amount: $n(flat.pricePerSquareMeter?.amount ?? 0), currency: $t(`currency.options.${flat.pricePerSquareMeter?.currency ?? CURRENCY.usd}`)}
    )"
    :is-flat-in-promotion="!!building.promotions?.length"
  >
    <template #status>
      <flat-status
        :status="flat.status"
        :flat-number="flat.flatNumber"
      />
    </template>
    <template #building>
      <slot
        name="building"
        :building="flat.building"
      />
    </template>
    <template #promotion>
      <slot name="promotion" />
    </template>
    <template #title>
      <h4 class="font-semibold text-lg">
        {{ $t(`building.flatsInfo.flatRoomsCountBuildingCard.${flat.flatRoomsCount || 'notIndicated.label'}`) }}
        {{ $t('flat.totalArea.value', {value: flat.totalArea}) }}
        {{ $t('flat.floorNumber.value', {value: flat.floorNumber}) }},
        {{ $t('flat.section', {value: sectionName}) }}
      </h4>
    </template>
    <template
      v-if="flat.decoration"
      #decoration
    >
      <flat-decoration
        :flat="flat"
      />
    </template>
    <template
      v-if="displayType !== DISPLAY_TYPE.presentation && !appStateStore.clientMode"
      #agentMotivation
    >
      <building-carousel-btn-motivation
        :building="building"
        :flat="flat"
        :entity="AGENT_MOTIVATION_ENTITY.flat"
      />
    </template>
    <template
      v-if="flat.cardinalDirections && flat.cardinalDirections.length"
      #compass
    >
      <cardinal-directions-compass :class="cardinalDirectionsClasses" />
    </template>
    <template
      v-if="flat.windowsViewTypes && flat.windowsViewTypes.length"
      #windowView
    >
      <custom-tooltip placement="top">
        <template #content>
          <div>
            {{ flat.windowsViewTypes.map((type) => $t(`flat.windowViewType.options.${type}.label`)).join(', ') }}
          </div>
        </template>
        <custom-button
          class="font-semibold"
          color="transparent"
          pill
        >
          <template #prefix>
            <nuxt-icon
              filled
              name="entities/window-view"
            />
          </template>
          {{ $t(`flat.windowViewType.label`) }}
        </custom-button>
      </custom-tooltip>
    </template>
    <template #actions>
      <slot name="actions" />
    </template>
  </flat-card-template>
</template>

<script setup lang="ts">
import FlatStatus from '~/components/flats/flat-list-card/FlatStatus.vue'
import FlatDecoration from '~/components/flats/flat-list-card/FlatDecoration.vue'
import FlatCardTemplate from '~/components/flats/templates/FlatCardTemplate.vue'
import BuildingCarouselBtnMotivation from '~/modules/building/components/carousel/BuildingCarouselBtnMotivation.vue'
import type { Flat } from '~/common/types/flat/Flat'
import type { Image } from '~/common/types/image/Image'
import { getEntityName } from '~/common/helpers/getEntityName'
import { AvailableCountry } from '~/common/types/country/Country.available'
import DISPLAY_TYPE from '~/common/constants/flat/Flat.displayType'
import { Building } from '~/modules/building/types/Building'
import { AGENT_MOTIVATION_ENTITY } from '~/modules/building/constants/AgentMotivation.entity'
import CustomButton from '~/ui/buttons/CustomButton.vue'
import CustomTooltip from '~/ui/tooltips/CustomTooltip.vue'
import { FLAT_CARDINAL_DIRECTIONS } from '~/common/constants/cardinalDirections/CardinalDirections.type'
import CardinalDirectionsCompass from '~/components/common/CardinalDirectionsCompass.vue'
import { useAppStateStore } from '~/store/app'

const props = defineProps({
  flat: {
    type: Object as PropType<Flat>,
    required: true,
  },
  displayType: {
    type: String as PropType<DISPLAY_TYPE>,
    default: DISPLAY_TYPE.basic,
  },
  building: {
    type: Object as PropType<Building>,
    required: true,
  },
})

const appStateStore = useAppStateStore()

const images = computed<Array<Image>>(() => [...(props.flat?.layout?.images ?? [])])

const injectedFlatAddressCountryCode: AvailableCountry | undefined = inject('flatAddressCountryCode')
const flatAddressCountryCode = props.flat?.building?.address?.countryIsoCode || props.flat?.address?.countryIsoCode || injectedFlatAddressCountryCode

if (!flatAddressCountryCode) {
  throw new Error('Не передан flatAddressCountryCode')
}

const sectionName = computed(() => getEntityName({
  name: props.flat?.section?.name,
  internationalName: props.flat.section.internationalName,
  entityCountry: flatAddressCountryCode,
}))

const cardinalDirectionsClasses = computed(() => {
  const cardinalDirections = {
    north: props.flat.cardinalDirections.includes(FLAT_CARDINAL_DIRECTIONS.north),
    south: props.flat.cardinalDirections.includes(FLAT_CARDINAL_DIRECTIONS.south),
    west: props.flat.cardinalDirections.includes(FLAT_CARDINAL_DIRECTIONS.west),
    east: props.flat.cardinalDirections.includes(FLAT_CARDINAL_DIRECTIONS.east),
    northeast: props.flat.cardinalDirections.includes(FLAT_CARDINAL_DIRECTIONS.northeast),
    southeast: props.flat.cardinalDirections.includes(FLAT_CARDINAL_DIRECTIONS.southeast),
    southwest: props.flat.cardinalDirections.includes(FLAT_CARDINAL_DIRECTIONS.southwest),
    northwest: props.flat.cardinalDirections.includes(FLAT_CARDINAL_DIRECTIONS.northwest),
  }

  return {
    'class-for-north': !cardinalDirections.north,
    'class-for-south': !cardinalDirections.south,
    'class-for-west': !cardinalDirections.west,
    'class-for-east': !cardinalDirections.east,
    'class-for-northeast': !cardinalDirections.northeast,
    'class-for-southeast': !cardinalDirections.southeast,
    'class-for-southwest': !cardinalDirections.southwest,
    'class-for-northwest': !cardinalDirections.northwest,
  }
})

</script>
<style lang="scss">
.class-for-north {
  .north-circle {
    display: none;
  }
}
.class-for-south {
  .south-circle {
    display: none;
  }
}
.class-for-west {
  .west-circle {
    display: none;
  }
}
.class-for-east {
  .east-circle {
    display: none;
  }
}
.class-for-northeast {
  .northeast-circle {
    display: none;
  }
}
.class-for-southeast {
  .southeast-circle {
    display: none;
  }
}
.class-for-southwest {
  .southwest-circle {
    display: none;
  }
}
.class-for-northwest {
  .northwest-circle {
    display: none;
  }
}
</style>
