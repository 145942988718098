<template>
  <component
    :is="flatCard"
    :flat="flat"
    :display-type="displayType"
    :building="flatBuilding"
  >
    <template #actions="actionObject">
      <slot name="actions">
        <component
          :is="flatBtns"
          :flat="flat"
          :button-class="{'bg-white': mini && !(actionObject as ActionObject)?.bgNormal}"
        />
      </slot>
    </template>
    <template #building="{building}">
      <slot
        name="building"
        :building="building"
      />
    </template>
    <template #promotion>
      <promotions-labels
        :promotions="flatBuilding.promotions"
        :container-class="`absolute -left-[10px] gap-[6px] ${flat.decoration ? 'bottom-[40px]' : 'bottom-[10px]' }`"
        :count-to-group="2"
        :building-id="flatBuilding.id"
      />
    </template>
  </component>
</template>

<script setup lang="ts">
import FlatsListMiniCardView from '~/components/flats/flat-list-card/FlatsListMiniCardView.vue'
import FlatsListCardView from '~/components/flats/flat-list-card/FlatsListCardView.vue'
import type { Flat } from '~/common/types/flat/Flat'
import DISPLAY_TYPE from '~/common/constants/flat/Flat.displayType'
import { Building } from '~/modules/building/types/Building'
import PromotionsLabels from '~/modules/widget/components/PromotionsLabels.vue'

type ActionObject = {bgNormal: boolean} | undefined

const props = defineProps({
  flat: {
    type: Object as PropType<Flat>,
    required: true,
  },
  displayType: {
    type: String as PropType<DISPLAY_TYPE>,
    default: DISPLAY_TYPE.basic,
  },
  mini: {
    type: Boolean,
    default: false,
  },
  building: {
    type: Object as PropType<Building>,
    required: true,
  },
})

const flatCard = computed(() => (props.mini ? markRaw(FlatsListMiniCardView) : markRaw(FlatsListCardView)))

const flatBtns = computed(() => {
  switch (props.displayType) {
    case DISPLAY_TYPE.basic:
      return defineAsyncComponent(() => import('~/components/flats/buttons/FlatsBtnsBasic.vue'))
    case DISPLAY_TYPE.suggestionManagement:
      return defineAsyncComponent(() => import('~/components/flats/buttons/FlatsBtnsSuggestionManagement.vue'))
    case DISPLAY_TYPE.presentation:
      return defineAsyncComponent(() => import('~/components/flats/buttons/FlatsBtnsPresentation.vue'))
    case DISPLAY_TYPE.buildingPresentation:
      return defineAsyncComponent(() => import('~/components/flats/buttons/FlatsBtnsBuildingPresentation.vue'))
    default:
      return defineAsyncComponent(() => import('~/components/flats/buttons/FlatsBtnsBasic.vue'))
  }
})

const flatBuilding = computed<Building>(() => props.building || props.flat.building)
</script>
