<template>
  <custom-dropdown
    :close-on-select="false"
    color="secondary"
    :button-class="[{'!bg-blue-100': countSelectedOptions}, 'w-full font-semibold']"
    dropdown-class="min-w-[375px]"
  >
    <template #triggerContent>
      <filter-counter v-if="countSelectedOptions">
        {{ countSelectedOptions }}
      </filter-counter>
      {{ triggerText }}
    </template>
    <filter-from-to
      v-model:from="totalAreaFilterFrom"
      v-model:to="totalAreaFilterTo"
      :placeholder-from="$t('common.from')"
      :placeholder-to="$t('common.to')"
      :selecting-values-from="selectingValues"
      :selecting-values-to="selectingValues"
      :inner-input-suffix="$t('common.unitsOfMeasurement.options.squareMeter')"
    />
  </custom-dropdown>
</template>

<script setup lang="ts">
import FilterFromTo from '~/components/filters/from-to/FilterFromTo.vue'
import CustomDropdown from '~/ui/dropdowns/CustomDropdown.vue'
import { FromToSelectingValue } from '~/common/types/filters/from-to/FromTo.selectingValue'
import type { FromTo } from '~/common/types/filters/from-to/FromTo'
import FilterCounter from '~/components/filters/FilterCounter.vue'

const props = defineProps({
  from: {
    type: Number as PropType<FromTo['from']>,
    default: null,
  },
  to: {
    type: Number as PropType<FromTo['to']>,
    default: null,
  },
  triggerText: {
    type: String,
    default: '',
  },
})
const emits = defineEmits<{(e: 'update:from', value: FromTo['from']): void,
  (e: 'update:to', value: FromTo['to']): void
}>()

const totalAreaFilterFrom = computed({
  get: () => props.from,
  set: value => {
    emits('update:from', value)
  },
})

const totalAreaFilterTo = computed({
  get: () => props.to,
  set: value => {
    emits('update:to', value)
  },
})

const countSelectedOptions = computed(() => {
  if (totalAreaFilterFrom.value && totalAreaFilterTo.value) {
    return 2
  }
  if (totalAreaFilterFrom.value || totalAreaFilterTo.value) {
    return 1
  }
  return 0
})

const selectingValues: Array<FromToSelectingValue> = []
for (let i = 1; i <= 15; i += 1) {
  selectingValues.push({ value: i * 10 })
}

</script>
