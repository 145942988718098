import { AuthService, checkTokenExpiration } from '~/modules/auth/AuthModule'
import { HttpHandledResponse } from '~/services/http/types/Http.handledResponse'
import SentryLogger from '~/services/sentry/SentryLogger'

type TSubscriber = (accessToken: string) => void

export class InterceptorsHandler {
  private authService = new AuthService()

  private isFetchingTokens = false

  private subscribers: TSubscriber[] = []

  constructor() {
    this.handleRejected = this.handleRejected.bind(this)
  }

  public handleRejected(error: HttpHandledResponse): Promise<any> {
    switch (error.status) {
      case 401:
        return this.handleUnauthorized(error)
      case 403:
        SentryLogger.captureScopeException(error, {
          message: 'Ошибка 403',
        })
        return Promise.reject(error)
      default:
        return Promise.reject(error)
    }
  }

  private async handleUnauthorized(error: HttpHandledResponse): Promise<any> {
    const { requestConfig: originalRequest } = error

    const refreshToken = await this.authService.getRefreshToken()

    if (refreshToken && checkTokenExpiration(refreshToken.expiresAtTime)) {
      if (!this.isFetchingTokens) {
        this.isFetchingTokens = true

        this.authService.updateTokens(refreshToken.token)
          .then(updatedTokens => {
            this.authService.httpInstance.setAuthToken(updatedTokens.accessToken)
            this.authService.setAccessToken({ token: updatedTokens.accessToken, expiresAtTime: updatedTokens.accessTokenExpiresAt })

            this.notifySubscribers(updatedTokens.accessToken)
          })
          .catch(error => {
            if(status !== 404) {
              SentryLogger.captureScopeException(error, {
                message: 'Проблема с обновлением токенов',
              })
            }
            this.authService.onLogout()
          })
          .finally(() => {
            this.isFetchingTokens = false
          })
      }

      const retryOriginalRequest: Promise<any> = new Promise(resolve => {
        this.addSubscriber(accessToken => {
          // @ts-ignore
          originalRequest.options.headers.Authorization = `Bearer ${accessToken}`
          resolve(this.authService.httpInstance.fetch(originalRequest.url, originalRequest.options))
        })
      })
      return retryOriginalRequest
    }

    this.authService.onLogout()
    return Promise.reject(error)
  }

  private addSubscriber(subscriber: TSubscriber): void {
    this.subscribers.push(subscriber)
  }

  private notifySubscribers(accessToken: string): void {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }
}
