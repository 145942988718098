import { LAYOUTS } from '~/router/constants/Router.layouts'
import { ROUTES_NAMES } from '~/router/constants/Router.routesNames'

type RouteNames = typeof ROUTES_NAMES
type LayoutNames = typeof LAYOUTS
export const ROUTES_LAYOUTS: Record<RouteNames[keyof RouteNames], LayoutNames[keyof LayoutNames]> = {
  [ROUTES_NAMES.home]: LAYOUTS.homeLayout,
  [ROUTES_NAMES.market]: LAYOUTS.authLayout,
  [ROUTES_NAMES.buildingPage]: LAYOUTS.authLayout,
  [ROUTES_NAMES.presentationBuildingPage]: LAYOUTS.presentationLayout,
  [ROUTES_NAMES.presentationBuildingPageFromSuggestion]: LAYOUTS.presentationLayout,
  [ROUTES_NAMES.suggestions]: LAYOUTS.guestLayout,
  [ROUTES_NAMES.suggestionsManagement]: LAYOUTS.authLayout,
  [ROUTES_NAMES.clients]: LAYOUTS.authLayout,
  [ROUTES_NAMES.suggestionPage]: LAYOUTS.suggestionLayout,
  [ROUTES_NAMES.login]: LAYOUTS.guestLayout,
  [ROUTES_NAMES.signup]: LAYOUTS.guestLayout,
  [ROUTES_NAMES.signupInvitation]: LAYOUTS.guestLayout,
  [ROUTES_NAMES.forgotPassword]: LAYOUTS.guestLayout,
  [ROUTES_NAMES.forbidden]: LAYOUTS.guestLayout,
  [ROUTES_NAMES.notFound]: LAYOUTS.guestLayout,
  [ROUTES_NAMES.developerPage]: LAYOUTS.homeLayout,
  [ROUTES_NAMES.journal]: LAYOUTS.homeLayout,
  [ROUTES_NAMES.journalNews]: LAYOUTS.homeLayout,
  [ROUTES_NAMES.events]: LAYOUTS.homeLayout,
  [ROUTES_NAMES.resetPassword]: LAYOUTS.guestLayout,
  [ROUTES_NAMES.privatePolicy]: LAYOUTS.guestLayout,
  [ROUTES_NAMES.licenseAgreement]: LAYOUTS.guestLayout,
}
