import { HttpApiService } from '~/services/http/HttpApiService'
import { HttpHandledResponse } from '~/services/http/types/Http.handledResponse'
import { Service } from '~/services/http/Service'
import { MarketEntitiesSort } from '~/modules/market-entities-actions/types/sort/Sort'
import { Country } from '~/modules/address/AddressModule'
import { MarketEntitiesFilterForRequest } from '~/modules/market-entities-actions/types/filter/Filter.forSearchRequest'
import { MapBuildingFeatureObject } from '~/common/types/map/ymaps.buildingFeatureObject'
import { MarketEntitiesSearchResponse } from '~/modules/market-entities-actions/types/search/Search.response'
import { Building } from '~/modules/building/BuildingModule'
import { Flat } from '~/common/types/flat/Flat'
import { EntitiesCount } from '~/common/types/entitiesCount/EntitiesCount'
import { Layout } from '~/common/types/layout/Layout'

export class MarketEntitiesActionsHttpApiService extends Service {
  private endPoints = {
    firstPart: '/v4/estate-market',
    searchBuildings: '/development/buildings/search',
    searchFlats: '/development/flats/search',
    searchLayouts: '/development/layouts/search',
    searchFeatureCollection: '/development/buildings/by-coordinates',
    count: '/development/buildings/count',
    suggestions: '/query-suggestions',
  }

  constructor(httpInstance: HttpApiService) {
    super(httpInstance)
  }

  public searchBuildings(filter: MarketEntitiesFilterForRequest, page: number, sort: MarketEntitiesSort, bBox?: ymaps.CoordinateBounds, zoom?: number): Promise<HttpHandledResponse<MarketEntitiesSearchResponse<Building>>> {
    return this.httpInstance.post(`${this.endPoints.firstPart}${this.endPoints.searchBuildings}`, {
      filter,
      page,
      sort,
      bBox,
      zoom,
    })
  }

  public searchFlats(filter: MarketEntitiesFilterForRequest, page: number, sort: MarketEntitiesSort, bBox?: ymaps.CoordinateBounds, zoom?: number): Promise<HttpHandledResponse<MarketEntitiesSearchResponse<Flat>>> {
    return this.httpInstance.post(`${this.endPoints.firstPart}${this.endPoints.searchFlats}`, {
      filter,
      page,
      sort,
      bBox,
      zoom,
    })
  }

  public searchLayouts(filter: MarketEntitiesFilterForRequest, page: number, sort: MarketEntitiesSort, bBox?: ymaps.CoordinateBounds, zoom?: number): Promise<HttpHandledResponse<MarketEntitiesSearchResponse<Layout>>> {
    return this.httpInstance.post(`${this.endPoints.firstPart}${this.endPoints.searchLayouts}`, {
      filter,
      page,
      sort,
      bBox,
      zoom,
    })
  }

  public getCount(filter: MarketEntitiesFilterForRequest, page?: number, sort?: MarketEntitiesSort): Promise<HttpHandledResponse<EntitiesCount>> {
    return this.httpInstance.post(`${this.endPoints.firstPart}${this.endPoints.count}`, {
      filter,
    })
  }

  public searchFeatureCollection(filter: MarketEntitiesFilterForRequest, bBox: ymaps.CoordinateBounds, zoom: number): Promise<HttpHandledResponse<ymaps.IFeatureCollection<MapBuildingFeatureObject>>> {
    return this.httpInstance.post(`${this.endPoints.firstPart}${this.endPoints.searchFeatureCollection}`, {
      bBox,
      filter,
      zoom,
    })
  }

  public suggest(country: Pick<Country, 'id'>, query: string, limit: number): Promise<HttpHandledResponse<any>> {
    return this.httpInstance.post(`${this.endPoints.firstPart}${this.endPoints.suggestions}`, {
      country, query, limit, options: ['building', 'developer'],
    })
  }
}
