export const FLAT_CARDINAL_DIRECTIONS = {
  north: 'north',
  south: 'south',
  west: 'west',
  east: 'east',
  northeast: 'northeast',
  southeast: 'southeast',
  southwest: 'southwest',
  northwest: 'northwest',
} as const
