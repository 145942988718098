<script lang="ts" setup>
import { DateTimeHelper } from '~/common/helpers/date/dateTime'
import CustomCalendarHoursWheel from '~/ui/calendars/components/CustomCalendarHoursWheel.vue'

const props = defineProps({
  modelValue: {
    type: String,
    required: true,
  },
})

const emits = defineEmits<{(e: 'update:modelValue', value: string): void }>()

const hoursLength = 24
const minutesLength = 60

const hours = ref(Number(props.modelValue?.split(':')?.[0] || 0))
const minutes = ref(Number(props.modelValue?.split(':')?.[1] || 0))

watch(hours, value => {
  const hourString = DateTimeHelper.normalizeTimeLength(value || 0)
  const minutesString = DateTimeHelper.normalizeTimeLength(minutes.value || 0)
  emits('update:modelValue', `${hourString}:${minutesString}`)
})

watch(minutes, value => {
  const hourString = DateTimeHelper.normalizeTimeLength(hours.value || 0)
  const minutesString = DateTimeHelper.normalizeTimeLength(value || 0)
  emits('update:modelValue', `${hourString}:${minutesString}`)
})

watch(() => props.modelValue, value => {
  hours.value = Number(value?.split(':')?.[0] || 0)
  minutes.value = Number(value?.split(':')?.[1] || 0)
})
</script>

<template>
  <div class="flex items-center gap-x-6">
    <custom-calendar-hours-wheel
      v-model="hours"
      :wheel-array-length="hoursLength"
    />
    <div class="text-[20px] text-neutral-750 font-bold">
      :
    </div>
    <custom-calendar-hours-wheel
      v-model="minutes"
      :wheel-array-length="minutesLength"
    />
  </div>
</template>
