import type { YearQuarterObject } from '~/common/types/base/YearQuarterObject'
import { Quarter } from '~/common/types/date/Quarter'
import { QUARTER } from '~/common/constants/date/Quarter'

export class QuarterUtils {
  static isQuarterStarted(quarter: Quarter, year: number): boolean {
    const currentYear = new Date().getFullYear()
    const currentQuarter = Math.ceil((new Date().getMonth() + 1) / 3)

    if (year < currentYear) {
      return true // Год уже прошел
    } if (year === currentYear) {
      return QUARTER[quarter] <= currentQuarter
    }
    return false
  }

  static getNextQuarters(quarterCount: number) {
    const currentYear = new Date().getFullYear()
    const currentQuarter = Math.ceil((new Date().getMonth() + 1) / 3)

    const quarters: YearQuarterObject[] = []
    let quarterIndex = currentQuarter
    let year = currentYear

    for (let i = 0; i < quarterCount; i += 1) {
      quarters.push({ quarter: QUARTER[quarterIndex] as Quarter, year })
      quarterIndex += 1
      if (quarterIndex > 4) {
        quarterIndex = 1
        year += 1
      }
    }
    return quarters
  }
}
