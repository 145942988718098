import { isEmpty } from '~/common/helpers/checkers'

type NestedObject = {
  [key: string]: NestedObject | string | number | boolean | null | undefined | Array<any>
}

export class ObjectHelper {
  private static separator = '.'

  public static setNestedValue(obj: NestedObject, path: string, value: any): void {
    const pathArr = path.split(this.separator)
    const key = pathArr[0]

    if (pathArr.length === 1) {
      obj[key] = value
    } else {
      if (!obj[key] || typeof obj[key] !== 'object') {
        obj[key] = {}
      }

      this.setNestedValue(obj[key] as NestedObject, pathArr.slice(1).join('.'), value)
    }
  }

  public static getNestedValue(obj: NestedObject, path: string): NestedObject[keyof NestedObject] {
    const pathArr = path.split(this.separator)
    const key = pathArr[0]
    const value = obj.hasOwnProperty(key) ? obj[key] : null

    if (pathArr.length === 1) {
      return value
    }
    if (this.isPrimitive(value)) {
      return null
    }
    return this.getNestedValue(value as NestedObject, pathArr.slice(1).join('.'))
  }

  public static getNestedPaths(obj: unknown, deadEndPaths: string[] = [], excludedPaths: string[] = [], rootPath = ''): Array<string> {
    if (deadEndPaths.includes(rootPath)) {
      return []
    }
    if (this.isPrimitive(obj)) {
      return []
    }
    if (Array.isArray(obj)) {
      return []
    }
    const paths: string[][] = []
    Object.keys(obj as NestedObject).forEach(key => {
      const keyPath = rootPath === '' ? key : [rootPath, key].join(this.separator)
      if (excludedPaths.includes(keyPath)) {
        return
      }
      const nestedPaths = this.getNestedPaths((obj as NestedObject)[key], deadEndPaths, excludedPaths, keyPath)
      if (nestedPaths.length) {
        paths.push(...nestedPaths.map(path => [key, path]))
      } else {
        paths.push([key])
      }
    })
    return paths.map(path => path.join(this.separator))
  }

  static isPrimitive(value: unknown) {
    return value === null || typeof value !== 'object'
  }

  static isEqual(obj1: NestedObject[keyof NestedObject], obj2: NestedObject[keyof NestedObject]) {
    return JSON.stringify(obj1, (obj1 && typeof obj1 === 'object') ? Object.keys(obj1).sort() : null) ===
      JSON.stringify(obj2, (obj2 && typeof obj2 === 'object') ? Object.keys(obj2).sort() : null)
  }

  static copy<T = NestedObject>(obj: T): T {
    return JSON.parse(JSON.stringify(obj))
  }

  static areAllValuesNull = (obj: NestedObject) => Object.values(obj).every(value => value === null)

  static isObject(value: any): boolean {
    return typeof value === 'object' && value !== null && !Array.isArray(value)
  }

  static syncObjects<R>(innerObject: Record<string, any>, standardObject: Record<string, any>): R {
    const result = { ...standardObject }

    Object.keys(standardObject).forEach(key => {
      if (innerObject && innerObject.hasOwnProperty(key)) {
        if (this.isObject(standardObject[key]) && this.isObject(innerObject)) {
          result[key] = this.syncObjects(innerObject[key], standardObject[key])
        } else {
          result[key] = innerObject[key]
        }
      }
    })
    return result as R
  }

  static addToFormData(formData: FormData, object: Record<string, any> | string, topLevelPath = '') {
    if (typeof object === 'string') {
      formData.append(topLevelPath, object)
      return
    }
    Object.keys(object).forEach(key => {
      if (Array.isArray(object[key])) {
        object[key].forEach((item, index) => {
          ObjectHelper.addToFormData(formData, item, topLevelPath ? `${topLevelPath}[${key}][${index}]` : `${key}[${index}]`)
        })
      } else if (typeof object[key] === 'object' && object[key] !== null && !Array.isArray(object[key])) {
        ObjectHelper.addToFormData(formData, object[key], topLevelPath ? `${topLevelPath}[${key}]` : key)
      } else if (topLevelPath) {
        !isEmpty(object[key] || object[key] === false || object[key] === null) && formData.append(`${topLevelPath}[${key}]`, object[key])
      } else {
        !isEmpty(object[key] || object[key] === false || object[key] === null) && formData.append(key, object[key])
      }
    })
  }
}
