<template>
  <chessboard
    v-model:selectedHouse="selectedHouse"
    :houses="housesWithOrder"
    :sections="sections"
    :is-fetching="isFetching"
    :flat-display-type="flatDisplayType"
    :building="building"
  >
    <template #filterButton>
      <custom-button
        class="font-semibold mr-4 hidden md:block self-start px-4 h-[38px]"
        pill
        size="none"
        @click="isModalOpen = true"
      >
        {{ $t('entityActions.filter.button') }}
      </custom-button>
    </template>
    <template #closeButton>
      <slot name="closeButton" />
    </template>
  </chessboard>
  <bottom-fixed-menu class="md:hidden flex items-center justify-center p-3">
    <saved-filters button-class="whitespace-nowrap" />
    <custom-button
      class="font-semibold ml-4 md:hidden"
      @click="isModalOpen = true"
    >
      {{ $t('entityActions.filter.button') }}
    </custom-button>
  </bottom-fixed-menu>
  <modal-or-bottom-sheet
    v-model="isModalOpen"
    :modal-props="{size: 'w-full xl:max-w-[60vw] max-w-[90vw]', centered: false}"
    :drawer-props="{fullHeight: true}"
    :title="$t('entityActions.filter.button')"
    with-out-body-overflow
  >
    <flats-filter
      v-model:filter="filter"
      layout-variant="modal"
      @reset-filters="resetFilters"
    >
      <template #searchButton>
        <search-button
          class="w-full font-semibold"
          :is-fetching="isCountFetching"
          @click="searchFlats"
        >
          {{ $t('entityActions.show.flats', searchCount) }}
        </search-button>
      </template>
    </flats-filter>
  </modal-or-bottom-sheet>
</template>

<script setup lang="ts">
import { FlatFilterForRequest } from '~/modules/building/components/page/flats/filter/types/FlatsFilter'
import { Building } from '~/modules/building/types/Building'
import { defaultFlatsFilter } from '~/modules/building/components/page/flats/filter/constants/FlatsFilter.default'
import { Chessboard } from '~/modules/chessboard/ChessboardModule'
import CustomButton from '~/ui/buttons/CustomButton.vue'
import ModalOrBottomSheet from '~/components/common/ModalOrBottomSheet.vue'
import FlatsFilter from '~/modules/building/components/page/flats/filter/FlatsFilter.vue'
import BottomFixedMenu from '~/ui/menus/BottomFixedMenu.vue'
import SavedFilters from '~/modules/market-entities-actions/components/filter/SavedFilters.vue'
import { HouseExpanded } from '~/common/types/house/House.expanded'
import SearchButton from '~/modules/market-entities-actions/components/filter/SearchButton.vue'
import { BuildingFlatFilter } from '~/modules/building/components/page/flats/filter/types/BuildingFlatFilter'
import DISPLAY_TYPE from '~/common/constants/flat/Flat.displayType'
import { FlatService } from '~/common/services/flat/FlatService'
import { FlatGuestService } from '~/common/services/flat/FlatGuestService'
import { ChessboardSection } from '~/modules/chessboard/types/Chessboard.section'
import { orderHouses } from '~/modules/building/helpers/Building.orderHouses'

const props = defineProps({
  building: {
    type: Object as PropType<Building>,
    required: true,
  },
  filter: {
    type: Object as PropType<BuildingFlatFilter>,
    required: true,
  },
  flatDisplayType: {
    type: String as PropType<DISPLAY_TYPE>,
    default: DISPLAY_TYPE.basic,
  },
})

const emits = defineEmits<{(e: 'resetFilters'): void,
  (e: 'update:filter', filter: BuildingFlatFilter): void
}>()

const flatService = inject<FlatService | FlatGuestService>('flatService', new FlatService())

const isFetching = ref(false)
const isCountFetching = ref(false)
const isModalOpen = ref(false)
const searchCount = ref(0)
const sections = ref<Array<ChessboardSection>>([])
const houses = ref<Array<HouseExpanded>>(props.building.houses as HouseExpanded[])
const housesWithOrder = computed(() => orderHouses<HouseExpanded>(houses.value))

const selectedHouse = ref<{ house: HouseExpanded, order: number }>(housesWithOrder.value[0])

const filter = computed<BuildingFlatFilter>({
  get: () => props.filter,
  set: value => {
    emits('update:filter', value)
  },
})

const getFilterForCountRequest: () => FlatFilterForRequest = () => ({
  flatFilter: filter.value.flatFilter,
  houseFilter: {
    building: { id: props.building?.id },
    deadLine: filter.value.houseFilter.deadLine,
  },
})

const getFilterForSearchRequest: () => FlatFilterForRequest = () => ({
  flatFilter: filter.value.flatFilter,
  houseFilter: {
    building: { id: props.building?.id },
    deadLine: filter.value.houseFilter.deadLine,
    includedIds: [selectedHouse.value.house.id],
  },
})

const filterFlats = () => {
  isFetching.value = true

  flatService.searchSectionsForChessboard(getFilterForSearchRequest())
    .then(res => {
      sections.value = res
    })
    .finally(() => {
      isFetching.value = false
    })
}
const countFlats = () => {
  isCountFetching.value = true

  flatService.count(getFilterForCountRequest())
    .then(flatsCount => {
      searchCount.value = flatsCount
    })
    .finally(() => {
      isCountFetching.value = false
    })
}
const searchFlats = () => {
  isModalOpen.value = false
  filterFlats()
}
const resetFilters = () => {
  filter.value = defaultFlatsFilter()
}

watch(filter, countFlats, { deep: true })

watch(selectedHouse, () => {
  filterFlats()
})

onMounted(() => {
  filterFlats()
  countFlats()
})
</script>
