import { Preferences } from '@capacitor/preferences'
import { QdStorage } from '~/common/storage/interfaces/QdStorage'

export class MobilePlatformStorage implements QdStorage {
  private readonly storage = Preferences
  async getItem<T>(key: string): Promise<T | null | undefined> {
    const storedData = (await this.storage.get({ key })).value
    if (storedData === null) {
      return null
    }
    try {
      return JSON.parse(storedData) as T
    } catch (error) {
      return null
    }
  }

  setItem<T>(key: string, value: T): Promise<void> {
    return this.storage.set({ key, value: JSON.stringify(value) })
  }

  removeItem(key: string): Promise<void> {
    return this.storage.remove({ key })
  }

  clear(): Promise<void> {
    return this.storage.clear()
  }
}
