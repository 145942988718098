<template>
  <custom-input
    v-model="value"
    :label="$t('auth.lastName.label')"
    :required="true"
    :invalid-feedback="errorMessage"
    :state="errorMessage ? false : null"
    :placeholder="$t('auth.lastName.placeholder')"
    :disabled="disabled"
  />
</template>

<script setup lang="ts">
import { useField } from 'vee-validate'
import CustomInput from '~/ui/inputs/CustomInput.vue'

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  errors: {
    type: Array as PropType<Array<string>>,
    default: () => [],
  },
})

const { t } = useI18n()

const { value, errorMessage, setErrors } = useField<string>('lastName', { required: true, min: 1, max: 30 }, {
  label: t('auth.lastName.label'),
})

watchEffect(() => {
  setErrors(props.errors)
})
</script>
