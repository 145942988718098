import { MARKET_ENTITIES_SORT } from '~/modules/market-entities-actions/constants/sort/Sort'
import { MarketEntitiesSortOption } from '~/modules/market-entities-actions/types/sort/Sort.option'

export const MARKET_ENTITIES_SORT_OPTIONS: ComputedRef<Array<MarketEntitiesSortOption>> = computed(() => {
  const { t } = useI18n()

  return [
    {
      sortGroupLabel: t('entityActions.sort.options.price.label'),
      sortGroupIcon: 'ruble-sign',
      options: [
        {
          value: MARKET_ENTITIES_SORT.ascPrice,
          icon: 'arrow-down-short-wide',
          label: t('entityActions.sort.options.price.order.ascending'),
        },
        {
          value: MARKET_ENTITIES_SORT.descPrice,
          icon: 'arrow-down-wide-short',
          label: t('entityActions.sort.options.price.order.descending'),
        },
      ],
    },
    {
      sortGroupLabel: t('entityActions.sort.options.deadline.label'),
      sortGroupIcon: 'house-circle-check',
      options: [
        {
          value: MARKET_ENTITIES_SORT.descDeadLine,
          icon: 'arrow-down-wide-short',
          label: t('entityActions.sort.options.deadline.order.descending'),
        },
        {
          value: MARKET_ENTITIES_SORT.ascDeadLine,
          icon: 'arrow-down-short-wide',
          label: t('entityActions.sort.options.deadline.order.ascending'),
        },
      ],
    },
    {
      sortGroupLabel: t('entityActions.sort.options.popularity.label'),
      sortGroupIcon: 'fire',
      options: [
        {
          value: MARKET_ENTITIES_SORT.descPopularity,
          icon: 'arrow-down-wide-short',
          label: t('entityActions.sort.options.popularity.order.descending'),
        },
        {
          value: MARKET_ENTITIES_SORT.ascPopularity,
          icon: 'arrow-down-short-wide',
          label: t('entityActions.sort.options.popularity.order.ascending'),
        },
      ],
    },
    {
      sortGroupLabel: t('entityActions.sort.options.percentage.label'),
      sortGroupIcon: 'fire',
      options: [
        {
          value: MARKET_ENTITIES_SORT.descAgentMotivation,
          icon: 'arrow-down-wide-short',
          label: t('entityActions.sort.options.percentage.order.descending'),
        },
        {
          value: MARKET_ENTITIES_SORT.ascAgentMotivation,
          icon: 'arrow-down-short-wide',
          label: t('entityActions.sort.options.percentage.order.ascending'),
        },
      ],
    },
  ]
})
