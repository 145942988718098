<script lang="ts" setup>
import { Calendar, DatePicker } from 'v-calendar'
import CustomButton from '~/ui/buttons/CustomButton.vue'
import { MONTH } from '~/common/constants/date/Month'
import { Page } from 'v-calendar/dist/types/src/utils/page'

const props = defineProps({
  pages: {
    type: Array as PropType<Array<Page>>,
    required: true,
  },
  calendarRef: {
    type: Object as PropType<InstanceType<typeof Calendar | typeof DatePicker> | null>,
    default: null,
  },
  selectedMonth: {
    type: Number,
    default: 0,
  },
  selectedYear: {
    type: Number,
    default: 0,
  },
  isMonthSelecting: {
    type: Boolean,
    default: false,
  },
  isYearSelecting: {
    type: Boolean,
    default: false,
  },
})

const emits = defineEmits<{(e: 'update:isMonthSelecting', value: boolean): void,
  (e: 'update:isYearSelecting', value: boolean): void
}>()

const months = Object.values(MONTH)

const isMonthSelecting = computed({
  get: () => props.isMonthSelecting,
  set: value => {
    emits('update:isMonthSelecting', value)
  },
})
const isYearSelecting = computed({
  get: () => props.isYearSelecting,
  set: value => {
    emits('update:isYearSelecting', value)
  },
})

const prevPage = () => {
  if (props.calendarRef?.movePrev) {
    props.calendarRef.movePrev()
  } else {
    props.calendarRef?.calendarRef.movePrev()
  }
}

const nextPage = () => {
  if (props.calendarRef?.moveNext) {
    props.calendarRef.moveNext()
  } else {
    props.calendarRef?.calendarRef.moveNext()
  }
}
</script>

<template>
  <div class="flex justify-between items-center">
    <div class="flex items-center">
      <custom-button
        variant="withoutBorders"
        :class="['font-medium text-[18px] flex', {'!text-neutral-950 hover:!text-primary': !isMonthSelecting}]"
        @click="isMonthSelecting = !isMonthSelecting; isYearSelecting = false"
      >
        <span>{{ $t(`common.months.full.${months[selectedMonth - 1]}`) }}</span>
        <client-only>
          <font-awesome-icon
            size="sm"
            :icon="['fas', isMonthSelecting ? 'chevron-up' : 'chevron-down']"
            class="ml-2 text-[15px]"
          />
        </client-only>
      </custom-button>
      <custom-button
        variant="withoutBorders"
        :class="['font-medium text-[18px] flex', {'!text-neutral-950 hover:!text-primary': !isYearSelecting}]"
        @click="isYearSelecting = !isYearSelecting; isMonthSelecting = false"
      >
        <span>{{ selectedYear }}</span>
        <client-only>
          <font-awesome-icon
            size="sm"
            :icon="['fas', isYearSelecting ? 'chevron-up' : 'chevron-down']"
            class="ml-2 text-[15px]"
          />
        </client-only>
      </custom-button>
    </div>
    <div class="flex gap-x-3 items-center">
      <custom-button
        class="w-[24px] h-[24px] text-neutral-950 hover:text-primary active:text-primary-dark"
        variant="withoutBorders"
        icon="common/arrows/chevron-left"
        icon-class="text-[15px]"
        size="none"
        color="none"
        @click="prevPage"
      >
        <client-only>
          <font-awesome-icon
            size="sm"
            :icon="['fas', 'chevron-left']"
            class="ml-2"
          />
        </client-only>
      </custom-button>
      <custom-button
        class="w-[24px] h-[24px] text-neutral-950 hover:text-primary active:text-primary-dark"
        variant="withoutBorders"
        icon="common/arrows/chevron-right"
        icon-class="text-[15px]"
        size="none"
        color="none"
        @click="nextPage"
      >
        <client-only>
          <font-awesome-icon
            size="sm"
            :icon="['fas', 'chevron-right']"
            class="ml-2"
          />
        </client-only>
      </custom-button>
    </div>
  </div>
</template>
