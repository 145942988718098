<template>
  <custom-tooltip placement="bottom">
    <template #content>
      {{ $t('building.actions.addToSuggestion.label') }}
    </template>
    <custom-button-icon
      color="secondary"
      :class="buttonClass"
      icon="common/circle-plus-regular"
      round
      @click="showAuthModalOrCallback(addToSuggestion)"
    />
  </custom-tooltip>
</template>

<script setup lang="ts">
import type { Flat } from '~/common/types/flat/Flat'
import { useBuildingStore } from '~/modules/building/BuildingModule'
import CustomButtonIcon from '~/ui/buttons/CustomButtonIcon.vue'
import { showAuthModalOrCallback } from '~/modules/auth/helpers/showAuthModalOrCallback'
import CustomTooltip from '~/ui/tooltips/CustomTooltip.vue'

const props = defineProps({
  flat: {
    type: Object as PropType<Flat>,
    required: true,
  },
  withDefaultClick: {
    type: Boolean,
    default: true,
  },
  buttonClass: {
    type: [String, Object, Array],
    default: '',
  },
})

const emits = defineEmits<{(e: 'click'): void}>()

const store = useBuildingStore()

const addToSuggestion = () => {
  if (props.withDefaultClick) {
    store.flatsToAddToSuggestion.push(props.flat as Flat)
    store.isDisplaySuggestionsModal = true
  } else {
    emits('click')
  }
}
</script>
