<template>
  <custom-card
    id="buildingItem"
    ref="buildingItem"
    class="w-full flex flex-col"
  >
    <building-carousel
      class="-mx-6 -mt-6"
      view-actions-variant="inModal"
      :bottom-rounded="false"
      :images="building.images"
      :video-url="building.videoUrl"
      :building="building"
      :is-display-on-map-show="isDisplayOnMapShow"
      is-full-screen
      :is-open-modal-on-click="false"
      @on-click-by-slide="openBuildingOrDisplayAuthModal"
    />
    <nuxt-link
      :class="linkClass"
      @click.stop="openBuildingOrDisplayAuthModal"
    >
      <div class="mt-6 flex justify-between gap-4 flex-wrap">
        <div>
          <building-grid-item-name
            :name="getEntityName({ name: building.name, internationalName: building.internationalName, entityCountry: building.address?.countryIsoCode })"
            :building-id="building.id"
          />
          <building-grid-item-address :address="building.address" />
        </div>
        <div>
          <building-grid-item-houses
            :houses="building.houses"
            :class="{'mb-1': building.developer}"
          >
            <template #text="{housesLength}">
              <slot
                name="housesText"
                :houses-length="housesLength"
              />
            </template>
          </building-grid-item-houses>
          <building-grid-item-developer
            v-if="building.developer"
            :building="building"
          />
        </div>
      </div>
      <building-grid-item-time-constraints
        :dead-line="building.deadLine"
        :sales-start="building.salesStart"
        :keys-getting="building.keysGetting"
        class="mt-5"
        :class="{'mb-6': !building.metro.length && !hasFlats}"
      />
      <building-flats-info
        v-if="hasFlats"
        :flats-info="building.flatsInfo"
        class="mt-5"
        :class="{'mb-6': !building.metro.length}"
      />
      <building-metro-info
        v-if="building.metro.length"
        :metro-info="building.metro"
        class="mt-5 mb-6"
      />
    </nuxt-link>
    <slot name="footer">
      <div class="flex items-center justify-between">
        <building-actions-btns
          :building="building"
          class="mt-auto"
        />
        <popularity-icon
          v-if="!appStateStore.clientMode"
          :suggestions-additions-count="building.suggestionsAdditionsCount"
          :total-suggestions-additions-count="building.totalSuggestionsAdditionsCount"
        />
      </div>
    </slot>
  </custom-card>
</template>

<script setup lang="ts">
import CustomCard from '~/ui/cards/CustomCard.vue'
import BuildingGridItemName from '~/modules/building/components/grid-item/BuildingGridItemName.vue'
import BuildingGridItemAddress from '~/modules/building/components/grid-item/BuildingGridItemAddress.vue'
import BuildingCarousel from '~/modules/building/components/carousel/BuildingCarousel.vue'
import BuildingFlatsInfo from '~/modules/building/components/grid-item/BuildingFlatsInfo.vue'
import BuildingMetroInfo from '~/modules/building/components/grid-item/metro/BuildingMetroInfo.vue'
import BuildingGridItemTimeConstraints
  from '~/modules/building/components/grid-item/BuildingGridItemTimeConstraints.vue'
import BuildingGridItemHouses from '~/modules/building/components/grid-item/BuildingGridItemHouses.vue'
import BuildingGridItemDeveloper from '~/modules/building/components/grid-item/BuildingGridItemDeveloper.vue'
import BuildingActionsBtns from '~/modules/building/components/actions/BuildingActionsBtns.vue'
import { BuildingExpanded } from '~/modules/building/types/Building.expanded'
import { useMarketEntitiesActionsStore } from '~/modules/market-entities-actions/MarketEntitiesActionsModule'
import { ROUTES_NAMES } from '~/router/constants/Router.routesNames'
import { showAuthModalOrCallback } from '~/modules/auth/helpers/showAuthModalOrCallback'
import { getEntityName } from '~/common/helpers/getEntityName'
import { useAppStateStore } from '~/store/app'
import PopularityIcon from '~/components/common/PopularityIcon.vue'

const props = defineProps({
  building: {
    type: Object as PropType<BuildingExpanded>,
    required: true,
  },
  linkClass: {
    type: [String, Object, Array],
    default: 'grow',
  },
  isDisplayOnMapShow: {
    type: Boolean,
    default: true,
  },
  linkTo: {
    type: Object as PropType<Record<string, any> | null>,
    default: null,
  },
  checkAuthorizationForLinkOpening: {
    type: Boolean,
    default: true,
  },
})

const appStateStore = useAppStateStore()

provide('flatAddressCountryCode', props.building.address?.countryIsoCode)

const flatFilterString = computed(() => JSON.stringify(useMarketEntitiesActionsStore().filter.flatFilter))

const hasFlats = computed(() => props.building?.flatsInfo?.some(info => info.count))

const openBuildingOrDisplayAuthModal = (): void => {
  const linkParams = props.linkTo || { name: ROUTES_NAMES.buildingPage, params: { id: props.building.id }, query: { flatFilter: flatFilterString.value } }

  const openLink = appStateStore.isWeb ? () => window.open(`${window.location.origin}${useRouter().resolve(linkParams).href}`, '_blank') : () => useRouter().push(linkParams)

  if (props.checkAuthorizationForLinkOpening) {
    showAuthModalOrCallback(openLink, () => event?.preventDefault())
  } else {
    openLink()
  }
}
</script>
