import { App } from '@capacitor/app'

export const useGoBackMobilePlatformListener = () => {
  App.addListener('backButton', data => {
    if (data.canGoBack) {
      useRouter().back()
    } else {
      App.exitApp()
    }
  })
}
