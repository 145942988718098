import { HttpApiService } from '~/services/http/HttpApiService'
import { HttpHandledResponse } from '~/services/http/types/Http.handledResponse'
import { Service } from '~/services/http/Service'
import type { Developer } from '~/common/types/developer/Developer'
import type { HttpPaginatedResponseData } from '~/services/http/types/Http.paginatedResponseData'

export class DeveloperHttpApiService extends Service {
  private endPoints = {
    firstPart: '/v4',
    estateMarket: '/estate-market',
    development: '/development',
    developers: '/developers',
    search: '/search',
  }

  constructor(httpInstance: HttpApiService) {
    super(httpInstance)
  }

  getDevelopers(filter: Pick<Developer, 'name'>, pageUrl?: string | null): Promise<HttpHandledResponse<HttpPaginatedResponseData<Developer>>> {
    const payload = pageUrl ? { ...filter, page: new URL(pageUrl).searchParams.get('page') } : filter
    return this.httpInstance.post<HttpPaginatedResponseData<Developer>>(`${this.endPoints.firstPart}${this.endPoints.development}${this.endPoints.developers}${this.endPoints.search}`, payload)
  }

  getDeveloper(id: number): Promise<HttpHandledResponse<Developer>> {
    return this.httpInstance.get(`${this.endPoints.firstPart}${this.endPoints.estateMarket}${this.endPoints.development}${this.endPoints.developers}/${id}`)
  }
}
