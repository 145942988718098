import { FLAT_STATUS } from '~/common/constants/flat/Flat.status'

export const FLAT_STATUS_CLASSES: Record<keyof typeof FLAT_STATUS, {bg: string, text: string}> = {
  [FLAT_STATUS.free]: {
    bg: 'bg-success-light',
    text: 'text-success',
  },
  [FLAT_STATUS.sold]: {
    bg: 'bg-white',
    text: 'text-secondary',
  },
  [FLAT_STATUS.booked]: {
    bg: 'bg-warning-light',
    text: 'text-warning',
  },
  [FLAT_STATUS.unavailable]: {
    bg: 'bg-danger-light',
    text: 'text-danger',
  },
}
